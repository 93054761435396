import axios from "config/axios";
import { EmployeeFormValues } from "views/Employee/EmployeeForm";
import { GenericListResponse, GenericPageResponse } from "shared/common";
import {
  Employee,
  PermissionGroup,
  RelDepartmentEmployee,
  RelEmployeeEmployee,
  Tool,
} from "shared/types";

const ENDPOINT = "/employee";

export const addEmployee = (values: EmployeeFormValues, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, {
    employeeEntry: values.employeeEntry,
    permanentAddress: values.permanentAddress,
    temporaryAddress: values.temporaryAddress,
    postAddress: values.postAddress,
    tenantId,
  }); //EMPLOYEE_ADMIN

export const modifyEmployee = (values: EmployeeFormValues, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, {
    employeeEntry: values.employeeEntry,
    permanentAddress: values.permanentAddress,
    temporaryAddress: values.temporaryAddress,
    postAddress: values.postAddress,
    tenantId,
  }); //EMPLOYEE_ADMIN

export const listEmployee = (tenantId: number) =>
  axios.post<GenericListResponse<Employee>>(`${ENDPOINT}/all`, { tenantId }); //EMPLOYEE_GET

export const getEmployeeById = (param: string, tenantId: number) =>
  axios.post<{
    employee: Employee;
    relDepartmentEmployeeList: RelDepartmentEmployee[];
  }>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //EMPLOYEE_GET

export const getEmployeeListPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Employee>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //EMPLOYEE_GET

export const listSubordinatesOfSuperiorEmployee = (tenantId: number, param: string) =>
  axios.post<GenericListResponse<RelEmployeeEmployee>>(
    `${ENDPOINT}/list-subordinates-of-superior`,
    { param, tenantId },
  ); //EMPLOYEE_GET

export const listSuperiorsOfSubordinateEmployee = (tenantId: number, param: string) =>
  axios.post<GenericListResponse<RelEmployeeEmployee>>(
    `${ENDPOINT}/list-superiors-of-subordinate`,
    { param, tenantId },
  ); //EMPLOYEE_GET

export const addToolToEmployee = (employeeId: string, toolList: Tool[], tenantId: number) =>
  axios.post(`${ENDPOINT}/add-tool`, { employeeId, toolList, tenantId }); //EMPLOYEE_ADMIN

export const removeToolToEmployee = (employeeId: string, toolId: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/remove-tool`, { employeeId, toolId, tenantId }); //EMPLOYEE_ADMIN

export const listPermissionGroupByEmployee = (param: string, tenantId: number) =>
  axios.post<GenericListResponse<PermissionGroup>>(`${ENDPOINT}/list-permission-by-employee`, {
    param,
    tenantId,
  });
