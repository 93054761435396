import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useHeader } from "components/Layout/HeaderContext";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router";

const HaccpItemQrReader = () => {
  const { t } = useTranslation();
  const { setTopPadding } = useHeader();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [openScanner, setOpenScanner] = useState(true);

  const handleScan = (data: string | null) => {
    if (data) {
      history.push(data);
    }
  };

  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="center">
        <Button
          startIcon={
            <FontAwesomeIcon
              icon={faQrcode}
              style={{
                fontSize: 24,
                width: 24,
              }}
            />
          }
          onClick={() => setOpenScanner(true)}
        >
          Olvasó megnyitása
        </Button>
      </Box>
      <Dialog fullWidth maxWidth="sm" open={openScanner} onClose={() => setOpenScanner(false)}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <span>Hűtő QR-kód beolvasása</span>
            <IconButton onClick={() => setOpenScanner(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%" pb={5}>
            <Box width="100%">
              <QrReader
                onError={() =>
                  enqueueSnackbar("Kérem engedélyezze a hozzáférést a kamerához!", {
                    variant: "warning",
                  })
                }
                onScan={handleScan}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default HaccpItemQrReader;
