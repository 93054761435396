import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { GridColDef } from "@mui/x-data-grid";
import { COLORS } from "config/theme";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Filter, FilterOptionsEntry } from "./FilterBar";
import { FilterOptions } from "./PageableTable";

type Props = {
  anchorEl: HTMLDivElement | HTMLButtonElement | null;
  onClose: () => void;
  filterState: Filter[] | null;
  setFilterState: Dispatch<SetStateAction<Filter[] | null>>;
  columns: GridColDef[];
  filterable?: string[];
  filterOptions?: FilterOptionsEntry[];
  defaultValues?: Filter | null;
};

const FilterPopover = ({
  anchorEl,
  onClose,
  columns,
  filterable,
  filterOptions,
  filterState,
  setFilterState,
  defaultValues,
}: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  const { handleSubmit, setValue, watch } = form;
  const type = watch("column")?.type;
  const name = watch("column")?.field;

  const [activeFilterOptions, setActiveFilterOptions] = useState<FilterOptions[]>([]);

  const formReset = () => {
    onClose();
    setValue("column", null);
    setValue("value", "");
    setValue("valueFrom", null);
    setValue("valueTo", null);
  };

  useEffect(() => {
    setActiveFilterOptions(filterOptions?.find(entry => entry.columnName === name)?.options || []);
  }, [setActiveFilterOptions, name, filterOptions]);

  function onSubmit(values: { column: any; value: string }) {
    if (values) {
      if (defaultValues) {
        setFilterState(prevState => {
          if (prevState) {
            let temp = [...prevState];
            const index = prevState.findIndex(state => state.column.field === values.column.field);
            temp[index] = values;
            return temp;
          } else {
            return [values];
          }
        });
      } else {
        setFilterState(prevState => {
          if (prevState) {
            return [...prevState, values];
          } else {
            return [values];
          }
        });
      }
    }
    formReset();
  }

  useEffect(() => {
    setValue("column", defaultValues?.column || null);
    setValue("value", defaultValues?.value || "");
    setValue("valueFrom", defaultValues?.valueFrom || null);
    setValue("valueTo", defaultValues?.valueTo || null);
  }, [
    setValue,
    defaultValues?.column,
    defaultValues?.value,
    defaultValues?.valueFrom,
    defaultValues?.valueTo,
  ]);

  return (
    <Popover open={!!anchorEl} onClose={formReset} anchorEl={anchorEl}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} id="filter-form">
          <Card style={{ border: "unset", maxWidth: 250 }}>
            <CardHeader
              style={{ paddingTop: 12, paddingBottom: 0 }}
              title={
                <Box display="flex" alignItems="center" gridGap={12}>
                  <FontAwesomeIcon
                    icon={faFilter}
                    style={{
                      fontSize: 20,
                      width: 20,
                      color: COLORS.main,
                    }}
                  />
                  <Typography variant="h6" style={{ color: COLORS.main }}>
                    Szűrés
                  </Typography>
                </Box>
              }
              disableTypography
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="column"
                    defaultValue={null}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, value) => field.onChange(value)}
                        disabled={!!defaultValues?.column}
                        options={columns.filter(
                          col =>
                            //hide actions column
                            col.headerName !== " " &&
                            //only filterable options
                            (filterable ? filterable?.includes(col.field) : true) &&
                            //only not selected options
                            (!defaultValues
                              ? !filterState?.some(state => state.column.field === col.field)
                              : true),
                        )}
                        getOptionLabel={(option: GridColDef) => option.headerName || ""}
                        getOptionSelected={option => option.field === field.value?.field}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t("common:filter.formValues.column")}
                            InputLabelProps={{ shrink: true, required: true }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                {type === "boolean" ? (
                  <Grid item xs={12}>
                    <Controller
                      name="value"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("common:filter.formValues.value")}
                          InputLabelProps={{ shrink: true, required: true }}
                          SelectProps={{
                            displayEmpty: true,
                          }}
                          select
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        >
                          <MenuItem disabled value="">
                            {t("common:choose")}
                          </MenuItem>
                          {[
                            { translated: "Igen", value: "true" },
                            { translated: "Nem", value: "false" },
                          ].map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.translated}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                ) : type === "select" ? (
                  <Grid item xs={12}>
                    <Controller
                      name="value"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("common:filter.formValues.value")}
                          InputLabelProps={{ shrink: true, required: true }}
                          SelectProps={{
                            displayEmpty: true,
                          }}
                          select
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        >
                          <MenuItem disabled value="">
                            {t("common:choose")}
                          </MenuItem>
                          {activeFilterOptions.map(option => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              style={{
                                marginLeft: option.subItem ? 20 : undefined,
                              }}
                            >
                              {option.translated}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                ) : type === "date" || type === "dateTime" ? (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        name="valueFrom"
                        rules={{
                          required: t("validation.required").toString(),
                        }}
                        defaultValue={null}
                        render={({ field, fieldState }) => (
                          <KeyboardDatePicker
                            {...field}
                            ref={undefined}
                            variant="inline"
                            label={t("common:filter.formValues.from")}
                            format="yyyy. MM. dd."
                            InputLabelProps={{
                              shrink: true,
                              required: true,
                            }}
                            autoOk
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="valueTo"
                        rules={{
                          required: t("validation.required").toString(),
                        }}
                        defaultValue={null}
                        render={({ field, fieldState }) => (
                          <KeyboardDatePicker
                            {...field}
                            ref={undefined}
                            variant="inline"
                            label={t("common:filter.formValues.to")}
                            format="yyyy. MM. dd."
                            InputLabelProps={{
                              shrink: true,
                              required: true,
                            }}
                            autoOk
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : type === "singleDateStart" ? (
                  <Grid item xs={12}>
                    <Controller
                      name="valueFrom"
                      rules={{
                        required: t("validation.required").toString(),
                      }}
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <KeyboardDatePicker
                          {...field}
                          ref={undefined}
                          variant="inline"
                          label={t("common:filter.formValues.from")}
                          format="yyyy. MM. dd."
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          autoOk
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                ) : type === "singleDateEnd" ? (
                  <Grid item xs={12}>
                    <Controller
                      name="valueTo"
                      rules={{
                        required: t("validation.required").toString(),
                      }}
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <KeyboardDatePicker
                          {...field}
                          ref={undefined}
                          variant="inline"
                          label={t("common:filter.formValues.to")}
                          format="yyyy. MM. dd."
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          autoOk
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Controller
                      name="value"
                      rules={{
                        required: t("validation.required").toString(),
                      }}
                      defaultValue={null}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("common:filter.formValues.value")}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Box width="100%" display="flex" justifyContent="space-around">
              <Button
                disableElevation
                onClick={formReset}
                style={{
                  color: COLORS.mainLight,
                  backgroundColor: COLORS.mainGrey,
                  width: "100%",
                  borderRadius: "unset",
                }}
              >
                Mégse
              </Button>
              <Button
                disableElevation
                type="submit"
                form="filter-form"
                style={{
                  width: "100%",
                  backgroundColor: COLORS.mainLight,
                  borderRadius: "unset",
                }}
              >
                Szűrés
              </Button>
            </Box>
          </Card>
        </form>
      </FormProvider>
    </Popover>
  );
};

export default FilterPopover;
