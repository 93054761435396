import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import {
  Company,
  Contract,
  Offer,
  PaymentPeriod,
  RelContractItemEntry,
} from "shared/types";
import { ContractType } from "./contract-type";

export type ContractRequest = {
  id?: number | string;
  tenantId?: number | string;
  name: string;
  start: string;
  signDate: string;
  end: string;
  description: string;
  company: Company;
  offer?: Offer;
  identifier: string;
  type: ContractType;
  paymentDueDays: number;
  paymentType?: string;
  paymentPeriod: PaymentPeriod;
  paymentPeriodOffsetDays: number;
  deliveryPeriod: PaymentPeriod;
  deliveryPeriodOffsetDays: number;
  notificationEmail: string;
  hourlyRate: number;
  invoiceDateOfTheMonth: number;
  frequencyOfInvoiceExhibitionType: string;
  frequencyOfInvoiceExhibition: number;
  dispatchKmPrice: number;
  contractItems: RelContractItemEntry[];
};

const ENDPOINT = "/contract";

export const createContract = (
  contract: ContractRequest,
  useOfferItems: boolean,
  tenantId: number,
) => axios.post(`${ENDPOINT}`, { contract, useOfferItems, tenantId }); //CONTRACT_GET

export const createCautionContractPdf = (
  contractId: number,
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}/create-caution-contract-pdf`, {
    contractId,
    tenantId,
  }); //CONTRACT_GET

export const modifyContract = (contract: ContractRequest, tenantId: number) =>
  axios.put(`${ENDPOINT}`, {
    contract,
    relContractItems: contract.contractItems,
    tenantId,
  }); //CONTRACT_GET

export const listContract = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Contract>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //CONTRACT_GET

export const getContractById = (tenantId: number, id?: number | string) =>
  axios.get<GenericResponse<Contract>>(
    `${ENDPOINT}/by-id?contractId=${id}&tenantId=${tenantId}`,
  ); //LOGIN_REQUIRED
