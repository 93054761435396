import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Clear, Done, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listIncomingInvoice } from "shared/network/incoming-invoice.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const IncomingInvoiceList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const isCompany = location.pathname.includes("/companies");
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");
  const isInvoices = location.pathname.includes("/invoices");

  const history = useHistory();
  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-tenantincominginvoice-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-tenantincominginvoice-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const invoiceListQuery = useQuery(
    ["IncomingInvoiceListQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await listIncomingInvoice(
        page,
        pageSize,
        tenant.id,
        (!!account.user.relUserCompany
          ? account?.user?.relUserCompany?.[0]?.company.name
            ? `supplier:${account?.user?.relUserCompany?.[0]?.company.name};`
            : ""
          : "") + filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "serialNumber",
      headerName: t("invoice.serialNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "supplierCompanyName",
      headerName: t("invoice.formValues.supplier.supplierSelect"),
      flex: 1.25,
    },
    {
      field: "creationDate",
      headerName: t("invoice.formValues.creationDate"),
      flex: 0.75,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.creationDate), "yyyy. MM. dd."),
      type: "date",
    },
    {
      //bejövő számla
      field: "grossPrice",
      headerName: t("invoice.invoiceItem.grossPrice"),
      flex: 0.75,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.grossPrice }) + " Ft",
    },

    {
      field: "isPaid",
      headerName: t("incomingInvoice.isPaid.title"),
      flex: 0.75,
      type: "select",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isPaid ? (
          <Tooltip title={t(`incomingInvoice.isPaid.true`).toString()}>
            <Done style={{ color: COLORS.green }} />
          </Tooltip>
        ) : (
          <Tooltip title={t(`incomingInvoice.isPaid.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_GET"]) && (
            <Tooltip title={t("invoice.details").toString()}>
              <IconButton
                component={Link}
                to={
                  isCompany
                    ? `/companies/${
                        isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                      }/company-details/incoming-invoices/incoming-invoice-details?id=${
                        row.id
                      }&backId=${id}`
                    : isInvoices
                    ? `/invoices/incoming-invoice/invoice-details?id=${row.id}`
                    : ``
                }
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Tooltip title={t("invoice.modify").toString()}>
              <IconButton
                component={Link}
                to={
                  isCompany
                    ? `/companies/${
                        isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                      }/company-details/incoming-invoices/incoming-invoice-details?id=${
                        row.id
                      }&backId=${id}`
                    : isInvoices
                    ? `/invoices/incoming-invoice/invoice-modify?id=${row.id}`
                    : ``
                }
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) && (
          <Button
            component={Link}
            to={
              isCompany
                ? `/companies/${
                    isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                  }/company-details/incoming-invoices/incoming-invoice-create?id=${id}`
                : isInvoices
                ? `/invoices/incoming-invoice/invoice-create`
                : ``
            }
            startIcon={<AddBox />}
          >
            {t("incomingInvoice.create")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(
        isCompany
          ? `/companies/${
              isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
            }/company-details/incoming-invoices/incoming-invoice-details?id=${
              params.row.id
            }&backId=${id}`
          : isInvoices
          ? `/invoices/incoming-invoice/invoice-details?id=${params.row.id}`
          : ``,
      );
    }
  }

  return (
    <PageableTable
      filterable={["serialNumber", "supplierCompanyName", "creationDate", "isPaid"]}
      filterOptions={[
        {
          columnName: "isPaid",
          options: [
            {
              translated: t(`incomingInvoice.isPaid.true`),
              value: "true",
            },
            {
              translated: t(`incomingInvoice.isPaid.false`),
              value: "false",
            },
          ],
        },
      ]}
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="tenantincominginvoice"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={invoiceListQuery}
      onCellClick={onCellClick}
    />
  );
};
export default IncomingInvoiceList;
