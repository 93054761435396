import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, AddCircle, Delete, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import NumberField from "components/NumberField";
import { NUMBER_FORMAT } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listProject } from "shared/network/project.api";
import { BaseCurrency, Company, Item, Offer, Project } from "shared/types";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import { OfferFormValues } from "./OfferForm";
import { round } from "lodash";
import { id } from "date-fns/locale";
import { getAllScalingY } from "shared/network/scaling-y.api";
import { ScalingPrice, getAllScalingPrice } from "shared/network/scaling-price";
import { getAllScalingX } from "shared/network/scaling-x.api";

export type Props = {
  item: Item | null;
  setItem: any;
  inputName: string;
  inputNameX: string;
  inputNameY: string;
  isIncoming: boolean;
  onSuccess?: () => void;
};

const OfferItemScalingSelect = ({
  item,
  setItem,
  inputName,
  inputNameX,
  inputNameY,
  isIncoming,
  onSuccess,
}: Props) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<OfferFormValues>();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const [values, setValues] = useState<ScalingPrice[][]>([]);

  const getAllScalingXQuery = useQuery(["getAllScalingX", item?.id, tenant.id], async () => {
    if (item?.id) {
      const { data } = await getAllScalingX(Number(item.id), true, tenant.id);
      return data.items?.sort((a, b) => Number(a.valueFrom) - Number(b.valueFrom));
    }
    return Promise.reject();
  });
  const scalingXList = getAllScalingXQuery.data;

  const getAllScalingYQuery = useQuery(["getAllScalingY", item?.id, tenant.id], async () => {
    if (item?.id) {
      const { data } = await getAllScalingY(Number(item.id), true, tenant.id);
      return data.items?.sort((a, b) => Number(a.valueFrom) - Number(b.valueFrom));
    }
    return Promise.reject();
  });
  const scalingYList = getAllScalingYQuery.data;

  useQuery(
    ["getAllScalingPrice", item?.id, tenant.id],
    async () => {
      if (item?.id) {
        const { data } = await getAllScalingPrice(
          Number(item.id),
          new Date().toISOString(),
          tenant.id,
          isIncoming,
        );
        return data.items;
      }
      return Promise.reject();
    },
    {
      enabled: !!scalingXList?.length && !!scalingYList?.length,
      onSuccess: data => {
        let matrix: any = [];
        if (scalingXList && scalingXList?.length && scalingYList?.length) {
          for (let i = 0; i < scalingYList.length; i++) {
            matrix[i] = [];
            for (let j = 0; j < scalingXList.length; j++) {
              matrix[i][j] = null;
            }
          }
        }

        data.forEach(value => {
          const xIndex = scalingXList?.findIndex(x => {
            return x.id === value.scalingX.id;
          });
          const yIndex = scalingYList?.findIndex(y => {
            return y.id === value.scalingY.id;
          });

          if (yIndex !== undefined && yIndex >= 0 && xIndex !== undefined && xIndex >= 0) {
            matrix[yIndex][xIndex] = value;
          }
        });
        setValues(matrix);
      },
    },
  );

  return (
    <Dialog maxWidth="md" fullWidth open={!!item} onClose={() => setItem(null)}>
      <DialogTitle>Árválasztás</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          gridGap={8}
          p={1}
          style={{ height: 60, borderBottom: `1px solid rgba(0, 0, 0, 0.12)` }}
        >
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>{item?.scalingXName}</Typography>
        </Box>
        <Box overflow="auto">
          <Box display="flex">
            <Box
              minWidth={160}
              height={55}
              style={{
                borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
                borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
                backgroundColor: "white",
              }}
            />
            <Box display="flex">
              {scalingXList?.map((scalingX, index, array) => (
                <Box
                  key={scalingX?.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  minWidth={100}
                  height={55}
                  paddingLeft={2}
                  textAlign="center"
                  borderBottom={`1px solid rgba(0, 0, 0, 0.12)`}
                  borderRight={
                    array?.length !== index + 1 ? `1px solid rgba(0, 0, 0, 0.12)` : "unset"
                  }
                >
                  <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                    {scalingX?.valueFrom}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box display="flex">
            <Box
              display="flex"
              align-items="center"
              justifyContent="center"
              flexDirection="column"
              gridGap={8}
              p={2}
              width={60}
              minHeight={200}
              borderRight={`1px solid rgba(0, 0, 0, 0.12)`}
              style={{
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="row"
                gridGap={8}
                style={{
                  transform: "rotate(-90deg)",
                }}
              >
                <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                  {item?.scalingYName}
                </Typography>
              </Box>
            </Box>
            <Box>
              {scalingYList?.map((scalingY, YIndex, YArray) => (
                <Box display="flex" key={scalingY?.id}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    paddingLeft={1}
                    alignItems="center"
                    width={100}
                    height={55}
                    textAlign="center"
                    style={{
                      borderBottom:
                        YArray?.length !== YIndex + 1 ? `1px solid rgba(0, 0, 0, 0.12)` : "unset",
                      borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
                      backgroundColor: "white",
                      zIndex: 1,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                      {scalingY?.valueFrom}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    {getAllScalingXQuery?.data?.map((scalingX, index, array) => (
                      <Box
                        key={scalingX?.id}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={100}
                        height={55}
                        textAlign="center"
                        style={{
                          borderRight:
                            array?.length !== index + 1 ? `1px solid rgba(0, 0, 0, 0.12)` : "unset",
                          borderBottom:
                            YArray?.length !== YIndex + 1
                              ? `1px solid rgba(0, 0, 0, 0.12)`
                              : "unset",
                        }}
                      >
                        {values?.[YIndex]?.[index] ? (
                          <Button
                            variant="outlined"
                            style={{ width: "100%", margin: 8 }}
                            onClick={() => {
                              //@ts-ignore
                              setValue(inputName, values?.[YIndex]?.[index]?.price);
                              //@ts-ignore
                              setValue(inputNameX, values?.[YIndex]?.[index]?.scalingX);
                              //@ts-ignore
                              setValue(inputNameY, values?.[YIndex]?.[index]?.scalingY);
                              setItem(null);
                              onSuccess?.();
                            }}
                          >
                            {values?.[YIndex]?.[index]?.price}
                          </Button>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setItem(null)}>
          {t("common:button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default OfferItemScalingSelect;
