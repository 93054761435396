import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Check, Close } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import { NUMBER_FORMAT } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { listCargoForVehiclePageable } from "shared/network/cargo.api";
import { getDeliveryById, modifyDelivery } from "shared/network/delivery.api";
import { getItemListWithWrappingByCompanySite } from "shared/network/rel-item-company-site";
import { listSerialItemsForWarehouse } from "shared/network/serial-item.api";
import { listItemsInWarehousePageable } from "shared/network/warehouse.api";
import { BaseCurrency, Item, RelItemDelivery, SerialItemEntry } from "shared/types";
import DeliveryItemsRow from "./DeliveryItemRow";

const useStyles = makeStyles({
  header: {
    alignItems: "center",
    border: `1px solid ${COLORS.mainGrey}`,
    background: COLORS.mainGrey,
    height: 60,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  divider: {
    marginTop: 8,
  },
  footy: {
    alignItems: "center",
    border: `1px solid ${COLORS.mainGrey}`,
    background: COLORS.mainGrey,
    height: 60,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  bodyMain: {
    borderLeft: `1px solid ${COLORS.mainGrey}`,
    borderRight: `1px solid ${COLORS.mainGrey}`,
  },
  bodyCollapse: {
    borderLeft: `1px solid ${COLORS.mainGrey}`,
    borderRight: `1px solid ${COLORS.mainGrey}`,
  },
});

export type DeliveryDateFormValues = {
  date: Date;
};

export type DeliveryItemFormValues = {
  item: Item | null;
  quantity: number | string | null;
  price: number | string | null;
  factoryNumber: SerialItemEntry | null;
};
export type Props = {
  deliveryId: number | null;
  setDeliveryId: Dispatch<SetStateAction<number | null>>;
};

const DeliveryItemDialog = ({ deliveryId, setDeliveryId }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const form = useForm<DeliveryItemFormValues>();
  const { setValue, watch, clearErrors, handleSubmit, reset } = form;
  const selectedItem = watch("item");
  const selectedItemIsSerial = selectedItem?.type === "SERIAL_NUMBER";
  const [loading, setLoading] = useState(false);
  const [itemPrice, setItemPrice] = useState("");
  const [itemCurrency, setItemCurrency] = useState<BaseCurrency>();
  const [deliveryType, setDeliveryType] = useState<
    "NORMAL" | "PICK_UP_WRAPPING" | "PICK_UP" | null
  >(null);
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const deliveryQuery = useQuery(
    "deliveryDeliveryItemDialog",
    async () => {
      if (deliveryId) {
        const { data } = await getDeliveryById(deliveryId, tenant.id);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!deliveryId },
  );
  const delivery = deliveryQuery?.data;

  const itemListQuery = useQuery(
    ["itemList", selectedRelTenant.tenant.id, delivery, deliveryType],
    async () => {
      if (delivery) {
        if (deliveryType === "NORMAL") {
          if (delivery?.status === "ACTIVE") {
            const { data } = await listItemsInWarehousePageable(
              0,
              1000000,
              selectedRelTenant.tenant.id,
              `warehouseId:${delivery?.fromWarehouse?.id};date=${format(new Date(), "yyyy-MM-dd")}`,
            );
            return { ...data.page, content: data.page.content.map(value => value.item) };
          } else if (delivery?.status === "IN_PROGRESS") {
            const { data } = await listCargoForVehiclePageable(
              0,
              1000000,
              delivery.vehicleId,
              selectedRelTenant.tenant.id,
              "quantity!=0",
            );

            return { ...data.page, content: data.page.content.map(value => value.item) };
          }
        } else if (deliveryType === "PICK_UP_WRAPPING" || deliveryType === "PICK_UP") {
          const { data } = await getItemListWithWrappingByCompanySite(
            0,
            1000000,
            delivery?.companySite,
            selectedRelTenant.tenant.id,
          );

          return { ...data.page, content: data.page.content.map(value => value.item) };
        }
      }

      return Promise.reject();
    },
    { enabled: !!delivery && !!deliveryType },
  );

  const avaibleSerialItemList = useQuery(
    ["serialItemListDeliveryItemDialog", selectedRelTenant.tenant.id, selectedItem],
    async () => {
      const { data } = await listSerialItemsForWarehouse(
        0,
        100000,
        selectedRelTenant.tenant.id,
        selectedItem?.id.toString() || "0",
        delivery?.fromWarehouse?.id.toString() || "0",
      );
      return data.page.content;
    },
    { enabled: !!selectedItem },
  );

  async function onSubmit(values: DeliveryItemFormValues) {
    setLoading(true);
    try {
      if (delivery) {
        await modifyDelivery(
          {
            deliveryEntry: delivery,
            relList: [
              ...(delivery?.relItemDeliveryItems?.map(item => {
                return { ...item, deliveryId: delivery.id };
              }) || []),
              {
                ...values,
                quantity: selectedItemIsSerial ? 1 : values.quantity,
                deliveryId: delivery.id,
                itemSerial: values.factoryNumber || undefined,
                type: deliveryType,
              },
            ],
          },
          selectedRelTenant.tenant.id,
        );
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("deliveryItem.subject"),
          }),
          { variant: "success" },
        );
        reset();
        setDeliveryType(null);
        deliveryQuery.refetch();
      } else {
        throw new Error();
      }
    } catch (error: any) {
      if (error.data.status === "DELIVERY_CANNOT_BE_MODIFIED") {
        enqueueSnackbar("A szállítást már nem lehet módosítani!", { variant: "error" });
      } else if ((error.data.status as string)?.includes("INSUFFICIENT_QUANTITY")) {
        enqueueSnackbar("Nincs elég termék!", { variant: "error" });
      } else {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("deliveryItem.subject"),
          }),
          { variant: "error" },
        );
      }
    }
    setLoading(false);
  }

  const onClose = async () => {
    setValue("item", null);
    setValue("quantity", "");
    setValue("price", "");
    setItemPrice("");
    setItemCurrency(undefined);
    clearErrors();
    setDeliveryType(null);
  };

  useEffect(() => {
    if (itemPrice) {
      setValue("price", itemPrice);
    }
  }, [itemPrice, setValue]);

  return (
    <Dialog
      open={!!deliveryId}
      onClose={() => {
        setDeliveryId(null);
        onClose();
      }}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>{t("delivery.itemListTitle")}</DialogTitle>
      <DialogContent>
        {!delivery?.relItemDeliveryItems?.length ? (
          <Box display="flex" justifyContent="center">
            <Typography className={classes.text}>{t("delivery.noItem")}</Typography>
          </Box>
        ) : (
          <>
            <Loading open={loading || deliveryQuery.isFetching} />
            <Grid
              container
              style={{ padding: "8px 0", minWidth: "525px" }}
              className={classes.header}
            >
              <Grid item xs={4} className={classes.cell}>
                <Typography className={classes.text} variant="body2">
                  {t("item.name")}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.cell}>
                <Typography className={classes.text} variant="body2">
                  Típus
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.cell}>
                <Typography className={classes.text} variant="body2">
                  {t("item.factoryNumber")}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.cell}>
                <Typography className={classes.text} variant="body2">
                  {t("delivery.quantityTitle")}
                </Typography>
              </Grid>
            </Grid>
            <Box className={classes.bodyMain} style={{ minWidth: "525px" }}>
              {delivery?.relItemDeliveryItems?.map((relItem: RelItemDelivery) => (
                <DeliveryItemsRow
                  key={relItem.id}
                  deliveryItem={{
                    ...relItem,
                    deliveryId: delivery.id,
                  }}
                  deliveryStatus={delivery.status}
                  refetch={() => {
                    deliveryQuery.refetch();
                    itemListQuery.refetch();
                  }}
                  queryLoading={itemListQuery.isFetching}
                  warehouseId={delivery?.fromWarehouse?.id.toString()}
                  itemList={delivery?.relItemDeliveryItems || []}
                  selectedDelivery={delivery}
                />
              ))}
            </Box>
          </>
        )}
        <Collapse
          in={!!deliveryType}
          className={classes.bodyCollapse}
          style={{ paddingLeft: 8, paddingRight: 8 }}
        >
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container alignItems="center" spacing={2} style={{ padding: "16px 0" }}>
                <Grid item xs={4}>
                  <Controller
                    name="item"
                    defaultValue={null}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        options={itemListQuery?.data?.content || []}
                        getOptionLabel={(option: Item) => {
                          if (option.name && option.productCode) {
                            return `${option.name} (${option.productCode})`;
                          }
                          return "";
                        }}
                        getOptionSelected={option => option.id === field.value?.id}
                        onChange={(_, value) => {
                          field.onChange(value);
                          if (value?.itemPriceHistories) {
                            setItemPrice(
                              value.itemPriceHistories[
                                value.itemPriceHistories.length - 1
                              ]?.price?.toString(),
                            );
                          }
                          setItemCurrency(selectedRelTenant?.tenant?.baseCurrency);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t("delivery.formValues.itemNameOrProductNumber")}
                            InputLabelProps={{
                              shrink: true,
                              required: true,
                            }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingLeft: 8 }}>
                  {deliveryType === "NORMAL" && "Leadás"}
                  {(deliveryType === "PICK_UP_WRAPPING" || deliveryType === "PICK_UP") &&
                    "Felvétel"}
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name="price"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("delivery.formValues.priceNetPerPiece")}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: itemCurrency?.postfix?.toString(),
                          startAdornment: itemCurrency?.prefix?.toString(),
                        }}
                        disabled
                      />
                    )}
                  />
                </Grid>
                {selectedItemIsSerial && (
                  <Grid item xs={2}>
                    <Controller
                      name="factoryNumber"
                      rules={{
                        required: t("validation.required").toString(),
                      }}
                      defaultValue={null}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          onChange={(_, value) => field.onChange(value)}
                          options={avaibleSerialItemList.data || []}
                          getOptionLabel={(option: SerialItemEntry) => option.factoryNumber}
                          getOptionSelected={option => option.id === field.value?.id}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={t("warehouse.transaction.formValues.factoryNumber")}
                              InputLabelProps={{
                                shrink: true,
                                required: true,
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                )}
                {!selectedItemIsSerial && (
                  <Grid item xs={2}>
                    <Controller
                      name="quantity"
                      defaultValue=""
                      rules={{
                        required: t("validation.required").toString(),
                        validate: value => {
                          if (value && !value.toString().match(NUMBER_FORMAT)) {
                            return t("common:validation.numberFormat").toString();
                          }
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("delivery.formValues.quantity")}
                          InputLabelProps={{ shrink: true, required: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={2} container justifyContent="center">
                  <Box display="flex" gridGap={8}>
                    <Tooltip title={t("delivery.formValues.addItem").toString()}>
                      <IconButton size="small" color="primary" type="submit">
                        <Check />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("delivery.formValues.deleteItem").toString()}>
                      <IconButton size="small" color="primary" onClick={onClose}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Collapse>
        <Grid
          container
          style={{
            padding: "8px 0",
            borderTop: !!deliveryType ? `1px solid ${COLORS.mainGrey}` : 0,
            minWidth: 525,
          }}
          justifyContent="center"
          className={classes.footy}
        >
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "COMPANY_ADMIN",
          ]) && (
            <Box display="flex" gridGap={8}>
              <Tooltip title="Leadás a kiszállítási ponton">
                <Button
                  size="small"
                  color="primary"
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    width: 30,
                    background: COLORS.white,
                  }}
                  variant="outlined"
                  startIcon={<ArrowDownward />}
                  disabled={!!deliveryType}
                  onClick={() => setDeliveryType("NORMAL")}
                >
                  Leadás
                </Button>
              </Tooltip>
              <Tooltip title="A kiválasztott termék csomagolásának felvétele a kiszállítási pontról">
                <Button
                  size="small"
                  color="primary"
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    width: 30,
                    background: COLORS.white,
                  }}
                  variant="outlined"
                  startIcon={<ArrowUpward />}
                  disabled={!!deliveryType}
                  onClick={() => setDeliveryType("PICK_UP_WRAPPING")}
                >
                  Felvétel
                </Button>
              </Tooltip>
            </Box>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setDeliveryId(null);
            onClose();
          }}
        >
          {t("common:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryItemDialog;
