import { Box, IconButton, Typography } from "@material-ui/core";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CheckItemType } from "shared/network/check-item";
import { CheckItemsEntry, createCheckItems } from "shared/network/check-items";

type Props = {
  checkAt: string;
  checkItem: CheckItemType;
  checkItems: CheckItemsEntry | null;
  childrenCheckItems?: CheckItemsEntry[];
};

const HaccpItemCheckInputCell = ({ checkAt, checkItem, checkItems, childrenCheckItems }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  async function onClick() {
    try {
      if (checkItem?.parent) {
        await createCheckItems([{ checkAt, checkItem }], tenant?.id);
      } else {
        const childrenIds = childrenCheckItems?.map(children => children?.checkItem.id);
        const tömb: any[] = [];

        checkItem?.children?.forEach(checkItemChildren => {
          if (!childrenIds?.includes(checkItemChildren?.id)) {
            tömb.push(checkItemChildren);
          }
        });

        await createCheckItems(
          tömb.map(checkItem => {
            return { checkAt, checkItem };
          }),
          tenant?.id,
        );
      }
      queryClient.refetchQueries("checkItemPagesQuery");
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }
  }

  return (
    <Box
      style={{
        padding: 8,
        height: 50,
        width: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {!!checkItem.children?.length && childrenCheckItems?.length === checkItem.children?.length ? (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <CheckBox />
        </Box>
      ) : checkItems ? (
        <>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography>{checkItems?.createdBy?.name}</Typography>
          </Box>
        </>
      ) : (
        <IconButton color="primary" size="small" onClick={() => onClick()}>
          <CheckBoxOutlineBlank />
        </IconButton>
      )}
    </Box>
  );
};

export default HaccpItemCheckInputCell;
