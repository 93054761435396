import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import { PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY } from "config/constants";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { PropsWithChildren, forwardRef, useCallback, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  RelDailyReportCashFlow,
  createRelDailyReportCashFlow,
} from "shared/network/rel-daily-report-cash-flow.api";
import {
  RelDailyReportVehicle,
  createRelDailyReportVehicle,
} from "shared/network/rel-daily-report-vehicle.api";
import { listUsers } from "shared/network/user.api";
import { getVehicleListPageable } from "shared/network/vehicle.api";
import { User, Vehicle } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ListboxComponent } from "views/Project/components/ProjectOfferAddDialog";

type Props = {
  dailyReportId?: number;
  open: boolean;
  onClose: () => void;
  refetch: () => void;
};

type FormValues = {
  user: User | null;
  cashAmount: string;
};

const DailyReportCashFlowDialog = ({ dailyReportId, open, onClose, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const form = useForm<FormValues>();
  const { control, handleSubmit } = form;

  const [userSearch, setUserSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const { data, fetchNextPage } = useInfiniteQuery(
    [`getUserListPageableQuery`, tenant.id, userSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listUsers(
        pageParam,
        20,
        tenant.id,
        userSearch ? `;name:${userSearch}` : "",
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatPages = data?.pages.map(page => page.page?.content).flat();
  const hasUserNextPage =
    data && flatPages && (data?.pages?.[0]?.page?.totalElements || 0) > flatPages?.length;

  const handleUserSearchChange = useDebouncedCallback((value: string) => {
    setUserSearch(value);
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      if (values?.user) {
        await createRelDailyReportCashFlow(
          {
            dailyReportId,
            user: values?.user,
            cashAmount: Number(values.cashAmount),
          },
          tenant?.id,
        );
      }
      refetch();
      onClose();
      enqueueSnackbar(t("dailyReport.saveSuccess"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("dailyReport.saveError"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <Loading open={loading} />
      <DialogTitle>{t("dailyReport.addCashFlow")}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="dailyReport.addCashFlow" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="user"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      disableListWrap
                      onChange={(_, value) => {
                        field.onChange(value);
                        handleUserSearchChange("");
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleUserSearchChange(newInputValue);
                      }}
                      options={flatPages || []}
                      getOptionLabel={(option: User) => {
                        return option?.name || "";
                      }}
                      getOptionSelected={option => option.id === field.value?.id}
                      ListboxComponent={props => (
                        <ListboxComponent
                          {...props}
                          hasNextPage={hasUserNextPage}
                          fetchNextPage={async () => {
                            await fetchNextPage();
                          }}
                        />
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("dailyReport.cashFlowUser")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="cashAmount"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      margin="none"
                      type="number"
                      label={t("dailyReport.cashAmount")}
                      InputLabelProps={{ shrink: true, required: true }}
                      InputProps={{
                        endAdornment: <Typography>{tenant.baseCurrency?.code}</Typography>,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onClose()}>
          {t("common:button.cancel")}
        </Button>
        <Button form="dailyReport.addCashFlow" type="submit">
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DailyReportCashFlowDialog;
