import { Box, IconButton, Tooltip } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import {
  deleteBankAccount,
  listBankAccountByCompany,
  listBankAccountByTenant,
} from "shared/network/bank-account.api";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

type Props = {
  id?: string | null;
  tenantId?: string | null;
  isAll?: boolean;
  isCustomer?: boolean;
};

const BankAccountList = ({ id, tenantId, isAll = false, isCustomer = false }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-list-bank-account-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-list-bank-account-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const listBankAccountQuery = useQuery(["listBankAccountQuery", id], async () => {
    if (id) {
      const { data } = await listBankAccountByCompany(id, tenant.id);
      return data;
    } else if (tenantId) {
      const { data } = await listBankAccountByTenant(Number.parseInt(tenantId));
      return data;
    } else {
      const { data } = await listBankAccountByTenant(tenant.id);
      return data;
    }
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("bankAccount.formValues.name"),
      flex: 2,
    },
    {
      field: "accountNumber",
      headerName: t("bankAccount.formValues.accountNumber"),
      flex: 2,
    },
    {
      field: "baseCurrency",
      headerName: t("bankAccount.formValues.currency"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.baseCurrency.name,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "BANK_ACCOUNT_ADMIN",
          ]) && (
            <>
              <Tooltip title={t("bankAccount.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={Link}
                  to={
                    id
                      ? `/companies/${
                          isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                        }/company-details/details/bank-account-modify?id=${
                          row.id
                        }&backId=${id}&from=company`
                      : `/admin/tenants/tenant-details/bank-accounts/bank-account-modify?id=${row.id}&backId=${tenantId}&from=tenant`
                  }
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("bankAccount.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => removeBankAccount(row.id)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  async function removeBankAccount(id: number) {
    try {
      await deleteBankAccount(id, selectedRelTenant.tenant.id);
      listBankAccountQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("bankAccount.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("bankAccount.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  return (
    <Box mb={2.5}>
      <SingleQueryTable
        query={listBankAccountQuery}
        columns={columns}
        setTableState={setTableState}
        tableState={tableState}
        sessionStorageKey={`bankAccountList_${tenant.id}`}
      />
    </Box>
  );
};

export default BankAccountList;
