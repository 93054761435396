import { RootState } from "config/store";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { generateInvoiceFromWorksheet } from "shared/network/invoice.api";

export default function useInvoiceGenerate(itemList: any[], sourceType: string) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [source, setSource] = useState<string>(sourceType); //eslint-disable-line

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  function add(item: any) {
    const temp = selectedItems;
    temp.push(item);
    setSelectedItems(temp);
  }

  function remove(item: any) {
    const temp = selectedItems.filter(entry => entry.id !== item.id);
    setSelectedItems(temp);
  }

  function reset() {
    setSelectedItems([]);
  }

  function isSelected(item: any): boolean {
    return selectedItems.includes(item);
  }

  async function generate() {
    if (isEmpty(selectedItems)) {
      enqueueSnackbar(
        t("common:notification.generateInvoiceNoData", {
          subject: t("invoice.subject"),
        }),
        {
          variant: "error",
        },
      );
      return;
    }

    const issueIds = selectedItems.map(entry => entry.id);

    const { data } = await generateInvoiceFromWorksheet(issueIds, tenant.id);

    history.push(`/invoices/invoices-list/invoice-modify?id=${data.invoice.id}`);
  }

  return {
    add,
    remove,
    reset,
    setSource,
    generate,
    isSelected,
  };
}
