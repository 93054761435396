import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { trim } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { registerUser } from "shared/network/user.api";
import { Company } from "shared/types";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import { trimData } from "shared/util/trimData";
import UserForm from "../components/UserForm";

export type UserFormValues = {
  id: number;
  name: string;
  email: string;
  company: Company | null;
  isSuperAdmin: boolean;
  isTenantAdmin: boolean;
  monitoringUserId: string;
};

const UserCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const form = useForm<UserFormValues>();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const name = query.get("name") || "";
  const email = query.get("email") || "";

  const [status, setStatus] = useState<SliceStatus>("idle");

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const mutation = useMutation<any, any, UserFormValues>(
    async values => {
      setStatus("pending");
      await registerUser(
        {
          ...trimData(removeEmptyStrings(values)),
          isTenantAdmin: values.company?.id ? false : values.isTenantAdmin,
          email: trim(values.email),
        },
        values.company?.id || "",
        tenant.id,
      );
    },
    {
      onSuccess: () => {
        history.goBack();
        enqueueSnackbar(t("user.createSuccess"), {
          variant: "success",
        });
        setStatus("success");
      },
      onError: err => {
        if (err.data.status === "EMAIL_ALREADY_EXISTS") {
          enqueueSnackbar("A megadott e-mail címmel már létezik felhasználó.", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(t("user.createFailure"), {
            variant: "error",
          });
        }
        setStatus("failure");
      },
    },
  );

  return (
    <>
      <Container maxWidth="lg">
        {status === "pending" ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(values => mutation.mutate(values))}>
              <UserForm user={{ user: { name, email } }} type="CREATE" />
            </form>
          </FormProvider>
        )}
      </Container>
    </>
  );
};

export default UserCreate;
