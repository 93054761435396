import { Box, IconButton, Tooltip } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { TYPE } from "config/constants";

import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { deleteDepartment, getDepartmentList } from "shared/network/department.api";
import displayAddress from "shared/util/displayAddress";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

const TenantSites = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tenant-site-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tenant-site-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const getDepartmentListQuery = useQuery(
    ["tenantDepartmentList", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await getDepartmentList(page, pageSize, tenant.id, filterValue, sortState);
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("department.formValues.name"),
      flex: 1,
    },
    {
      field: "address",
      headerName: t("department.formValues.address.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.address ? displayAddress(row.address) : "-",
    },
    {
      field: "description",
      headerName: t("department.formValues.description"),
      flex: 1,
    },
    {
      field: "type",
      headerName: t("department.formValues.type"),
      flex: 1,
      type: "select",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.type ? t(`common:type.${row.type}`) : "-",
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DEPARTRMENT_ADMIN",
          ]) && (
            <>
              <Tooltip title={t("department.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={Link}
                  to={`/admin/tenants/tenant-details/departments/department-modify?id=${row.id}&backId=${row.tenantId}`}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("department.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    if (row.id) {
                      removeDepartment(row.id);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  async function removeDepartment(id: number) {
    try {
      await deleteDepartment(id, tenant.id);
      getDepartmentListQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("department.subject"),
        }),
        { variant: "success" },
      );
    } catch (e: any) {
      if (e.message === "conflict") {
        enqueueSnackbar(
          t("common:notification.delete.failure", {
            subject: t("item.category.subject"),
          }) +
            " " +
            t("common:notification.CONFLICT"),
          {
            variant: "error",
          },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.delete.failure", {
            subject: t("department.subject"),
          }),
          { variant: "error" },
        );
      }
    }
  }

  return (
    <PageableTable
      transparent
      sortState={sortState}
      filterOptions={[
        {
          columnName: "type",
          options: TYPE.map(value => {
            return {
              translated: t(`common:type.${value}`),
              value,
            };
          }),
        },
      ]}
      setSortState={setSortState}
      sessionStorageKey="tenant-site"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={getDepartmentListQuery}
    />
  );
};

export default TenantSites;
