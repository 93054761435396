import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { modifyStatus } from "shared/network/report.api";

type Props = {
  id: number;
  tenantId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  defaultValue: string;
};
export const reportStatus = ["NEW", "EXAMINATION_IN_PROGRESS", "CLOSED"];

const StatusModify = ({ id, tenantId, open, setOpen, defaultValue }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setStatus(defaultValue);
  }, [defaultValue]);

  async function onSubmit(status: string) {
    setLoading(true);
    try {
      await modifyStatus({ id, status }, tenantId);
      setOpen(false);
      queryClient.refetchQueries("getReportByIdQuery");
    } catch {
      enqueueSnackbar(t(`report.statusModifyError`), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("report.statusModify")}</DialogTitle>
      <DialogContent>
        <Loading open={loading} />
        <TextField
          value={status}
          onChange={event => setStatus(event.target.value)}
          select
          InputLabelProps={{ shrink: true, required: true }}
          label={t("comment.type.title")}
          SelectProps={{ displayEmpty: true }}
        >
          {reportStatus.map(type => (
            <MenuItem key={type} value={type}>
              {t(`report.status.${type}`)}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          {t("common:button.cancel")}
        </Button>
        <Button onClick={() => onSubmit(status)} disabled={loading}>
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusModify;
