export const WALL_TYPES = [
  "tömör tégla",
  "B30",
  "YTONG",
  "vasbeton",
  "szendvicspanel",
  "szilikát",
  "Porotherm30",
  "Porotherm50",
  "vályog",
  "hungarocell",
  "fa",
  "szalma",
  "egyéb",
];

export const ROOF_TYPES = [
  "lapos tető",
  "hagyományos tető padlástérrel",
  "hagyományos teő tetőtérrel",
  "hagyományos tető padlés nélkül",
];

export const WALL_THICKNESS = [10, 20, 30, 40, 50, 60, 80, 90, 100, 110];

export const FLOORS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, "több"];

export const BOUNDARY_TYPE = ["fal", "üveg", "vegyes"];

export const THERMAL_INSULATION = [
  "nincs",
  "kerámia réteg",
  "üveggyapot",
  "kőzetgyapot",
  "dryvit",
  "fagyapot",
  "polisztirol(hungarocell)",
];

export const INSULATION_THICKNESS = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

export const OPEN_CLOSERS = [
  "1 rétegű üveg",
  "2 rétegű üveg",
  "3 rétegű üveg",
  "4 rétegű üveg",
  "egyéb speciális üveg",
];

export const HEAT_TANSFER = [0.5, 0.9, 1.4, 2, 3, 4];

export const ENERGY_SOURCES = [
  "nincs",
  "napelem",
  "szélenergia",
  "geotermikus",
  "napkollektor",
  "egyéb",
];

export const HEATING_TYPES = [
  "nincs",
  "gázfűtés",
  "kondenzációs gázfűtés",
  "vegyes tüzelés",
  "hőszivattyú",
  "VRV/VRF",
  "pelletkazán",
  "távfűtés",
  "geotermikus",
  "egyéb",
  "solar, napkollektor",
];

export const HEATING_KW = [
  0, 5, 10, 15, 20, 30, 40, 50, 100, 150, 200, 250, 300, 400, 500, 600, 800, 1000, 1200, 1400, 1600,
  1800, 2000, 2500, 3000, 3500, 4000, 5000, 5500, 6000, 6500, 7000, 7500, 8000, 8500, 9000, 9500,
  10000,
];

export const HEATER_NUMBER = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const USED_HMV = [
  "nincs",
  "gázfűtés",
  "kondenzációs gázfűtés",
  "vegyes tüzelés",
  "hőszivattyú",
  "VRV/VRF",
  "pelletkazán",
  "távfűtés",
  "geotermikus",
  "egyéb",
  "solar, napkollektor",
  "villanyboiler",
];

export const HEATER_CIRCUIT = [
  "nincs",
  "radiátoros fűtés",
  "padlófűtés",
  "fan-coilos fűtés",
  "felületfűtés",
  "légtechnikai rendszerek",
  "uszoda párátlanító rendszerek",
  "rámpafűtés",
  "medencevíz fűtés",
  "sugárzófűtés (gáz)",
  "fűtőgerenda",
  "infrapanel",
  "egyéb",
];

export const HEATER_CIRCUIT_OPERATION = [
  "termosztát",
  "külső hőm. követő automatika",
  "egyedi automatika",
  "épületfelügyelet",
  "folyamatos üzem, nem szabályzott",
];

export const HEATPUMP_TYPES = ["víz-víz", "levegő-víz"];

export const YES_OR_NO = ["IGEN", "NEM"];

export const TIME = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
];

export const SET_POINT = [10, 12, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 30];

export const FAN_COIL = [
  "termosztát",
  "programozható termosztát",
  "gyári IRC sziget üzemben",
  "gyári IRC felügyeleti r.-be kötve",
];

export const COOLER_TYPES = [
  "nincs",
  "folyadékhűtő (kompakt)",
  "folyadékhűtő (osztott)",
  "szárazhűtő",
  "hőszivattyú",
  "split klíma",
  "VRV/VRF",
];

export const COOLER_CIRCUIT_TYPES = [
  "nincs",
  "fan-coilos hűtés",
  "felülethűtés",
  "légtechnikai rendszerek",
  "hűtőgerenda",
  "egyéb",
];
