import * as React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles, fade } from "@material-ui/core/styles";
import { COLORS } from "config/theme";
import clsx from "clsx";

const useStyles = makeStyles(
  {
    root: {
      "& .MuiInputLabel-outlined": {
        justifyContent: "flex-start",
        transformOrigin: "top left",
        transform: "translate(12px, 20px) scale(1)",
        "&.MuiInputLabel-shrink": {
          transform: "translate(12px, -8px) scale(0.75)",
        },
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 10,
        color: fade(COLORS.mainLight, 0.75),
        fontWeight: "normal",
        fontStyle: "italic",
        letterSpacing: "0.025em",
        fontSize: 18,
        "& .MuiOutlinedInput-input::placeholder": {
          fontStyle: "italic",
          fontSize: 18,
          fontWeight: "normal",
          letterSpacing: "0.025em",
          color: fade(COLORS.mainLight, 0.75),
          opacity: 1,
        },
        "&:focus .MuiOutlinedInput-input::placeholder, &:hover .MuiOutlinedInput-input::placeholder":
          {
            color: "#bcbcc5",
          },
      },
    },
  },
  { name: "TextArea" },
);

const TextArea = ({
  type,
  variant,
  minRows = 2,
  rowsMax = 5,
  className,
  ...rest
}: TextFieldProps) => {
  const classes = useStyles();

  return (
    <TextField
      {...rest}
      minRows={minRows}
      rowsMax={rowsMax}
      className={clsx(classes.root, className)}
      type="textarea"
      variant="outlined"
      multiline
    />
  );
};
export default TextArea;
