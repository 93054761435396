import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { TaxEntry } from "shared/types";

const ENDPOINT = "/tax";

export const getTaxEntryListPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<TaxEntry>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //LOGIN_REQUIRED

export const createTaxEntry = (param: TaxEntry, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param, tenantId }); //ADMIN

export const modifyTaxEntry = (param: TaxEntry, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId }); //ADMIN
