import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { Edit, GetApp, Visibility } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import Loading from "components/Loading";
import PageableTable from "components/PageableTable/PageableTable";
import { PDF_TEMPLATE_TYPES } from "config/constants";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getPdfTemaplateList, previewPdfTemplate } from "shared/network/pdf-template.api";
import { PdfTemplate } from "shared/types";
import { pdfDownload, previewInspect } from "shared/util/fileDownload";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import { PdfTemplateFormValues } from "./PdfTemplateForm";

const PdfTemplateList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const viewType = location.pathname?.includes("/offer/pdf-template")
    ? "OFFER"
    : location.pathname?.includes("/contract-pdf-template")
    ? "CONTRACT"
    : "";
  const filterAbles = viewType === "" ? ["name", "type"] : ["name"];
  const [loading, setLoading] = useState<boolean>(false);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(
          `rap-pdf-template${viewType === "" ? "" : `-${viewType}`}-page-number`,
        ),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(
          `rap-pdf-template${viewType === "" ? "" : `-${viewType}`}-page-size`,
        ),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;
  const getOfferPdfTemplateListQuery = useQuery(
    [
      "getOfferPdfTemplateListQuery",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
      viewType,
    ],
    async () => {
      const { data } = await getPdfTemaplateList(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue + `referenceId(IS_NULL)$;` + (viewType === "" ? "" : `type:${viewType};`),
        sortState,
      );
      return data;
    },
  );

  async function onSubmitPreview(values: PdfTemplateFormValues, isDownload?: boolean, id?: string) {
    try {
      setLoading(true);
      const { data } = await previewPdfTemplate(values, selectedRelTenant.tenant.id);
      if (isDownload && id) {
        pdfDownload(data, id, t("common:pdfDownload.PDF_TEMPLATE"));
      } else {
        previewInspect(data);
      }
      setLoading(false);
      enqueueSnackbar(
        t("common:notification.preview.success", {
          subject: t("pdfTemplate.subject"),
        }),
        { variant: "success" },
      );
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(
        t("common:notification.preview.failure", {
          subject: t("pdfTemplate.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("pdfTemplate.list.name"),
      flex: 1,
    },
    {
      field: "type",
      headerName: t("pdfTemplate.list.type"),
      flex: 1,
      type: "select",
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`pdfTemplate.templateType.${row.type}`)?.toString(),
      filterable: viewType === "",
      hide: viewType === "" ? false : true,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {!row.isInsideDelivery &&
            hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "PDF_TEMPLATE_ADMIN",
            ]) && (
              <Tooltip title={t("pdfTemplate.list.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  component={Link}
                  to={
                    viewType === "OFFER"
                      ? `/offer/pdf-template/modify?id=${(row as PdfTemplate)?.id}`
                      : viewType === "CONTRACT"
                      ? `/contract-pdf-template/modify?id=${(row as PdfTemplate)?.id}`
                      : `/my-company/pdf-template/modify?id=${(row as PdfTemplate)?.id}`
                  }
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "PDF_TEMPLATE_ADMIN",
          ]) && (
            <Tooltip title={t("pdfTemplate.list.download").toString()}>
              <IconButton
                size="small"
                color="primary"
                onClick={() =>
                  onSubmitPreview(row as PdfTemplate, true, (row as PdfTemplate)?.id?.toString())
                }
              >
                <GetApp />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "PDF_TEMPLATE_ADMIN",
          ]) && (
            <Tooltip title={t("pdfTemplate.list.inspect").toString()}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => onSubmitPreview(row as PdfTemplate)}
                style={{ margin: "0 8px" }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        <Button
          component={Link}
          to={
            viewType === "OFFER"
              ? `/offer/pdf-template/create`
              : viewType === "CONTRACT"
              ? `/contract-pdf-template/create`
              : `/my-company/pdf-template/create`
          }
        >
          <FontAwesomeIcon
            icon={faBoxOpen}
            style={{
              fontSize: "20px",
              marginRight: 8,
            }}
          />
          {t("pdfTemplate.create")}
        </Button>
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  return (
    <>
      <Loading open={loading} />
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="pdf-template-offer"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getOfferPdfTemplateListQuery}
        minWidth={{ value: "sm" }}
        filterOptions={[
          {
            columnName: "type",
            options: PDF_TEMPLATE_TYPES?.map(value => {
              return {
                translated: t(`pdfTemplate.templateType.${value}`),
                value: value?.toString(),
              };
            }),
          },
        ]}
        filterable={filterAbles}
      />
    </>
  );
};

export default PdfTemplateList;
