import axios from "config/axios";
import { GenericListResponse, GenericPageResponse } from "shared/common";
import { Cargo, Transaction } from "shared/types";
import { CargoRemoveFormValues } from "views/Vehicle/Cargo/CargoRemove";

const ENDPOINT = "/cargo";
const ENDPOINT_TRANSACTION = "/transactions";

export const listCargoForVehicle = (vehicleId: string, tenantId: number) =>
  axios.get<GenericListResponse<Cargo>>(`${ENDPOINT}?vehicleId=${vehicleId}&tenantId=${tenantId}`); //CARGO_GET

export const listCargoForVehiclePageable = (
  page: number,
  size: number,
  vehicleId: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
  distinct?: boolean,
) =>
  axios.get<GenericPageResponse<Cargo>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=vehicleId:${vehicleId};${search}&sort=${sort}&distinct=${!!distinct}`,
  ); //CARGO_GET

export const listCargoHistoryForVehiclePageable = (
  page: number,
  size: number,
  vehicleId: number,
  date: string,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Cargo>>(
    `${ENDPOINT}/get-history-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}&time=${date}&vehicleId=${vehicleId}`,
  ); //CARGO_GET

export const listCargoHistoryForVehicleTransactionPageable = (
  page: number,
  size: number,
  vehicleId: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Transaction>>(
    `${ENDPOINT_TRANSACTION}/get-page-by-vehicle?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}&vehicleId=${vehicleId}`,
  ); //CARGO_GET

export const modifyVehicleCargo = (request: CargoRemoveFormValues, tenantId: number) =>
  axios.post(`${ENDPOINT}/modify-items`, {
    vehicleId: request.vehicleId,
    removeEverything: request.removeEverything,
    cargoItems: request.cargoItems,
    warehouseId: request.warehouseId,
    tenantId,
  }); //CARGO_ADMIN

export const removeAllCargoFromVehicle = (
  vehicleId: number,
  warehouseId: number,
  tenantId: number,
) => axios.post(`${ENDPOINT}/back-to-warehouse`, { vehicleId, warehouseId, tenantId }); //CARGO_ADMIN

export const getVehicleCargoByDriver = (userId: number, tenantId: number) =>
  axios.get<GenericListResponse<Cargo>>(
    `${ENDPOINT}/get-by-driver?userId=${userId}&tenantId=${tenantId}`,
  ); //CARGO_GET

export const historyGenerate = () => axios.get(`${ENDPOINT}/history-generate`);
