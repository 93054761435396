import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { Add, Assignment, Block, CheckCircleOutline } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { Link as RouterLink } from "components/Router";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listSupplierPriceHistories } from "shared/network/supplier-price-history.api";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import { useGetSessionStorageKey } from "views/Comment/function";

const SupplierPriceHistoryList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { setHeaderButtons } = useHeader();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const isCompany = location.pathname.includes("/companies");
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-supplier-price-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-supplier-price-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const supplierPriceHistoryQuery = useQuery(
    [
      "supplierPriceHistoryQuery",
      page,
      pageSize,
      filterValue,
      sortState,
      selectedRelTenant.tenant.id,
    ],
    async () => {
      const { data } = await listSupplierPriceHistories(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        id ? `company.id:${id}${filterValue ? `;${filterValue}` : ""}` : filterValue,
        sortState,
      );

      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "item.name",
      headerName: t("supplierPriceHistory.formValues.item"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.item.name,
    },
    {
      field: "price",
      headerName: t("supplierPriceHistory.formValues.price"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        const price = row.price;
        return price
          ? `${row.currency?.prefix || ""} ${row.currency?.prefix || ""} ${t("common:number", {
              num: price,
            })} ${row.currency?.postfix || ""}`
          : "-";
      },
    },
    {
      field: "isActive",
      headerName: t("supplierPriceHistory.formValues.isActive"),
      flex: 1,
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isActive ? (
          <Tooltip title={t("personalData.statuses.ACTIVE").toString()}>
            <CheckCircleOutline style={{ color: COLORS.green }} />
          </Tooltip>
        ) : (
          <Tooltip title={t("personalData.statuses.NACTIVE").toString()}>
            <Block style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "validFrom",
      headerName: t("supplierPriceHistory.formValues.validFrom"),
      flex: 1,
      type: "dateTime",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.validFrom ? format(new Date(row.validFrom), "yyyy. MM. dd.") : "",
    },
    {
      field: "validTo",
      headerName: t("supplierPriceHistory.formValues.validTo"),
      flex: 1,
      type: "dateTime",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.validTo ? format(new Date(row.validTo), "yyyy. MM. dd.") : "",
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ITEM_GET"]) && (
            <Tooltip title={t("supplierPriceHistory.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={RouterLink}
                to={`/items/list/item-details/details?id=${row.item.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" alignItems="center" gridGap={8}>
        <Button
          variant="contained"
          to={
            isCompany
              ? `/companies/${
                  isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                }/company-details/supplier-prices/supplier-price-history-create?id=${id}`
              : ``
          }
          component={Link}
          startIcon={<Add />}
        >
          {t("supplierPriceHistory.create")}
        </Button>
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions" && params.field !== "purchasable") {
      history.push(`/items/list/item-details/details?id=${params.row.item.id}`);
    }
  }

  return (
    <>
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="supplier-price"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={supplierPriceHistoryQuery}
        onCellClick={onCellClick}
      />
    </>
  );
};

export default SupplierPriceHistoryList;
