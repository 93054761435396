import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Block, CheckCircleOutline, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getMonitoringDataPageable } from "shared/network/monitoring.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const MonitoringMachineList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setHeaderButtons } = useHeader();

  const [userCompanyId, setUserCompanyId] = useState<number | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-monitoring-machine-list-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-monitoring-machine-list-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  useEffect(() => {
    setUserCompanyId(
      account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin
        ? null
        : account.user.relUserCompany?.[0]?.company.id || null,
    );
  }, [account, selectedRelTenant, setUserCompanyId]);

  const monitoringMachineListQuerry = useQuery(
    [
      "monitoringMachineListQuerry",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
      userCompanyId,
    ],
    async () => {
      const { data } = await getMonitoringDataPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        (userCompanyId ? `company.id:${userCompanyId};` : ``) + filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "machineId",
      headerName: t("monitoring.machineDetails.machineId"),
      flex: 0.75,
      type: "stringContainsNumber",
    },
    {
      field: "company.name",
      headerName: t("monitoring.machineDetails.companyName"),
      flex: 1.25,
      valueGetter: ({ row }: GridValueGetterParams) => row.company.name,
    },
    {
      field: "itemSerial.item.name",
      headerName: t("monitoring.machineDetails.itemSerial"),
      flex: 1,
      hide: true,
    },
    {
      field: "itemSerial",
      headerName: t("monitoring.machineDetails.itemSerial"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.itemSerial.item.name + ` (${row.itemSerial.serialNumber})`,
    },
    {
      field: "fillGrade",
      headerName: t("monitoring.machineDetails.fillGrade"),
      flex: 0.75,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", {
          num: (row?.capacity ? (row?.currentAmount || 0) / row?.capacity : 0) * 100,
        }) + " %",
    },
    {
      field: "status",
      headerName: t("monitoring.machineDetails.status"),
      flex: 0.75,
      type: "select",
    },
    {
      field: "currency.code",
      headerName: t("monitoring.machineDetails.currency"),
      flex: 0.75,
      valueGetter: ({ row }: GridValueGetterParams) => row.currency.code || "",
    },
    {
      field: "isActive",
      headerName: t("monitoring.machineDetails.isActive"),
      flex: 0.5,
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isActive ? (
          <CheckCircleOutline style={{ color: COLORS.green }} />
        ) : (
          <Block style={{ color: COLORS.red }} />
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "MONITORING_GET",
          ]) && (
            <Tooltip title={t("monitoring.machineDetails.details").toString()}>
              <IconButton
                component={Link}
                to={`/monitoring/machines/machine-details?id=${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "MONITORING_ADMIN",
          ]) && (
            <Tooltip title={t("monitoring.machineDetails.modify").toString()}>
              <IconButton
                component={Link}
                to={`/monitoring/machines/monitoring-entry-modify?id=${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/monitoring/machines/machine-details?id=${params.row.id}`);
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "MONITORING_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/monitoring/machines/monitoring-entry-create"
            variant="contained"
            style={{ margin: "auto" }}
            startIcon={<AddBox />}
          >
            {t("monitoring.machineDetails.create")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <PageableTable
      filterOptions={[
        {
          columnName: "status",
          options: [
            { translated: "OFFLINE", value: "OFFLINE" },
            { translated: "ONLINE", value: "ONLINE" },
            { translated: "FULL", value: "FULL" },
            { translated: "", value: "" },
          ],
        },
      ]}
      sortState={sortState}
      filterable={[
        "machineId",
        "status",
        "currency.code",
        "isActive",
        "company.name",
        "itemSerial.name",
      ]}
      setSortState={setSortState}
      sessionStorageKey="monitoring-machine-list"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={monitoringMachineListQuerry}
      onCellClick={onCellClick}
    />
  );
};

export default MonitoringMachineList;
