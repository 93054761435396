import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ContractType } from "shared/network/contract-type";
import { createRelCompanyTypeTenant } from "shared/network/rel-contract-type-tenant.api";

const ContractTypeAdd = ({
  open,
  setOpen,
  relContractTypeTenantList,
  contractTypeList,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  relContractTypeTenantList?: {
    id: number;
    contractType: {
      id: number;
      name: string;
    };
  }[];
  contractTypeList?: ContractType[];
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<{ contractTypeId: number | string }>();

  const { selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const tenant = selectedRelTenant.tenant;

  const onSubmit = async (values: { contractTypeId: number | string }) => {
    try {
      const contractType = contractTypeList?.find(
        value => value.id === values.contractTypeId,
      );
      if (contractType) {
        await createRelCompanyTypeTenant(contractType, tenant.id);
        queryClient.invalidateQueries(["relContractTypeTenantList", tenant.id]);
        setOpen(false);
      } else {
        throw new Error("NO_CONTRACT_TYPE");
      }
    } catch {
      enqueueSnackbar(t("common:notification.add.failure"), {
        variant: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle>Hozzárendelés</DialogTitle>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent>
            <Controller
              control={form.control}
              name="contractTypeId"
              defaultValue=""
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true, required: true }}
                  SelectProps={{ displayEmpty: true }}
                  label="Szerződéstípus"
                  select
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                >
                  <MenuItem disabled value="">
                    {t("common:choose")}
                  </MenuItem>
                  {contractTypeList
                    ?.filter(
                      item =>
                        !relContractTypeTenantList?.find(
                          rel => rel.contractType.id === item.id,
                        ),
                    )
                    .map(contractType => (
                      <MenuItem key={contractType.id} value={contractType.id}>
                        {t(`contract.type.${contractType.name}`)}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="text">
              Mégse
            </Button>
            <Button type="submit">Ok</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ContractTypeAdd;
