import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { Worksheet } from "shared/types";

const ENDPOINT = "/worksheet";

export const createWorksheet = (param: Worksheet, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId });

export const modifyWorksheet = (param: Worksheet, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param, tenantId });

export const listWorksheet = (tenantId: number) =>
  axios.get<GenericListResponse<Worksheet>>(`${ENDPOINT}/all-by-tenant?tenantId=${tenantId}`);

export const getWorksheetById = (id: number | string, tenantId: number) =>
  axios.get<GenericResponse<Worksheet>>(`${ENDPOINT}?id=${id}&tenantId=${tenantId}`);

export const deleteWorksheet = (id: number | string, tenantId: number) =>
  axios.delete(`${ENDPOINT}?id=${id}&tenantId=${tenantId}`);
