import { lazy } from "react";
import RelCompanyItemCreate from "views/Company/ItemCompany/CompanyItemCreate";

const OfferCreate = lazy(() => import("views/Offer/OfferCreate"));
const OfferModify = lazy(() => import("views/Offer/OfferModify"));
const OfferDetails = lazy(() => import("views/Offer/OfferDetails"));
const ItemModify = lazy(() => import("views/Items/ItemModify/ItemModify"));
const ItemDetailsTabs = lazy(() => import("views/Items/ItemDetails/ItemDetailsTabs"));
const DeliveryPointList = lazy(() => import("views/DeliveryPoint/DeliveryPointList"));
const DeliveryRoundModify = lazy(() => import("views/DeliveryRound/DeliveryRoundModify"));
const DeliveryRoundItemModify = lazy(() => import("views/DeliveryRound/DeliveryRoundItemModify"));
const DeliveryRoundCreate = lazy(() => import("views/DeliveryRound/DeliveryRoundCreate"));
const BankAccountCreate = lazy(
  () => import("views/Company/CompanyDetailsTabs/BankAccount/BankAccountCreate"),
);
const BankAccountModify = lazy(
  () => import("views/Company/CompanyDetailsTabs/BankAccount/BankAccountModify"),
);
const CompanyTabs = lazy(() => import("views/Company/CompanyTabs"));
const OwnCompanyDetailsTabs = lazy(
  () => import("views/Company/OwnCompanyDetailsTabs/OwnCompanyDetailsTabs"),
);
const CompanyCreate = lazy(() => import("views/Company/CompanyCreate"));
const CompanyDetailsTabs = lazy(
  () => import("views/Company/CompanyDetailsTabs/CompanyDetailsTabs"),
);
const CompanyModify = lazy(() => import("views/Company/CompanyModify"));
const ContactCreate = lazy(() => import("views/Company/Contact/ContactCreate"));
const ContactDetails = lazy(() => import("views/Company/Contact/ContactDetails"));
const ContactModify = lazy(() => import("views/Company/Contact/ContactModify"));
const CompanySiteCreate = lazy(() => import("views/Company/CompanySite/CompanySiteCreate"));
const CompanySiteModify = lazy(() => import("views/Company/CompanySite/CompanySiteModify"));

const InvoiceCreate = lazy(() => import("views/Invoice/InvoiceCreate"));
const InvoiceDetails = lazy(() => import("views/Invoice/InvoiceDetails"));
const InvoiceModify = lazy(() => import("views/Invoice/InvoiceModify"));
const IncomingInvoiceCreate = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceCreate"),
);
const IncomingInvoiceDetails = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceDetails"),
);
const IncomingInvoiceModify = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceModify"),
);
const SupplierPriceHistoryCreate = lazy(
  () => import("views/SupplierPriceHistory/SupplierPriceHistoryCreate"),
);
const SupplierPriceHistoryDetails = lazy(
  () => import("views/SupplierPriceHistory/SupplierPriceHistoryDetails"),
);
const SerialItemCreate = lazy(() => import("views/Items/SerialItems/SerialItemCreate"));

export const COMPANIES_PRIVATE_ROUTES = [
  {
    path: "/companies",
    component: CompanyTabs,
    permissions: ["COMPANY_GET"],
    routes: [
      {
        path: "/list-all",
        component: CompanyTabs,
        permissions: ["COMPANY_GET"],
        routes: [
          {
            path: "/company-details",
            component: CompanyDetailsTabs,
            permissions: ["COMPANY_GET"],
            routes: [
              {
                path: "/details",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/contact-modify",
                    component: ContactModify,
                  },
                  {
                    path: "/contact-details",
                    component: ContactDetails,
                  },
                  {
                    path: "/contact-create",
                    component: ContactCreate,
                  },
                  {
                    path: "/companysite-create",
                    component: CompanySiteCreate,
                  },
                  {
                    path: "/companysite-modify",
                    component: CompanySiteModify,
                  },
                  {
                    path: "/bank-account-create",
                    component: BankAccountCreate,
                  },
                  {
                    path: "/bank-account-modify",
                    component: BankAccountModify,
                  },
                ],
              },
              {
                path: "/products",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/item-details",
                    component: ItemDetailsTabs,
                    routes: [
                      {
                        path: "/details",
                        component: ItemDetailsTabs,
                      },
                      {
                        path: "/serial-item-list",
                        component: ItemDetailsTabs,
                        routes: [
                          {
                            path: "/serial-item-create",
                            component: SerialItemCreate,
                            permissions: ["ITEM_ADMIN"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/item-create",
                    component: RelCompanyItemCreate,
                  },
                  {
                    path: "/item-modify",
                    component: ItemModify,
                  },
                ],
              },
              {
                path: "/invoices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/invoice-create",
                    component: InvoiceCreate,
                    permissions: ["INVOICE_ADMIN"],
                  },
                  {
                    path: "/invoice-details",
                    component: InvoiceDetails,
                    permissions: ["INVOICE_ADMIN"],
                  },
                  {
                    path: "/invoice-modify",
                    component: InvoiceModify,
                    permissions: ["INVOICE_ADMIN"],
                  },
                ],
              },
              {
                path: "/incoming-invoices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/incoming-invoice-create",
                    component: IncomingInvoiceCreate,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                  {
                    path: "/incoming-invoice-details",
                    component: IncomingInvoiceDetails,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                  {
                    path: "/incoming-invoice-modify",
                    component: IncomingInvoiceModify,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                ],
              },
              {
                path: "/delivery-notes",
                component: CompanyDetailsTabs,
              },
              {
                path: "/delivery",
                component: CompanyDetailsTabs,
              },
              {
                path: "/offers",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/in",
                    component: CompanyDetailsTabs,
                    routes: [
                      {
                        path: "/offer-modify",
                        component: OfferModify,
                        permissions: ["OFFER_ADMIN", "OFFER_GET"],
                      },
                      {
                        path: "/offer-create",
                        component: OfferCreate,
                        permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
                      },
                      {
                        path: "/offer-details",
                        component: OfferDetails,
                        permissions: ["OFFER_GET"],
                      },
                    ],
                  },
                  {
                    path: "/out",
                    component: CompanyDetailsTabs,
                    routes: [
                      {
                        path: "/offer-modify",
                        component: OfferModify,
                        permissions: ["OFFER_ADMIN", "OFFER_GET"],
                      },
                      {
                        path: "/offer-create",
                        component: OfferCreate,
                        permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
                      },
                      {
                        path: "/offer-details",
                        component: OfferDetails,
                        permissions: ["OFFER_GET"],
                      },
                    ],
                  },
                ],
              },
              {
                path: "/supplier-prices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/supplier-price-history-create",
                    component: SupplierPriceHistoryCreate,
                  },
                  {
                    path: "/supplier-price-history-modify",
                    component: SupplierPriceHistoryDetails,
                  },
                ],
              },
            ],
          },
          {
            path: "/company-modify",
            component: CompanyModify,
            permissions: ["COMPANY_ADMIN"],
          },
          {
            path: "/company-create",
            component: CompanyCreate,
            permissions: ["COMPANY_ADMIN"],
          },
        ],
      },
      {
        path: "/list-customer",
        component: CompanyTabs,
        permissions: ["COMPANY_GET"],
        routes: [
          {
            path: "/company-details",
            component: CompanyDetailsTabs,
            permissions: ["COMPANY_GET"],
            routes: [
              {
                path: "/details",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/contact-modify",
                    component: ContactModify,
                  },
                  {
                    path: "/contact-details",
                    component: ContactDetails,
                  },
                  {
                    path: "/contact-create",
                    component: ContactCreate,
                  },
                  {
                    path: "/companysite-create",
                    component: CompanySiteCreate,
                  },
                  {
                    path: "/companysite-modify",
                    component: CompanySiteModify,
                  },
                  {
                    path: "/bank-account-create",
                    component: BankAccountCreate,
                  },
                  {
                    path: "/bank-account-modify",
                    component: BankAccountModify,
                  },
                ],
              },
              {
                path: "/products",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/item-details",
                    component: ItemDetailsTabs,
                    routes: [
                      {
                        path: "/details",
                        component: ItemDetailsTabs,
                      },
                      {
                        path: "/serial-item-list",
                        component: ItemDetailsTabs,
                        routes: [
                          {
                            path: "/serial-item-create",
                            component: SerialItemCreate,
                            permissions: ["ITEM_ADMIN"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/item-create",
                    component: RelCompanyItemCreate,
                  },
                  {
                    path: "/item-modify",
                    component: ItemModify,
                  },
                ],
              },
              {
                path: "/invoices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/invoice-create",
                    component: InvoiceCreate,
                    permissions: ["INVOICE_ADMIN"],
                  },
                  {
                    path: "/invoice-details",
                    component: InvoiceDetails,
                    permissions: ["INVOICE_ADMIN"],
                  },
                  {
                    path: "/invoice-modify",
                    component: InvoiceModify,
                    permissions: ["INVOICE_ADMIN"],
                  },
                ],
              },
              {
                path: "/incoming-invoices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/incoming-invoice-create",
                    component: IncomingInvoiceCreate,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                  {
                    path: "/incoming-invoice-details",
                    component: IncomingInvoiceDetails,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                  {
                    path: "/incoming-invoice-modify",
                    component: IncomingInvoiceModify,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                ],
              },
              {
                path: "/delivery-notes",
                component: CompanyDetailsTabs,
              },
              {
                path: "/delivery",
                component: CompanyDetailsTabs,
              },
              {
                path: "/offers",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/in",
                    component: CompanyDetailsTabs,
                    routes: [
                      {
                        path: "/offer-modify",
                        component: OfferModify,
                        permissions: ["OFFER_ADMIN", "OFFER_GET"],
                      },
                      {
                        path: "/offer-create",
                        component: OfferCreate,
                        permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
                      },
                      {
                        path: "/offer-details",
                        component: OfferDetails,
                        permissions: ["OFFER_GET"],
                      },
                    ],
                  },
                  {
                    path: "/out",
                    component: CompanyDetailsTabs,
                    routes: [
                      {
                        path: "/offer-modify",
                        component: OfferModify,
                        permissions: ["OFFER_ADMIN", "OFFER_GET"],
                      },
                      {
                        path: "/offer-create",
                        component: OfferCreate,
                        permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
                      },
                      {
                        path: "/offer-details",
                        component: OfferDetails,
                        permissions: ["OFFER_GET"],
                      },
                    ],
                  },
                ],
              },
              {
                path: "/supplier-prices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/supplier-price-history-create",
                    component: SupplierPriceHistoryCreate,
                  },
                  {
                    path: "/supplier-price-history-modify",
                    component: SupplierPriceHistoryDetails,
                  },
                ],
              },
            ],
          },
          {
            path: "/company-modify",
            component: CompanyModify,
            permissions: ["COMPANY_ADMIN"],
          },
          {
            path: "/company-create",
            component: CompanyCreate,
            permissions: ["COMPANY_ADMIN"],
          },
        ],
      },
      {
        path: "/list-supplier",
        component: CompanyTabs,
        permissions: ["COMPANY_GET"],
        routes: [
          {
            path: "/company-details",
            component: CompanyDetailsTabs,
            permissions: ["COMPANY_GET"],
            routes: [
              {
                path: "/details",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/contact-modify",
                    component: ContactModify,
                  },
                  {
                    path: "/contact-details",
                    component: ContactDetails,
                  },
                  {
                    path: "/contact-create",
                    component: ContactCreate,
                  },
                  {
                    path: "/companysite-create",
                    component: CompanySiteCreate,
                  },
                  {
                    path: "/companysite-modify",
                    component: CompanySiteModify,
                  },
                  {
                    path: "/bank-account-create",
                    component: BankAccountCreate,
                  },
                  {
                    path: "/bank-account-modify",
                    component: BankAccountModify,
                  },
                ],
              },
              {
                path: "/products",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/item-details",
                    component: ItemDetailsTabs,
                    routes: [
                      {
                        path: "/details",
                        component: ItemDetailsTabs,
                      },
                      {
                        path: "/serial-item-list",
                        component: ItemDetailsTabs,
                        routes: [
                          {
                            path: "/serial-item-create",
                            component: SerialItemCreate,
                            permissions: ["ITEM_ADMIN"],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "/item-create",
                    component: RelCompanyItemCreate,
                  },
                  {
                    path: "/item-modify",
                    component: ItemModify,
                  },
                ],
              },
              {
                path: "/invoices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/invoice-create",
                    component: InvoiceCreate,
                    permissions: ["INVOICE_ADMIN"],
                  },
                  {
                    path: "/invoice-details",
                    component: InvoiceDetails,
                    permissions: ["INVOICE_ADMIN"],
                  },
                  {
                    path: "/invoice-modify",
                    component: InvoiceModify,
                    permissions: ["INVOICE_ADMIN"],
                  },
                ],
              },
              {
                path: "/incoming-invoices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/incoming-invoice-create",
                    component: IncomingInvoiceCreate,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                  {
                    path: "/incoming-invoice-details",
                    component: IncomingInvoiceDetails,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                  {
                    path: "/incoming-invoice-modify",
                    component: IncomingInvoiceModify,
                    permissions: ["INCOMING_INVOICE_ADMIN"],
                  },
                ],
              },
              {
                path: "/delivery-notes",
                component: CompanyDetailsTabs,
              },
              {
                path: "/delivery",
                component: CompanyDetailsTabs,
              },
              {
                path: "/offers",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/in",
                    component: CompanyDetailsTabs,
                    routes: [
                      {
                        path: "/offer-modify",
                        component: OfferModify,
                        permissions: ["OFFER_ADMIN", "OFFER_GET"],
                      },
                      {
                        path: "/offer-create",
                        component: OfferCreate,
                        permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
                      },
                      {
                        path: "/offer-details",
                        component: OfferDetails,
                        permissions: ["OFFER_GET"],
                      },
                    ],
                  },
                  {
                    path: "/out",
                    component: CompanyDetailsTabs,
                    routes: [
                      {
                        path: "/offer-modify",
                        component: OfferModify,
                        permissions: ["OFFER_ADMIN", "OFFER_GET"],
                      },
                      {
                        path: "/offer-create",
                        component: OfferCreate,
                        permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
                      },
                      {
                        path: "/offer-details",
                        component: OfferDetails,
                        permissions: ["OFFER_GET"],
                      },
                    ],
                  },
                ],
              },
              {
                path: "/supplier-prices",
                component: CompanyDetailsTabs,
                routes: [
                  {
                    path: "/supplier-price-history-create",
                    component: SupplierPriceHistoryCreate,
                  },
                  {
                    path: "/supplier-price-history-modify",
                    component: SupplierPriceHistoryDetails,
                  },
                ],
              },
            ],
          },
          {
            path: "/company-modify",
            component: CompanyModify,
            permissions: ["COMPANY_ADMIN"],
          },
          {
            path: "/company-create",
            component: CompanyCreate,
            permissions: ["COMPANY_ADMIN"],
          },
        ],
      },
      {
        path: "/delivery-round",
        component: CompanyTabs,
        permissions: ["DELIVERY_ROUND_GET"],
        routes: [
          {
            path: "/deliverypoint-list",
            component: DeliveryPointList,
            permissions: ["DELIVERY_ROUND_GET"],
          },
          {
            path: "/deliveryround-modify",
            component: DeliveryRoundModify,
            permissions: ["DELIVERY_ROUND_ADMIN"],
          },
          {
            path: "/deliveryround-item-modify",
            component: DeliveryRoundItemModify,
            permissions: ["DELIVERY_ROUND_ADMIN"],
          },
          {
            path: "/deliveryround-create",
            component: DeliveryRoundCreate,
            permissions: ["DELIVERY_ROUND_ADMIN"],
          },
        ],
      },
    ],
  },
  {
    path: "/own-company",
    component: OwnCompanyDetailsTabs,
    permissions: ["COMPANY_GET"],
    routes: [
      {
        path: "/company-details",
        component: OwnCompanyDetailsTabs,
        permissions: ["COMPANY_GET"],
        routes: [
          {
            path: "/details",
            component: OwnCompanyDetailsTabs,
            routes: [
              {
                path: "/contact-modify",
                component: ContactModify,
              },
              {
                path: "/contact-details",
                component: ContactDetails,
              },
              {
                path: "/contact-create",
                component: ContactCreate,
              },
              {
                path: "/companysite-create",
                component: CompanySiteCreate,
              },
              {
                path: "/companysite-modify",
                component: CompanySiteModify,
              },
              {
                path: "/bank-account-create",
                component: BankAccountCreate,
              },
              {
                path: "/bank-account-modify",
                component: BankAccountModify,
              },
            ],
          },
          {
            path: "/products",
            component: OwnCompanyDetailsTabs,
            routes: [
              {
                path: "/item-details",
                component: ItemDetailsTabs,
                routes: [
                  {
                    path: "/details",
                    component: ItemDetailsTabs,
                  },
                  {
                    path: "/serial-item-list",
                    component: ItemDetailsTabs,
                    routes: [
                      {
                        path: "/serial-item-create",
                        component: SerialItemCreate,
                        permissions: ["ITEM_ADMIN"],
                      },
                    ],
                  },
                ],
              },
              {
                path: "/item-create",
                component: RelCompanyItemCreate,
              },
              {
                path: "/item-modify",
                component: ItemModify,
              },
            ],
          },
          {
            path: "/invoices",
            component: OwnCompanyDetailsTabs,
            routes: [
              {
                path: "/invoice-create",
                component: InvoiceCreate,
                permissions: ["INVOICE_ADMIN"],
              },
              {
                path: "/invoice-details",
                component: InvoiceDetails,
                permissions: ["INVOICE_ADMIN"],
              },
              {
                path: "/invoice-modify",
                component: InvoiceModify,
                permissions: ["INVOICE_ADMIN"],
              },
            ],
          },
          {
            path: "/incoming-invoices",
            component: OwnCompanyDetailsTabs,
            routes: [
              {
                path: "/incoming-invoice-create",
                component: IncomingInvoiceCreate,
                permissions: ["INCOMING_INVOICE_ADMIN"],
              },
              {
                path: "/incoming-invoice-details",
                component: IncomingInvoiceDetails,
                permissions: ["INCOMING_INVOICE_ADMIN"],
              },
              {
                path: "/incoming-invoice-modify",
                component: IncomingInvoiceModify,
                permissions: ["INCOMING_INVOICE_ADMIN"],
              },
            ],
          },
          {
            path: "/delivery-notes",
            component: OwnCompanyDetailsTabs,
          },
          {
            path: "/delivery",
            component: OwnCompanyDetailsTabs,
          },
          {
            path: "/offers",
            component: OwnCompanyDetailsTabs,
            routes: [
              {
                path: "/in",
                component: OwnCompanyDetailsTabs,
                routes: [
                  {
                    path: "/offer-modify",
                    component: OfferModify,
                    permissions: ["OFFER_ADMIN", "OFFER_GET"],
                  },
                  {
                    path: "/offer-create",
                    component: OfferCreate,
                    permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
                  },
                  {
                    path: "/offer-details",
                    component: OfferDetails,
                    permissions: ["OFFER_GET"],
                  },
                ],
              },
              {
                path: "/out",
                component: OwnCompanyDetailsTabs,
                routes: [
                  {
                    path: "/offer-modify",
                    component: OfferModify,
                    permissions: ["OFFER_ADMIN", "OFFER_GET"],
                  },
                  {
                    path: "/offer-create",
                    component: OfferCreate,
                    permissions: ["OFFER_ADMIN", "PROJECT_ADMIN"],
                  },
                  {
                    path: "/offer-details",
                    component: OfferDetails,
                    permissions: ["OFFER_GET"],
                  },
                ],
              },
            ],
          },
          {
            path: "/supplier-prices",
            component: OwnCompanyDetailsTabs,
            routes: [
              {
                path: "/supplier-price-history-create",
                component: SupplierPriceHistoryCreate,
              },
              {
                path: "/supplier-price-history-modify",
                component: SupplierPriceHistoryDetails,
              },
            ],
          },
        ],
      },
    ],
  },
];
