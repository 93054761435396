import { Box, Button, Container, Grid, TextField } from "@material-ui/core";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { CompanyCategory, RelCompanyCategory } from "shared/types";
import { Dispatch, SetStateAction, useState } from "react";
import { listCompanyCategoryByTenant } from "shared/network/company-category.api";
import { Autocomplete } from "@material-ui/lab";
import { useDebouncedCallback } from "use-debounce";
import { PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY } from "config/constants";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const RelCompanyCategoryForm = ({ setOpen }: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext<RelCompanyCategory>();

  const [companyCategorySearch, setCompanyCategorySearch] =
    useState<string>("");

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const companyCategoryListQuery = useQuery(
    ["listCompanyCategoryForRel", tenant.id, companyCategorySearch],
    async () => {
      const { data } = await listCompanyCategoryByTenant(
        0,
        10,
        tenant.id,
        companyCategorySearch ? `name: ${companyCategorySearch}` : ``,
      );
      return data.page;
    },
  );

  const handleCompanyCategorySearchChange = useDebouncedCallback(
    (value: string) => {
      if (value.length >= 3 || value.length === 0) {
        setCompanyCategorySearch(value);
      }
    },
    PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  );

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} style={{ width: 250 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="companyCategory"
            defaultValue={null}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {field.onChange(value)
                  handleCompanyCategorySearchChange("");
                }}
                onInputChange={(event, newInputValue) => {
                  handleCompanyCategorySearchChange(newInputValue);
                }}
                options={companyCategoryListQuery.data?.content || []}
                getOptionLabel={(option: CompanyCategory) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true, required: true }}
                    label={t("relCompanyCategory.companyCategory")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" m={2} gridGap={8}>
        <Button color="primary" variant="text" onClick={() => setOpen(false)}>
          {t("common:button.cancel")}
        </Button>
        <Button type="submit" color="primary">
          {t("common:button.save")}
        </Button>
      </Box>
    </Container>
  );
};

export default RelCompanyCategoryForm;
