import axios from "config/axios";
import { GenericListResponse } from "shared/common";

export type ContractType = {
  id: number;
  name: string;
};

const ENDPOINT = "/contract-type";

export const getContractTypeList = (tenantId: number) =>
  axios.get<GenericListResponse<ContractType>>(
    `${ENDPOINT}/all?tenantId=${tenantId}`,
  );
