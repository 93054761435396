import { Dialog } from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { trim } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createTimeEntry } from "shared/network/time-entry.api";
import { Company, Issue, Project, User } from "shared/types";
import TimeEntryForm from "views/TimeEntry/TimeEntryForm";

export type Props = {
  project: Project;
  open: "IN" | "OUT" | null;
  onClose: () => void;
  issue?: Issue;
  reportedDate?: Date;
};

export type TimeEntryFormValues = {
  issueId: string;
  projectId: string;
  workDate: Date;
  workTime: number;
  description: string;
  company: Company;
  user: User;
};

const ProjectTimeEntryCreate = ({ open, onClose, project, issue, reportedDate }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<TimeEntryFormValues>();
  const { setValue, reset } = form;

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const tenant = selectedRelTenant?.tenant;

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: TimeEntryFormValues) => {
    setLoading(true);
    try {
      await createTimeEntry(
        {
          ...values,
          user: values.user,
          tenantId: tenant.id,
          projectId: values.projectId,
          offerId: project.offer?.id || null,
          contractId: project.contract?.id || null,
          description: trim(values.description),
          companyId: Number(values?.company?.id) || null,
          issue: { id: values.issueId },
        },
        tenant.id,
      );
      onClose();
      form.reset();
      queryClient.invalidateQueries([`getIssueByIdQuery`, issue?.id, tenant.id]);

      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("timeEntry.createSuccess"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      const error = e as any;
      if (error.data.status === "ISSUE_NOT_FOUND") {
        enqueueSnackbar(t("common:notification.issueNotFound.failure"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("timeEntry.createError"),
          }),
          { variant: "error" },
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (reportedDate) {
      setValue("workDate", reportedDate);
    }
  }, [reportedDate, setValue]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!open}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <Loading open={loading} />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <TimeEntryForm project={project} issue={issue} inout={open} onClose={onClose} />
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ProjectTimeEntryCreate;
