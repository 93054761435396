import { Box, Button, Card, IconButton, MenuItem, TextField, Tooltip } from "@material-ui/core";
import { AddBox, Clear, Done, Edit, ListAlt } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { PROGRESS_STATUS } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format as dateFormat } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listCompanyByTenant } from "shared/network/company.api";
import { getSupplierOrderListByTenantId } from "shared/network/order.api";
import { OrderStatusHistoryEntry } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

export interface StyleProps {
  value: number;
}

// export function getProgressValue(row: GridRowData) {
//   var progress,
//     correction = 1;
//   switch (row.orderStatusHistoryList[row.orderStatusHistoryList.length - 1].status) {
//     case PROGRESS_STATUS[0]: {
//       progress = 5;
//       break;
//     }
//     case PROGRESS_STATUS[1]: {
//       progress = 25;
//       break;
//     }
//     case PROGRESS_STATUS[2]: {
//       progress = 50;
//       break;
//     }
//     case PROGRESS_STATUS[3]: {
//       progress = 75;
//       break;
//     }
//     case PROGRESS_STATUS[4]: {
//       progress = 100 - correction;
//       break;
//     }
//     default: {
//       progress = 0;
//       break;
//     }
//   }
//   return progress + correction;
// }

const SupplierOrderList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-supplier-order-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-supplier-order-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const companyId = account?.user?.relUserCompany?.[0]?.company?.id;
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyId || "");

  const companyListQuery = useQuery(["companyListQuery", selectedRelTenant.tenant.id], async () => {
    const { data } = await listCompanyByTenant(
      0,
      1000000,
      selectedRelTenant.tenant.id,
      "isSupplier:true",
    );

    return data;
  });

  const fullSupplierOrderListQuery = useQuery(
    [
      "getSupplierOrderListByCompanyIdQuery",
      page,
      pageSize,
      selectedRelTenant.tenant.id,
      filterValue,
      sortState,
      companyId,
      selectedCompanyId,
    ],
    async () => {
      const { data } = await getSupplierOrderListByTenantId(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        (selectedCompanyId ? `company.id:${selectedCompanyId};` : "") + filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "orderId",
      headerName: t("order.orderId"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.orderId,
    },
    {
      field: "company.name",
      headerName: t("order.supplierCompany"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => row.company?.name,
    },
    {
      field: "deliveryAddress",
      headerName: t("order.deliveryAddress"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => displayAddress(row.deliveryAddress),
    },
    {
      field: "orderDate",
      headerName: t("order.orderDate"),
      sortable: false,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.orderStatusHistoryList
          ? dateFormat(new Date(row.orderStatusHistoryList[0].createdOn), "yyyy. MM. dd. HH:mm")
          : "-",
    },
    {
      field: "isPaid",
      headerName: t("order.isPaid.title"),
      flex: 0.5,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isPaid ? (
          <Tooltip
            title={
              t("order.isPaid.title") +
              " " +
              (row.paidDate ? dateFormat(new Date(row.paidDate), "yyyy. MM. dd. HH:mm") : "")
            }
          >
            <Done style={{ color: COLORS.green }} />
          </Tooltip>
        ) : (
          <Tooltip title={t(`order.isPaid.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "invoiceSent",
      headerName: t("order.invoiceSent"),
      flex: 0.6,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.orderStatusHistoryList ? (
          row.orderStatusHistoryList.find(
            (orderStatus: OrderStatusHistoryEntry) => orderStatus.status === PROGRESS_STATUS[5],
          ) ? (
            <Tooltip
              title={
                t(`order.billed.true`).toString() +
                " " +
                dateFormat(
                  row.orderStatusHistoryList.find(
                    (orderStatus: OrderStatusHistoryEntry) =>
                      orderStatus.status === PROGRESS_STATUS[5],
                  ).createdOn,
                  "yyyy. MM. dd. HH:mm",
                )
              }
            >
              <Done style={{ color: COLORS.green }} />
            </Tooltip>
          ) : (
            <Tooltip title={t(`order.billed.false`).toString()}>
              <Clear style={{ color: COLORS.red }} />
            </Tooltip>
          )
        ) : (
          <Tooltip title={t(`order.billed.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Box display="flex" justifyContent="flex-end" width="100%">
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "ORDER_GET",
              "ORDER_ADMIN",
            ]) && (
              <Tooltip title={t("order.details.title").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  component={Link}
                  to={`/order/supplier-list/details?id=${row.id}`}
                >
                  <ListAlt />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "ORDER_GET",
              "ORDER_ADMIN",
            ]) && (
              <Tooltip title={t("order.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  component={Link}
                  to={`/order/supplier-list/modify?id=${row.id}`}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/order/supplier-list/details?id=${params.row.id}`);
    }
  }

  useEffect(
    () => {
      setHeaderButtons(
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_ADMIN"]) && (
            <Button
              component={Link}
              to={`/order/supplier-list/create?id=${
                !!account.user.relUserCompany && account?.user?.relUserCompany[0]?.id
              }`}
              startIcon={<AddBox />}
            >
              {t("order.createSupply")}
            </Button>
          )}
        </Box>,
      );
      return () => {
        setHeaderButtons(null);
      };
    },
    /*eslint-disable*/
    [account.user, account.permissions, selectedRelTenant, filterValue],
  ); /*eslint-enable*/

  return (
    <Card style={{ backgroundColor: "rgba(255,255,255,0.6)", marginBottom: 16 }}>
      <Box width="100%" display="flex" justifyContent="center" pt={2}>
        <Box>
          <TextField
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            label={t("companySite.formValues.company")}
            style={{ width: 300 }}
            value={selectedCompanyId}
            onChange={event => {
              setSelectedCompanyId(event.target.value);
            }}
          >
            <MenuItem value="">{t("order.allCompany").toString()}</MenuItem>
            {companyListQuery.data?.page?.content.map(company => (
              <MenuItem key={company.id} value={company.id}>
                {company.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <PageableTable
        transparent
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="supplier-order"
        filterable={["deliveryAddress", "orderId"]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={fullSupplierOrderListQuery}
        onCellClick={onCellClick}
      />
    </Card>
  );
};

export default SupplierOrderList;
