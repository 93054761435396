import { faEdit, faPenFancy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Switch,
  Tooltip,
} from "@material-ui/core";
import {
  AccessTime,
  AddBox,
  Assignment,
  AssignmentTurnedInOutlined,
  Block,
  CancelOutlined,
  Check,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  CheckCircleOutline,
  Edit,
  GetApp,
  IndeterminateCheckBox,
  MonetizationOnOutlined,
  Replay,
  SaveOutlined,
  Send,
  SendOutlined,
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowData,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import Loading from "components/Loading";
import PageableTable from "components/PageableTable/PageableTable";
import { INVOICE_TYPE } from "config/constants";
import { RootState } from "config/store";
import { COLORS, GRADIENT } from "config/theme";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { useGetSessionStorageKey } from "views/Comment/function";
import { getInvoiceFile } from "shared/network/files.api";
import {
  approveInvoice,
  exportInvoiceCSV,
  listInvoicePageable,
  sendInvoiceToNAV,
} from "shared/network/invoice.api";
import { Invoice } from "shared/types";
import { csvDownload, pdfDownload } from "shared/util/fileDownload";
import { TableState } from "views/Company/CompanyList";
import InvoiceStatusModifyDialog from "views/Invoice/InvoiceStatusModifyDialog";

const useStyles = makeStyles(
  {
    progressPercentage1: {
      "& .MuiLinearProgress-barColorPrimary": {
        background: GRADIENT,
        width: "100%",
        height: 10,
        borderRadius: 5,
      },
      "&.MuiLinearProgress-colorPrimary": {
        background: COLORS.mainGrey,
        width: "100%",
        height: 10,
        borderRadius: 5,
      },
    },
    switch: {
      "&.MuiFormControlLabel-labelPlacementStart": {
        marginLeft: 0,
        marginRight: 0,
        flexDirection: "row-reverse",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      },
    },
  },
  { name: "OrderlistByStatus" },
);

const PROGRESS_STATUS = [
  "PENDING",
  "REJECTED",
  "ACCEPTED",
  "SENT_TO_PARTNER",
  "SAVED",
  "CLOSED",
  "RECEIVABLES_MANAGEMENT",
  "PAID",
];

function getProgressValue(row: GridRowData) {
  let progress = 0;
  switch (row.status) {
    case PROGRESS_STATUS[0]: {
      progress = 12.5;
      break;
    }
    case PROGRESS_STATUS[1]: {
      progress = 25;
      break;
    }
    case PROGRESS_STATUS[2]: {
      progress = 37.5;
      break;
    }
    case PROGRESS_STATUS[3]: {
      progress = 50;
      break;
    }
    case PROGRESS_STATUS[4]: {
      progress = 62.5;
      break;
    }
    case PROGRESS_STATUS[5]: {
      progress = 75;
      break;
    }
    case PROGRESS_STATUS[6]: {
      progress = 87.5;
      break;
    }
    case PROGRESS_STATUS[7]: {
      progress = 100;
      break;
    }
    default: {
      progress = 0;
      break;
    }
  }
  return progress;
}

const TenantAllInvoice = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tenantallinvoice-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-tenantallinvoice-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const [multiSelectTarget, setMultiSelectTarget] = useState<string>("");
  const [toSelectedList, setToSelectedList] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [approveOpen, setApproveOpen] = useState<boolean>(false);

  const form = useForm<{ sendToNav: boolean }>();

  const getAllInvoiceListQuery = useQuery(
    [
      "getAllInvoiceListQuery",
      page,
      pageSize,
      tenant.id,
      filterValue,
      sortState,
      multiSelectTarget,
      selectedStatus,
    ],
    async () => {
      const { data } = await listInvoicePageable(
        page,
        pageSize,
        tenant.id,
        (!!account.user.relUserCompany?.length
          ? `customerId:${account?.user?.relUserCompany?.[0]?.company.id || ""};`
          : "") +
          (multiSelectTarget === "APPROVE" ? `isApproved:false;` : "") +
          (multiSelectTarget === "SEND" ? `status:PENDING;invoiceType!=REQUESTER` : "") +
          (!multiSelectTarget && selectedStatus ? `status:${selectedStatus};` : "") +
          filterValue,
        sortState,
      );
      return data;
    },
  );

  const { control, handleSubmit } = useForm<{
    startDate: Date;
    endDate: Date;
  }>();

  const [csvOpen, setCsvOpen] = useState(false);

  function isSelected(id: number) {
    return toSelectedList.includes(id);
  }

  async function approveInvoiceFunction(values: { sendToNav: boolean }) {
    try {
      if (!isEmpty(toSelectedList)) {
        setLoading(true);
        await approveInvoice(toSelectedList, values.sendToNav, tenant.id);
        enqueueSnackbar(t("common:notification.crediting.success"), {
          variant: "success",
        });
        setToSelectedList([]);
        setMultiSelectTarget("");
        getAllInvoiceListQuery.refetch();
        setApproveOpen(false);
        setLoading(false);
      }
    } catch {
      setLoading(false);
      enqueueSnackbar(t("common:notification.crediting.failure"), {
        variant: "error",
      });
    }
  }

  async function sendInvoiceToNAVFunction(ids: number[]) {
    try {
      if (!isEmpty(ids)) {
        setLoading(true);
        await sendInvoiceToNAV(ids, tenant.id);
        enqueueSnackbar(t("common:notification.navSend.success"), {
          variant: "success",
        });
        setToSelectedList([]);
        setMultiSelectTarget("");
        getAllInvoiceListQuery.refetch();
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      enqueueSnackbar(t("common:notification.navSend.failure"), {
        variant: "error",
      });
    }
  }

  async function exportCsv(values: { startDate: Date; endDate: Date }) {
    try {
      const { data } = await exportInvoiceCSV(
        selectedRelTenant.tenant.id,
        format(values.startDate, "yyyy-MM-dd"),
        format(values.endDate, "yyyy-MM-dd"),
      );
      csvDownload(
        data.contentBase64,
        `${t("common:csvDownload.title")}-${t("common:csvDownload.INVOICE")}-${data.fileName}`,
      );
      enqueueSnackbar(
        t("common:notification.csvExport.success", {
          subject: t("invoice.subject"),
        }),
        {
          variant: "success",
        },
      );
      setCsvOpen(false);
    } catch {
      enqueueSnackbar(
        t("common:notification.csvExport.failure", {
          subject: t("invoice.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "multiSelect",
      headerName: " ",
      width: 50,
      hide: !multiSelectTarget,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        if (toSelectedList?.length === getAllInvoiceListQuery.data?.page?.content?.length) {
          return (
            <IconButton size="small" color="primary" onClick={() => setToSelectedList([])}>
              <CheckBoxOutlined />
            </IconButton>
          );
        } else if (toSelectedList?.length === 0) {
          return (
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                setToSelectedList(
                  getAllInvoiceListQuery.data?.page?.content?.map(value => value.id as number) ||
                    [],
                )
              }
            >
              <CheckBoxOutlineBlankOutlined />
            </IconButton>
          );
        } else {
          return (
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                setToSelectedList(
                  getAllInvoiceListQuery.data?.page?.content?.map(value => value.id as number) ||
                    [],
                )
              }
            >
              <IndeterminateCheckBox />
            </IconButton>
          );
        }
      },
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {isSelected(row.id) ? (
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                setToSelectedList(prevState => [...prevState].filter(entry => entry !== row.id))
              }
            >
              <CheckBoxOutlined />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              color="primary"
              onClick={() => setToSelectedList(prevState => [...prevState, row.id])}
            >
              <CheckBoxOutlineBlankOutlined />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: "serialNumber",
      headerName: t("invoice.serialNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "customerName",
      headerName: t("invoice.formValues.customer.customerName"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "creationDate",
      headerName: t("invoice.formValues.creationDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.creationDate), "yyyy. MM. dd."),
      type: "date",
    },
    {
      field: "invoiceType",
      headerName: t("invoice.formValues.invoiceType.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`invoice.formValues.invoiceType.${row.invoiceType}`),
      type: "select",
    },
    {
      //kiallitott szamla
      field: "grossPrice",
      headerName: t("invoice.invoiceItem.grossPrice"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => {
        if (
          account.user.isSuperAdmin ||
          selectedRelTenant.isTenantAdmin ||
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["PRICE_WATCHER"])
        ) {
          return t("common:number", { num: row.grossPrice || "0" }) + " Ft";
        } else return null;
      },
    },
    {
      field: "isApproved",
      headerName: t("invoice.isApproved"),
      flex: 1,
      type: "boolean",
      hide: !tenant.invoiceCreditingIsRequired,
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isApproved ? (
          <CheckCircleOutline style={{ color: COLORS.green }} />
        ) : (
          <Block style={{ color: COLORS.red }} />
        ),
    },
    {
      field: "status",
      headerName: t("order.status"),
      width: 350,
      sortable: false,
      hide: !!multiSelectTarget,
      type: "select",
      renderHeader: () => (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Tooltip title={t("invoice.formValues.status.PENDING").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <AccessTime
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[0] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[0] ? null : PROGRESS_STATUS[0],
                );
                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.REJECTED").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <CancelOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[1] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[1] ? null : PROGRESS_STATUS[1],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.ACCEPTED").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <CheckCircleOutline
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[2] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[2] ? null : PROGRESS_STATUS[2],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("invoice.formValues.status.SENT_TO_PARTNER").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              children={
                <SendOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[3] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[3] ? null : PROGRESS_STATUS[3],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.SAVED").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <SaveOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[4] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[4] ? null : PROGRESS_STATUS[4],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.CLOSED").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <AssignmentTurnedInOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[5] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[5] ? null : PROGRESS_STATUS[5],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("invoice.formValues.status.RECEIVABLES_MANAGEMENT").toString()}
            placement="top"
            arrow
          >
            <IconButton
              size="small"
              children={
                <FontAwesomeIcon
                  icon={faPenFancy}
                  style={{
                    fontSize: 20,
                    width: 20,

                    color:
                      selectedStatus === PROGRESS_STATUS[6] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[6] ? null : PROGRESS_STATUS[6],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
          <Tooltip title={t("invoice.formValues.status.PAID").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <MonetizationOnOutlined
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[7] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                setSelectedStatus(
                  selectedStatus === PROGRESS_STATUS[7] ? null : PROGRESS_STATUS[7],
                );

                setTableState(prevState => {
                  return { ...prevState, page: 0 };
                });
              }}
            />
          </Tooltip>
        </Box>
      ),
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Grid container>
            <Grid container item xs={12}>
              <Tooltip
                arrow
                placement="top"
                title={
                  !isEmpty(row.orderStatusHistoryList)
                    ? `${format(
                        new Date(
                          row.orderStatusHistoryList[
                            row.orderStatusHistoryList.length - 1
                          ].createdOn,
                        ),
                        "yyyy. MM. dd. HH:mm",
                      )}`
                    : ""
                }
              >
                <LinearProgress
                  className={classes.progressPercentage1}
                  value={getProgressValue(row)}
                  variant="determinate"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.pdfPath && (
              <Tooltip title={t("invoice.downloadPdf").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 4px" }}
                  onClick={async () => {
                    if (row.id) {
                      try {
                        const { data } = await getInvoiceFile(row.id, tenant.id);
                        pdfDownload(data, row?.serialNumber, t("common:pdfDownload.INVOICE"));
                        enqueueSnackbar(
                          t("common:notification.download.success", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "success",
                          },
                        );
                      } catch {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "error",
                          },
                        );
                      }
                    }
                  }}
                >
                  <GetApp />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_GET"]) && (
            <Tooltip title={t("invoice.details").toString()}>
              <IconButton
                component={Link}
                to={`/invoices/invoices-list/invoice-details?id=${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 4px" }}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Tooltip title={t("invoice.modifyStatus").toString()}>
              <IconButton
                onClick={() => setSelectedInvoice(row as Invoice)}
                color="primary"
                size="small"
                style={{ margin: "0 4px" }}
              >
                <FontAwesomeIcon icon={faEdit} width={24} height={24} />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.status !== "PENDING" &&
            row.invoiceType !== "STORNO" && (
              <Tooltip title={t("invoice.storno").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 4px" }}
                  component={Link}
                  to={`/invoices/invoices-list/invoice-create?id=${row.id}&type=del`}
                  disabled={row.invoiceType !== "NORMAL"}
                >
                  <Replay color="primary" />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.status === "PENDING" && (
              <Tooltip title={t("invoice.modify").toString()}>
                <IconButton
                  component={Link}
                  to={`/invoices/invoices-list/invoice-modify?id=${row.id}`}
                  size="small"
                  color="primary"
                  style={{ margin: "0 4px" }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            row.status !== "PENDING" && (
              <Tooltip title={t("invoice.modifyInvoice").toString()}>
                <IconButton
                  component={Link}
                  to={`/invoices/invoices-list/invoice-create?id=${row.id}&type=mod`}
                  size="small"
                  color="primary"
                  style={{ margin: "0 4px" }}
                  disabled={row.invoiceType !== "NORMAL"}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (!["actions", "multiSelect"].includes(params.field)) {
      history.push(`/invoices/invoices-list/invoice-details?id=${params.row.id}`);
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <>
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_ADMIN",
          ]) && (
            <Button
              component={Link}
              to="/invoices/invoices-list/invoice-create"
              startIcon={<AddBox />}
            >
              {t("invoice.create")}
            </Button>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "INVOICE_GET",
            "INVOICE_CSV_GET",
          ]) && (
            <Button
              onClick={() => {
                setCsvOpen(true);
              }}
              startIcon={<GetApp />}
            >
              {t("invoice.csvExport")}
            </Button>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            multiSelectTarget !== "SEND" &&
            tenant.invoiceCreditingIsRequired && (
              <Button
                onClick={() => {
                  multiSelectTarget !== "APPROVE"
                    ? setMultiSelectTarget("APPROVE")
                    : setApproveOpen(true) /*approveInvoiceFunction()*/;
                }}
                variant={multiSelectTarget === "APPROVE" ? "outlined" : "contained"}
                startIcon={<Check />}
              >
                {t("invoice.multiApprove")}
              </Button>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            multiSelectTarget !== "APPROVE" &&
            tenant.technicalSignatureKey && (
              <Button
                onClick={() => {
                  multiSelectTarget !== "SEND"
                    ? setMultiSelectTarget("SEND")
                    : sendInvoiceToNAVFunction([...toSelectedList]);
                }}
                variant={multiSelectTarget === "SEND" ? "outlined" : "contained"}
                startIcon={<Send />}
              >
                {t("invoice.multiNavSend")}
              </Button>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) &&
            !!multiSelectTarget && (
              <Button
                variant="text"
                onClick={() => {
                  setMultiSelectTarget("");
                  setToSelectedList([]);
                }}
              >
                {t("common:button.cancel")}
              </Button>
            )}
        </Box>
      </>,
    );
    return () => {
      setHeaderButtons(null);
    };
  //eslint-disable-next-line
  }, [account.user, account.permissions, selectedRelTenant, filterValue, multiSelectTarget, toSelectedList ]); // prettier-ignore

  return (
    <Box mb={2.5}>
      <Loading open={loading} />
      <InvoiceStatusModifyDialog
        setSelectedInvoice={setSelectedInvoice}
        selectedInvoice={selectedInvoice}
        refetch={getAllInvoiceListQuery.refetch}
      />
      <PageableTable
        filterOptions={[
          {
            columnName: "invoiceType",
            options: INVOICE_TYPE.map(value => {
              return {
                translated: t(`invoice.formValues.invoiceType.${value}`),
                value,
              };
            }),
          },
          {
            columnName: "status",
            options: PROGRESS_STATUS.map(value => {
              return {
                translated: t(`common:progress.status.${value}`),
                value,
              };
            }),
          },
        ]}
        filterable={["customerName", "creationDate", "serialNumber", "status", "invoiceType"]} //ezt ha a backenden jó, ki kell szedni
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="tenantallinvoice"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getAllInvoiceListQuery}
        onCellClick={onCellClick}
      />
      <Dialog open={csvOpen} maxWidth="xs" onClose={() => setCsvOpen(false)}>
        <DialogTitle>{t("invoice.csvExport")}</DialogTitle>
        <DialogContent>
          <form id="csvexport" onSubmit={handleSubmit(exportCsv)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="startDate"
                  defaultValue={new Date()}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="validTo"
                      variant="inline"
                      label={t("invoice.csvStartDate")}
                      format="yyyy.MM.dd"
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoOk
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="endDate"
                  defaultValue={new Date()}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="validTo"
                      variant="inline"
                      label={t("invoice.csvEndDate")}
                      format="yyyy.MM.dd"
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoOk
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setCsvOpen(false);
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" form="csvexport">
            {t("common:button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={approveOpen} maxWidth="xs" onClose={() => setApproveOpen(false)}>
        <DialogTitle>{t("invoice.approveConfirmTitle")}</DialogTitle>
        <DialogContent>
          <form id="approveForm" onSubmit={form.handleSubmit(approveInvoiceFunction)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="sendToNav"
                  control={form.control}
                  defaultValue={true}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("invoice.formValues.sendToNavAfterApprove")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setApproveOpen(false);
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" form="approveForm">
            {t("common:button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TenantAllInvoice;
