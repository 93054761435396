import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect, Switch } from "react-router-dom";
import { hasAnyAuthority, hasAuthority } from "shared/authorization";
import TenantAllScrapList from "views/Admin/Tenants/TenantAllScrapList";
import ItemTransfer from "./ItemTransfer";
import WarehouseTransactionList from "./Transaction/WarehouseTransactionList";
import WarehouseList from "./WarehouseList";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";

export const WAREHOUSE_TABS = [
  "/warehouse/list",
  "/warehouse/item-transfer",
  `/warehouse/scrap-list`,
  `/warehouse/transaction-list`,
];

const WarehouseTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/warehouse" && <Redirect to="/warehouse/list" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_GET",
        ]) && (
          <Tab
            label={t("drawer./warehouse/list")}
            component={Link}
            to={WAREHOUSE_TABS[0]}
            value={WAREHOUSE_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_ADMIN",
        ]) && (
          <Tab
            label={t("drawer./warehouse/item-transfer")}
            component={Link}
            to={WAREHOUSE_TABS[1]}
            value={WAREHOUSE_TABS[1]}
          />
        )}

        {(selectedRelTenant?.isTenantAdmin || account.user.isSuperAdmin) && (
          <Tab
            label={t("tenant.tabs.scrapList")}
            component={Link}
            to={WAREHOUSE_TABS[2]}
            value={WAREHOUSE_TABS[2]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "TRANSACTIONS_GET",
          "TRANSACTIONS_ADMIN",
        ]) && (
          <Tab
            label={t("tenant.tabs.transactions")}
            component={Link}
            to={WAREHOUSE_TABS[3]}
            value={WAREHOUSE_TABS[3]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={WAREHOUSE_TABS[0]}
              component={WarehouseList}
              permissions={["WAREHOUSE_GET"]}
            />
            <PrivateRoute
              path={WAREHOUSE_TABS[1]}
              component={ItemTransfer}
              permissions={["WAREHOUSE_GET"]}
            />
            {(selectedRelTenant?.isTenantAdmin || account.user.isSuperAdmin) && (
              <PrivateRoute path={WAREHOUSE_TABS[2]} component={TenantAllScrapList} />
            )}
            <PrivateRoute
              path={WAREHOUSE_TABS[3]}
              component={WarehouseTransactionList}
              permissions={["TRANSACTIONS_GET", "TRANSACTIONS_ADMIN"]}
            />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default WarehouseTabs;
