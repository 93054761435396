//export const functionSearchList: string[] = ["", "/contract-create"];

export type FunctionSearchEntry = {
  target: string;
  needTenantAdmin: boolean;
  needSuperAdmin: boolean;
  permissions: string[];
};

// prettier-ignore
export const functionSearchList: FunctionSearchEntry[] = [
  // FROM RouteOne.tsx  
  { target: "/home", needTenantAdmin: false, needSuperAdmin: false, permissions: [] },
  { target: "/tos", needTenantAdmin: false, needSuperAdmin: false, permissions: [] },
  { target: "/gdpr", needTenantAdmin: false, needSuperAdmin: false, permissions: [] },
  { target: "/offer/out", needTenantAdmin: false, needSuperAdmin: false, permissions: ["OFFER_ADMIN", "OFFER_GET"]},
  { target: "/offer/in", needTenantAdmin: false, needSuperAdmin: false, permissions: ["OFFER_ADMIN", "OFFER_GET"]},
  { target: "/offer/out/create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["OFFER_ADMIN", "OFFER_GET"]},
  { target: "/offer/out/copy", needTenantAdmin: false, needSuperAdmin: false, permissions: ["OFFER_ADMIN", "OFFER_GET"]},
  { target: "/offer/in/create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["OFFER_ADMIN", "OFFER_GET"]},
  { target: "/offer/out/copy", needTenantAdmin: false, needSuperAdmin: false, permissions: ["OFFER_ADMIN", "OFFER_GET"]},
  { target: "/contract", needTenantAdmin: false, needSuperAdmin: false, permissions: ["CONTRACT_GET"]},
  { target: "/contract/create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["CONTRACT_GET"]},
  { target: "/order/list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ORDER_ADMIN","ORDER_GET"]},
  { target: "/order/supplier-list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ORDER_ADMIN","ORDER_GET"]},   
  { target: "/order/list/create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ORDER_ADMIN"]},
  { target: "/order/supplier-list/create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ORDER_ADMIN"]},
  { target: "/project", needTenantAdmin: false, needSuperAdmin: false, permissions: ["PROJECT_ADMIN"]},
  { target: "/project/create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["PROJECT_ADMIN"]},
  { target: "/planning", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_ADMIN", "VEHICLE_GET"]},
  { target: "/planning/inside-delivery-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_ADMIN"]},

  //FROM RouteTwo.tsx
  { target: "/warehouse/list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_GET"]},
  { target: "/warehouse/list/create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_ADMIN"]},
  { target: "/warehouse/list/warehouse-details/scrap-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_ADMIN"]},
  { target: "/warehouse/item-transfer", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_GET", "MILESTONE_ADMIN"]},

  //FROM RouteThree.tsx
  { target: "/issues/all", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_GET"]},
  { target: "/issues/all/time-entry-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_GET"]},
  { target: "/issues/all/issue-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_ADMIN"]},
  { target: "/issues/personal", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_GET"]},
  { target: "/issues/templates", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_TEMPLATE_GET"]},
  { target: "/issues/templates/issue-template-create"	, needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_TEMPLATE_ADMIN"]},
  { target: "/issues/issue-type"	, needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_TYPE_GET"]},
  { target: "/issues/issue-type/issue-type-create"	, needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_TYPE_ADMIN"]},
  { target: "/issues/custom-fields"	, needTenantAdmin: false, needSuperAdmin: false, permissions: ["ISSUE_GET"]},
  { target: "/issues/custom-fields/custom-fields-create"	, needTenantAdmin: false, needSuperAdmin: false, permissions: ["CUSTOM_FIELDS_ADMIN"]},

  //FROM RoutesFour.tsx
  { target: "/admin/bundles", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/bundles/bundle-create", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/tenants", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/tenants/tenant-create", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/tenants/subscription-create", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/currencies", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/currencies/currency-create", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/permission-groups", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/company-category", needTenantAdmin: true, needSuperAdmin: false, permissions: []},  
  { target: "/admin/articles", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/articles/article-create", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/packaging", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
  { target: "/admin/audit-log", needTenantAdmin: false, needSuperAdmin: true, permissions: []},
  { target: "/admin/properties", needTenantAdmin: false, needSuperAdmin: true, permissions: []},

//FROM RoutesFive.tsx
{ target: "/my-company/users", needTenantAdmin: false, needSuperAdmin: false, permissions: ["USER_ADMIN", "USER_GET"]},
{ target: "/my-company/user/user-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["USER_ADMIN"]},
{ target: "/my-company/employees", needTenantAdmin: false, needSuperAdmin: false, permissions: ["EMPLOYEE_ADMIN","EMPLOYEE_GET"]},
{ target: "/my-company/employees/employee-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["EMPLOYEE_ADMIN"]},
{ target: "/my-company/departments", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DEPARTMENT_ADMIN", "DEPARTMENT_GET"]},
{ target: "/my-company/absences", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ABSENCE_ADMIN", "ABSENCE_GET"]},
{ target: "/vehicles", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_ADMIN", "VEHICLE_GET"]},
{ target: "/vehicles/list/vehicle-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_ADMIN"]},
{ target: "/vehicles/cash-by-vehicle", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_GET", "MILESTONE_ADMIN"]},
{ target: "/vehicle/daily-delivery",  needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_ADMIN", "DELIVERY_GET", "WAREHOUSE_GET"]},
{ target: "/my-company/tools", needTenantAdmin: false, needSuperAdmin: false, permissions: ["TOOL_ADMIN", "TOOL_GET"]},
{ target: "/my-company/tools/tool-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["TOOL_ADMIN"]},

//FROM RoutesSix.tsx
{ target: "/companies/list-all", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_GET", "COMPANY_ADMIN"]},
{ target: "/companies/company-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_GET", "COMPANY_ADMIN"]},
{ target: "/companies/list-customer", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_GET", "COMPANY_ADMIN"]},
{ target: "/companies/list-supplier", needTenantAdmin: false, needSuperAdmin: false, permissions: ["COMPANY_GET", "COMPANY_ADMIN"]},
{ target: "/companies/delivery-round", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_ROUND_GET", "DELIVERY_ROUND_ADMIN"]},
{ target: "/companies/delivery-round/deliveryround-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_ROUND_ADMIN"]},

//FROM RoutesSeven.tsx
{ target: "/mydelivery", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_GET", "DELIVERY_GET", "DELIVERY_ROUND_GET"]},
{ target: "/monitoring/dashboard", needTenantAdmin: false, needSuperAdmin: false, permissions: ["MONITORING_GET", "MONITORING_ADMIN"]},
{ target: "/monitoring/machines", needTenantAdmin: false, needSuperAdmin: false, permissions: ["MONITORING_GET", "MONITORING_ADMIN"]},
{ target: "/monitoring/reports", needTenantAdmin: false, needSuperAdmin: false, permissions: ["MONITORING_GET", "MONITORING_ADMIN"]},
{ target: "/monitoring/transactions", needTenantAdmin: false, needSuperAdmin: false, permissions: ["MONITORING_DEPOSITOR_GET"]},
{ target: "/statistics/item", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_STATISTIC_GET"]},
{ target: "/statistics/delivery-round", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_STATISTIC_GET"]},
{ target: "/statistics/offer", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_STATISTIC_GET"]},
{ target: "/statistics/contract", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_STATISTIC_GET"]},
{ target: "/statistics/order", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_STATISTIC_GET"]},
{ target: "/items/list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_GET", "ITEM_ADMIN"]},
{ target: "/items/list/item-create", needTenantAdmin: false, needSuperAdmin: false, permissions: ["ITEM_ADMIN"]},
{ target: "/items/list/categories", needTenantAdmin: false, needSuperAdmin: false, permissions: ["CATEGORY_ADMIN", "CATEGORY_GET"]},
{ target: "/profile/details", needTenantAdmin: false, needSuperAdmin: false, permissions: []},
{ target: "/profile/modify", needTenantAdmin: false, needSuperAdmin: false, permissions: []},
{ target: "/profile/password-modify", needTenantAdmin: false, needSuperAdmin: false, permissions: []},
{ target: "/my-company/details", needTenantAdmin: false, needSuperAdmin: false, permissions: []},
{ target: "/order/delivery-note", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_NOTE_ADMIN"]},
{ target: "/order/delivery-all", needTenantAdmin: false, needSuperAdmin: false, permissions: ["DELIVERY_GET", "DELIVERY_ADMIN"]},
{ target: "/invoices/invoices-list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["INVOICE_GET","INVOICE_ADMIN"]},
{ target: "/invoices/incoming-list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["INCOMING_INVOICE_ADMIN", "INCOMING_INVOICE_GET"]},
{ target: "/warehouse/scrap-list", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
{ target: "/statistics/expense", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
{ target: "/my-company/settings", needTenantAdmin: true, needSuperAdmin: false, permissions: []},
{ target: "/warehouse/transaction-list", needTenantAdmin: false, needSuperAdmin: false, permissions: ["WAREHOUSE_GET"]},


{ target: "/admin/expense-identifier", needTenantAdmin: true, needSuperAdmin: false, permissions:[]},
{ target: "/invoices/invoice-generate", needTenantAdmin: false, needSuperAdmin: false,  permissions: ["INVOICE_ADMIN", "INVOICE_GET"]},
{ target: "/planning/delivery-round",  needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_ADMIN", "VEHICLE_GET"]},

{ target: "/vehicles/", needTenantAdmin: false, needSuperAdmin: false, permissions: ["VEHICLE_ADMIN", "VEHICLE_GET"]},
];
