import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AddBox, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DailyReport } from "shared/network/daily-report.api";
import { RelDailyReportIssue } from "shared/network/rel-daily-report-issue.api";
import UseProjectItemDialog from "views/Project/components/UseProjectItemDialog";
import DailyReportCreateItem from "./DailyReportCreateItem";

type Props = {
  index: number;
  dailyReport?: DailyReport | null;
  relDailyReportIssue: RelDailyReportIssue;
  isClosed: boolean;
};

const DailyReportCreateItemRow = ({ index, dailyReport, relDailyReportIssue, isClosed }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const form = useFormContext();
  const { watch } = form;
  const project = watch("project");
  const [openItem, setOpenItem] = useState(false);
  const [open, setOpen] = useState(false);

  const issue = relDailyReportIssue?.issue;

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gridGap={8}
        pb={2}
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">
              {index + 1}. {issue?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent={matches ? "flex-end" : "flex-start"}>
            {!isClosed && (
              <Button
                color="primary"
                size="small"
                startIcon={<AddBox />}
                onClick={() => setOpen(true)}
              >
                {t("dailyReport.dailyReportItem")}
              </Button>
            )}
          </Grid>
        </Grid>
        <IconButton size="small" onClick={() => setOpenItem(!openItem)}>
          {openItem ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Box>
      <UseProjectItemDialog
        open={open}
        onClose={() => setOpen(false)}
        project={project}
        isDailyReport={true}
        issue={relDailyReportIssue?.issue}
        date={dailyReport?.reportedDate}
      />
      <Collapse in={openItem}>
        <DailyReportCreateItem
          issue={issue}
          openTime={openItem}
          setOpenTime={setOpenItem}
          reportedDate={dailyReport?.reportedDate}
          isClosed={isClosed}
        />
      </Collapse>
      <Divider />
    </Grid>
  );
};

export default DailyReportCreateItemRow;
