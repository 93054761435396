import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import { PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY } from "config/constants";
import queryClient from "config/query";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { listItems } from "shared/network/item.api";
import { createRelIssueItem } from "shared/network/rel-issue-item.api";
import { createUsedProjectItem } from "shared/network/used-project-item.api";
import { Issue, Item, Project } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ListboxComponent } from "./ProjectOfferAddDialog";

type Props = {
  open: boolean;
  onClose: () => void;
  project: Project;
  isDailyReport?: boolean;
  issue?: Issue;
  date?: string;
  refetch?: () => void;
};

type FormValues = {
  item: any;
  quantity: string;
  price: string;
  netPrice: string;
  discountPrice: string;
};

const UseProjectItemDialog = ({
  open,
  onClose,
  project,
  isDailyReport,
  issue,
  date,
  refetch,
}: Props) => {
  const { t } = useTranslation();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const form = useForm<FormValues>();
  const { control, handleSubmit, watch } = form;
  const selectedItem = watch("item");

  const [itemSearch, setItemSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      if (isDailyReport) {
        await createRelIssueItem(
          {
            issue: issue,
            item: values.item,
            quantity: Number(values.quantity),
            isUsed: true,
            tenantId: tenant?.id,
            date: date ? format(new Date(date), "yyyy-MM-dd") : undefined,
          },
          tenant?.id,
        );
      } else {
        await createUsedProjectItem({ ...values, project }, tenant?.id);
      }
      queryClient.invalidateQueries([`getIssueByIdQuery`, issue?.id, tenant.id]);
      refetch?.();
      onClose();
    } catch {}
    setLoading(false);
  }

  const { data, fetchNextPage } = useInfiniteQuery(
    [`listItemsAsdQuery`, tenant.id, itemSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listItems(
        pageParam,
        20,
        tenant.id,
        itemSearch ? `;name:${itemSearch}` : "",
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatPages = data?.pages.map(page => page.page?.content).flat();
  const hasNextPage =
    data && flatPages && data?.pages?.[0]?.page?.totalElements > flatPages?.length;

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    setItemSearch(value);
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <Loading open={loading} />
      <DialogTitle>Anyag felhasználása</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="project-item-use-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="item"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        handleItemSearchChange("");
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleItemSearchChange(newInputValue);
                      }}
                      options={flatPages || []}
                      getOptionLabel={(option: Item) =>
                        option.name + (!!option.productCode ? ` (${option.productCode})` : "")
                      }
                      getOptionSelected={option => option.id === field.value?.id}
                      ListboxComponent={props => (
                        <ListboxComponent
                          {...props}
                          hasNextPage={hasNextPage}
                          fetchNextPage={fetchNextPage}
                        />
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("cargo.formValues.item")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="quantity"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      margin="none"
                      type="number"
                      label={t("Mennyiség")}
                      InputLabelProps={{ shrink: true, required: true }}
                      InputProps={{
                        endAdornment: (
                          <Typography>{t(`item.unit.${selectedItem?.productUnit}`)}</Typography>
                        ),
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {!isDailyReport && (
                <>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="price"
                      defaultValue=""
                      rules={{ required: t("validation.required").toString() }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          margin="none"
                          type="number"
                          label={t("Bruttó ár")}
                          InputLabelProps={{ shrink: true, required: true }}
                          InputProps={{
                            endAdornment: <Typography>{tenant.baseCurrency?.code}</Typography>,
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="netPrice"
                      defaultValue=""
                      rules={{ required: t("validation.required").toString() }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          margin="none"
                          type="number"
                          label={t("Nettó ár")}
                          InputLabelProps={{ shrink: true, required: true }}
                          InputProps={{
                            endAdornment: <Typography>{tenant.baseCurrency?.code}</Typography>,
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      control={control}
                      name="discountPrice"
                      defaultValue=""
                      rules={{ required: t("validation.required").toString() }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          margin="none"
                          type="number"
                          label={t("Kedvezményes ár")}
                          InputLabelProps={{ shrink: true, required: true }}
                          InputProps={{
                            endAdornment: <Typography>{tenant.baseCurrency?.code}</Typography>,
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onClose()}>
          {t("common:button.cancel")}
        </Button>
        <Button form="project-item-use-form" type="submit">
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UseProjectItemDialog;
