import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Item, SerialItemEntry } from "shared/types";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import { useQuery } from "react-query";
import { Autocomplete } from "@material-ui/lab";
import { Dispatch, SetStateAction } from "react";
import { Delete, Info } from "@material-ui/icons";
import { NUMBER_FORMAT } from "config/constants";
import { COLORS } from "config/theme";
import { useDebouncedCallback } from "use-debounce";
import {
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
} from "config/constants";
import NumberField from "components/NumberField";
import { ItemTransferValues } from "./ItemTransfer";
import { listSerialItemsForWarehouse } from "shared/network/serial-item.api";

type Props = {
  itemList: Item[];
  index: number;
  remove: (index?: number | number[] | undefined) => void;
  itemSearch: {
    loading: boolean;
    setSearch: Dispatch<SetStateAction<string>>;
  };
  warehouseId: string;
};

const ItemTransferRow = ({
  itemList,
  index,
  remove,
  itemSearch,
  warehouseId,
}: Props) => {
  const { t } = useTranslation();
  const { watch } = useFormContext<ItemTransferValues>();

  const item = watch(`itemEntries.${index}.itemEntry`);
  const isSerialItem = item?.type === "SERIAL_NUMBER";

  const { selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    if (
      value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH ||
      value.length === 0
    ) {
      itemSearch.setSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const avaibleSerialItemList = useQuery(
    [
      "avaibleSerialItemListQueryForWarehouseTransaction",
      selectedRelTenant.tenant.id,
      item,
      warehouseId,
    ],
    async () => {
      const { data } = await listSerialItemsForWarehouse(
        0,
        100000,
        selectedRelTenant.tenant.id,
        item?.id.toString() || "0",
        warehouseId,
      );
      return data.page.content;
    },
    { enabled: !!item },
  );

  return (
    <>
      <Grid container item xs={12} justifyContent="space-between">
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "8px 16px 8px 12px",
            color: COLORS.main,
            borderRadius: "50%",
            fontSize: 18,
          }}
        >
          {index + 1}. {t("common:item")}
        </Typography>
        <Tooltip title={t("common:button.delete").toString()}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => remove(index)}
            style={{ margin: "8px 12px 8px 16px" }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={8}>
          <Controller
            name={`itemEntries.${index}.itemEntry`}
            rules={{
              required: t("validation.required").toString(),
            }}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleItemSearchChange("");
                }}
                onInputChange={(event, newInputValue) => {
                  handleItemSearchChange(newInputValue);
                }}
                options={itemList || []}
                getOptionLabel={(option: Item) => {
                  if (option.name && option.productCode) {
                    return `${option.name} (${option.productCode})`;
                  }
                  return "";
                }}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Tooltip
                          style={{
                            paddingRight: "2px",
                          }}
                          title={t(
                            "warehouse.itemTransfer.itemName",
                          ).toString()}
                        >
                          <Info style={{ color: COLORS.lightBlue }} />
                        </Tooltip>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    label={t("warehouse.transaction.formValues.item")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          {!isSerialItem ? (
            <Controller
              name={`itemEntries.${index}.quantity`}
              defaultValue={""}
              rules={{
                required: t("validation.required").toString(),
                validate: value => {
                  if (value && !value.match(NUMBER_FORMAT)) {
                    return t("common:validation.numberFormat").toString();
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <NumberField
                  field={field}
                  fieldState={fieldState}
                  onChange={event => {
                    field.onChange(event.target.value);
                  }}
                  label={t("order.quantity")}
                  InputLabelProps={{ shrink: true, required: true }}
                  numberLimits={{ step: "0.01", min: "0.01" }}
                />
              )}
            />
          ) : (
            <Controller
              name={`itemEntries.${index}.factoryNumber`}
              rules={{
                required: isSerialItem && t("validation.required").toString(),
              }}
              defaultValue={null}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, value) => field.onChange(value)}
                  options={avaibleSerialItemList.data || []}
                  getOptionLabel={(option: SerialItemEntry) =>
                    option.factoryNumber
                  }
                  getOptionSelected={option => option.id === field.value?.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t(
                        "warehouse.transaction.formValues.factoryNumber",
                      )}
                      InputLabelProps={{
                        shrink: true,
                        required: isSerialItem,
                      }}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ItemTransferRow;
