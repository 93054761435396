import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { CalendarToday, Clear } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import TextArea from "components/TextArea";
import {
  BOUNDARY_TYPE,
  COOLER_CIRCUIT_TYPES,
  COOLER_TYPES,
  ENERGY_SOURCES,
  FAN_COIL,
  FLOORS,
  HEATER_CIRCUIT,
  HEATER_CIRCUIT_OPERATION,
  HEATER_NUMBER,
  HEATING_KW,
  HEATING_TYPES,
  HEATPUMP_TYPES,
  HEAT_TANSFER,
  INSULATION_THICKNESS,
  OPEN_CLOSERS,
  ROOF_TYPES,
  SET_POINT,
  THERMAL_INSULATION,
  TIME,
  USED_HMV,
  WALL_THICKNESS,
  WALL_TYPES,
  YES_OR_NO,
} from "config/MolEnergeticFormConstants";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { checkQuestionnaire, createQuestionnaire } from "shared/network/questionnaire.api";
import { QuestionnaireEntry } from "shared/types";

const MolEnergeticCreate = () => {
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const tenantId = query.get("tenantId");

  const form = useForm<QuestionnaireEntry>({});
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [targetId, setTargetId] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, watch } = form;

  const company = useSelector(
    (state: RootState) => state?.authentication?.account?.user?.relUserCompany?.[0]?.company,
  );
  const [open, setOpen] = useState(false);

  const onSubmit = async (values: QuestionnaireEntry) => {
    setLoading(true);
    try {
      if (tenantId) {
        await createQuestionnaire({ ...values, company }, tenantId);
      } else {
        throw new Error("ERROR");
      }
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("article.subject"),
        }),
        { variant: "success" },
      );
      history.replace("/home");
    } catch (e) {
      setOpen(true);
      const error = e as any;
      const status = error?.data?.status;
      if (status === "DUPLICATED") {
        enqueueSnackbar(t("Vegye fel a kapcsolatot a kollégákkal"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t(""), {
          variant: "error",
        });
      }
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcZMxMmAAAAAKsErKw9jNHTiepHk2BhEU5V5zy9">
      <Container>
        <Loading open={loading} />
        <Dialog open={open} onClose={handleClose} style={{ alignItems: "center" }}>
          <DialogTitle style={{ textAlign: "center" }}>
            Már van ilyen nevű telephely vagy telephely cím!
          </DialogTitle>
          <DialogContent>
            <Grid style={{ fontSize: 16, fontWeight: "bold" }}>
              Vegye fel a kapcsolatot a kollégákkal
            </Grid>
            <Grid style={{ fontSize: 16, marginTop: 12 }}>
              molcontrol@molcontrol.com vagy 06 99/999-9999
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="primary" onClick={handleClose}>
              {t("Mégse")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (targetId) {
                  history.push(`/energetic/details?id=${targetId}`);
                } else {
                  history.push("/my-company/energetic");
                }
              }}
            >
              {t("Kitöltött kérdőív megjelenítése")}
            </Button>
          </DialogActions>
        </Dialog>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card style={{ backgroundColor: "rgba(255,255,255, 0.6)" }}>
              <CardHeader title="Cég adatok" />
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={6}>
                    Cég neve
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="companyName"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t(" Cég neve")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Cég címe
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="companyAddress"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t(" Cég címe")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Telephely neve
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="siteName"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Telephely neve")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onBlur={async () => {
                            try {
                              if (tenantId && field.value) {
                                await checkQuestionnaire({
                                  siteName: field.value,
                                  tenantId,
                                });
                              } else {
                                throw new Error("ERROR");
                              }
                            } catch (error: any) {
                              if (error?.data?.status === "DUPLICATED") {
                                setTargetId(error.data.item);
                                setOpen(true);
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Telephely címe
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="siteAddress"
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Telephely címe")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          onBlur={async () => {
                            try {
                              if (tenantId && field.value) {
                                await checkQuestionnaire({
                                  siteAddress: field.value,
                                  tenantId,
                                });
                              } else {
                                throw new Error("ERROR");
                              }
                            } catch (error: any) {
                              if (error?.data?.status === "DUPLICATED") {
                                setTargetId(error.data.item);
                                setOpen(true);
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Dátum
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="date"
                      defaultValue={null}
                      rules={{ required: t("validation.required").toString() }}
                      render={({ field, fieldState }) => (
                        <KeyboardDatePicker
                          {...field}
                          ref={undefined}
                          className="startDate"
                          variant="inline"
                          label={t("issues.formValues.startDate")}
                          format="yyyy.MM.dd"
                          InputLabelProps={{ shrink: true, required: true }}
                          InputProps={{ endAdornment: <CalendarToday /> }}
                          autoOk
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Kitöltő neve
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="name"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t(" Kitöltő neve")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Elérhetőség
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="contact"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Elérhetőség")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Divider style={{ marginTop: 24, marginBottom: 24 }} />
            <Card style={{ backgroundColor: "rgba(255,255,255, 0.6)" }}>
              <CardHeader title="Épület" />
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={2}>
                    Falszerkezet anyaga
                  </Grid>
                  <Grid item xs={10}>
                    <Controller
                      name="wallMaterial"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Válasszon a listából")}
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          select
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        >
                          {WALL_TYPES.map(area => (
                            <MenuItem key={area} value={area}>
                              {area}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {watch("wallMaterial") === "egyéb" && (
                      <Controller
                        name="otherWallMaterial"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            InputLabelProps={{ shrink: true, required: true }}
                            label="Egyéb"
                            {...field}
                            style={{ marginTop: 8 }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    Falszerkezet vastagsága (cm)
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name="wallThickness"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Válasszon a listából")}
                          select
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        >
                          {WALL_THICKNESS.map(area => (
                            <MenuItem key={area} value={area}>
                              {/* {t(`common:areaType.${area}`)} */}
                              {area}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    Tető kialakítása
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name="roofDesign"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Válasszon a listából")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          select
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        >
                          {ROOF_TYPES.map(area => (
                            <MenuItem key={area} value={area}>
                              {/* {t(`common:areaType.${area}`)} */}
                              {area}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    Szintek száma
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name="allFloorsWithBasements"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Válasszon a listából")}
                          select
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        >
                          {FLOORS.map(area => (
                            <MenuItem key={area} value={area}>
                              {/* {t(`common:areaType.${area}`)} */}
                              {area}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    Északi határoló felület típusa
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="northSurfaceType"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Válasszon a listából")}
                          select
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        >
                          {BOUNDARY_TYPE.map(area => (
                            <MenuItem key={area} value={area}>
                              {/* {t(`common:areaType.${area}`)} */}
                              {area}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    Nagysága (m2)
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name="northSurfaceSize"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t(" ")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Ha a határoló felület vegyes, tömör fal aránya a teljesfelülethez képest
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="ifNorthSurfaceIsMixed"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Mező kitöltése kötelező!")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    Déli határoló felület típusa
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="southSurfaceType"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Válasszon a listából")}
                          select
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        >
                          {BOUNDARY_TYPE.map(area => (
                            <MenuItem key={area} value={area}>
                              {/* {t(`common:areaType.${area}`)} */}
                              {area}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}>
                    Nagysága (m2)
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name="southSurfaceSize"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t(" ")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    Ha a határoló felület vegyes, tömör fal aránya a teljesfelülethez képest
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="ifSouthSurfaceIsMixed"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Mező kitöltése kötelező!")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    A teljes külső ÜVEG felületek nagysága (m2)
                  </Grid>
                  <Grid item xs={1}>
                    <Controller
                      name="glassSurfaceSize"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t(" ")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    A teljes FAL üvegfelületek nagysága (m2)
                  </Grid>
                  <Grid item xs={1}>
                    <Controller
                      name="wallSurfaceSize"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t(" ")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        ></TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    A szintek ÁTLAG területe (m2)
                  </Grid>
                  <Grid item xs={1}>
                    <Controller
                      name="avgAreaOfFloors"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t(" ")}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box pt={1} pb={3}>
                      <Typography>Alkalmazott külső hőszigetelő anyag típusa</Typography>
                    </Box>
                    <Box pb={2}>
                      <Controller
                        name="externalInsulationMaterialType1"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        //defaultValue={THERMAL_INSULATION[0]}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {THERMAL_INSULATION.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                    <Box pb={2}>
                      <Controller
                        name="externalInsulationMaterialType2"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {THERMAL_INSULATION.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                    <Box>
                      <Controller
                        name="externalInsulationMaterialType3"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {THERMAL_INSULATION.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box pt={1} pb={3}>
                      <Typography> Vastagsága (cm)</Typography>
                    </Box>
                    <Box pb={2}>
                      <Controller
                        name="thicknessOfExternalInsulationMaterial1"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {INSULATION_THICKNESS.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                    <Box pb={2}>
                      <Controller
                        name="thicknessOfExternalInsulationMaterial2"
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {INSULATION_THICKNESS.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                    <Box>
                      <Controller
                        name="thicknessOfExternalInsulationMaterial3"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {INSULATION_THICKNESS.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    Beépített nyílászárók típusa
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="fenestrationType"
                      rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("Válasszon a listából")}
                          select
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        >
                          {OPEN_CLOSERS.map(area => (
                            <MenuItem key={area} value={area}>
                              {/* {t(`common:areaType.${area}`)} */}
                              {area}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    Hőátadási tényező (U - ha ismert)
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="fenestrationHeatFactor"
                      //rules={{ required: t("validation.required").toString() }}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true }}
                          label={t("Válasszon a listából")}
                          select
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                        >
                          {HEAT_TANSFER.map(area => (
                            <MenuItem key={area} value={area}>
                              {area}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: 8 }}>
                  <Grid item xs={4}>
                    <Typography style={{ paddingTop: 8 }}>Egyéb adatok</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      name="otherData"
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextArea
                          {...field}
                          InputProps={{
                            endAdornment: field.value && (
                              <IconButton
                                onClick={() => field.onChange("")}
                                size={"small"}
                                style={{ padding: 0 }}
                              >
                                <Clear fontSize="small" />
                              </IconButton>
                            ),
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} container style={{ height: "100%" }}>
                    <Typography style={{ paddingTop: 8 }}>
                      Megújuló energia források használata
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box pb={2}>
                      <Controller
                        name="renewableEnergySources1"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {ENERGY_SOURCES.map(area => (
                              <MenuItem key={area} value={area}>
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                    {watch("renewableEnergySources1") === "egyéb" && (
                      <Box pb={2}>
                        <Controller
                          name="renewableEnergySourcesOtherData1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    )}
                    <Box pb={2}>
                      <Controller
                        name="renewableEnergySources2"
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {ENERGY_SOURCES.map(area => (
                              <MenuItem key={area} value={area}>
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                    {watch("renewableEnergySources2") === "egyéb" && (
                      <Box pb={2}>
                        <Controller
                          name="renewableEnergySourcesOtherData2"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    )}
                    <Box>
                      <Controller
                        name="renewableEnergySources3"
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {ENERGY_SOURCES.map(area => (
                              <MenuItem key={area} value={area}>
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Box>
                    {watch("renewableEnergySources3") === "egyéb" && (
                      <Box pt={2}>
                        <Controller
                          name="renewableEnergySourcesOtherData3"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Divider style={{ marginTop: 24, marginBottom: 24 }} />
            <Card style={{ backgroundColor: "rgba(255, 160, 82, 0.6)" }}>
              <CardHeader title="Fűtés" />
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={3}>
                      Alkalmazott fűtés típusai
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="heatingType1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true, required: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_TYPES.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        {watch("heatingType1") === "egyéb" && (
                          <Controller
                            name="heatingTypeOtherData1"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                InputLabelProps={{ shrink: true, required: true }}
                                label="Egyéb"
                                {...field}
                                style={{ marginTop: 8 }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name="heatingType2"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_TYPES.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        {watch("heatingType2") === "egyéb" && (
                          <Controller
                            name="heatingTypeOtherData2"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                InputLabelProps={{ shrink: true, required: true }}
                                label="Egyéb"
                                {...field}
                                style={{ marginTop: 8 }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name="heatingType3"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_TYPES.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        {watch("heatingType3") === "egyéb" && (
                          <Controller
                            name="heatingTypeOtherData3"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                InputLabelProps={{ shrink: true, required: true }}
                                label="Egyéb"
                                {...field}
                                style={{ marginTop: 8 }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="heatingPerformance1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true, required: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="heatingPerformance2"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="heatingPerformance3"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={3}>
                      Fűtési berendezések életkora
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="heatingAge1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true, required: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATER_NUMBER.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="heatingAge2"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATER_NUMBER.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="heatingAge3"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATER_NUMBER.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12} style={{ marginTop: 8 }}>
                        év
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 8 }}>
                        év
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 8 }}>
                        év
                      </Grid>
                    </Grid>
                    <Grid item container xs={3}>
                      {/* <Grid item xs={12}>
                          ?
                        </Grid>
                        <Grid item xs={12}>
                          ?
                        </Grid>
                        <Grid item xs={12}>
                          ?
                        </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={3}>
                      Alkalmazott HMV típusai
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="HMWType1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true, required: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {USED_HMV.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        {watch("HMWType1") === "egyéb" && (
                          <Controller
                            name="HMWTypeOtherData1"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                InputLabelProps={{ shrink: true, required: true }}
                                label="Egyéb"
                                {...field}
                                style={{ marginTop: 8 }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name="HMWType2"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {USED_HMV.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        {watch("HMWType2") === "egyéb" && (
                          <Controller
                            name="HMWTypeOtherData2"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                InputLabelProps={{ shrink: true, required: true }}
                                label="Egyéb"
                                {...field}
                                style={{ marginTop: 8 }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name="HMWType3"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {USED_HMV.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        {watch("HMWType3") === "egyéb" && (
                          <Controller
                            name="HMWTypeOtherData3"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                InputLabelProps={{ shrink: true, required: true }}
                                label="Egyéb"
                                {...field}
                                style={{ marginTop: 8 }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      Szükgséges fűtőteljesítmény kW
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="HMWPerformance1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true, required: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name="HMWPerformance2"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="HMWPerformance3"
                          // rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={3}>
                      fűtési kör 01
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit1"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit1") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>

                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance1"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 02
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit2"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit2") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData2"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance2"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 03
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit3"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit3") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData3"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance3"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 04
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit4"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit4") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData4"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance4"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 05
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit5"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit5") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData5"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance5"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 06
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit6"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit6") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData6"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance6"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 07
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit7"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit7") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData7"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance7"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 08
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit8"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit8") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData8"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance8"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 09
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit9"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit9") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData9"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformanc9"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      fűtési kör 10
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuit10"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("heatingCircuit10") === "egyéb" && (
                        <Controller
                          name="heatingCircuitOtherData10"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Fűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitPerformance10"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={3}>
                      Fűtési körök működtetése
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingCircuitOperation"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT_OPERATION.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      Hőszivattyú fajtája
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatingHeatPumpType"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATPUMP_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={3}>
                      Alkalmaznek-e termosztatikus szelepfejeket
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="useThermostaticHeads"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {YES_OR_NO.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      Alkalmaznak-e időprogramozást
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="useTimeProgramming"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {YES_OR_NO.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={8}>
                      Időprogramozás esetén komfort üzem időtartama naponta hétköznap
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="heatTimeProgrammingDailyOnWeekdays"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {TIME.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      Időprogramozás esetén komfort üzem időtartama naponta hétvégente
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="heatTimeProgrammingDailyOnWeekends"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {TIME.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                      Alkalmazott komfort alapjel fűtésre vonatkozó belső hömérséklet komfort
                      alapján
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="heatTimeProgrammingComfortStandard"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {SET_POINT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Alkalmazott CSÖKKENTETT alapjel
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="heatTimeProgrammingReducedStandard"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {SET_POINT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    {(watch("heatingCircuit1") === "fan-coilos fűtés" ||
                      watch("heatingCircuit9") === "fan-coilos fűtés" ||
                      watch("heatingCircuit8") === "fan-coilos fűtés" ||
                      watch("heatingCircuit7") === "fan-coilos fűtés" ||
                      watch("heatingCircuit6") === "fan-coilos fűtés" ||
                      watch("heatingCircuit5") === "fan-coilos fűtés" ||
                      watch("heatingCircuit4") === "fan-coilos fűtés" ||
                      watch("heatingCircuit3") === "fan-coilos fűtés" ||
                      watch("heatingCircuit2") === "fan-coilos fűtés" ||
                      watch("heatingCircuit10") === "fan-coilos fűtés") && (
                      <Grid item xs={12}>
                        <Grid item xs={10}>
                          Fan-Coil-os helyiségek működtetése
                        </Grid>

                        <Grid item xs={2}>
                          <Controller
                            name="heatingFanCoilRooms"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true, required: true }}
                                label={t("Válasszon a listából")}
                                select
                                style={{ marginTop: 8 }}
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {FAN_COIL.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {/* {t(`common:areaType.${area}`)} */}
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                      Van e kiemelt a fűtési körök között?
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="priorityHeatingInCircuit"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {YES_OR_NO.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Melyik fűtési kört érinti?
                    </Grid>
                    <Grid item xs={2}>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            name="priorityHeatingSystemsWhichCircuit1"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true, required: true }}
                                label={t("Válasszon a listából")}
                                select
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {HEATER_NUMBER.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {/* {t(`common:areaType.${area}`)} */}
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="priorityHeatingSystemsWhichCircuit2"
                            // rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true }}
                                label={t("Válasszon a listából")}
                                select
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {HEATER_NUMBER.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {/* {t(`common:areaType.${area}`)} */}
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="priorityHeatingSystemsWhichCircuit3"
                            //rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true }}
                                label={t("Válasszon a listából")}
                                select
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {HEATER_NUMBER.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {/* {t(`common:areaType.${area}`)} */}
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {(watch("heatingCircuit10") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit9") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit8") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit7") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit6") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit5") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit4") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit3") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit2") === "légtechnikai rendszerek" ||
                      watch("heatingCircuit1") === "légtechnikai rendszerek") && (
                      <Grid item container xs={12}>
                        <Grid item xs={4}>
                          Van e kiemelt működtetésű rendszer a légkezelők között?
                        </Grid>
                        <Grid item xs={2}>
                          <Controller
                            name="priorityAirHandlers"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true, required: true }}
                                label={t("Válasszon a listából")}
                                select
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {YES_OR_NO.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {/* {t(`common:areaType.${area}`)} */}
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          Melyik légkezelőt érinti?
                        </Grid>
                        <Grid item container xs={2} spacing={2}>
                          <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                              <Controller
                                name="priorityAirHandlersWhichCircuit1"
                                rules={{ required: t("validation.required").toString() }}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    label={t("Válasszon a listából")}
                                    select
                                    InputProps={{
                                      endAdornment: field.value && (
                                        <IconButton
                                          onClick={() => field.onChange("")}
                                          size={"small"}
                                          style={{ padding: 0 }}
                                        >
                                          <Clear fontSize="small" />
                                        </IconButton>
                                      ),
                                    }}
                                  >
                                    {HEATER_NUMBER.map(area => (
                                      <MenuItem key={area} value={area}>
                                        {/* {t(`common:areaType.${area}`)} */}
                                        {area}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Controller
                                name="priorityAirHandlersWhichCircuit2"
                                //rules={{ required: t("validation.required").toString() }}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    InputLabelProps={{ shrink: true }}
                                    label={t("Válasszon a listából")}
                                    select
                                    InputProps={{
                                      endAdornment: field.value && (
                                        <IconButton
                                          onClick={() => field.onChange("")}
                                          size={"small"}
                                          style={{ padding: 0 }}
                                        >
                                          <Clear fontSize="small" />
                                        </IconButton>
                                      ),
                                    }}
                                  >
                                    {HEATER_NUMBER.map(area => (
                                      <MenuItem key={area} value={area}>
                                        {/* {t(`common:areaType.${area}`)} */}
                                        {area}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Controller
                                name="priorityAirHandlersWhichCircuit3"
                                // rules={{ required: t("validation.required").toString() }}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    InputLabelProps={{ shrink: true }}
                                    label={t("Válasszon a listából")}
                                    select
                                    InputProps={{
                                      endAdornment: field.value && (
                                        <IconButton
                                          onClick={() => field.onChange("")}
                                          size={"small"}
                                          style={{ padding: 0 }}
                                        >
                                          <Clear fontSize="small" />
                                        </IconButton>
                                      ),
                                    }}
                                  >
                                    {HEATER_NUMBER.map(area => (
                                      <MenuItem key={area} value={area}>
                                        {/* {t(`common:areaType.${area}`)} */}
                                        {area}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                      Alkalmazott komfort alapjel fűtésre vonatkozó belső hömérséklet komfort
                      alapján
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="priorityHeatingSystemsComfortStandard"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {SET_POINT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Alkalmazott CSÖKKENTETT alapjel
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="priorityHeatingSystemsReducedStandard"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {SET_POINT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Divider style={{ marginTop: 24, marginBottom: 24 }} />
            <Card style={{ backgroundColor: "rgba(181, 226, 248, 0.6)" }}>
              <CardHeader title="Hűtés" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={3}>
                      Alkalmazott hűtés típusai
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingType1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true, required: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {COOLER_TYPES.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingType2"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {COOLER_TYPES.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingType3"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {COOLER_TYPES.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingTypePerformance1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true, required: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingTypePerformance2"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingTypePerformance3"
                          // rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATING_KW.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={3}>
                      Hűtési berendezések életkora
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingTypeAge1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true, required: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATER_NUMBER.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingTypeAge2"
                          //rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATER_NUMBER.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          name="coolingTypeAge3"
                          // rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              {...field}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              InputLabelProps={{ shrink: true }}
                              label={t("Válasszon a listából")}
                              select
                              InputProps={{
                                endAdornment: field.value && (
                                  <IconButton
                                    onClick={() => field.onChange("")}
                                    size={"small"}
                                    style={{ padding: 0 }}
                                  >
                                    <Clear fontSize="small" />
                                  </IconButton>
                                ),
                              }}
                            >
                              {HEATER_NUMBER.map(area => (
                                <MenuItem key={area} value={area}>
                                  {/* {t(`common:areaType.${area}`)} */}
                                  {area}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={3} spacing={2}>
                      <Grid item xs={12}>
                        év
                      </Grid>
                      <Grid item xs={12}>
                        év
                      </Grid>
                      <Grid item xs={12}>
                        év
                      </Grid>
                    </Grid>
                    <Grid item container xs={3}>
                      {/* <Grid item xs={12}>
                          ?
                        </Grid>
                        <Grid item xs={12}>
                          ?
                        </Grid>
                        <Grid item xs={12}>
                          ?
                        </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={3}>
                      hűtési kör 01
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit1"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit1") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData1"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance1"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hűtési kör 02
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit2"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit2") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData2"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance2"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hűtési kör 03
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit3"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit3") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData3"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance3"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hűtési kör 04
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit4"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit4") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData4"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance4"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hűtési kör 05
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit5"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit5") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData5"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance5"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hűtési kör 06
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit6"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit6") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData6"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance6"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hűtési kör 07
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit7"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit7") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData7"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance7"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hűtési kör 08
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit8"
                        // rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit8") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData8"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance8"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hűtési kör 09
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit9"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit9") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData9"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance9"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      Hűtési kör 10
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuit10"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {COOLER_CIRCUIT_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {watch("coolingCircuit10") === "egyéb" && (
                        <Controller
                          name="coolingCircuitOtherData10"
                          rules={{ required: t("validation.required").toString() }}
                          defaultValue=""
                          render={({ field, fieldState }) => (
                            <TextField
                              InputLabelProps={{ shrink: true, required: true }}
                              label="Egyéb"
                              {...field}
                              style={{ marginTop: 8 }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      Hűtőteljesítmény kW
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitPerformance10"
                        //rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATING_KW.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={3}>
                      Hűtési körök működtetése
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="coolingCircuitOperation"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATER_CIRCUIT_OPERATION.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      hőszivattyú fajtája
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name="heatPumpType"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {HEATPUMP_TYPES.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={8}>
                      Időprogramozás esetén komfort üzem időtartama naponta hétköznap
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="coolingTimeProgrammingDailyOnWeekdays"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {TIME.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      Időprogramozás esetén komfort üzem időtartama naponta hétvégente
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="coolingTimeProgrammingDailyOnWeekends"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {TIME.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                      Alkalmazott komfort alapjel hűtésre vonatkozó belső levegő komfort alapján
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="coolingTimeProgrammingComfortStandard"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {SET_POINT.map(area => (
                              <MenuItem key={area} value={area}>
                                {/* {t(`common:areaType.${area}`)} */}
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Alkalmazott CSÖKKENTETT alapjel
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="coolingTimeProgrammingReducedStandard"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {SET_POINT.map(area => (
                              <MenuItem key={area} value={area}>
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    {(watch("coolingCircuit10") === "fan-coilos hűtés" ||
                      watch("coolingCircuit9") === "fan-coilos hűtés" ||
                      watch("coolingCircuit8") === "fan-coilos hűtés" ||
                      watch("coolingCircuit7") === "fan-coilos hűtés" ||
                      watch("coolingCircuit6") === "fan-coilos hűtés" ||
                      watch("coolingCircuit5") === "fan-coilos hűtés" ||
                      watch("coolingCircuit4") === "fan-coilos hűtés" ||
                      watch("coolingCircuit3") === "fan-coilos hűtés" ||
                      watch("coolingCircuit2") === "fan-coilos hűtés" ||
                      watch("coolingCircuit1") === "fan-coilos hűtés") && (
                      <Grid item xs={12}>
                        <Grid item xs={10}>
                          Fan-Coil-os helyiségek működtetése
                        </Grid>
                        <Grid item xs={2}>
                          <Controller
                            name="coolingFanCoilRooms"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true, required: true }}
                                label={t("Válasszon a listából")}
                                select
                                style={{ marginTop: 8 }}
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {FAN_COIL.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {/* {t(`common:areaType.${area}`)} */}
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                      Van e kiemelt a hűtési körök között?
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="priorityCoolingInCircuit"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {YES_OR_NO.map(area => (
                              <MenuItem key={area} value={area}>
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Melyik hűtési kört érinti?
                    </Grid>
                    <Grid item xs={2}>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            name="priorityCoolingSystemsWhichCircuit1"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true, required: true }}
                                label={t("Válasszon a listából")}
                                select
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {HEATER_NUMBER.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="priorityCoolingSystemsWhichCircuit2"
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true }}
                                label={t("Válasszon a listából")}
                                select
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {HEATER_NUMBER.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="priorityCoolingSystemsWhichCircuit3"
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true }}
                                label={t("Válasszon a listából")}
                                select
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {HEATER_NUMBER.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {(watch("coolingCircuit10") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit9") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit8") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit7") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit6") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit5") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit4") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit3") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit2") === "légtechnikai rendszerek" ||
                      watch("coolingCircuit1") === "légtechnikai rendszerek") && (
                      <Grid item container xs={12}>
                        <Grid item xs={4}>
                          Van e kiemelt működtetésű rendszer a légkezelők között?
                        </Grid>
                        <Grid item xs={1}>
                          <Controller
                            name="priorityCoolingInAirhandlerCircuit"
                            rules={{ required: t("validation.required").toString() }}
                            defaultValue=""
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputLabelProps={{ shrink: true, required: true }}
                                label={t("Válasszon a listából")}
                                select
                                InputProps={{
                                  endAdornment: field.value && (
                                    <IconButton
                                      onClick={() => field.onChange("")}
                                      size={"small"}
                                      style={{ padding: 0 }}
                                    >
                                      <Clear fontSize="small" />
                                    </IconButton>
                                  ),
                                }}
                              >
                                {YES_OR_NO.map(area => (
                                  <MenuItem key={area} value={area}>
                                    {area}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          Melyik légkezelőt érinti?
                        </Grid>
                        <Grid item container xs={3}>
                          <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                              <Controller
                                name="priorityCoolingSystemsWhichAirHandlers1"
                                rules={{ required: t("validation.required").toString() }}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    label={t("Válasszon a listából")}
                                    select
                                    InputProps={{
                                      endAdornment: field.value && (
                                        <IconButton
                                          onClick={() => field.onChange("")}
                                          size={"small"}
                                          style={{ padding: 0 }}
                                        >
                                          <Clear fontSize="small" />
                                        </IconButton>
                                      ),
                                    }}
                                  >
                                    {HEATER_NUMBER.map(area => (
                                      <MenuItem key={area} value={area}>
                                        {area}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Controller
                                name="priorityCoolingSystemsWhichAirHandlers2"
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    InputLabelProps={{ shrink: true }}
                                    label={t("Válasszon a listából")}
                                    select
                                    InputProps={{
                                      endAdornment: field.value && (
                                        <IconButton
                                          onClick={() => field.onChange("")}
                                          size={"small"}
                                          style={{ padding: 0 }}
                                        >
                                          <Clear fontSize="small" />
                                        </IconButton>
                                      ),
                                    }}
                                  >
                                    {HEATER_NUMBER.map(area => (
                                      <MenuItem key={area} value={area}>
                                        {area}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Controller
                                name="priorityCoolingSystemsWhichAirHandlers3"
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <TextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    InputLabelProps={{ shrink: true }}
                                    label={t("Válasszon a listából")}
                                    select
                                    InputProps={{
                                      endAdornment: field.value && (
                                        <IconButton
                                          onClick={() => field.onChange("")}
                                          size={"small"}
                                          style={{ padding: 0 }}
                                        >
                                          <Clear fontSize="small" />
                                        </IconButton>
                                      ),
                                    }}
                                  >
                                    {HEATER_NUMBER.map(area => (
                                      <MenuItem key={area} value={area}>
                                        {area}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={4}>
                      Alkalmazott komfort alapjel fűtésre vonatkozó belső hömérséklet komfort
                      alapján
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="priorityCoolingSystemsComfortStandard"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {SET_POINT.map(area => (
                              <MenuItem key={area} value={area}>
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      Alkalmazott CSÖKKENTETT alapjel
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="priorityCoolingSystemsReducedStandard"
                        rules={{ required: t("validation.required").toString() }}
                        defaultValue=""
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("Válasszon a listából")}
                            select
                            InputProps={{
                              endAdornment: field.value && (
                                <IconButton
                                  onClick={() => field.onChange("")}
                                  size={"small"}
                                  style={{ padding: 0 }}
                                >
                                  <Clear fontSize="small" />
                                </IconButton>
                              ),
                            }}
                          >
                            {SET_POINT.map(area => (
                              <MenuItem key={area} value={area}>
                                {area}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid item xs={12} container justifyContent="center">
              <Box p={2}>
                <Button type="submit" color="primary">
                  {t("common:button.create")}
                </Button>
              </Box>
            </Grid>
          </form>
        </FormProvider>
      </Container>
    </GoogleReCaptchaProvider>
  );
};
export default MolEnergeticCreate;
