import { Breadcrumbs, IconButton, Typography } from "@material-ui/core";
import { NavigateNextOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { NON_CLICKABLE_ROUTES, NON_VISIBLE_ROUTES } from "config/constants";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "config/store";

type Props = {
  pathname: string;
};

const CustomBreadcrumb = ({ pathname }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const backId = query.get("backId");
  const projectId = query.get("projectId");
  const from = query.get("from");
  const splittedPathnames = pathname.split("/").filter(route => route !== "");
  const paths = splittedPathnames.map((pathname: string, index: number) => {
    let path = "";
    for (let current = 0; current <= index; current++) {
      path += `/${splittedPathnames[current]}`;
    }
    return path;
  });
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  return (
    <Breadcrumbs separator={<NavigateNextOutlined fontSize="small" />}>
      <IconButton
        color="inherit"
        component={Link}
        to={"/home?original=true"}
        size="small"
        style={{
          textDecoration: "none",
          color: "inherit",
          fontSize: "16px",
        }}
      >
        <HomeIcon fontSize="small" />
      </IconButton>
      {paths.map((path: string, index: number) => {
        if (!NON_VISIBLE_ROUTES.find(route => route === path)) {
          if (index + 1 !== paths.length && !NON_CLICKABLE_ROUTES.find(route => route === path)) {
            return (
              <Link
                key={index}
                color="inherit"
                to={`${path}${
                  path.includes("/details") || path.includes("-details")
                    ? backId && backId !== "null"
                      ? `?id=${backId}${from ? `&from=${from}` : ""}`
                      : projectId
                      ? `?id=${projectId}${from ? `&from=${from}` : ""}`
                      : `?id=${id}${from ? `&from=${from}` : ""}`
                    : ""
                }`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: "16px",
                }}
              >
                {path === "/issues/all" &&
                account.user.isSuperAdmin === false &&
                selectedRelTenant.isTenantAdmin === false
                  ? t("issues.coworkerIssueList")
                  : t(`drawer.${path}`)}
              </Link>
            );
          } else if (index + 1 !== paths.length) {
            return (
              <Typography
                key={index}
                style={{
                  textDecoration: "none",
                  fontSize: "16px",
                  color: "inherit",
                }}
              >
                {path === "/issues/all" &&
                account.user.isSuperAdmin === false &&
                selectedRelTenant.isTenantAdmin === false
                  ? t("issues.coworkerIssueList")
                  : t(`drawer.${path}`)}
              </Typography>
            );
          } else {
            return (
              <Typography
                key={index}
                color="primary"
                style={{
                  textDecoration: "none",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {path === "/issues/all" &&
                account.user.isSuperAdmin === false &&
                selectedRelTenant.isTenantAdmin === false
                  ? t("issues.coworkerIssueList")
                  : t(`drawer.${path}`)}
              </Typography>
            );
          }
        } else {
          return null;
        }
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumb;
