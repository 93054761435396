import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { createSelector } from "@reduxjs/toolkit";
import { Link } from "components/Router";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export type TosChecked = {
  tos: boolean;
  gdpr: boolean;
  companyTos: boolean;
  companyGdpr: boolean;
};

export const defaultTosChecked = {
  tos: false,
  gdpr: false,
  companyTos: false,
  companyGdpr: false,
};

type Props = {
  setIsTosOk: Dispatch<SetStateAction<boolean>>;
};

const AcceptTos = ({ setIsTosOk }: Props) => {
  const { t } = useTranslation();
  const [isTosChecked, setIsTosChecked] = useState<TosChecked>(defaultTosChecked);

  const { account } = useSelector(
    createSelector(
      (state: RootState) => state.authentication.account,
      (state: RootState) => state.application.profileType,
      (account, profileType) => ({
        account,
        profileType,
      }),
    ),
  );

  useEffect(() => {
    setIsTosOk(
      ((!account?.user && isTosChecked.tos) || !!account?.user) &&
        ((!account?.user && isTosChecked.gdpr) || !!account?.user),
    );
  }, [account?.user, isTosChecked, setIsTosOk]);

  return (
    <Box display="flex" flexDirection="column" gridGap={4} m={1}>
      {!account?.user && (
        <>
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                style={{ padding: 0, marginRight: 4 }}
                color="primary"
                size="small"
                value={!!isTosChecked.tos}
                onChange={(_, checked) =>
                  setIsTosChecked(state => {
                    return { ...state, tos: checked };
                  })
                }
              />
            }
            label={
              <Typography style={{ fontSize: 13 }}>
                {"Elfogadom a rendszer "}
                <Link to={`/tos`} target="_blank">
                  Általános Szerződési Feltételeit.
                </Link>
              </Typography>
            }
          />
          <FormControlLabel
            style={{ margin: 0 }}
            control={
              <Checkbox
                style={{ padding: 0, marginRight: 4 }}
                color="primary"
                size="small"
                value={!!isTosChecked.gdpr}
                onChange={(_, checked) =>
                  setIsTosChecked(state => {
                    return { ...state, gdpr: checked };
                  })
                }
              />
            }
            label={
              <Typography style={{ fontSize: 13 }}>
                {"Elfogadom a rendszer "}
                <Link to={`/gdpr`} target="_blank">
                  Adatkezelési Tájékoztatóját.
                </Link>
              </Typography>
            }
          />
        </>
      )}
    </Box>
  );
};

export default AcceptTos;
