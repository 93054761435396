import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  AddBox,
  Assignment,
  Build,
  Cancel,
  Clear,
  Done,
  Edit,
  Home,
  LocalShipping,
  NewReleases,
  Note,
  Receipt,
  Schedule,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowData,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import ConfirmationButton from "components/ConfirmationButton";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import {
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PROGRESS_STATUS,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS, GRADIENT } from "config/theme";
import { format as dateFormat } from "date-fns";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listCompanyByTenant } from "shared/network/company.api";
import { getDeliveryNoteFile, getInvoiceFile } from "shared/network/files.api";
import {
  cancelOrder,
  getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder,
  getOrderListByTenantId,
} from "shared/network/order.api";
import { Company, OrderStatusHistoryEntry } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { deliveryNoteOpen } from "shared/util/fileDownload";
import { useDebouncedCallback } from "use-debounce";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

export interface StyleProps {
  value: number;
}

const useStyles = makeStyles<StyleProps>(
  theme => ({
    progressPercentage1: {
      "& .MuiLinearProgress-barColorPrimary": {
        background: GRADIENT,
        width: "100%",
        height: 10,
        borderRadius: 5,
      },
      "&.MuiLinearProgress-colorPrimary": {
        background: COLORS.mainGrey,
        width: "100%",
        height: 10,
        borderRadius: 5,
      },
    },
  }),
  { name: "OrderlistByStatus" },
);

export function getProgressValue(row: GridRowData) {
  var progress,
    correction = 1;
  var status = "";

  const orderStatus = row.status;

  const filteredStatusHistory =
    row.orderStatusHistoryList?.filter(
      (entry: OrderStatusHistoryEntry) =>
        entry.status !== "BILLED" && entry.status !== "BILL_CREATED",
    ) || [];

  if (isEmpty(filteredStatusHistory)) {
    return 0 + correction;
  }

  const statusHistoryStatus = filteredStatusHistory?.[filteredStatusHistory?.length - 1].status;

  if (
    orderStatus !== statusHistoryStatus &&
    orderStatus !== "BILLED" &&
    orderStatus !== "BILL_CREATED"
  ) {
    status = orderStatus;
  } else {
    status = statusHistoryStatus;
  }

  switch (status) {
    case "NEW": {
      progress = 5;
      break;
    }
    case "PRODUCED": {
      progress = 25;
      break;
    }
    case "SCHEDULED": {
      progress = 50;
      break;
    }
    case "IN_PROGRESS": {
      progress = 75;
      break;
    }
    case "DELIVERED": {
      progress = 100 - correction;
      break;
    }
    default: {
      progress = 0;
      break;
    }
  }
  return progress + correction;
}

const OrderList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [selectedStatus, setSelectedStatus] = useState<string | null>("NEW");

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-order-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-order-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const companyId = account?.user?.relUserCompany?.[0]?.company?.id;

  const [companySearch, setCompanySearch] = useState<string>("");

  const { control, watch, setValue } = useForm<{ company: Company }>();
  const selectedCompany = watch("company");

  const companyListQuery = useQuery(
    ["companyListQuery", selectedRelTenant.tenant.id, companySearch],
    async () => {
      let searchString = companySearch.replaceAll(t("order.allCompany").toString(), "");
      const { data } = await listCompanyByTenant(
        0,
        10,
        selectedRelTenant.tenant.id,
        searchString ? `name:${searchString}` : "",
      );

      return data;
    },
  );

  const fullOrderListQuery = useQuery(
    [
      "getOrderListByCompanyIdQuery",
      page,
      pageSize,
      selectedRelTenant.tenant.id,
      filterValue,
      sortState,
      selectedStatus,
      companyId,
      selectedCompany,
    ],
    async () => {
      const hasInvoiceSentFilter = filterValue.includes("invoiceSent:");
      let newFilterValue = filterValue;
      let invoiceSent = "";
      if (hasInvoiceSentFilter) {
        newFilterValue = "";
        const idx = filterValue.search(/invoiceSent:(.+);/);
        const endIdx = filterValue.indexOf(";", idx);
        filterValue.substr(idx, endIdx);
        newFilterValue = `${filterValue.substring(0, idx === 0 ? 0 : idx)}${filterValue.substring(
          endIdx,
        )}`;
        invoiceSent = filterValue.substring(idx, endIdx)?.split(":")?.[1];
      }

      const { data } = await getOrderListByTenantId(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        (selectedCompany
          ? selectedCompany.name !== t("order.allCompany").toString()
            ? `company.id:${selectedCompany.id};`
            : " "
          : "") +
          (selectedStatus
            ? selectedStatus === PROGRESS_STATUS[5]
              ? `(status:${selectedStatus};(OR)status:BILLED;(OR)status:BILL_CREATED;)`
              : `status:${selectedStatus};`
            : "") +
          newFilterValue,
        sortState,
        invoiceSent,
      );
      return data;
    },
  );

  async function handleOrderCancel(id: number) {
    try {
      await cancelOrder(id, selectedRelTenant.tenant.id);
      enqueueSnackbar(t("common:notification.orderCancel.success"), {
        variant: "success",
      });
      fullOrderListQuery.refetch();
    } catch (e) {
      enqueueSnackbar(t("common:notification.orderCancel.failure"), {
        variant: "error",
      });
    }
  }

  const handleCompanySearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setCompanySearch(value);
      if (value === "") {
        setValue("company", { id: 0, name: t("order.allCompany").toString() } as Company);
      }
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const columns: GridColDef[] = [
    {
      field: "orderId",
      headerName: t("order.orderId"),
      flex: 1,
      type: "stringContainsNumber",
      valueGetter: ({ row }: GridValueGetterParams) => row.orderId,
    },
    {
      field: "companyId",
      headerName: t("companySite.formValues.company"),
      flex: 1.5,
      type: "stringContainsNumber",
      valueGetter: ({ row }: GridValueGetterParams) => row.company?.name,
    },
    {
      field: "deliveryAddress",
      headerName: t("order.deliveryAddress"),
      flex: 1.5,
      type: "stringContainsNumber",
      valueGetter: ({ row }: GridValueGetterParams) => displayAddress(row.deliveryAddress),
    },
    {
      field: "orderDate",
      headerName: t("order.orderDate"),
      sortable: false,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.orderStatusHistoryList?.[0]
          ? dateFormat(new Date(row.orderStatusHistoryList[0]?.createdOn), "yyyy. MM. dd. HH:mm")
          : "-",
    },
    {
      field: "deliveryFrom",
      headerName: t("order.deliveryFrom"),
      sortable: false,
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.deliveryFrom ? dateFormat(new Date(row.deliveryFrom), "yyyy. MM. dd. HH:mm") : "-",
    },
    {
      field: "status",
      headerName: t("order.status"),
      width: 300,
      sortable: false,
      type: "select",
      renderHeader: () => (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Tooltip title={t("common:progress.new").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <NewReleases
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[0] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[0]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[0]);
                }
              }}
            />
          </Tooltip>
          <Tooltip title={t("common:progress.produced").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <Schedule
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[2] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[2]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[2]);
                }
              }}
            />
          </Tooltip>
          <Tooltip title={t("common:progress.scheduled").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <Build
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[1] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[1]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[1]);
                }
              }}
            />
          </Tooltip>
          <Tooltip title={t("common:progress.inCargo").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <LocalShipping
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[4] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[4]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[4]);
                }
              }}
            />
          </Tooltip>
          <Tooltip title={t("common:progress.delivered").toString()} placement="top" arrow>
            <IconButton
              size="small"
              children={
                <Home
                  style={{
                    color:
                      selectedStatus === PROGRESS_STATUS[5] ? COLORS.statusBlue : COLORS.greyWater,
                  }}
                />
              }
              onClick={() => {
                if (selectedStatus === PROGRESS_STATUS[5]) {
                  setSelectedStatus(null);
                } else {
                  setSelectedStatus(PROGRESS_STATUS[5]);
                }
              }}
            />
          </Tooltip>
        </Box>
      ),
      renderCell: ({ row }: GridRenderCellParams) =>
        row.status === PROGRESS_STATUS[7] ? (
          <Box display="flex" justifyContent="center" width="100%">
            <Typography> {t("order.cancelled")} </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Grid container>
              <Grid container item xs={12}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    !isEmpty(row.orderStatusHistoryList)
                      ? `${dateFormat(
                          new Date(
                            row.orderStatusHistoryList[
                              row.orderStatusHistoryList.length - 1
                            ].createdOn,
                          ),
                          "yyyy. MM. dd. HH:mm",
                        )}`
                      : ""
                  }
                >
                  <LinearProgress
                    className={classes.progressPercentage1}
                    value={getProgressValue(row)}
                    variant="determinate"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        ),
    },
    {
      field: "isPaid",
      headerName: t("order.isPaid.title"),
      flex: 0.5,
      sortable: false,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isPaid ? (
          <Tooltip
            title={
              t("order.isPaid.title") +
              " " +
              (row.paidDate ? dateFormat(new Date(row.paidDate), "yyyy. MM. dd. HH:mm") : "")
            }
          >
            <Done style={{ color: COLORS.green }} />
          </Tooltip>
        ) : (
          <Tooltip title={t(`order.isPaid.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "invoiceSent",
      headerName: t("order.invoiceSent"),
      flex: 0.6,
      sortable: false,
      headerAlign: "center",
      align: "center",
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.orderStatusHistoryList ? (
          row.orderStatusHistoryList.find(
            (orderStatus: OrderStatusHistoryEntry) => orderStatus.status === PROGRESS_STATUS[6],
          ) ? (
            <Tooltip
              title={
                t(`order.billed.true`).toString() +
                " " +
                dateFormat(
                  new Date(
                    row.orderStatusHistoryList.find(
                      (orderStatus: OrderStatusHistoryEntry) =>
                        orderStatus.status === PROGRESS_STATUS[6],
                    ).createdOn,
                  ),
                  "yyyy. MM. dd. HH:mm",
                )
              }
            >
              <Done style={{ color: COLORS.green }} />
            </Tooltip>
          ) : (
            <Tooltip title={t(`order.billed.false`).toString()}>
              <Clear style={{ color: COLORS.red }} />
            </Tooltip>
          )
        ) : (
          <Tooltip title={t(`order.billed.false`).toString()}>
            <Clear style={{ color: COLORS.red }} />
          </Tooltip>
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 224,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Box>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "DELIVERY_NOTE_ADMIN",
            ]) && (
              <Tooltip title={t("deliveryNote.titleView").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ height: 30 }}
                  onClick={async () => {
                    if (row.id) {
                      try {
                        const { data } = await getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder(
                          row.id,
                          row.tenantId,
                          "deliveryNote",
                        );
                        if (data.item) {
                          const response = await getDeliveryNoteFile(data.item, row.tenantId);
                          deliveryNoteOpen(response.data);
                        } else {
                          enqueueSnackbar(
                            t("common:notification.deliveryNote.notExist", {
                              subject: t("deliveryNote.subject"),
                            }),
                            { variant: "error" },
                          );
                        }
                      } catch (e) {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("deliveryNote.subject"),
                          }),
                          { variant: "error" },
                        );
                      }
                    }
                  }}
                >
                  <LocalShipping />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "INVOICE_ADMIN",
            ]) && (
              <Tooltip title={t("invoice.titleView").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ height: 30 }}
                  onClick={async event => {
                    event.preventDefault();
                    if (row.id) {
                      try {
                        const { data } = await getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder(
                          row.id,
                          row.tenantId,
                          "invoice",
                        );
                        if (data.item) {
                          const response = await getInvoiceFile(data.item, row.tenantId);
                          deliveryNoteOpen(response.data);
                          enqueueSnackbar(
                            t("common:notification.download.success", {
                              subject: t("invoice.subject"),
                            }),
                            { variant: "success" },
                          );
                        } else {
                          enqueueSnackbar(
                            t("common:notification.invoice.notExist", {
                              subject: t("invoice.subject"),
                            }),
                            { variant: "error" },
                          );
                        }
                      } catch (e) {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("invoice.subject"),
                          }),
                          {
                            variant: "error",
                          },
                        );
                      }
                    }
                  }}
                >
                  <Receipt />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "ISSUE_ADMIN",
            ]) && (
              <Tooltip title={t("issues.titleView").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ height: 30 }}
                  onClick={async () => {
                    if (row.id) {
                      try {
                        const { data } = await getIdOfIssueOrInvoiceOrDeliveryNoteOfOrder(
                          row.id,
                          row.tenantId,
                          "issue",
                        );
                        if (data?.item) {
                          history.push(`/issues/all/issue-details?id=${data.item}`);
                        } else {
                          enqueueSnackbar(
                            t("common:notification.issue.notExist", {
                              subject: t("issues.subject"),
                            }),
                            { variant: "error" },
                          );
                        }
                      } catch (e) {
                        enqueueSnackbar(
                          t("common:notification.issue.notExist", {
                            subject: t("issues.subject"),
                          }),
                          { variant: "error" },
                        );
                      }
                    }
                  }}
                >
                  <Note />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "ORDER_GET",
              "ORDER_ADMIN",
            ]) && (
              <Tooltip title={t("order.details.title").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  component={Link}
                  style={{ height: 30 }}
                  to={`/order/list/details?id=${row.id}`}
                >
                  <Assignment />
                </IconButton>
              </Tooltip>
            )}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "ORDER_GET",
              "ORDER_ADMIN",
            ]) && (
              <Tooltip title={t("order.modify").toString()}>
                <span>
                  <IconButton
                    size="small"
                    color="primary"
                    component={Link}
                    disabled={PROGRESS_STATUS.slice(4).includes(row.status)}
                    to={
                      row.isLight
                        ? `/order/list/fast-modify?id=${row.id}`
                        : `/order/list/modify?id=${row.id}`
                    }
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "ORDER_GET",
              "ORDER_ADMIN",
            ]) && (
              <Tooltip title={t("order.cancel.button").toString()}>
                <span>
                  <ConfirmationButton
                    variant="text"
                    size="small"
                    color="primary"
                    buttonType="ICON"
                    title={t("order.cancel.title").toString()}
                    body={t("order.cancel.desc").toString()}
                    disabled={!["NEW", "PRODUCED", "SCHEDULED", "FAILED"].includes(row.status)}
                    onSubmit={async () => {
                      handleOrderCancel(row.id);
                    }}
                  >
                    <Cancel />
                  </ConfirmationButton>
                </span>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      if (params.row.isLight) {
        history.push(`/order/list/fast-modify?id=${params.row.id}`);
      } else {
        history.push(`/order/list/details?id=${params.row.id}`);
      }
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_ADMIN"]) && (
          <Button
            component={Link}
            to={"/order/list/fast-create"}
            variant="outlined"
            startIcon={<AddBox />}
          >
            {t("order.fastCreate")}
          </Button>
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_ADMIN"]) && (
          <Button
            component={Link}
            to={`/order/list/create?id=${
              !!account.user.relUserCompany && account?.user?.relUserCompany[0]?.company.id
            }`}
            startIcon={<AddBox />}
          >
            {t("order.create")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue, selectedCompany]); //eslint-disable-line

  useEffect(() => {
    setValue(
      "company",
      !!account.user.relUserCompany ? account?.user?.relUserCompany?.[0]?.company : null,
    );
  }, [account, setValue]);

  return (
    <Card style={{ backgroundColor: "rgba(255,255,255,0.6)", marginBottom: 16 }}>
      <Box display="flex" justifyContent="center" pt={2}>
        <Box width={300}>
          <Controller
            control={control}
            name="company"
            defaultValue={{ id: 0, name: t("order.allCompany").toString() } as Company}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                  handleCompanySearchChange(value?.name || "");
                }}
                onInputChange={(event, newInputValue) => {
                  handleCompanySearchChange(newInputValue);
                }}
                options={
                  !!companyListQuery.data?.page?.content
                    ? [
                        { id: 0, name: "Összes partner" } as Company,
                        ...companyListQuery.data?.page?.content,
                      ]
                    : [{ id: 0, name: "Összes partner" } as Company]
                }
                getOptionLabel={(option: Company) => option.name}
                getOptionSelected={option => option.id === field.value?.id}
                disabled={!isEmpty(account.user.relUserCompany)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t("companySite.formValues.company")}
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />
        </Box>
      </Box>
      <PageableTable
        transparent
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="order"
        filterable={["deliveryAddress", "isPaid", "invoiceSent", "orderId"]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={fullOrderListQuery}
        onCellClick={onCellClick}
      />
    </Card>
  );
};

export default OrderList;
