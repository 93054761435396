import axios from "config/axios";
import { GenericPageResponse } from "shared/common";

const ENDPOINT = "/check-time";

export const createCheckTime = (param: any, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

export const modifyCheckTime = (param: any, tenantId: number) =>
  axios.put(ENDPOINT, { param, tenantId });

export const getCheckTimePage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.get<GenericPageResponse<any>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search || ""}&sort=${
      sort || ""
    }`,
  );

// export const getHaccpItem = (id: number, tenantId: number) =>
//   axios.get<GenericResponse<any>>(`${ENDPOINT}/get-by-id?id=${id}&tenantId=${tenantId}`);
