import { Grid, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import {
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { deleteCompositeItem, listItems } from "shared/network/item.api";
import { Item } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ItemFormValues } from "views/Items/components/ItemForm";

type Props = {
  field: any;
  remove: any;
  calculatePriceFromComposites: () => void;
  index: any;
  compositeItems: any;
};

const CompositeItemFormRow = ({
  field,
  remove,
  index,
  calculatePriceFromComposites,
  compositeItems,
}: Props) => {
  const { t } = useTranslation();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const { control, setValue, getValues, watch } = useFormContext<ItemFormValues>();
  const selectedBuildingItem = watch(`compositeItems.${index}.buildingItem`);

  const [itemSearch, setItemSearch] = useState("");

  const listItemsQuery = useQuery(["listItemsQueryItemModify", tenant.id, itemSearch], async () => {
    const { data } = await listItems(
      0,
      10,
      tenant.id,
      itemSearch &&
        `(name:${itemSearch};(OR)productCode:${itemSearch};)
    `,
    );
    return data.page.content;
  });
  const itemList = listItemsQuery?.data;

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setItemSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  async function deleteComposite(id: string) {
    if (id) {
      await deleteCompositeItem(tenant.id, id);
    }
    return Promise.reject();
  }

  return (
    <Grid container item xs={12} key={field.key} spacing={2} style={{ marginBottom: 12 }}>
      <Grid item xs={5}>
        <Controller
          control={control}
          name={`compositeItems.${index}.buildingItem`}
          defaultValue={compositeItems?.[index]?.buildingItem || null}
          rules={{
            required: t("validation.required").toString(),
          }}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
                handleItemSearchChange("");
                setValue(`compositeItems.${index}.type`, value?.type || "");
                calculatePriceFromComposites();
              }}
              onInputChange={(event, newInputValue) => {
                handleItemSearchChange(newInputValue);
              }}
              options={itemList || []}
              getOptionLabel={(option: Item) => option.name}
              getOptionSelected={option => option.id === field.value?.id}
              renderInput={params => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true, required: true }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <Tooltip
                        style={{
                          paddingRight: "2px",
                        }}
                        title={t("tooltip.item").toString()}
                      >
                        <Info style={{ color: COLORS.lightBlue }} />
                      </Tooltip>
                    ),
                  }}
                  label={t("item.name")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          name={`compositeItems.${index}.quantity`}
          defaultValue={compositeItems?.[index]?.quantity || 1}
          rules={{
            required: t("validation.required").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              onChange={event => {
                field.onChange(event);
                calculatePriceFromComposites();
              }}
              label={t("item.quantity")}
              InputLabelProps={{ shrink: true, required: true }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              InputProps={{
                endAdornment: selectedBuildingItem?.productUnit ? (
                  <Typography>{t(`item.unit.${selectedBuildingItem?.productUnit}`)}</Typography>
                ) : undefined,
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography style={{ marginTop: 8 }}>
          {selectedBuildingItem
            ? t("item.productCode") + ": " + selectedBuildingItem?.productCode
            : ""}
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            if (index < (compositeItems?.length || 0)) {
              deleteComposite(getValues(`compositeItems.${index}.id`).toString());
            }
            remove(index);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CompositeItemFormRow;
