import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DetailsCard from "components/DetailsCard";
import Loading from "components/Loading";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getCompanyUsersById } from "shared/network/company.api";
import { Company } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import RelCompanyCategoryList from "views/Admin/CompanyCategory/RelCompanyCategoryList";
import CommentList from "views/Comment/CommentList";
import { TableState } from "views/Company/CompanyList";
import CompanySite from "views/Company/CompanySite/CompanySite";
import ContactList from "views/Company/Contact/ContactList";
import NicknameList from "views/Nickname/NicknameList";
import BankAccountListPage from "../BankAccount/BankAccountListPage";
import { useGetSessionStorageKey } from "views/Comment/function";

type Props = {
  company: Company | null;
  loading: boolean;
  refetch: () => void;
};

const useStyles = makeStyles({
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px 8px 8px",
  },
  cardContainer: {
    padding: 12,
    width: "100%",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    marginBottom: 16,
  },
});

const CompanyDetails = ({ company, loading, refetch }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const isAll = location.pathname.includes("/list-all");
  const isCustomer = location.pathname.includes("/list-customer");
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-company-details-user-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-company-details-user-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const companyUsersQuery = useQuery(["companyUsersQuery"], async () => {
    if (id) {
      const { data } = await getCompanyUsersById(Number.parseInt(id), tenant.id);
      return { ...data, items: data.items || [] };
    }
    return Promise.reject();
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("userList.name"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("userList.email"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("userList.status.title"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t("userList.status." + row.status) || "-",
    },
  ];

  return (
    <>
      <Loading open={loading || companyUsersQuery.isFetching} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <DetailsCard
            style={{ padding: 0 }}
            title={t("company.data")}
            headerButtons={
              hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "COMPANY_GET",
                "COMPANY_ADMIN",
              ]) && (
                <Button
                  variant="text"
                  component={Link}
                  to={`/companies/${
                    isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                  }/company-modify?id=${id}`}
                  startIcon={<Edit />}
                  style={{ height: 24 }}
                >
                  {t("common:button.modify")}
                </Button>
              )
            }
            columns={"flex"}
            columnFlexRule={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
            dataList={[
              { name: t("company.formValues.name"), value: company?.name },
              {
                name: t("company.formValues.taxNumber"),
                value: company?.taxNumber,
              },
              {
                name: t("company.formValues.registrationNumber"),
                value: company?.registrationNumber,
              },
              {
                name: t("company.formValues.email"),
                value: company?.notificationEmail,
              },
              {
                name: t("company.formValues.phone"),
                value: company?.phone,
              },
              {
                name: t("company.formValues.bankAccount"),
                value: company?.bankAccount,
              },
              {
                name: t("company.formValues.isSupplier"),
                value: company?.isSupplier ? t("common:YES") : t("common:NO"),
              },
              {
                name: t("company.formValues.isCustomer"),
                value: company?.isCustomer ? t("common:YES") : t("common:NO"),
              },
              {
                name: t("company.formValues.baseLanguage"),
                value: company?.baseLanguage ? t(`common:language.${company?.baseLanguage}`) : "-",
              },
              {
                name: t("company.addressTitle"),
                value: displayAddress(company?.address) || "",
              },
            ]}
          />
          <DetailsCard
            style={{ padding: 0, margin: "16px 0 0 0" }}
            title={t("company.baseCurrencyTitle")}
            columns={2}
            dataList={[
              {
                name: t("company.formValues.baseCurrency.name"),
                value: company?.baseCurrency?.name,
              },
              {
                name: t("company.formValues.baseCurrency.code"),
                value: company?.baseCurrency?.code,
              },
              {
                name: t("company.formValues.baseCurrency.prefix"),
                value: company?.baseCurrency?.prefix || "",
              },
              {
                name: t("company.formValues.baseCurrency.postfix"),
                value: company?.baseCurrency?.postfix || "",
              },
            ]}
          />
          <Box mb={2}>{!!company && <NicknameList company={company} refetch={refetch} />}</Box>
          <RelCompanyCategoryList />
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["COMMENT_ADMIN"]) &&
            !!id && <CommentList paramId={id} />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            <Box className={classes.cardHeader}>
              <Typography variant="h2">{t("company.users")}</Typography>
            </Box>
            <SingleQueryTable
              query={companyUsersQuery}
              columns={columns}
              setTableState={setTableState}
              tableState={tableState}
              sessionStorageKey={`companyDetailsUserList_${tenant.id}`}
            />
          </Box>
          <Box mb={2}>
            <ContactList isCompany isAll={isAll} isCustomer={isCustomer} />
          </Box>
          <Box mb={2}>
            <CompanySite isCompany isAll={isAll} isCustomer={isCustomer} />
          </Box>
          <Box mb={2}>
            <BankAccountListPage isCompany isAll={isAll} isCustomer={isCustomer} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDetails;
