import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { Assignment, Edit } from "@material-ui/icons";
import { GridCellParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getBundleList } from "shared/network/bundle.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const BundleList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setHeaderButtons } = useHeader();
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-bundle-list-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-bundle-list-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const bundleList = useQuery(["listBundles", tenant.id], async () => {
    const { data } = await getBundleList(tenant.id);
    return data;
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("bundle.formValues.name"),
      flex: 1,
    },
    {
      field: "price",
      headerName: t("bundle.formValues.price"),
      flex: 1,
    },
    {
      field: "description",
      headerName: t("bundle.formValues.description"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Tooltip title={t("bundle.details").toString()}>
            <IconButton
              component={Link}
              to={`/admin/bundles/bundle-details?id=${row.id}`}
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
            >
              <Assignment color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bundle.modify").toString()}>
            <IconButton
              component={Link}
              to={`/admin/bundles/bundle-modify?id=${row.id}`}
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        <Button component={Link} to="/admin/bundles/bundle-create">
          <FontAwesomeIcon
            icon={faBoxOpen}
            style={{
              fontSize: "20px",
              marginRight: 8,
            }}
          />
          {t("bundle.create")}
        </Button>
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/admin/bundles/bundle-details?id=${params.row.id}`);
    }
  }

  return (
    <Container>
      <SingleQueryTable
        query={bundleList}
        columns={columns}
        setTableState={setTableState}
        tableState={tableState}
        sessionStorageKey={`bundleList_${tenant.id}`}
        onCellClick={onCellClick}
      />
    </Container>
  );
};
export default BundleList;
