import { Box, Button, Container, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { AddBox, Block, CheckCircleOutline, Delete, Done } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listSuperiorsOfSubordinateEmployee } from "shared/network/employee.api";
import { deleteRelEmployeeEmployee } from "shared/network/rel-employee-employee.api";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

const useStyles = makeStyles({
  userListTitle: {
    color: "secondary",
    fontSize: "16px",
    fontWeight: "bold",
  },
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const SuperiorsOfSubordinates = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { setHeaderButtons } = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-list-superiors-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-list-superiors-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const superiorsQuery = useQuery(["superiorListQuery"], async () => {
    if (id) {
      const { data } = await listSuperiorsOfSubordinateEmployee(tenant.id, id);
      return data;
    }
    return Promise.reject();
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("userList.name"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.superiorEmployee.name || "-",
    },
    {
      field: "email",
      headerName: t("userList.email"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.superiorEmployee.email || "-",
    },
    {
      field: "status",
      headerName: t("userList.status.title"),
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={t(`userList.status.${row.superiorEmployee.status}`).toString()}>
          {row.superiorEmployee.status === "ACTIVE" ? (
            <CheckCircleOutline style={{ color: COLORS.green }} />
          ) : row.superiorEmployee.status === "NEEDS_ACTIVATION" ? (
            <Done />
          ) : (
            <Block style={{ color: COLORS.red }} />
          )}
        </Tooltip>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
          ]) && (
            <Tooltip
              title={t("relEmployeeEmployee.deleteSuperior").toString()}
              className={classes.text}
            >
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => {
                  if (row?.id) {
                    removeRelEmployeeEmployee(row.id);
                  }
                }}
              >
                <Delete color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  async function removeRelEmployeeEmployee(id: number) {
    try {
      await deleteRelEmployeeEmployee(id, tenant.id);
      superiorsQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("relEmployeeEmployee.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("relEmployeeEmployee.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["EMPLOYEE_ADMIN"]) && (
          <Button
            component={Link}
            to={`/my-company/employees/employee-details/superiors/superior-to-subordinate?id=${id}&to=superior-to-subordinate`}
            startIcon={<AddBox />}
          >
            {t("relEmployeeEmployee.createSuperior")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Container>
      <SingleQueryTable
        query={superiorsQuery}
        columns={columns}
        setTableState={setTableState}
        tableState={tableState}
        sessionStorageKey={`superiorList_${tenant.id}`}
      />
    </Container>
  );
};

export default SuperiorsOfSubordinates;
