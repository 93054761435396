import {
  Box,
  Button,
  Card,
  Container,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Editor } from "@tinymce/tinymce-react";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import useLocale from "shared/hooks/useLocale";
import { getImportedDocumentTypePage } from "shared/network/imported-document-type.api";
import {
  IMPORTED_DOCUMENT_TYPES,
  importedDocumentCreate,
} from "shared/network/imported-document.api";

type FormValues = {
  files: FileList | null;
  tenantId: number;
  title: string;
  type: number;
  shortDescription: string;
  description: string;
  issuedAt: Date;
};

const HaccpDocumentsCreate = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );
  const form = useForm();
  const { control, formState, handleSubmit, register, watch } = form;

  const [loading, setLoading] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await importedDocumentCreate({
        file: values.files?.[0],
        tenantId: selectedRelTenant?.tenant?.id,
        title: values.title,
        type: values.type,
        shortDescription: values.shortDescription,
        description: values.description,
        issuedAt: values.issuedAt.toISOString(),
      });
      queryClient.refetchQueries("importedDocumentListQuery");
      history.goBack();
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  const documentsPageQuery = useQuery(
    ["documentsPageQuery", selectedRelTenant?.tenant?.id],
    async () => {
      const { data } = await getImportedDocumentTypePage(0, 2000, selectedRelTenant?.tenant?.id);
      return data?.page?.content;
    },
  );

  return (
    <Container maxWidth="md">
      <Loading open={loading} />
      <Card style={{ padding: 16 }}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item xs={12} sm={8} style={{ padding: 8 }}>
                <Controller
                  control={control}
                  name="title"
                  defaultValue={""}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      margin="none"
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      name="message"
                      label={"Megnevezés"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ padding: 8 }}>
                <Controller
                  name={`type`}
                  control={control}
                  defaultValue={""}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select
                      label={"Típus"}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    >
                      <MenuItem disabled value="">
                        {t("common:choose")}
                      </MenuItem>
                      {documentsPageQuery.data?.map(type => (
                        <MenuItem key={type?.id} value={type?.id}>
                          {type?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={8} style={{ padding: 8 }}>
                <Controller
                  control={control}
                  name="shortDescription"
                  defaultValue={""}
                  rules={{
                    required: t("validation.required").toString(),
                    maxLength: {
                      value: 300,
                      message: "Maximum 300 karakter hosszú lehet.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      margin="none"
                      name="message"
                      label={"Rövid leírás"}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ padding: 8 }}>
                <Controller
                  name="issuedAt"
                  defaultValue={null}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      variant="inline"
                      className="endDate"
                      label={t("Kiállítás dátuma")}
                      format="yyyy.MM.dd"
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoOk
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Typography style={{ fontWeight: "bold", paddingBottom: 4, paddingLeft: 8 }}>
                  Leírás
                </Typography>
                <Controller
                  control={form.control}
                  name="description"
                  defaultValue={""}
                  render={props => (
                    <Editor
                      init={{
                        height: 400,
                        plugins:
                          "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                        imagetools_cors_hosts: ["picsum.photos"],
                        menubar: "file edit view insert format tools table help",
                        toolbar: `undo redo | bold italic underline strikethrough | 
              fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | 
              outdent indent | numlist bullist | forecolor backcolor removeformat | 
              pagebreak | charmap emoticons | fullscreen  preview save print | 
              insertfile image media template link anchor codesample | ltr rtl`,
                        toolbar_sticky: true,
                        autosave_ask_before_unload: true,
                        autosave_interval: "30s",
                        autosave_prefix: "{path}{query}-{id}-",
                        autosave_restore_when_empty: false,
                        autosave_retention: "2m",
                        language: locale.tinyMCELang,
                        insertdatetime_formats: locale.tinyMCEDates,
                      }}
                      value={props.field?.value}
                      onEditorChange={(content, editor) => {
                        props.field?.onChange(content);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 8 }}>
                <Box display="flex" alignItems="center" flexDirection="column">
                  <Button
                    variant="text"
                    component="label"
                    htmlFor="personalDataFiles"
                    startIcon={<AttachFile />}
                  >
                    {t("personalData.formValues.chooseFiles")}
                    <input
                      id="personalDataFiles"
                      style={{ display: "none" }}
                      type="file"
                      multiple
                      {...register("files", {
                        required: {
                          value: true,
                          message: "Fájl kiválasztása kötelező",
                        },
                        validate: value => {
                          if (
                            Array.from(watch("files"))?.find((file: any) => file.size >= 5242880)
                          ) {
                            return t("validation.maxSize", {
                              size: 5,
                              unit: "MB",
                            }).toString();
                          }
                        },
                      })}
                    />
                  </Button>
                  {formState?.errors?.files?.message ? (
                    <FormHelperText error>{formState.errors.files?.message}</FormHelperText>
                  ) : (
                    "Fájlonként maximum 5 MB"
                  )}
                  {!!watch("files")?.length && (
                    <Box mt={1}>
                      <Typography style={{ fontWeight: "bold" }}>Kiválasztott fájlok:</Typography>
                      {Array.from(watch("files"))?.map((file: any, index) => (
                        <Typography key={index}>{file.name}</Typography>
                      ))}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center", paddingTop: 16 }}>
                <Button type="submit" disabled={loading}>
                  {t("common:button.save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Card>
    </Container>
  );
};

export default HaccpDocumentsCreate;
