import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";

export const MAGERO_COLORS = {
  main: "#2F586B",
  mainLight: "#5A8EA6",
  mainGrey: "#E7EDF1",
  red: "#BA3727",
  green: "#1E632C",
  white: "#FFFFFF",
  shadowLight: "rgba(0, 0, 0, 0.06)",
  shadowDark: "rgba(0, 0, 0, 0.1)",
  greyWater: "#5C5F58",
  lighterGrey: "#DCE1E3",
  mintGreenLight: "#D4F3EC",
  lightGrey: "#959595",
  lightRed: "#f7e3e1",
  lightBlue: "#b1d4f0",
  statusBlue: "#049dd9",
};

export const BOX_SHADOW = `${MAGERO_COLORS.shadowLight} 0px 4px 6px -1px, ${MAGERO_COLORS.shadowLight} 0px 2px 4px -1px`;

export const MAGERO_GRADIENT = `linear-gradient(45deg, ${MAGERO_COLORS.main} 0%, ${MAGERO_COLORS.mainLight} 100%)`;

const mageroTheme = createTheme({
  palette: {
    primary: {
      main: MAGERO_COLORS.main,
      light: MAGERO_COLORS.mainLight,
    },
    secondary: {
      main: MAGERO_COLORS.greyWater,
      light: MAGERO_COLORS.lighterGrey,
    },
    background: {
      default: MAGERO_COLORS.white,
    },
    error: {
      main: MAGERO_COLORS.red,
    },
    success: {
      main: MAGERO_COLORS.green,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
        a: {
          color: MAGERO_COLORS.main,
        },
        input: {
          "&[type=number]": {
            "-moz-appearance": "textfield",
          },
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      },
    },
    MuiFormControlLabel: {
      labelPlacementStart: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "Rubik",
        fontSize: "30px",
        fontWeight: "bold",
      },
      h2: {
        fontFamily: "Rubik",
        fontSize: "20px",
        fontWeight: "bold",
      },
      h3: {
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: "bold",
      },
      h6: {
        fontFamily: "Rubik",
        fontWeight: "bold",
      },
      body1: {
        fontFamily: "Rubik",
        fontSize: "0.875rem",
      },
      body2: {
        fontFamily: "Rubik",
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiSelect: {
      icon: {
        position: "relative",
        marginLeft: "-22px",
      },
    },
    MuiChip: {
      outlined: {
        color: MAGERO_COLORS.mainLight,
        border: `1px solid ${MAGERO_COLORS.mainLight}`,
        boxShadow: BOX_SHADOW,
      },
      deleteIcon: {
        color: MAGERO_COLORS.mainLight,
        "&:hover": {
          color: MAGERO_COLORS.red,
        },
      },
      colorPrimary: {
        backgroundColor: MAGERO_COLORS.mainLight,
      },
      label: {
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiButton: {
      root: {
        boxShadow: "unset",
        minWidth: "max-content",
        borderRadius: 8,
      },
      label: {
        whiteSpace: "nowrap",
      },
    },
    MuiSwitch: {
      root: {
        "& + .MuiFormControlLabel-label": {
          margin: "0 4px",
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow: BOX_SHADOW,
        border: `1px solid ${MAGERO_COLORS.mainGrey}`,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
      adornedStart: {
        paddingLeft: 6,
      },
    },
  },
  props: {
    MuiTextField: {
      margin: "none",
      fullWidth: true,
      variant: "outlined",
      size: "small",
    },
    MuiButton: {
      variant: "contained",
      color: "primary",
    },
    MuiCardHeader: {
      titleTypographyProps: {
        variant: "h2",
      },
    },
  },
});

export default mageroTheme;
