import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { COLORS } from "config/theme";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Issue } from "shared/types";
import { ISSUE_RELATION_TYPE, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY } from "config/constants";
import { useDebouncedCallback } from "use-debounce";
import { useQuery } from "react-query";
import { listIssues, listIssueswithInactive } from "shared/network/issues.api";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import { createRelIssueIssue } from "shared/network/rel-issue-issue.api";
import { useSnackbar } from "notistack";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  issue?: Issue;
  refetch?: () => void;
  fromProjectTemplate?: boolean;
};

export type RelIssueIssueFormValues = {
  childIssue: Issue | null;
  relIssueIssueRelationType: string;
};

const IssueRelIssueCreateModal = ({
  open,
  setOpen,
  issue,
  refetch,
  fromProjectTemplate,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [issueSearch, setIssueSearch] = useState<string>("");

  const form = useForm<RelIssueIssueFormValues>();

  const issueList = useQuery(
    ["listIssueQueryForRelIssueIssue", selectedRelTenant.tenant.id, issueSearch],
    async () => {
      const { data } = await listIssues(
        0,
        10,
        selectedRelTenant.tenant.id,
        !!issueSearch ? `(id:${issueSearch};(OR)name:${issueSearch};)` : "",
      );
      return data.page.content;
    },
    { enabled: open && !fromProjectTemplate },
  );

  const fullIssueList = useQuery(
    ["listIssueQueryForRelIssueIssueAll", selectedRelTenant.tenant.id, issueSearch],
    async () => {
      const { data } = await listIssueswithInactive(
        0,
        10,
        selectedRelTenant.tenant.id,
        !!issueSearch ? `(id:${issueSearch};(OR)name:${issueSearch};)` : "",
      );
      return data.page.content;
    },
    { enabled: open && fromProjectTemplate },
  );

  const onSubmit = async (values: RelIssueIssueFormValues) => {
    try {
      if (!!issue && !!values.childIssue) {
        await createRelIssueIssue(
          {
            firstIssue: issue,
            secondIssue: values.childIssue,
            relIssueIssueRelationType: values.relIssueIssueRelationType,
          },
          selectedRelTenant.tenant.id,
        );
        setOpen(false);
        form.reset();
        refetch && refetch();
        enqueueSnackbar(
          t("common:notification.add.success", {
            subject: t("issues.subject"),
          }),
          { variant: "success" },
        );
      }
    } catch {
      enqueueSnackbar(
        t("common:notification.add.failure", {
          subject: t("issues.subject"),
        }),
        { variant: "error" },
      );
    }
  };

  const handleIssueSearchChange = useDebouncedCallback((value: string) => {
    setIssueSearch(value);
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={"sm"} fullWidth>
      <DialogTitle>{t("issues.relIssuesAddTitle")}</DialogTitle>
      <DialogContent>
        <form id="relIssueIssueForm" onSubmit={form.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={form.control}
                name="childIssue"
                defaultValue={null}
                rules={{ required: t("validation.required").toString() }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleIssueSearchChange("");
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleIssueSearchChange(newInputValue);
                    }}
                    options={fromProjectTemplate ? fullIssueList.data || [] : issueList.data || []}
                    getOptionLabel={(option: Issue) => `#${option.id} ${option.name}`}
                    getOptionSelected={option => option.id === field.value?.id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("issues.title").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        label={t("issues.title")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={form.control}
                name="relIssueIssueRelationType"
                rules={{ required: t("validation.required").toString() }}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("issues.relIssueIssueRelationType")}
                    InputLabelProps={{ shrink: true, required: true }}
                    SelectProps={{
                      displayEmpty: true,
                    }}
                    select
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    <MenuItem disabled value="">
                      {t("common:choose")}
                    </MenuItem>
                    {ISSUE_RELATION_TYPE.map(option => (
                      <MenuItem key={option} value={option}>
                        {t(`issues.relationType.${option}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Box display="flex" m={2} gridGap={8}>
          <Button color="primary" variant="text" onClick={() => setOpen(false)}>
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" form="relIssueIssueForm" color="primary">
            {t("common:button.save")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default IssueRelIssueCreateModal;
