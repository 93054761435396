import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { AddBox, Delete, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  createFailedOrderReason,
  deleteFailedOrderReason,
  getFailedOrderReasonList,
} from "shared/network/failed-order-reason.api";
import { FailedOrderReason } from "shared/types";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import FailReasonForm, { FailReasonFormValues } from "./FailReasonForm";

const FailReasonList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [modifyOpen, setModifyOpen] = useState<boolean>(false);
  const form = useForm<FailReasonFormValues>();
  const [selectedReason, setSelectedReason] = useState<FailedOrderReason | null>(null);

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-fail-reason-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-fail-reason-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const failedOrderReasonListQuery = useQuery(
    [`failReasonList_${selectedRelTenant.tenant.id}`, selectedRelTenant.tenant.id],
    async () => {
      const { data } = await getFailedOrderReasonList(selectedRelTenant.tenant.id);
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("failReason.title"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {(selectedRelTenant?.isTenantAdmin || account?.user?.isSuperAdmin) && (
            <>
              <Tooltip title={t("common:button.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    setModifyOpen(row.id);
                    setSelectedReason(row as FailedOrderReason);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("department.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => deleteReason(row.id)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Box>
      ),
    },
  ];

  async function deleteReason(id: number) {
    try {
      await deleteFailedOrderReason(id, selectedRelTenant.tenant.id);
      failedOrderReasonListQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("failReason.subject"),
        }),
        { variant: "success" },
      );
    } catch (e) {
      enqueueSnackbar(t("failReason.delete.failure"), { variant: "error" });
    }
  }

  const onSubmitCreate = async (values: FailReasonFormValues) => {
    try {
      await createFailedOrderReason(
        values.name,
        selectedRelTenant.tenant.id,
        values?.id ? values.id : undefined,
      );
      failedOrderReasonListQuery.refetch();
      setCreateOpen(false);
      setModifyOpen(false);
      setSelectedReason(null);
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("contract.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("contract.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  };

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {(selectedRelTenant?.isTenantAdmin || account?.user?.isSuperAdmin) && (
          <Button
            variant="contained"
            startIcon={<AddBox />}
            style={{ margin: "auto" }}
            onClick={() => setCreateOpen(true)}
          >
            {t("failReason.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <Container maxWidth="sm">
      <SingleQueryTable
        sessionStorageKey="failReason"
        columns={columns}
        query={failedOrderReasonListQuery}
        tableState={tableState}
        setTableState={setTableState}
      />
      {/*CREATE DIALOG*/}
      <Dialog
        open={createOpen}
        onClose={() => {
          setCreateOpen(false);
          setSelectedReason(null);
          form.reset({ name: "", id: undefined, tenantId: undefined });
        }}
        maxWidth="md"
      >
        <DialogTitle>{t("failReason.form.titleCreate")}</DialogTitle>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <FormProvider {...form}>
              <FailReasonForm
                setOpen={setCreateOpen}
                failReason={selectedReason || undefined}
                setSelectedReason={setSelectedReason}
              />
            </FormProvider>
          </form>
        </DialogContent>
      </Dialog>
      {/*MODIFY DIALOG*/}
      <Dialog
        open={modifyOpen}
        onClose={() => {
          setModifyOpen(false);
          setSelectedReason(null);
          form.reset({ name: "", id: undefined, tenantId: undefined });
        }}
        maxWidth="md"
      >
        <DialogTitle>{t("failReason.form.titleEdit")}</DialogTitle>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <FormProvider {...form}>
              <FailReasonForm
                setOpen={setModifyOpen}
                failReason={selectedReason || undefined}
                setSelectedReason={setSelectedReason}
              />
            </FormProvider>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default FailReasonList;
