import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Redirect, Switch, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import MonitoringTransactionList from "./components/MonitoringTransactionList";
import MonitoringDashboard from "./MonitoringDashboard";
import MonitoringMachineList from "./MonitoringMachineList";
import ReportsDataCard from "./ReportsDataCard";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";

export const MONITORING_TABS = [
  "/monitoring/dashboard",
  "/monitoring/machines",
  "/monitoring/transactions",
  "/monitoring/reports",
];

const MonitoringTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/monitoring" && (
        <Redirect
          to={
            !account.user.isSuperAdmin &&
            hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "MONITORING_DEPOSITOR_GET",
            ])
              ? "/monitoring/transactions"
              : "/monitoring/dashboard"
          }
        />
      )}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "MONITORING_ALERT_GET",
        ]) && (
          <Tab
            label={t("monitoring.tabs.dashboard")}
            component={Link}
            to={MONITORING_TABS[0]}
            value={MONITORING_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["MONITORING_GET"]) && (
          <Tab
            label={t("monitoring.tabs.machines")}
            component={Link}
            to={MONITORING_TABS[1]}
            value={MONITORING_TABS[1]}
          />
        )}
        {!account.user.isSuperAdmin &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "MONITORING_DEPOSITOR_GET",
          ]) && (
            <Tab
              label={t("monitoring.tabs.transactions")}
              component={Link}
              to={MONITORING_TABS[2]}
              value={MONITORING_TABS[2]}
            />
          )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["MONITORING_GET"]) && (
          <Tab
            label={t("monitoring.tabs.reports")}
            component={Link}
            to={MONITORING_TABS[3]}
            value={MONITORING_TABS[3]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={MONITORING_TABS[0]}
              component={MonitoringDashboard}
              permissions={["MONITORING_ALERT_GET"]}
            />
            <PrivateRoute
              path={MONITORING_TABS[1]}
              component={MonitoringMachineList}
              permissions={["MONITORING_GET"]}
            />
            <PrivateRoute
              path={MONITORING_TABS[2]}
              component={/*MonitoringEmpty*/ MonitoringTransactionList}
              permissions={["MONITORING_DEPOSITOR_GET"]}
            />
            <PrivateRoute
              path={MONITORING_TABS[3]}
              component={ReportsDataCard}
              permissions={["MONITORING_GET"]}
            />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default MonitoringTabs;
