import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { HeaderProvider } from "components/Layout/HeaderContext";
import Layout from "components/Layout/Layout";
import LayoutLoading from "components/LayoutLoading";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import supportedLocales from "config/supportedLocales";
import { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { buildPaths, flattenRoutes } from "routes/RouteFunctions";
import {
  HACCP_CHECK_ROUTES,
  HACCP_DEPARTMENT_ROUTES,
  HACCP_ITEM_ROUTES,
  MY_COMPANY_PRIVATE_ROUTES,
  STERILIZATION_ROUTES,
} from "routes/RoutesFive";
import { ADMIN_PRIVATE_ROUTES } from "routes/RoutesFour";
import { BASIC_PRIVATE_ROUTES, ROUTES } from "routes/RoutesOne";
import { BASIC_PRIVATE_ROUTES_II } from "routes/RoutesSeven";
import { COMPANIES_PRIVATE_ROUTES } from "routes/RoutesSix";
import { ISSUE_PRIVATE_ROUTES } from "routes/RoutesThree";
import { WAREHOUSE_PRIVATE_ROUTES } from "routes/RoutesTwo";
import { getSystemProperties } from "shared/network/properties.api";
import { fetchAccount, finishInitializing, logout } from "shared/reducers/authentication";
import { CustomRoute, Property } from "shared/types";
import { saveToken, tokenExists } from "shared/util/authToken";
import { getPageName } from "shared/util/getPageName";
import ResetPassword from "views/ForgottenPassword/ResetPassword";
import Home from "views/Home/Home";
import MolEnergeticCreate from "views/MolEnergetic/MolEnergeticCreate";
import MolEnergeticList from "views/MolEnergetic/MolEnergeticList";
import PageNotFound from "views/PageNotFound";
import PublicReportDetails from "views/Report/PublicReportDetails";
import ReportCreate from "views/Report/ReportCreate"; //@ts-ignore
import ReportDetails from "views/Report/ReportDetails";
import ReportList from "views/Report/ReportList"; //@ts-ignore
import favicon from "./favicon.ico";
import mageroFavicon from "./mageroFavicon.png"; //@ts-ignore
import roraFavicon from "./roraFavicon.ico";
import MolEnergeticDetails from "views/MolEnergetic/MolEnergeticDetails";

function getCookie(name: string) {
  return document.cookie
    ?.split("; ")
    ?.find(row => row.startsWith(name))
    ?.split("=")[1];
}

export default function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { account, initialized } = useSelector((state: RootState) => state.authentication);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (tokenExists()) {
      dispatch(fetchAccount());
    } else {
      dispatch(finishInitializing());
    }
  }, [dispatch]);

  useEffect(() => {
    const authToken = getCookie("auth_token");

    if (authToken) {
      saveToken(authToken);
      dispatch(fetchAccount());
    }
  }, [dispatch]);

  const versionQueryMainApp = useQuery(
    "versionQueryMainApp",
    async () => {
      const { data } = await getSystemProperties();
      return data.items;
    },
    { onSuccess: checkVersion },
  );
  const version = versionQueryMainApp.data?.find(property => property.name === "version")?.value;

  useEffect(() => {
    const interval = setInterval(() => {
      versionQueryMainApp.refetch();
    }, 600000);
    return () => clearInterval(interval);
  }, []); //eslint-disable-line

  function checkVersion(data: Property[]) {
    const currentVersion = data.find(property => property.name === "version")?.value;
    const localVersion = localStorage.getItem("version");
    if (localVersion !== currentVersion) {
      setReload(true);
    }
  }

  function reloadSite() {
    setReload(false);
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("version", version || "");
    dispatch(logout());
    window.location.reload();
  }

  if (!initialized) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <Grid container justifyContent="space-between" alignItems="center" direction="column">
          {getPageName(window.location.hostname)}
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  const isMagero = !!window.location.hostname.includes("magero");

  return (
    <>
      <Dialog open={reload}>
        <DialogTitle>{t("common:versionCheckTitle")}</DialogTitle>
        <DialogContent>{t("common:versionCheckMessage")}</DialogContent>
        <DialogActions>
          <Button onClick={reloadSite}>{t("common:button.reload")}</Button>
        </DialogActions>
      </Dialog>
      <HeaderProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={supportedLocales[i18n.language]}>
          <Suspense fallback={<LayoutLoading />}>
            <Helmet>
              <title>{getPageName(window.location.hostname)}</title>
              <link
                id="favicon"
                rel="icon"
                href={
                  !!window.location.hostname.includes("rajosszikviz")
                    ? roraFavicon
                    : isMagero
                    ? mageroFavicon
                    : favicon
                }
                type="image/x-icon"
              />
            </Helmet>
            <Layout>
              <Switch>
                <PrivateRoute path="/my-company/energetic" component={MolEnergeticList} />
                <PrivateRoute path="/my-company/energetic" component={MolEnergeticList} />
                <Route path="/reset/finish/:token" component={ResetPassword} />
                <Route exact path="/" component={Home} />
                <PrivateRoute path="/my-company/energetic" component={MolEnergeticList} />
                <Route path="/energetic/create" component={MolEnergeticCreate} />
                <Route path="/energetic/details" component={MolEnergeticDetails} />
                <PrivateRoute exact path="/report" component={ReportList} />
                <Route path="/report/details" component={ReportDetails} />
                <Route path="/view-report" component={PublicReportDetails} />
                <Route path="/new-report" component={ReportCreate} />
                {flattenRoutes(
                  buildPaths([
                    ...BASIC_PRIVATE_ROUTES,
                    ...BASIC_PRIVATE_ROUTES_II,
                    ...MY_COMPANY_PRIVATE_ROUTES,
                    ...ISSUE_PRIVATE_ROUTES,
                    ...ADMIN_PRIVATE_ROUTES,
                    ...COMPANIES_PRIVATE_ROUTES,
                    ...WAREHOUSE_PRIVATE_ROUTES,
                    ...HACCP_ITEM_ROUTES,
                    ...HACCP_CHECK_ROUTES,
                    ...HACCP_DEPARTMENT_ROUTES,
                    ...STERILIZATION_ROUTES,
                  ]),
                ).map((route: CustomRoute) => {
                  if (route?.permissions?.find(permission => permission === "SUPER_ADMIN")) {
                    if (account?.user?.isSuperAdmin) {
                      return (
                        <PrivateRoute
                          path={route.path}
                          component={route.component}
                          key={route.path}
                        />
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <PrivateRoute
                        path={route.path}
                        component={route.component}
                        permissions={route.permissions}
                        key={route.path}
                      />
                    );
                  }
                })}

                {flattenRoutes(buildPaths(ROUTES)).map((route: CustomRoute) => (
                  <Route path={route.path} component={route.component} key={route.path} />
                ))}
                <Route component={PageNotFound} />
              </Switch>
            </Layout>
          </Suspense>
        </MuiPickersUtilsProvider>
      </HeaderProvider>
    </>
  );
}
