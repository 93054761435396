import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import SignatureCanvas from "components/SignatureCanvas";
import {
  DELIVERY_TYPE,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { SliceStatus } from "shared/common";
import { listContactByTenant } from "shared/network/contact.api";
import { updateDeliveryStatus } from "shared/network/delivery.api";
import { getFailedOrderReasonList } from "shared/network/failed-order-reason.api";
import { Contact, Delivery } from "shared/types";
import { useDebouncedCallback } from "use-debounce";

export type DeliveryStatusDialogValues = {
  status: string;
  deliveryId: string;
  tenantId: string;
  comment?: string;
  contact: Contact | null;
  file?: FileList; //ha isCompany, akkor kötelező
  failedOrderReasonId?: number | string;
};

type Props = {
  selectedDelivery: Delivery | null;
  setSelectedDelivery: Dispatch<SetStateAction<Delivery | null>>;
  refetch: () => void;
  selectedStatus?: string;
  fromStatusCell?: boolean;
};

const useStyles = makeStyles({
  formCardHeader: {
    height: "fit-content",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
    textAlign: "center",
    padding: "8px 8px 8px 8px",
    width: "100%",
    fontWeight: "bold",
    background: COLORS.mainGrey,
  },
});

const DeliveryStatusDialog = ({
  selectedDelivery,
  setSelectedDelivery,
  refetch,
  selectedStatus,
  fromStatusCell,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch, handleSubmit, setValue } = useForm<DeliveryStatusDialogValues>();
  const status = watch("status");
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const [statusList, setStatusList] = useState<string[]>(DELIVERY_TYPE);
  const [contactSearch, setContactSearch] = useState<string>("");
  const [progressStatus, setProgressStatus] = useState<SliceStatus>("idle");
  const [transferorSignatureFile, setTransferorSignatureFile] = useState<File>(new File([], ""));
  const [recipientSignatureFile, setRecipientSignatureFile] = useState<File>(new File([], ""));

  const contactListQuery = useQuery(
    ["contactListForDeliveryStatus", tenant.id, selectedDelivery?.company?.id, contactSearch],
    async () => {
      const { data } = await listContactByTenant(
        0,
        10,
        tenant.id,
        (contactSearch ? `name:${contactSearch}` : ``) +
          (selectedDelivery?.company?.id ? `companyId:${selectedDelivery.company.id}` : ""),
      );
      return data.page;
    },
    { enabled: !!selectedDelivery && selectedStatus === "DELIVERED" },
  );

  const failedOrderReasonQuery = useQuery(["failedOrderReasonQuery", tenant.id], async () => {
    const { data } = await getFailedOrderReasonList(tenant.id);
    return data.items;
  });

  const handleContactSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setContactSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  useEffect(() => {
    if (selectedStatus) {
      setValue("status", selectedStatus);
    }
  }, [selectedStatus, setValue]);

  useEffect(() => {
    setStatusList(fromStatusCell ? DELIVERY_TYPE : ["SUSPENDED", "WITHDRAWN"]);
  }, [fromStatusCell, setStatusList]);

  const onSubmitCreate = async (values: DeliveryStatusDialogValues) => {
    try {
      setProgressStatus("pending");
      if (selectedDelivery) {
        if (values.status === "IN_PROGRESS") {
          const transferor = document.getElementById("signatureWarehouse") as HTMLCanvasElement;
          const recipient = document.getElementById("signatureVehicleDriver") as HTMLCanvasElement;
          if (transferor && recipient) {
            transferor.toBlob(blob => {
              if (blob) {
                setTransferorSignatureFile(
                  new File([blob], "transferorSignature.png", {
                    type: "image/png",
                  }),
                );
              }
            });
            recipient.toBlob(blob => {
              if (blob) {
                setRecipientSignatureFile(
                  new File([blob], "recipientSignature.png", {
                    type: "image/png",
                  }),
                );
              }
            });
            await updateDeliveryStatus(
              values.status,
              selectedDelivery?.id.toString(),
              tenant.id.toString(),
              values.comment,
              values.contact ? values.contact?.name : "",
              {
                transferorSignature: transferorSignatureFile,
                recipientSignature: recipientSignatureFile,
              },
            );
          }
          refetch();
          enqueueSnackbar("Sikeres módosítás.", {
            variant: "success",
          });
          setProgressStatus("success");
        } else {
          const canvas = document.getElementById("signature") as HTMLCanvasElement;
          if (canvas) {
            canvas.toBlob(async blob => {
              if (blob) {
                const deliveryPromise = await updateDeliveryStatus(
                  values.status,
                  selectedDelivery?.id.toString(),
                  tenant.id.toString(),
                  values.comment,
                  values.contact ? values.contact?.name : "",
                  {
                    file: new File([blob], "signature.png", {
                      type: "image/png",
                    }),
                  },
                ).catch((e: any) => {
                  setProgressStatus("failure");
                  if (e.data?.status === "EMAIL_NOT_SENT") {
                    enqueueSnackbar(
                      t("common:notification.send.failure", {
                        subject: t("commmon:email.subject"),
                      }),
                      {
                        variant: "error",
                      },
                    );
                  } else if (e.data.status === "INSUFFICIENT_QUANTITY_CARGO") {
                    enqueueSnackbar(t("delivery.INSUFFICIENT_QUANTITY_CARGO"), {
                      variant: "error",
                    });
                    // enqueueSnackbar(
                    //   "Nincs elég termék a járművön, ellenőrizze a megadott értéket!",
                    //   { variant: "error" },
                    //);
                  } else {
                    enqueueSnackbar(t("Sikertelen módosítás"), {
                      variant: "error",
                    });
                  }
                });
                if (!!deliveryPromise) {
                  refetch();
                  enqueueSnackbar("Sikeres módosítás.", {
                    variant: "success",
                  });
                  setProgressStatus("success");
                }
              }
            }, "image/png");
          } else {
            await updateDeliveryStatus(
              values.status,
              selectedDelivery?.id.toString(),
              tenant.id.toString(),
              values.comment,
              values.contact ? values.contact?.name : "",
              undefined,
              values.failedOrderReasonId,
            );
            refetch();
            enqueueSnackbar("Sikeres módosítás.", {
              variant: "success",
            });
            setProgressStatus("success");
          }
        }
        setSelectedDelivery(null);
      } else {
        enqueueSnackbar("Hiba történt a módosítás során.", {
          variant: "error",
        });
        setProgressStatus("failure");
      }
    } catch (e: any) {
      setProgressStatus("failure");
      if (e.data?.status === "EMAIL_NOT_SENT") {
        enqueueSnackbar(
          t("common:notification.send.failure", {
            subject: t("commmon:email.subject"),
          }),
          {
            variant: "error",
          },
        );
      } else if (e.data?.status === "INSUFICIENT_QUANTITY_IN_CARGO") {
        enqueueSnackbar(t("common:notification.INSUFICIENT_QUANTITY_IN_CARGO"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(t("common:notification.DEFAULT"), { variant: "error" });
      }
    }
  };

  const ref = useRef<HTMLDivElement | null>();
  const [offset, setOffset] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  return (
    <>
      <Loading open={progressStatus === "pending"} />
      <Dialog open={!!selectedDelivery} onClose={() => setSelectedDelivery(null)}>
        <form onSubmit={handleSubmit(onSubmitCreate)}>
          <DialogTitle>{t("invoice.modifyStatus")}</DialogTitle>
          <DialogContent
            ref={ref}
            onScroll={() => {
              setOffset({ top: ref.current?.scrollTop || 0, left: ref.current?.scrollLeft || 0 });
            }}
          >
            <Grid container spacing={2} style={{ width: 350 }}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="status"
                  defaultValue={selectedStatus || "ACTIVE"}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select
                      InputLabelProps={{ shrink: true, required: true }}
                      label={t("delivery.formValues.status")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled={!!fromStatusCell}
                    >
                      {statusList.map(status => (
                        <MenuItem key={status} value={status}>
                          {t(`delivery.status.${status}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              {status === "DELIVERED" && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="contact"
                    defaultValue={null}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, value) => {
                          field.onChange(value);
                          handleContactSearchChange("");
                        }}
                        onInputChange={(event, newInputValue) => {
                          handleContactSearchChange(newInputValue);
                        }}
                        options={contactListQuery.data?.content || []}
                        getOptionLabel={(option: Contact) => option.name}
                        getOptionSelected={option => option.name === field.value?.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label={t("delivery.formValues.contactName")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
              {status === "SUSPENDED" && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="comment"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        multiline
                        minRows={2}
                        InputLabelProps={{ shrink: true }}
                        label={t("delivery.formValues.comment")}
                      />
                    )}
                  />
                </Grid>
              )}
              {status === "DELIVERED" && (
                <>
                  <Box className={classes.formCardHeader}>
                    <Typography variant="h3">{t("common:signature.subject")}</Typography>
                  </Box>
                  <Grid item xs={12} container justifyContent="center">
                    <SignatureCanvas
                      id={"signature"}
                      offsetTop={offset.top}
                      offsetLeft={offset.left}
                    />
                  </Grid>
                </>
              )}

              {status === "FAILED" && (
                <Controller
                  control={control}
                  name="failedOrderReasonId"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select
                      InputLabelProps={{ shrink: true, required: true }}
                      label="Meghiúsulás oka"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      style={{ marginLeft: 8, marginRight: 8 }}
                    >
                      {failedOrderReasonQuery.data?.map(reason => (
                        <MenuItem key={reason.id} value={reason.id}>
                          {reason.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              )}

              {status === "IN_PROGRESS" && (
                <>
                  <Grid container justifyContent="center">
                    <Box className={classes.formCardHeader}>
                      <Typography variant="h3">{t("common:signature.warehouse")}</Typography>
                    </Box>
                    <Grid item xs={6} container justifyContent="center">
                      <SignatureCanvas
                        id={"signatureWarehouse"}
                        offsetTop={offset.top}
                        offsetLeft={offset.left}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Box className={classes.formCardHeader}>
                      <Typography variant="h3">{t("common:signature.vehicleDriver")}</Typography>
                    </Box>
                    <Grid item xs={6} container justifyContent="center">
                      <SignatureCanvas
                        id={"signatureVehicleDriver"}
                        offsetTop={offset.top}
                        offsetLeft={offset.left}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="center" m={2} gridGap={8}>
              <Button color="primary" variant="text" onClick={() => setSelectedDelivery(null)}>
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DeliveryStatusDialog;
