import { Box, Button, Container } from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { itemCreate, listItems } from "shared/network/item.api";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import ItemForm, { ItemFormValues } from "../components/ItemForm";

type Props = {
  isLoading?: boolean;
  setItemCreateState?: Dispatch<SetStateAction<number | null>>;
};

const ItemCreate = ({ setItemCreateState, isLoading }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<ItemFormValues>();
  const [itemSearch, setItemSearch] = useState("");
  const [status, setStatus] = useState<SliceStatus>("idle");

  const listItemsQuery = useQuery(["listItemsQuery", tenant.id, itemSearch], async () => {
    const { data } = await listItems(
      0,
      10,
      tenant.id,
      itemSearch &&
        `(name:${itemSearch};(OR)productCode:${itemSearch};)
    `,
    );
    return data.page.content;
  });

  const onSubmitCreate = async (values: ItemFormValues) => {
    const temp = removeEmptyStrings(values);
    try {
      setStatus("pending");
      let wrapping;
      if (values.wrappingId) {
        wrapping = listItemsQuery.data?.find(target => values.wrappingId === target.id);
      }
      await itemCreate(tenant.id, {
        ...temp,
        itemPriceHistoryEntries: values.itemPriceHistoryEntries,
        supplierPriceHistoryEntries: values.supplierPriceHistoryEntries,
        item: {
          ...temp.item,
          wrapping,
          isSerialItem: !!temp.item.isSerialItem,
          futurePriceHandling: !!temp.item.futurePriceHandling,
          compositeItems: values.compositeItems,
          showInvoiceSubItems: values.item?.isComposite ? values.showInvoiceSubItems : false,
          useCompositePrice: values.item?.isComposite ? !!values.useCompositePrice : false,
        },
        compositeItems: values.compositeItems,
      });
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("item.subject"),
        }),
        { variant: "success" },
      );
      setStatus("success");
      if (setItemCreateState) {
        setItemCreateState(null);
        queryClient.invalidateQueries("invoiceItemList");
      } else {
        history.goBack();
      }
    } catch (error: any) {
      if (error.data.status === "MISSING_PRICE_HISTORY") {
        enqueueSnackbar("Hiányzó kompozit termék ár, adja meg kézzel az árakat.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("item.subject"),
          }),
          { variant: "error" },
        );
      }
      setStatus("failure");
    }
  };

  return (
    <Container maxWidth="xl">
      <FormProvider {...form}>
        <form id="item-create-form" onSubmit={form.handleSubmit(onSubmitCreate)}>
          <Loading open={status === "pending"} />
          <ItemForm
            itemList={listItemsQuery.data}
            itemSearch={{ loading: listItemsQuery.isFetching, setSearch: setItemSearch }}
            isModal={!!setItemCreateState}
            isLoading={status !== "idle" && status !== "failure"}
          />
          <Box display="flex" justifyContent="center" m={2} gridGap={8}>
            <Button
              color="primary"
              variant="text"
              onClick={() => {
                if (setItemCreateState) {
                  setItemCreateState(null);
                } else {
                  history.goBack();
                }
              }}
            >
              {t("common:button.cancel")}
            </Button>
            <Button form="item-create-form" type="submit" color="primary" disabled={isLoading}>
              {t("common:button.save")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};
export default ItemCreate;
