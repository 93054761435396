import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format, getTime } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { listCargoForVehiclePageable } from "shared/network/cargo.api";
import { getDeliveryById, modifyDelivery } from "shared/network/delivery.api";
import { getItemListWithWrappingByCompanySite } from "shared/network/rel-item-company-site";
import { listItemsInWarehousePageable } from "shared/network/warehouse.api";
import { Item, SerialItemEntry } from "shared/types";
import { getPriceForItems } from "../../shared/network/delivery.api";
import MyDeliveryItemModifyRow from "./MyDeliveryItemModifyRow";

export type DeliveryItemFormValues = {
  normalItemList: {
    item: Item | null;
    quantity: string | number | null;
    maxQuantity: string | number | null;
    price: number | string | null;
    type: "PICK_UP" | "PICK_UP_WRAPPING" | "NORMAL";
    itemSerial: SerialItemEntry | null;
    connected: number;
  }[];
  pickUpWithWrappingItemList: {
    item: Item | null;
    quantity: string | number | null;
    maxQuantity: string | number | null;
    type: "PICK_UP" | "PICK_UP_WRAPPING" | "NORMAL";
    price: number | string | null;
    itemSerial: SerialItemEntry | null;
    connected: number;
  }[];
};

export type Props = {
  deliveryId: number | null;
  setDeliveryId: Dispatch<SetStateAction<number | null>>;
};

const MyDeliveryItemDialog = ({ deliveryId, setDeliveryId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);

  const form = useForm<DeliveryItemFormValues>();
  const { control, watch, handleSubmit, reset } = form;

  const normalItemList = useFieldArray({
    control,
    name: "normalItemList",
    keyName: "key",
  });
  const pickUpWithWrappingItemList = useFieldArray({
    control,
    name: "pickUpWithWrappingItemList",
    keyName: "key",
  });

  const deliveryQuery = useQuery(
    "deliveryDeliveryItemDialog",
    async () => {
      if (deliveryId) {
        const { data } = await getDeliveryById(deliveryId, tenant.id);
        return data.item;
      }
      return Promise.reject();
    },
    { enabled: !!deliveryId },
  );
  const delivery = deliveryQuery?.data;

  const normalItemListQuery = useQuery(
    ["normalItemListQuery", selectedRelTenant.tenant.id, delivery],
    async () => {
      if (delivery) {
        if (delivery?.status === "ACTIVE") {
          const { data } = await listItemsInWarehousePageable(
            0,
            1000000,
            selectedRelTenant.tenant.id,
            `warehouseId:${delivery?.fromWarehouse?.id};date=${format(new Date(), "yyyy-MM-dd")}`,
          );
          return {
            ...data.page,
            content: data.page.content.map(value => {
              return { ...value.item, maxQuantity: value.quantity };
            }),
          };
        } else if (delivery?.status === "IN_PROGRESS") {
          const { data } = await listCargoForVehiclePageable(
            0,
            1000000,
            delivery.vehicleId,
            selectedRelTenant.tenant.id,
            "quantity!=0",
            "",
            true,
          );

          return {
            ...data.page,
            content: data.page.content.map(value => {
              return {
                ...value.item,
                maxQuantity: value.quantity + value.reservedQuantity,
              };
            }),
          };
        }
      }
      return Promise.reject();
    },
    { enabled: !!delivery },
  );

  const pickUpWithWrappingItemListQuery = useQuery(
    ["pickUpWithWrappingItemListQuery", selectedRelTenant.tenant.id, delivery],
    async () => {
      if (delivery) {
        const { data } = await getItemListWithWrappingByCompanySite(
          0,
          1000000,
          delivery?.companySite,
          selectedRelTenant.tenant.id,
          "",
          "",
          true,
          true,
        );
        return {
          ...data.page,
          content: data.page.content.map(value => {
            return { ...value.item, maxQuantity: value.quantity };
          }),
        };
      }
      return Promise.reject();
    },
    { enabled: !!delivery },
  );

  const pickUpItemListQuery = useQuery(
    ["pickUpItemListQuery", selectedRelTenant.tenant.id, delivery],
    async () => {
      if (delivery) {
        const { data } = await getItemListWithWrappingByCompanySite(
          0,
          1000000,
          delivery?.companySite,
          selectedRelTenant.tenant.id,
          "",
          "",
          true,
          false,
        );
        return {
          ...data.page,
          content: data.page.content.map(value => {
            return { ...value.item, maxQuantity: value.quantity };
          }),
        };
      }
      return Promise.reject();
    },
    { enabled: !!delivery },
  );

  const formValues = watch();

  const getPriceForItemsQuery = useQuery(
    ["getPriceForItemsQuery", selectedRelTenant.tenant.id, delivery, formValues],
    async () => {
      if (
        delivery &&
        (formValues.normalItemList?.length || formValues.pickUpWithWrappingItemList?.length)
      ) {
        const { data } = await getPriceForItems(
          [
            ...formValues.normalItemList
              .filter(item => item.item)
              .map(value => {
                return { ...value, deliveryId: delivery.id, type: "NORMAL" };
              }),
            ...formValues.pickUpWithWrappingItemList
              .filter(item => item.item)
              .map(value => {
                return { ...value, deliveryId: delivery.id };
              }),
          ],
          selectedRelTenant.tenant.id,
        );

        return data.items;
      }
      return Promise.reject();
    },
    { enabled: !!delivery },
  );

  useEffect(() => {
    let temp = 0;
    getPriceForItemsQuery?.data?.forEach((value: any) => {
      temp += value?.grossPrice * value?.relItemDelivery?.quantity;
    });
    setPrice(temp);
  }, [getPriceForItemsQuery?.data]);

  async function onSubmit(values: DeliveryItemFormValues) {
    setLoading(true);
    try {
      if (delivery) {
        await modifyDelivery(
          {
            deliveryEntry: delivery,
            relList: [
              ...values.normalItemList.map(value => {
                return { ...value, deliveryId: delivery?.id };
              }),
              ...values.pickUpWithWrappingItemList.map(value => {
                return { ...value, deliveryId: delivery?.id };
              }),
            ],
          },
          selectedRelTenant.tenant.id,
        );
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("deliveryItem.subject"),
          }),
          { variant: "success" },
        );
        reset();
        setDeliveryId(null);
        deliveryQuery.refetch();
        queryClient.invalidateQueries("deliveryListQueryVehicles");
      } else {
        throw new Error();
      }
    } catch (error: any) {
      if (error.data.status === "DELIVERY_CANNOT_BE_MODIFIED") {
        enqueueSnackbar(t("delivery.DELIVERY_CANNOT_BE_MODIFIED"), { variant: "error" });
      } else if ((error.data.status as string)?.includes("INSUFFICIENT_QUANTITY")) {
        enqueueSnackbar("delivery.INSUFFICIENT_QUANTITY", { variant: "error" });
      } else if ((error.data.status as string)?.includes("INSUFFICIENT_QUANTITY_COMPANY_SITE")) {
        enqueueSnackbar("delivery.INSUFFICIENT_QUANTITY_COMPANY_SITE", { variant: "error" });
      } else {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("deliveryItem.subject"),
          }),
          { variant: "error" },
        );
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    reset({
      normalItemList:
        delivery?.relItemDeliveryItems
          .filter(value => value.type === "NORMAL")
          .map(entry => {
            return {
              ...entry,
              maxQuantity:
                normalItemListQuery.data?.content?.find(item => item?.id === entry?.item?.id)
                  ?.maxQuantity || 0,
            };
          }) || [],
      pickUpWithWrappingItemList:
        delivery?.relItemDeliveryItems
          .filter(value => value.type === "PICK_UP_WRAPPING" || value.type === "PICK_UP")
          .map(entry => {
            return {
              ...entry,
              maxQuantity:
                entry.type === "PICK_UP_WRAPPING"
                  ? pickUpWithWrappingItemListQuery.data?.content?.find(
                      item => item.id === entry.item.id,
                    )?.maxQuantity || 0
                  : pickUpItemListQuery.data?.content?.find(item => item?.id === entry?.item?.id)
                      ?.maxQuantity || 0,
            };
          }) || [],
    });
  }, [
    delivery?.relItemDeliveryItems,
    normalItemListQuery.data,
    pickUpWithWrappingItemListQuery.data,
    pickUpItemListQuery.data,
    reset,
  ]); //eslint-disable-line

  return (
    <FormProvider {...form}>
      <form>
        <Dialog
          open={!!deliveryId}
          onClose={() => setDeliveryId(null)}
          maxWidth="sm"
          fullWidth
          fullScreen={matchesSm}
          disablePortal
        >
          <Loading open={loading || deliveryQuery.isFetching} />
          <DialogContent>
            <DialogTitle style={{ paddingLeft: 0 }}>{t("delivery.normalItemList")}</DialogTitle>
            {normalItemList.fields.map((field, index) => {
              return (
                <MyDeliveryItemModifyRow
                  key={field.key}
                  normalField={field}
                  connectedFieldIndex={pickUpWithWrappingItemList.fields.findIndex(
                    value => value.connected === field.connected,
                  )}
                  pickUpWithWrappingRemove={pickUpWithWrappingItemList.remove}
                  pickUpWithWrappingAppend={pickUpWithWrappingItemList.append}
                  index={index}
                  delivery={delivery}
                  itemListName="normalItemList"
                  type="NORMAL"
                  selectedItem={watch(`normalItemList.${index}.item`)}
                  selectedItemIsSerial={
                    watch(`normalItemList.${index}.item`)?.type === "SERIAL_NUMBER"
                  }
                  itemList={normalItemListQuery.data}
                  remove={normalItemList.remove}
                />
              );
            })}
            <Box display="flex" justifyContent="center">
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "DELIVERY_ADMIN",
              ]) && (
                <Button
                  size="small"
                  color="primary"
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    width: 30,
                    background: COLORS.white,
                  }}
                  variant="outlined"
                  startIcon={<AddBox />}
                  onClick={() => {
                    const timestamp = getTime(new Date());
                    normalItemList.append({
                      item: null,
                      quantity: "",
                      type: "NORMAL",
                      connected: timestamp,
                    });
                    pickUpWithWrappingItemList.append({
                      item: null,
                      quantity: "",
                      type: "PICK_UP_WRAPPING",
                      connected: timestamp,
                    });
                  }}
                >
                  {t("common:button.add")}
                </Button>
              )}
            </Box>
            <Box
              borderBottom={`1px solid ${COLORS.mainGrey}`}
              margin={1}
              marginLeft={0}
              marginRight={0}
            />
            <DialogTitle style={{ margin: 0, padding: 0, marginBottom: 16 }}>
              {t("delivery.pickUpItemList")}
            </DialogTitle>
            {pickUpWithWrappingItemList.fields.map((field, index) => {
              if (field.type === "PICK_UP_WRAPPING") {
                return (
                  <MyDeliveryItemModifyRow
                    key={field.key}
                    index={index}
                    delivery={delivery}
                    type="PICK_UP_WRAPPING"
                    itemListName="pickUpWithWrappingItemList"
                    selectedItem={watch(`pickUpWithWrappingItemList.${index}.item`)}
                    selectedItemIsSerial={
                      watch(`pickUpWithWrappingItemList.${index}.item`)?.type === "SERIAL_NUMBER"
                    }
                    itemList={pickUpWithWrappingItemListQuery.data}
                    remove={pickUpWithWrappingItemList.remove}
                  />
                );
              } else if (field.type === "PICK_UP") {
                return (
                  <MyDeliveryItemModifyRow
                    key={field.key}
                    index={index}
                    delivery={delivery}
                    type="PICK_UP"
                    itemListName="pickUpWithWrappingItemList"
                    selectedItem={watch(`pickUpWithWrappingItemList.${index}.item`)}
                    selectedItemIsSerial={
                      watch(`pickUpWithWrappingItemList.${index}.item`)?.type === "SERIAL_NUMBER"
                    }
                    itemList={pickUpItemListQuery.data}
                    remove={pickUpWithWrappingItemList.remove}
                  />
                );
              } else return null;
            })}
            <Grid container justifyContent="center">
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "DELIVERY_ADMIN",
              ]) && (
                <Box display="flex" gridGap={8}>
                  <Button
                    size="small"
                    color="primary"
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      width: 30,
                      background: COLORS.white,
                    }}
                    variant="outlined"
                    startIcon={<AddBox />}
                    onClick={() =>
                      pickUpWithWrappingItemList.append({
                        item: null,
                        quantity: "",
                        type: "PICK_UP",
                      })
                    }
                  >
                    Termék
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      width: 30,
                      background: COLORS.white,
                    }}
                    variant="outlined"
                    startIcon={<AddBox />}
                    onClick={() =>
                      pickUpWithWrappingItemList.append({
                        item: null,
                        quantity: "",
                        type: "PICK_UP_WRAPPING",
                      })
                    }
                  >
                    Göngyöleg
                  </Button>
                </Box>
              )}
            </Grid>
            <DialogTitle style={{ paddingLeft: 0 }}>
              {t("deliveryPoint.price") +
                " " +
                delivery?.currency?.prefix +
                " " +
                t("common:number", { num: price })}
              {" " + delivery?.currency?.postfix}
            </DialogTitle>
            <DialogTitle style={{ padding: 0 }}>
              {t("contract.formValues.paymentType") +
                ": " +
                t(`project.paymentTypes.${delivery?.order?.paymentType}`)}
            </DialogTitle>
          </DialogContent>
          <DialogActions>
            <Box display="flex" gridGap={8}>
              <Button
                variant="text"
                onClick={() => {
                  setDeliveryId(null);
                }}
              >
                {t("common:button.cancel")}
              </Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                {t("common:button.save")}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

export default MyDeliveryItemDialog;
