import { Box, Button, Grid, TextField } from "@material-ui/core";
import FormCard from "components/FormCard";
import NumberField from "components/NumberField";
import { NUMBER_FORMAT } from "config/constants";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { TaxEntry } from "shared/types";

export type TaxTypeFormValues = {
  name: string;
  percent: number;
  description: string;
};

type Props = {
  taxEntry?: TaxEntry;
};

const TaxTypeForm = ({ taxEntry }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <FormCard title={t("taxType.formTitle").toString()}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="name"
              defaultValue={taxEntry?.name || ""}
              rules={{
                required: t("validation.required").toString(),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("taxType.name")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="percent"
              defaultValue={taxEntry?.percent || 0}
              rules={{
                validate: value => {
                  if (value && !value.toString().match(NUMBER_FORMAT)) {
                    return t("common:validation.numberFormat").toString();
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <NumberField
                  field={field}
                  fieldState={fieldState}
                  label={t("taxType.percent")}
                  InputLabelProps={{ shrink: true }}
                  numberLimits={{ step: "0.01", min: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="description"
              defaultValue={taxEntry?.description || ""}
              rules={{
                required: t("validation.required").toString(),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("taxType.description")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  multiline
                  rows={4}
                />
              )}
            />
          </Grid>
        </Grid>
      </FormCard>
      <Box display="flex" justifyContent="center" m={2} gridGap={8}>
        <Button color="primary" variant="text" onClick={history.goBack}>
          {t("common:button.cancel")}
        </Button>
        <Button type="submit" color="primary">
          {taxEntry ? t("common:button.save") : t("common:button.create")}
        </Button>
      </Box>
    </>
  );
};

export default TaxTypeForm;
