import { Box, Button, Card, Typography } from "@material-ui/core";
import { AddBox, Edit, Settings } from "@material-ui/icons";
import DetailsCard from "components/DetailsCard";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import displayAddress from "shared/util/displayAddress";
import BankAccountList from "views/Company/CompanyDetailsTabs/BankAccount/BankAccountList";
import OwnCompanyAddTechUser from "views/OwnCompany/OwnCompanyAddTechUser";
import ContractType from "./ContractType";
import CompanyLogoDialog from "./TenantIcon/CompanyLogoDialog";
import TenantSites from "./TenantSites";

const TenantProfileDetails = () => {
  const { t } = useTranslation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const [open, setOpen] = useState(false);
  const [isCompanyLogoOpen, setIsCompanyLogoOpen] = useState(false);

  return (
    <Box display="flex" flexDirection="column" gridGap={16} pb={2}>
      {(selectedRelTenant.isTenantAdmin || account.user.isSuperAdmin) && (
        <CompanyLogoDialog
          setOpen={setIsCompanyLogoOpen}
          open={isCompanyLogoOpen}
          tenantId={tenant.id.toString()}
        />
      )}
      <DetailsCard
        title={t("tenant.datas")}
        headerButtons={
          <>
            {(account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin) && (
              <>
                <Button
                  color="primary"
                  variant="text"
                  component={Link}
                  to={`/admin/tenants/tenant-modify?id=${tenant.id}`}
                  startIcon={<Edit />}
                  style={{ height: 24 }}
                >
                  {t("common:button.modify")}
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => setIsCompanyLogoOpen(true)}
                  startIcon={<Edit />}
                  style={{ height: 24 }}
                >
                  {t("tenant.addInvoiceIcon")}
                </Button>
              </>
            )}
          </>
        }
        columns={2}
        dataList={[
          {
            name: t("tenant.formValues.name"),
            value: tenant?.name || "-",
          },
          {
            name: t("tenant.formValues.tenantCode"),
            value: tenant?.tenantCode || "-",
          },
          {
            name: t("tenant.formValues.bankAccount"),
            value: tenant?.bankAccount || "-",
          },
          {
            name: t("tenant.formValues.taxNumber"),
            value: tenant?.taxNumber || "-",
          },
          {
            name: t("tenant.formValues.registrationNumber"),
            value: tenant?.registrationNumber || "-",
          },
          {
            name: t("tenant.formValues.baseLanguage"),
            value: tenant?.baseLanguage ? t(`common:language.${tenant.baseLanguage}`) : "-",
          },
          {
            name: t("tenant.formValues.baseCurrency.title"),
            value: tenant?.baseCurrency.name || "-",
          },
          {
            name: t("tenant.formValues.baseCurrencySource"),
            value: tenant?.baseCurrencySource || "-",
          },
          {
            name: t("tenant.formValues.description"),
            value: tenant?.description || "-",
          },
        ]}
      />
      <DetailsCard
        title={t("tenant.availability")}
        columns={2}
        dataList={[
          {
            name: t("tenant.formValues.phone"),
            value: tenant?.phone || "-",
          },
          {
            name: t("tenant.formValues.fullAddress"),
            value: tenant?.address ? displayAddress(tenant?.address) : "-",
          },
          {
            name: t("tenant.formValues.email"),
            value: tenant?.notificationEmail || "-",
          },
        ]}
      />
      <DetailsCard
        title={t("ownCompany.navTechnicalUser")}
        headerButtons={
          (account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin) && (
            <>
              <Button
                color="primary"
                variant="text"
                startIcon={<Settings />}
                style={{ height: 24 }}
                onClick={() => setOpen(true)}
              >
                {t("ownCompany.setNavTechnicalUser")}
              </Button>
              <OwnCompanyAddTechUser open={open} setOpen={setOpen} />
            </>
          )
        }
        columns={1}
        dataList={[
          {
            name: t("ownCompany.login"),
            value: tenant?.technicalLogin || "-",
          },
          {
            name: t("ownCompany.signatureKey"),
            value: tenant?.technicalSignatureKey || "-",
          },
          {
            name: t("ownCompany.exchangeKey"),
            value: tenant?.technicalExchangeKey || "-",
          },
        ]}
      />
      <ContractType />
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["BANK_ACCOUNT_GET"]) && (
        <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between" p={2}>
            <Typography variant="h2">{t("tenant.tabs.accountNumber")}</Typography>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "BANK_ACCOUNT_ADMIN",
            ]) && (
              <Button
                color="primary"
                variant="text"
                component={Link}
                to="/admin/tenants/tenant-details/bank-accounts/bank-account-create"
                startIcon={<AddBox />}
                style={{ height: 24 }}
              >
                Új bankszámla
              </Button>
            )}
          </Box>
          <BankAccountList transparent />
        </Card>
      )}
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["DEPARTRMENT_GET"]) && (
        <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between" p={2}>
            <Typography variant="h2">{t("tenant.tabs.department")}</Typography>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "DEPARTRMENT_ADMIN",
            ]) && (
              <Button
                color="primary"
                variant="text"
                component={Link}
                to="/my-company/departments/department-create"
                startIcon={<AddBox />}
                style={{ height: 24 }}
              >
                {t("department.create").toString()}
              </Button>
            )}
          </Box>
          <TenantSites />
        </Card>
      )}
    </Box>
  );
};
export default TenantProfileDetails;
