import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { MonitoringAlert } from "shared/types";

const ENDPOINT = "/monitoring-alert";

export const getMonitoringAlertPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<MonitoringAlert>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //MONITORING_ALERT_GET
