import { isArray } from "lodash";

export function removeEmptyStrings(values: object) {
  let temp: any = [];

  Object.entries(values).forEach(([key, value]) => {
    if (typeof value === "string") {
      temp = { ...temp, [key]: value === "" ? null : value };
    } else if (typeof value === "object" && isArray(value)) {
      temp = {
        ...temp,
        [key]: value.map((item: any) => {
          if (typeof item === "object" && item !== null) {
            return removeEmptyStrings(item);
          } else {
            return item === "" ? null : item;
          }
        }),
      };
    } else if (typeof value === "object" && value !== null) {
      temp = { ...temp, [key]: removeEmptyStrings(value) };
    } else {
      temp = { ...temp, [key]: value };
    }
  });
  return temp;
}
