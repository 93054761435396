import * as React from "react";
import { User } from "shared/types";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import {
  RouteProps,
  Route,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import { hasAnyAuthority, hasAuthority } from "shared/authorization";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
  onlyAdmin?: boolean;
  strict?: boolean;
  permissions?: string[];
  condition?: (account: User, permissions: string[]) => boolean;
} & RouteProps;

const selector = createSelector(
  (state: RootState) => state.authentication,
  (_: RootState, props: Props) => props,
  (
    { isAuthenticated, account, selectedRelTenant },
    { permissions, strict, condition },
  ) => {
    let isAuthorized;
    if (condition) {
      isAuthorized = condition(account.user, permissions ?? []);
    } else {
      if (strict) {
        isAuthorized = hasAuthority(
          account.user,
          account.permissions,
          selectedRelTenant,
          permissions ?? [],
        );
      } else {
        isAuthorized = hasAnyAuthority(
          account.user,
          account.permissions,
          selectedRelTenant,
          permissions ?? [],
        );
      }
    }
    return {
      isAuthorized,
      isAuthenticated,
    };
  },
);

const PrivateRoute = ({ component, ...rest }: Props) => {
  const { t } = useTranslation();
  const { isAuthenticated, isAuthorized } = useSelector((state: RootState) =>
    selector(state, rest),
  );

  const checkAuthorities = (props: RouteComponentProps<any>) =>
    isAuthorized && component ? (
      React.createElement(component, props)
    ) : (
      // TODO: Create better page for not having a matchin authority
      <Box display="flex" justifyContent="center">
        <Typography variant="h5">{t("noPermission")}</Typography>
      </Box>
    );

  const renderRedirect = (props: RouteComponentProps<any>) => {
    if (isAuthenticated) {
      return checkAuthorities(props);
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
            search: props.location.search,
            state: { from: props.location },
          }}
        />
      );
    }
  };

  if (!component) {
    throw new Error(
      `A component needs to be specified for private route for path ${rest.path}`,
    );
  }

  return <Route {...rest} render={renderRedirect} />;
};

export default PrivateRoute;
