import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect, Switch } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import TenantAllDelivery from "views/Admin/Tenants/TenantAllDelivery";
import DeliveryNoteList from "views/Delivery/DeliveryNote/DeliveryNoteList";
import OrderListByStatus from "./OrderList";
import SupplierOrderList from "./SupplierOrderList";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";

export const ORDER_TABS = [
  "/order/list",
  "/order/supplier-list",
  `/order/delivery-note`,
  `/order/delivery-all`,
];

const OrderTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/order" && <Redirect to="/order/list" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_GET"]) && (
          <Tab
            label={t("order.listTab")}
            component={Link}
            to={ORDER_TABS[0]}
            value={ORDER_TABS[0]}
          />
        )}
        {isEmpty(account.user.relUserCompany) &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["ORDER_GET"]) && (
            <Tab
              label={t("order.supplierListTab")}
              component={Link}
              to={ORDER_TABS[1]}
              value={ORDER_TABS[1]}
            />
          )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DELIVERY_NOTE_ADMIN",
        ]) && (
          <Tab
            label={t("deliveryNote.deliveryNoteTitle")}
            component={Link}
            to={ORDER_TABS[2]}
            value={ORDER_TABS[2]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["DELIVERY_GET"]) && (
          <Tab
            label={t("tenant.tabs.deliveryAll")}
            component={Link}
            to={ORDER_TABS[3]}
            value={ORDER_TABS[3]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={ORDER_TABS[0]}
              component={OrderListByStatus}
              permissions={["ORDER_GET", "ORDER_ADMIN"]}
            />
            <PrivateRoute
              path={ORDER_TABS[1]}
              component={SupplierOrderList}
              permissions={["ORDER_GET", "ORDER_ADMIN"]}
            />
            <PrivateRoute
              path={ORDER_TABS[2]}
              component={DeliveryNoteList}
              permissions={["DELIVERY_NOTE_ADMIN"]}
            />
            <PrivateRoute
              path={ORDER_TABS[3]}
              component={TenantAllDelivery}
              permissions={["DELIVERY_GET"]}
            />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default OrderTabs;
