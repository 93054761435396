import {
  Container,
  Step,
  StepButton,
  StepContent,
  Stepper,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DailyReport, getFullDailyReportById } from "shared/network/daily-report.api";
import { RelDailyReportIssue } from "shared/network/rel-daily-report-issue.api";
import DailyReportCreatePage1 from "./DailyReportCreatePage1/DailyReportCreatePage1";
import DailyReportCreatePage2 from "./DailyReportCreatePage2/DailyReportCreatePage2";
import DailyReportCreatePage3 from "./DailyReportCreatePage3/DailyReportCreatePage3";
import DailyReportCreatePage4 from "./DailyReportCreatePage4/DailyReportCreatePage4";
import DailyReportCreatePage5 from "./DailyReportCreatePage5/DailyReportCreatePage5";

type FormValues = {
  project: any; //Project;
  reportedDate: Date;
  issues: {
    issue: any; //Issue;
  }[];
};

const DailyReportCreate = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);
  const [activeStep, setActiveStep] = useState(0);
  const [dailyReport, setDailyReport] = useState<DailyReport | null>(null);
  const [relDailyReportIssues, setRelDailyReportIssues] = useState<RelDailyReportIssue[] | null>(
    null,
  );
  const form = useForm<FormValues>();
  const { setValue } = form;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const isClosed = dailyReport?.reportStatus === "CLOSED";

  const { isFetching, refetch } = useQuery(
    [`getInitDailyReport`, id, tenant?.id],
    async () => {
      if (id) {
        const { data } = await getFullDailyReportById(id, tenant.id);
        return data;
      }
      return Promise.reject();
    },
    {
      onSuccess: data => {
        setDailyReport(data?.dailyReportEntry);
        setRelDailyReportIssues(data?.relDailyReportIssueEntries);
        setValue("reportedDate", new Date(data?.dailyReportEntry?.reportedDate) || new Date());
        setValue("project", data?.dailyReportEntry?.project);
      },
    },
  );

  function onStepClick(currentStep: number, step: number) {
    setActiveStep(step);
    if (currentStep === 1) {
      if (!id) {
        history.replace(`/my-company/daily-report/create?id=${dailyReport?.id}`);
      }
      refetch();
    }
  }

  return (
    <Container maxWidth="lg">
      <Loading open={isFetching} />
      {!isFetching && (
        <FormProvider {...form}>
          <form>
            {matches ? (
              <>
                <Stepper
                  nonLinear
                  orientation="horizontal"
                  activeStep={activeStep}
                  alternativeLabel
                >
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 0)}
                    >
                      {t("dailyReport.tab1")}
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 1)}
                    >
                      {t("dailyReport.tab2")}
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 2)}
                    >
                      {t("dailyReport.tab3")}
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 3)}
                    >
                      {t("dailyReport.tab4")}
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 4)}
                    >
                      {t("dailyReport.tab5")}
                    </StepButton>
                  </Step>
                </Stepper>
                {activeStep === 0 && (
                  <DailyReportCreatePage1
                    dailyReport={dailyReport}
                    setDailyReport={setDailyReport}
                    setActiveStep={setActiveStep}
                    isClosed={isClosed}
                  />
                )}
                {activeStep === 1 && (
                  <DailyReportCreatePage2
                    dailyReport={dailyReport}
                    relDailyReportIssues={relDailyReportIssues}
                    setDailyReport={setDailyReport}
                    setActiveStep={setActiveStep}
                    isClosed={isClosed}
                  />
                )}
                {activeStep === 2 && (
                  <DailyReportCreatePage3
                    dailyReport={dailyReport}
                    relDailyReportIssues={relDailyReportIssues}
                    setDailyReport={setDailyReport}
                    setActiveStep={setActiveStep}
                    isClosed={isClosed}
                  />
                )}
                {activeStep === 3 && (
                  <DailyReportCreatePage4
                    dailyReport={dailyReport}
                    relDailyReportIssues={relDailyReportIssues}
                    setDailyReport={setDailyReport}
                    setActiveStep={setActiveStep}
                    isClosed={isClosed}
                  />
                )}
                {activeStep === 4 && (
                  <DailyReportCreatePage5
                    dailyReport={dailyReport}
                    relDailyReportIssues={relDailyReportIssues}
                    setDailyReport={setDailyReport}
                    setActiveStep={setActiveStep}
                    isClosed={isClosed}
                  />
                )}
              </>
            ) : (
              <>
                <Stepper
                  nonLinear
                  orientation="vertical"
                  activeStep={activeStep}
                  style={{ padding: 0 }}
                >
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 0)}
                    >
                      {t("dailyReport.tab1")}
                    </StepButton>
                    <StepContent>
                      <DailyReportCreatePage1
                        dailyReport={dailyReport}
                        setDailyReport={setDailyReport}
                        setActiveStep={setActiveStep}
                        isClosed={isClosed}
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 1)}
                    >
                      {t("dailyReport.tab2")}
                    </StepButton>
                    <StepContent>
                      <DailyReportCreatePage2
                        dailyReport={dailyReport}
                        relDailyReportIssues={relDailyReportIssues}
                        setDailyReport={setDailyReport}
                        setActiveStep={setActiveStep}
                        isClosed={isClosed}
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 2)}
                    >
                      {t("dailyReport.tab3")}
                    </StepButton>
                    <StepContent>
                      <DailyReportCreatePage3
                        dailyReport={dailyReport}
                        relDailyReportIssues={relDailyReportIssues}
                        setDailyReport={setDailyReport}
                        setActiveStep={setActiveStep}
                        isClosed={isClosed}
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 3)}
                    >
                      {t("dailyReport.tab4")}
                    </StepButton>
                    <StepContent>
                      <DailyReportCreatePage4
                        dailyReport={dailyReport}
                        relDailyReportIssues={relDailyReportIssues}
                        setDailyReport={setDailyReport}
                        setActiveStep={setActiveStep}
                        isClosed={isClosed}
                      />
                    </StepContent>
                  </Step>

                  <Step>
                    <StepButton
                      disabled={!dailyReport?.id}
                      onClick={() => onStepClick(activeStep, 4)}
                    >
                      {t("dailyReport.tab5")}
                    </StepButton>
                    <StepContent>
                      <DailyReportCreatePage5
                        dailyReport={dailyReport}
                        relDailyReportIssues={relDailyReportIssues}
                        setDailyReport={setDailyReport}
                        setActiveStep={setActiveStep}
                        isClosed={isClosed}
                      />
                    </StepContent>
                  </Step>
                </Stepper>
              </>
            )}
          </form>
        </FormProvider>
      )}
    </Container>
  );
};

export default DailyReportCreate;
