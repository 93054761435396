import * as React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

type Props = RouteComponentProps;

const PageNotFound = ({ history }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      height="100%"
    >
      <Typography variant="h3" align="center" gutterBottom>
        {t("notFound")}
      </Typography>
      <Box alignSelf="center">
        <Button color="secondary" size="large" onClick={() => history.goBack()}>
          {t("button.back")}
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
