import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Delete, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import {
  EXPENSE_TYPE,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getDepartmentList } from "shared/network/department.api";
import { getEmployeeListPageable } from "shared/network/employee.api";
import { createExpenseIdentifier } from "shared/network/expense-identifier.api";
import { listOfferByTenant } from "shared/network/offer.api";
import { listProject } from "shared/network/project.api";
import { listTools } from "shared/network/tools.api";
import { getVehicleListPageable } from "shared/network/vehicle.api";
import { useDebouncedCallback } from "use-debounce";

export type ExpenseIdentifierForm = {
  expenseIdentifierEntryList: ExpenseIdentifierEntry[];
};

export type ExpenseIdentifierEntry = {
  expenseType: string;
  expenseIdentifier: string;
  selectedItem?: any;
};

const ExpenseIdentifierSearch = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const form = useForm<ExpenseIdentifierForm>({ shouldUnregister: true });

  const { control, watch, setError, resetField } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: "expenseIdentifierEntryList",
    keyName: "key",
  });

  const [employeeSearch, setEmployeeSearch] = useState<string>("");
  const [vehicleSearch, setVehicleSearch] = useState<string>("");
  const [toolSearch, setToolSearch] = useState<string>("");
  const [departmentSearch, setDepartmentSearch] = useState<string>("");
  const [offerSearch, setOfferSearch] = useState<string>("");
  const [projectSearch, setProjectSearch] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);

  const expenseIdentifierEntryList = watch("expenseIdentifierEntryList");

  const employeeListQuery = useQuery(
    ["employeeListQuery", tenant.id, employeeSearch],
    async () => {
      const { data } = await getEmployeeListPageable(
        0,
        10,
        tenant.id,
        `expenseIdentifier:$;` + (employeeSearch ? `name:${employeeSearch}` : ``),
      );

      const selectedList = expenseIdentifierEntryList.filter(
        entry => entry.expenseType === "EMPLOYEE",
      );
      return data.page?.content.filter(
        entry => !selectedList.find(value => value.selectedItem?.id === entry.id),
      );
    },
    {
      enabled: !!expenseIdentifierEntryList?.find(entry => entry.expenseType === "EMPLOYEE"),
    },
  );

  const vehicleListQuery = useQuery(
    ["vehicleListQuery", tenant.id, vehicleSearch],
    async () => {
      const { data } = await getVehicleListPageable(
        0,
        10,
        tenant.id,
        `expenseIdentifier:$;` + (vehicleSearch ? `licensePlateNumber:$${vehicleSearch}` : ``),
      );

      const selectedList = expenseIdentifierEntryList.filter(
        entry => entry.expenseType === "VEHICLE",
      );
      return data.page?.content.filter(
        entry => !selectedList.find(value => value.selectedItem?.id === entry.id),
      );
    },
    {
      enabled: !!expenseIdentifierEntryList?.find(entry => entry.expenseType === "VEHICLE"),
    },
  );

  const toolListQuery = useQuery(
    ["toolListQuery", tenant.id, toolSearch],
    async () => {
      const { data } = await listTools(
        0,
        10,
        tenant.id,
        `expenseIdentifier:$;` + (toolSearch ? `name:${toolSearch}` : ``),
      );

      const selectedList = expenseIdentifierEntryList.filter(entry => entry.expenseType === "TOOL");
      return data.page?.content.filter(
        entry => !selectedList.find(value => value.selectedItem?.id === entry.id),
      );
    },
    {
      enabled: !!expenseIdentifierEntryList?.find(entry => entry.expenseType === "TOOL"),
    },
  );

  const departmentListQuery = useQuery(
    ["departmentListQuery", tenant.id, departmentSearch],
    async () => {
      const { data } = await getDepartmentList(
        0,
        10,
        tenant.id,
        `expenseIdentifier:$;` + (departmentSearch ? `name:${departmentSearch}` : ``),
      );

      const selectedList = expenseIdentifierEntryList.filter(
        entry => entry.expenseType === "DEPARTMENT",
      );
      return data.page?.content.filter(
        entry => !selectedList.find(value => value.selectedItem?.id === entry.id),
      );
    },
    {
      enabled: !!expenseIdentifierEntryList?.find(entry => entry.expenseType === "DEPARTMENT"),
    },
  );

  const offerListQuery = useQuery(
    ["offerListQuery", tenant.id, offerSearch],
    async () => {
      const { data } = await listOfferByTenant(
        0,
        10,
        tenant.id,
        `expenseIdentifier:$;` +
          (offerSearch ? `(name:${offerSearch};(OR)identifier:${offerSearch};)` : ``),
      );

      const selectedList = expenseIdentifierEntryList.filter(
        entry => entry.expenseType === "OFFER",
      );
      return data.page?.content.filter(
        entry => !selectedList.find(value => value.selectedItem?.id === entry.id),
      );
    },
    {
      enabled: !!expenseIdentifierEntryList?.find(entry => entry.expenseType === "OFFER"),
    },
  );

  const projectListQuery = useQuery(
    ["projectListQuery", tenant.id, projectSearch],
    async () => {
      const { data } = await listProject(
        0,
        10,
        tenant.id,
        `expenseIdentifier:$;` + (projectSearch ? `name:${projectSearch}` : ``),
      );

      const selectedList = expenseIdentifierEntryList.filter(
        entry => entry.expenseType === "PROJECT",
      );
      return data.page?.content.filter(
        entry => !selectedList.find(value => value.selectedItem?.id === entry.id),
      );
    },
    {
      enabled: !!expenseIdentifierEntryList?.find(entry => entry.expenseType === "PROJECT"),
    },
  );

  const isIdentitySelected = useDebouncedCallback((index: number, value: string) => {
    let duplicateFound = expenseIdentifierEntryList
      ?.filter((value, fIndex) => fIndex !== index)
      ?.find(entry => entry.expenseIdentifier === value);
    if (duplicateFound) {
      setError(`expenseIdentifierEntryList.${index}.expenseIdentifier`, {
        type: "custom",
        message: t("expense.error"),
      });
      setIsValid(false);
    } else {
      resetField(`expenseIdentifierEntryList.${index}.expenseIdentifier`, { defaultValue: value });
      setIsValid(true);
    }
  }, 500);

  const refetchQuery = (source: string) => {
    switch (source) {
      case "EMPLOYEE":
        employeeListQuery.refetch();
        break;
      case "VEHICLE":
        vehicleListQuery.refetch();
        break;
      case "TOOL":
        toolListQuery.refetch();
        break;
      case "DEPARTMENT":
        departmentListQuery.refetch();
        break;
      case "OFFER":
        offerListQuery.refetch();
        break;
      case "PROJECT":
        projectListQuery.refetch();
        break;
      default:
        return;
    }
  };

  const handleSearchStringChange = useDebouncedCallback((value: string, source: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      switch (source) {
        case "EMPLOYEE":
          setEmployeeSearch(value);
          break;
        case "VEHICLE":
          setVehicleSearch(value);
          break;
        case "TOOL":
          setToolSearch(value);
          break;
        case "DEPARTMENT":
          setDepartmentSearch(value);
          break;
        case "OFFER":
          setOfferSearch(value);
          break;
        case "PROJECT":
          setProjectSearch(value);
          break;
        default:
          return;
      }
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  function getDataSource(expenseType: string) {
    switch (expenseType) {
      case "EMPLOYEE":
        return employeeListQuery.data || [];
      case "VEHICLE":
        return vehicleListQuery.data || [];
      case "TOOL":
        return toolListQuery.data || [];
      case "DEPARTMENT":
        return departmentListQuery.data || [];
      case "OFFER":
        return offerListQuery.data || [];
      case "PROJECT":
        return projectListQuery.data || [];
      default:
        return [];
    }
  }

  async function onSubmitCreate(values: ExpenseIdentifierForm) {
    try {
      await createExpenseIdentifier(
        values.expenseIdentifierEntryList.map(entry => {
          return {
            expenseType: entry.expenseType,
            subjectId: entry.selectedItem.id,
            expenseIdentifier: entry.expenseIdentifier,
          };
        }),
        tenant.id,
      );
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("expense.subject"),
        }),
        { variant: "success" },
      );
      form.reset({ expenseIdentifierEntryList: [] });
    } catch (error) {
      if ((error as any).data.status === "CONFLICT") {
        enqueueSnackbar(t("common:notification.conflict"), {
          variant: "error",
        });
      }

      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("expense.subject"),
        }),
        { variant: "error" },
      );
    }
  }

  return (
    <Container maxWidth="lg">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmitCreate)} id={"expenseForm"}>
          <FormCard title={t("expense.title")}>
            <Grid container spacing={2}>
              {fields.map((field, index) => {
                const expenseType = watch(`expenseIdentifierEntryList.${index}.expenseType`);
                return (
                  <Fragment key={field.key}>
                    <Grid item xs={2}>
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "8px 16px 8px 12px",
                          color: COLORS.main,
                          borderRadius: "50%",
                          fontSize: 22,
                        }}
                        variant={"h2"}
                      >
                        {index + 1}.
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Controller
                        name={`expenseIdentifierEntryList.${index}.expenseType`}
                        defaultValue={"EMPLOYEE"}
                        rules={{
                          required: t("validation.required").toString(),
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            select
                            label={t("incomingInvoice.formValues.expenseType")}
                            InputLabelProps={{ shrink: true, required: true }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          >
                            {EXPENSE_TYPE.map(type => (
                              <MenuItem key={type} value={type}>
                                {t(`common:expenseTypeValues.${type}`)}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Controller
                        control={control}
                        name={`expenseIdentifierEntryList.${index}.selectedItem`}
                        defaultValue={null}
                        rules={{
                          required: t("validation.required").toString(),
                        }}
                        render={({ field, fieldState }) => (
                          <Autocomplete
                            {...field}
                            onChange={(_, value) => {
                              field.onChange(value);
                              handleSearchStringChange("", expenseType);
                              refetchQuery(expenseType);
                            }}
                            onInputChange={(event, newInputValue) => {
                              handleSearchStringChange(newInputValue, expenseType);
                            }}
                            options={getDataSource(expenseType)}
                            getOptionLabel={(option: any) =>
                              (option.name || "") +
                              (!!option.identifier ? ` (${option.identifier})` : "") +
                              (option.licensePlateNumber || "")
                            }
                            getOptionSelected={option => option.id === field.value?.id}
                            renderInput={params => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <Tooltip
                                      style={{
                                        paddingRight: "2px",
                                      }}
                                      title={t("tooltip.worker").toString()}
                                    >
                                      <Info style={{ color: COLORS.lightBlue }} />
                                    </Tooltip>
                                  ),
                                }}
                                label={t(`expense.labels.${expenseType}`)}
                                InputLabelProps={{
                                  shrink: true,
                                  required: true,
                                }}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name={`expenseIdentifierEntryList.${index}.expenseIdentifier`}
                        defaultValue={""}
                        rules={{
                          required: t("validation.required").toString(),
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label={t("incomingInvoice.formValues.expenseIdentifier")}
                            onChange={event => {
                              field.onChange(event);
                              isIdentitySelected(index, event.target.value);
                            }}
                            InputLabelProps={{ shrink: true, required: true }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title={t("common:button.delete").toString()}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            remove(index);
                          }}
                          style={{ margin: "4px 12px 16px 12px" }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
            <Box display="flex" justifyContent="center">
              <Button
                onClick={() => append({})}
                variant="outlined"
                color="primary"
                startIcon={<AddBox />}
              >
                {t("common:button.add")}
              </Button>
            </Box>
          </FormCard>
        </form>
      </FormProvider>
      <Box display="flex" justifyContent="center" p={2}>
        <Button disabled={!isValid} type="submit" form={"expenseForm"}>
          {t("common:button.save")}
        </Button>
      </Box>
    </Container>
  );
};

export default ExpenseIdentifierSearch;
