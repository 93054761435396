import axios from "config/axios";

const ENDPOINT = "/report";

export const reportCreate = (param: any, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param, tenantId });

export const getReportByToken = (token: string, email: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-token`, { token, email, tenantId });

export const getReportById = (param: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/by-id`, { param, tenantId });

export const getReportPage = (
  page: number,
  size: number,
  search: string,
  tenantId: number,
  sortState: string,
) =>
  axios.post(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sortState}`,
  );

export const modifyStatus = (param: any, tenantId: number) =>
  axios.post(`${ENDPOINT}/modify-status`, { param: { ...param, tenantId }, tenantId });

export const modifyVisibility = (param: any, tenantId: number) =>
  axios.post(`${ENDPOINT}/modify-visibility`, { param: { ...param, tenantId }, tenantId });
