import axios from "config/axios";
import { GenericListResponse, GenericResponse } from "shared/common";
import { PersonalData } from "shared/types";

const ENDPOINT = "/personal-data";

export const createEmployeePersonalData = (params: {
  type: string;
  employeeId: string;
  tenantId: number;
  comment: string;
  status: string;
  validTo: string;
  file?: File;
  name: string;
  timeOfObtaining: string;
  placeOfObtaining: string;
}) => {
  const formData = new FormData();

  if (params.type) {
    formData.set("type", params.type);
  }
  if (params.employeeId) {
    formData.set("employeeId", params.employeeId);
  }
  if (params.tenantId) {
    formData.set("tenantId", params.tenantId.toString());
  }
  if (params.comment) {
    formData.set("comment", params.comment);
  }
  if (params.status) {
    formData.set("status", params.status);
  }
  if (params.validTo) {
    formData.set("validTo", params.validTo);
  }
  if (params.name) {
    formData.set("name", params.name);
  }
  if (params.timeOfObtaining) {
    formData.set("timeOfObtaining", params.timeOfObtaining);
  }
  if (params.placeOfObtaining) {
    formData.set("placeOfObtaining", params.placeOfObtaining);
  }
  return axios.post(`${ENDPOINT}/create`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //EMPLOYEE_ADMIN
export const modifyEmployeePersonalData = (params: {
  id: string;
  type: string;
  employeeId: string;
  tenantId: number;
  comment: string;
  status: string;
  validTo: string;
  file?: File;
  name: string;
  timeOfObtaining: string;
  placeOfObtaining: string;
  filePath: string;
  originalFileName: string;
}) => {
  const formData = new FormData();

  if (params.id) {
    formData.set("id", params.id);
  }
  if (params.filePath) {
    formData.set("filePath", params.filePath);
  }
  if (params.originalFileName) {
    formData.set("originalFileName", params.originalFileName);
  }
  if (params.type) {
    formData.set("type", params.type);
  }
  if (params.employeeId) {
    formData.set("employeeId", params.employeeId);
  }
  if (params.tenantId) {
    formData.set("tenantId", params.tenantId.toString());
  }
  if (params.comment) {
    formData.set("comment", params.comment);
  }
  if (params.status) {
    formData.set("status", params.status);
  }
  if (params.validTo) {
    formData.set("validTo", params.validTo);
  }
  if (params.name) {
    formData.set("name", params.name);
  }
  if (params.timeOfObtaining) {
    formData.set("timeOfObtaining", params.timeOfObtaining);
  }
  if (params.placeOfObtaining) {
    formData.set("placeOfObtaining", params.placeOfObtaining);
  }
  return axios.put(`${ENDPOINT}/modify`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //EMPLOYEE_ADMIN

export const listEmployeePersonalData = (param: string, tenantId: number) =>
  axios.post<GenericListResponse<PersonalData>>(`${ENDPOINT}/list-by-employee`, {
    param,
    tenantId,
  }); //EMPLOYEE_ADMIN

export const getPersonalDataById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<PersonalData>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //EMPLOYEE_ADMIN
