import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { AddBox, ArrowBack, Check } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import FormCard from "components/FormCard";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { DailyReport, modifyDailyReport } from "shared/network/daily-report.api";
import { listRelDailyReportCashFlowByTenant } from "shared/network/rel-daily-report-cash-flow.api";
import { RelDailyReportIssue } from "shared/network/rel-daily-report-issue.api";
import DailyReportCashFlowDialog from "./DailyReportCashFlowDialog";
import DailyReportCreateCashFlowRow from "./DailyReportCreateCashFlowRow";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

type Props = {
  dailyReport: DailyReport | null;
  relDailyReportIssues: RelDailyReportIssue[] | null;
  setDailyReport: Dispatch<SetStateAction<DailyReport | null>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isClosed: boolean;
};

const DailyReportCreatePage5 = ({ dailyReport, setActiveStep, isClosed }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { data, isFetching, refetch } = useQuery(
    [`listRelDailyReportCashFlowQuery`, tenant?.id, page],
    async () => {
      if (tenant?.id) {
        const { data } = await listRelDailyReportCashFlowByTenant(
          page,
          20,
          tenant.id,
          `dailyReportId:${dailyReport?.id}`,
        );
        return data?.page;
      }
      return Promise.reject();
    },
  );
  const totalElements = data?.totalElements || 0;
  const totalPages = data?.totalPages || 0;

  async function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  async function handleNext() {
    setLoading(true);
    try {
      await modifyDailyReport({ ...dailyReport, reportStatus: "CLOSED" }, tenant?.id);
      history.push("/my-company/daily-report");
      enqueueSnackbar(t("dailyReport.finishSuccess"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("dailyReport.finishError"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  return (
    <Box>
      <Loading open={loading} />
      <FormCard title={t("dailyReport.cashFlow")}>
        <Loading open={isFetching} />
        {totalElements > 0 ? (
          <Box overflow="auto">
            <Box minWidth={300} m={0.5}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("dailyReport.cashFlowUser")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: "bold" }}>{t("dailyReport.cash")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              {data?.content?.map((rel, index) => (
                <DailyReportCreateCashFlowRow
                  key={rel.id}
                  index={index}
                  dailyReport={dailyReport}
                  relDailyReportCashFlow={rel}
                  refetch={refetch}
                  isClosed={isClosed}
                />
              ))}
              {data && data?.totalPages > 1 && (
                <Pagination
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "8px 0 0 0",
                  }}
                  count={totalPages}
                  color="primary"
                  page={data?.number + 1}
                  onChange={(e, page) => {
                    sessionStorage.setItem(
                      `IssueTypePageNumber-${tenant?.id}`,
                      JSON.stringify(page - 1),
                    );
                    setPage(page - 1);
                  }}
                />
              )}
            </Box>
          </Box>
        ) : (
          <Box textAlign="center">
            <Typography>{t("dailyReport.noCashFlow")}</Typography>
          </Box>
        )}
        {!isClosed && (
          <Box display="flex" justifyContent="center" pt={2}>
            <Button startIcon={<AddBox />} onClick={() => setOpen(true)}>
              {t("dailyReport.cashFlow")}
            </Button>
          </Box>
        )}
        <DailyReportCashFlowDialog
          dailyReportId={dailyReport?.id}
          open={open}
          onClose={() => setOpen(false)}
          refetch={refetch}
        />
      </FormCard>
      <Box display="flex" justifyContent="center" gridGap={8} p={1.5}>
        <Button startIcon={<ArrowBack />} variant="outlined" onClick={handleBack}>
          {t("common:button.prev")}
        </Button>
        {!isClosed && (
          <Button startIcon={<Check />} variant="contained" color="primary" onClick={handleNext}>
            {t("dailyReport.finish")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default DailyReportCreatePage5;
