import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { getReportByToken } from "shared/network/report.api";
import { Report } from "./ReportDetails";
import ReportFooter from "./ReportFooter";
import ReportDetailsCard from "./components/ReportDetailsCard";
import PublicCommentList from "./components/PublicComment/PublicCommentList";

type FormValues = {
  token: string;
  email: string;
};

const PublicReportDetails = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);
  const tokenParam = query.get("token");
  const { t } = useTranslation();
  const [report, setReport] = useState<Report | null>(null);
  const form = useForm<FormValues>();
  const { setValue, watch } = form;
  const token = watch("token");
  const email = watch("email");

  async function onSubmit(values: FormValues) {
    try {
      const { data } = await getReportByToken(values.token, values.email, 0);
      setReport(data.item);
    } catch {
      enqueueSnackbar(t("report.createError"), {
        variant: "info",
      });
    }
  }

  useEffect(() => {
    if (tokenParam) {
      setValue("token", tokenParam);
    }
  }, [setValue, tokenParam]);

  return (
    <Box>
      {!report ? (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Container maxWidth="sm" style={{ padding: 16 }}>
              <Card>
                <CardHeader title={t("report.reportData")} />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={form.control}
                        name="token"
                        defaultValue={""}
                        rules={{ required: t("validation.required").toString() }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label="Token"
                            InputLabelProps={{ shrink: true, required: true }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        control={form.control}
                        name="email"
                        defaultValue={""}
                        rules={{ required: t("validation.required").toString() }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label={t("report.email")}
                            InputLabelProps={{ shrink: true, required: true }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <Button type="submit" color="primary">
                          {t("report.create")}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Box textAlign="center">
                <ReportFooter />
              </Box>
            </Container>
          </form>
        </FormProvider>
      ) : (
        <Container maxWidth="md" style={{ padding: 16 }}>
          <Box pb={2}>
            <ReportDetailsCard report={report} />
            {report?.id && token && report.tenantId && (
              <Box pt={2}>
                <PublicCommentList
                  subjectId={report.id}
                  token={token}
                  email={email}
                  tenantId={report.tenantId}
                />
              </Box>
            )}
            <ReportFooter />
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default PublicReportDetails;
