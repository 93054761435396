import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { KeyboardTimePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format, set } from "date-fns";
import hu from "date-fns/esm/locale/hu/index.js";
import { deburr } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { createCheckTime, getCheckTimePage, modifyCheckTime } from "shared/network/check-time.api";
import { getHaccpItem } from "shared/network/haccp-item.api";

type FormValues = {
  checkAt: string;
  isActive: boolean;
};

const HaccpItemDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [open, setOpen] = useState(false);
  const form = useForm<FormValues>();
  const { handleSubmit } = form;

  const haccpItemQuery = useQuery(["haccpItemQuery"], async () => {
    if (id) {
      const { data } = await getHaccpItem(Number(id), tenant.id);
      return data.item;
    }
    return Promise.reject();
  });
  const haccpItem = haccpItemQuery.data;

  const [loading, setLoading] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createCheckTime(
        {
          checkAt: set(new Date(values.checkAt), { year: 2000, month: 0, date: 1 }).toISOString(),
          haccpItem,
          isActive: values.isActive,
        },
        tenant?.id,
      );
      setOpen(false);
      form.reset();
      queryClient.refetchQueries("checkTimePageQuery");

      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  const checkTimePageQuery = useQuery(
    ["checkTimePageQuery", tenant?.id, haccpItem?.id],
    async () => {
      const { data } = await getCheckTimePage(
        0,
        2000,
        tenant.id,
        haccpItem ? `haccpItem.id=${haccpItem.id}` : "",
        "checkAt,asc",
      );
      return data.page.content;
    },
  );

  return (
    <Container>
      <Loading open={loading || haccpItemQuery?.isFetching} />
      <Card style={{ padding: 16, marginBottom: 16 }}>
        {!!haccpItemQuery?.data && (
          <Box display="flex" alignItems="center" gridGap={8}>
            <Box>
              <Tooltip title={t("common:button.qrDownload")}>
                <a
                  href={`data:image/png;base64, ${haccpItem?.qrLinkBase64}`}
                  download={"qr-" + deburr(haccpItem?.name).replaceAll(" ", "_")}
                >
                  <img src={`data:image/png;base64, ${haccpItem?.qrLinkBase64}`} alt="QR-code" />
                </a>
              </Tooltip>
            </Box>
            <Box>
              <Typography style={{ fontWeight: "bold" }}>{t("haccpItem.name")}:</Typography>
              <Typography style={{ paddingBottom: 12 }}>{haccpItem?.name}</Typography>
              <Typography style={{ fontWeight: "bold" }}>
                {t("haccpItem.workingTemperature2")}:
              </Typography>
              <Typography
                style={{ paddingBottom: 12 }}
              >{`${haccpItem?.workingTemperature}°C`}</Typography>
              <Typography style={{ fontWeight: "bold" }}>{t("haccpItem.status.label")}:</Typography>
              <Typography>{t(`haccpItem.status.${haccpItem?.status}`)}</Typography>
            </Box>
          </Box>
        )}
      </Card>
      <Card style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "bold", fontSize: 18, paddingBottom: 8 }}>
          Szükséges ellenőrzések időpontjai
        </Typography>
        {checkTimePageQuery.data?.map(checkTime => (
          <Chip
            label={format(new Date(checkTime.checkAt), "p", { locale: hu })}
            style={{ margin: 4 }}
            color={checkTime.isActive ? "primary" : "default"}
            onClick={async () => {
              try {
                await modifyCheckTime({ ...checkTime, isActive: !checkTime.isActive }, tenant?.id);
                checkTimePageQuery.refetch();
              } catch {}
            }}
          />
        ))}
        <IconButton
          size="small"
          style={{ background: COLORS.main, color: "white", margin: 4 }}
          onClick={() => setOpen(true)}
        >
          <Add />
        </IconButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle>Szükséges ellenőrzések időpontjai</DialogTitle>
              <DialogContent>
                <Controller
                  name="checkAt"
                  defaultValue={new Date()}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <KeyboardTimePicker
                      {...field}
                      label="Ellenőrzés ideje"
                      ref={undefined}
                      InputLabelProps={{ shrink: true, required: true }}
                      ampm={false}
                      autoOk
                    />
                  )}
                />
                <Controller
                  name="isActive"
                  defaultValue={true}
                  render={props => (
                    <FormControlLabel
                      labelPlacement="end"
                      label={"Aktív"}
                      control={
                        <Checkbox
                          {...props.field}
                          checked={props.field.value}
                          size="small"
                          onChange={e => props.field.onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="text" onClick={() => setOpen(false)}>
                  {t("common:button.cancel")}
                </Button>
                <Button variant="contained" type="submit">
                  {t("common:button.save")}
                </Button>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      </Card>
    </Container>
  );
};
export default HaccpItemDetails;
