import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { reportCreate } from "shared/network/report.api";
import { listReportTenants } from "shared/network/tenant.api";
import { Tenant } from "shared/types";
import AcceptTos from "./AcceptTos";
import ReportFooter from "./ReportFooter";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  name: string;
  description: string;
  tenant: {
    id: number;
    name: string;
  } | null;
  zipCode: string;
  city: string;
  address: string;
  hasOlderReportSameIssue: boolean;
  hasAnyOtherKnownCourtCase: boolean;
  isValidData: boolean;
};

const ReportCreate = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const companyId = query.get("companyId");
  const form = useForm<FormValues>();
  const { control, setValue, watch } = form;
  const [email, phone, zipCode, city, address] = watch([
    "email",
    "phone",
    "zipCode",
    "city",
    "address",
  ]);

  const [isTosOk, setIsTosOk] = useState(false);
  const [state, setState] = useState<"IDLE" | "LOADING" | "SUCCESS">("IDLE");

  const [hasOlderReportSameIssue, setHasOlderReportSameIssue] = useState(false);
  const [hasAnyOtherKnownCourtCase, setHasAnyOtherKnownCourtCase] = useState(false);
  const [isValidData, setIsValidData] = useState(false);

  const listReportTenantsQuery = useQuery("listReportTenantsQuery", async () => {
    const { data } = await listReportTenants();
    return data.items;
  });

  const onSubmitCreate = async (values: FormValues) => {
    setState("LOADING");
    try {
      if (values.tenant) {
        await reportCreate(
          {
            ...values,
            tenantId: values.tenant?.id,
            status: "NEW",
            hasOlderReportSameIssue,
            hasAnyOtherKnownCourtCase,
            isValidData,
          },
          values.tenant?.id,
        );
      }
      setState("SUCCESS");
    } catch (e) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
      setState("IDLE");
    }
  };

  useEffect(() => {
    if (companyId) {
      setValue(
        "tenant",
        listReportTenantsQuery?.data?.find(value => value.id === Number(companyId)) || null,
      );
    }
  }, [companyId, listReportTenantsQuery?.data, setValue]);

  return (
    <Container maxWidth="md" style={{ padding: 16 }}>
      <form onSubmit={form.handleSubmit(onSubmitCreate)}>
        {state === "LOADING" ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : state === "SUCCESS" ? (
          <Card>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gridGap={32}
              p={4}
            >
              <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                {t("report.success")}
              </Typography>
              <Button color="primary" variant="outlined" onClick={() => history.push("/")}>
                {t("report.back")}
              </Button>
            </Box>
          </Card>
        ) : (
          <FormProvider {...form}>
            <FormCard title={t("Visszaélés bejelentő")}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <Typography style={{ color: COLORS.red, paddingBottom: 8 }}>
                    Tisztelt Bejelentő!
                  </Typography>
                  <Typography style={{ color: COLORS.red, paddingBottom: 4 }}>
                    Tájékoztatjuk Önt, hogy a panaszokról, a közérdekű bejelentésekről, valamint a
                    visszaélések bejelentésével összefüggő szabályokról 2023. évi XXV. törvény
                    előírásának eleget téve Visszaélés-bejelentő rendszert üzemeltetünk.
                  </Typography>
                  <Typography style={{ color: COLORS.red, paddingBottom: 4 }}>
                    Annak érdekében, hogy a bejelentő személye anonim módon, rejtve maradjon,
                    független Bejelentővédelmi megbízott kijelölésére került sor.
                  </Typography>
                  <Typography style={{ color: COLORS.red, paddingBottom: 4 }}>
                    Tájékoztatjuk Önt, hogy a Visszaélés-bejelentő rendszer működtetésének
                    köszönhetően a Bejelentő személyes adatait kizárólag a Bejelentővédelmi
                    megbízott ismerheti meg, kezelheti.
                  </Typography>
                  <Typography style={{ color: COLORS.red, paddingBottom: 4 }}>
                    Kérjük Önt, hogy az egyszerűbb és gyorsabb ügyintézés érdekében, az esetleges
                    bejelentését ezen a bejelentői felületen keresztül megtenni szíveskedjen!
                  </Typography>
                  <Typography style={{ color: COLORS.red, paddingBottom: 8 }}>
                    Ezúton tájékoztatjuk Önt, amennyiben nem kívánja megadni nevét, elérhetőségi
                    adatait a bejelentés kivizsgálása nem kötelező.
                  </Typography>
                  <Typography style={{ color: COLORS.red, paddingBottom: 16 }}>
                    Együttműködését köszönjük!
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="lastName"
                    defaultValue={""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("report.lastName")}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="firstName"
                    defaultValue={""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("report.firstName")}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold", fontSize: 16, marginLeft: 8 }}>
                    Kapcsolattartási adatok
                  </Typography>
                  <Typography style={{ color: COLORS.red, fontSize: 14, marginLeft: 8 }}>
                    Az e-mail cím, a telefonszám és a levelezési cím közül legalább az egyiket
                    kötelező kitölteni.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("report.email")}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="phone"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("report.phone")}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold", marginLeft: 8 }}>
                    Levelezési cím
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Controller
                    control={control}
                    name="zipCode"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Irányítószám"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    control={control}
                    name="city"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField {...field} label="Város" InputLabelProps={{ shrink: true }} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="address"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField {...field} label="Cím" InputLabelProps={{ shrink: true }} />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ marginTop: 4, marginBottom: 4 }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="tenant"
                    defaultValue={null}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, value) => field.onChange(value)}
                        options={listReportTenantsQuery?.data || []}
                        getOptionLabel={(option: Partial<Tenant>) => option.name || ""}
                        getOptionSelected={option => option.id === field.value?.id}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t("report.tenant")}
                            InputLabelProps={{ shrink: true, required: true }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="name"
                    defaultValue={"Visszaélés bejelentés"}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("report.name")}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="description"
                    defaultValue=""
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("report.description")}
                        InputLabelProps={{ shrink: true, required: true }}
                        multiline
                        minRows={14}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    labelPlacement="start"
                    style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
                    control={
                      <Box display="flex" alignItems="center" gridGap={8}>
                        <Typography style={{ fontSize: 13 }}>Nem</Typography>
                        <Switch
                          style={{ marginRight: 4 }}
                          color="primary"
                          size="small"
                          value={!!hasOlderReportSameIssue}
                          onChange={(_, checked) => setHasOlderReportSameIssue(checked)}
                        />
                        <Typography style={{ fontSize: 13 }}>Igen</Typography>
                      </Box>
                    }
                    label={
                      <Typography style={{ fontSize: 13 }}>
                        Tudomása szerint volt-e, van-e a konkrét ügyben bírósági, hatósági eljárás
                        folyamatban?
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    labelPlacement="start"
                    style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
                    control={
                      <Box display="flex" alignItems="center" gridGap={8}>
                        <Typography style={{ fontSize: 13 }}>Nem</Typography>
                        <Switch
                          style={{ marginRight: 4 }}
                          color="primary"
                          size="small"
                          value={!!hasAnyOtherKnownCourtCase}
                          onChange={(_, checked) => setHasAnyOtherKnownCourtCase(checked)}
                        />
                        <Typography style={{ fontSize: 13 }}>Igen</Typography>
                      </Box>
                    }
                    label={
                      <Typography style={{ fontSize: 13 }}>
                        Volt-e korábban azonos ügyben bejelentése?
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <FormControlLabel
                      style={{ marginLeft: 8 }}
                      control={
                        <Checkbox
                          style={{ padding: 0, marginRight: 4 }}
                          color="primary"
                          size="small"
                          value={!!isValidData}
                          onChange={(_, checked) => setIsValidData(checked)}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: 13 }}>
                          Nyilatkozom, hogy a bejelentést jóhiszeműen teszem, az általam megadott
                          adatok, információk a valóságnak megfelelnek.
                        </Typography>
                      }
                    />
                    <AcceptTos setIsTosOk={setIsTosOk} />
                  </Box>
                  <Box display="flex" justifyContent="center" gridGap={8}>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={
                        (!email && !phone && !(zipCode && city && address)) ||
                        !isTosOk ||
                        !isValidData
                      }
                    >
                      {t("common:button.save")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </FormCard>
          </FormProvider>
        )}
      </form>
      <ReportFooter />
    </Container>
  );
};

export default ReportCreate;
