import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Assignment, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { hasAuthority } from "shared/authorization";
import { getAllDelivery } from "shared/network/delivery.api";
import { Delivery, RelItemDelivery } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import DeliveryItemsRow from "views/Delivery/DeliveryItemRow";
import DeliveryStatusDialog from "views/Delivery/DeliveryStatusDialog";
import { TableState } from "./CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";
import { format } from "date-fns";

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
    verticalAlign: "center",
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const CompanyDelivery = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const [statusDelivery, setStatusDelivery] = useState<Delivery | null>(null);
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery | null>(null);

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-company-delivery-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-company-delivery-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const getAllDeliveryListQuery = useQuery(
    [
      "getAllDeliveryListQuery",
      page,
      pageSize,
      filterValue,
      selectedRelTenant.tenant.id,
      sortState,
    ],
    async () => {
      const { data } = await getAllDelivery(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        `company.id:${id};${filterValue || ""}`,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("delivery.formValues.orderIdCode"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.order.orderId,
    },
    {
      field: "date",
      headerName: t("deliveryNote.formValues.date"),
      flex: 1,
      type: "singleDateStart",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.date ? format(new Date(row.date), "yyyy.MM.dd") : "-",
    },
    {
      field: "sourceAddress",
      headerName: t("delivery.formValues.sourceAddress"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.sourceAddress ? displayAddress(row.sourceAddress) : "-",
    },
    {
      field: "targetAddress",
      headerName: t("delivery.formValues.destinationAddress"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.targetAddress ? displayAddress(row.targetAddress) : "-",
    },
    {
      field: "fromWarehouse.name",
      headerName: t("delivery.formValues.fromWarehouse"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.fromWarehouse ? row.fromWarehouse.name : "-",
    },
    {
      field: "toWarehouse.name",
      headerName: t("delivery.formValues.toWarehouse"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.toWarehouse ? row.toWarehouse.name : "-",
    },
    {
      field: "status",
      headerName: t("delivery.formValues.status"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t(`delivery.status.${row.status}`),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ADMIN",
          ]) && (
            <Tooltip title={t("delivery.showItems").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => setSelectedDelivery(row as Delivery)}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ADMIN",
          ]) && (
            <Tooltip title={t("delivery.modifyStatus").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => setStatusDelivery(row as Delivery)}
                disabled={["DELIVERED", "BILLED", "CANCELLED"].includes(
                  (row as Delivery).status.toString(),
                )}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {/*Export gomb*/}
        {/* <Button
          color="primary"
          variant="outlined"
          startIcon={<GetApp />}
          style={{ margin: "auto" }}
        >
          {t("common:button.export")}
       </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  return (
    <>
      <DeliveryStatusDialog
        selectedDelivery={statusDelivery}
        setSelectedDelivery={setStatusDelivery}
        refetch={getAllDeliveryListQuery.refetch}
      />
      <Dialog
        open={!!selectedDelivery}
        onClose={() => setSelectedDelivery(null)}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>{t("delivery.itemListTitle")}</DialogTitle>
        <DialogContent>
          {!selectedDelivery?.relItemDeliveryItems?.length ? (
            <Box display="flex" justifyContent="center">
              <Typography className={classes.text}>{t("delivery.noItem")}</Typography>
            </Box>
          ) : (
            <>
              <Grid container style={{ padding: "8px 0" }} className={classes.header}>
                <Grid item xs={4} className={classes.cell}>
                  <Typography className={classes.text} variant="body2">
                    {t("item.name")}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.cell}>
                  <Typography className={classes.text} variant="body2">
                    Típus
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.cell}>
                  <Typography className={classes.text} variant="body2">
                    {t("item.productCode")}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.cell}>
                  <Typography className={classes.text} variant="body2">
                    {t("delivery.quantityTitle")}
                  </Typography>
                </Grid>
              </Grid>
              {selectedDelivery?.relItemDeliveryItems?.map((relItem: RelItemDelivery) => (
                <DeliveryItemsRow
                  key={relItem.id}
                  deliveryItem={{
                    ...relItem,
                    deliveryId: selectedDelivery.id,
                  }}
                  deliveryStatus={selectedDelivery.status}
                  refetch={getAllDeliveryListQuery.refetch}
                  warehouseId={selectedDelivery?.fromWarehouse?.id.toString()}
                  itemList={selectedDelivery?.relItemDeliveryItems || []}
                  selectedDelivery={selectedDelivery}
                />
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedDelivery(null)} variant="text">
            {t("common:button.close")}
          </Button>
        </DialogActions>
      </Dialog>
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="company-delivery"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getAllDeliveryListQuery}
      />
    </>
  );
};

export default CompanyDelivery;
