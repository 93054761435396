import { Box, Typography } from "@material-ui/core";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  name: string;
  description: string;
  tenant: {
    id: number;
    name: string;
  } | null;
  zipCode: string;
  city: string;
  address: string;
};

const ReportFooter = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state?.authentication?.account?.user);

  if (!user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="100%"
        p={2}
      >
        <Typography style={{ fontSize: 12 }}>
          ©&nbsp;
          {new Date().getFullYear()}
          &nbsp;
          <a href="https://promera.hu" target="_blank" rel="noopener noreferrer">
            Promera Menedzsment Kft.
          </a>
          &nbsp;
          {t("login.allRightsReserved")}&nbsp;
          <Link to="/tos">Általános szerződési feltételek</Link>
          {","}&nbsp;
          <Link to="/gdpr">Adatkezelési tájékoztató</Link>
        </Typography>
      </Box>
    );
  } else {
    return null;
  }
};

export default ReportFooter;
