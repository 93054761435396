import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Redirect, Switch, useLocation } from "react-router-dom";
import { hasAnyAuthority, hasAuthority } from "shared/authorization";
import DailyDeliveryList from "views/Warehouse/DailyDeliveryList";
import DepartureList from "./Departure/DepartureList";
import VehicleCashByVehicle from "./VehicleCashByVehicle";
import VehicleList from "./VehicleList";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";

export const VEHICLE_TABS = [
  "/vehicles/list",
  "/vehicles/cash-by-vehicle",
  "/vehicles/daily-delivery",
  "/vehicles/departures",
];

const VehicleTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/vehicles" && <Redirect to="/vehicles/list" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["VEHICLE_GET"]) && (
          <Tab
            label={t("vehicle.listTabs.vehicles")}
            component={Link}
            to={VEHICLE_TABS[0]}
            value={VEHICLE_TABS[0]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DELIVERY_GET",
          "DELIVERY_NOTE_ADMIN",
        ]) && (
          <Tab
            label={t("vehicle.listTabs.cashByDelivery")}
            component={Link}
            to={VEHICLE_TABS[1]}
            value={VEHICLE_TABS[1]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_ADMIN",
          "DELIVERY_GET",
          "WAREHOUSE_GET",
        ]) && (
          <Tab
            label={t("drawer.daily-delivery-list")}
            component={Link}
            to={VEHICLE_TABS[2]}
            value={VEHICLE_TABS[2]}
          />
        )}
        {(account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin) && (
          <Tab
            label={t("vehicle.listTabs.departures")}
            component={Link}
            to={VEHICLE_TABS[3]}
            value={VEHICLE_TABS[3]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={VEHICLE_TABS[0]}
              component={VehicleList}
              permissions={["VEHICLE_GET"]}
            />
            <PrivateRoute
              path={VEHICLE_TABS[1]}
              component={VehicleCashByVehicle}
              permissions={["DELIVERY_GET", "DELIVERY_NOTE_ADMIN"]}
            />
            <PrivateRoute
              path={VEHICLE_TABS[2]}
              component={DailyDeliveryList}
              permissions={["WAREHOUSE_ADMIN", "DELIVERY_GET", "WAREHOUSE_GET"]}
            />
            <PrivateRoute path={VEHICLE_TABS[3]} component={DepartureList} />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default VehicleTabs;
