import { STATUSES } from "config/constants";
import { Dispatch, SetStateAction } from "react";
import { Issue } from "shared/types";
import KanbanItem from "./KanbanItem";
import { Grid, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";

type Props = {
  index: number;
  issues?: Issue[];
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
  setModalIssue: Dispatch<SetStateAction<Issue | undefined>>;
  status: string;
  refetch: () => void;
};

const KanbanItemList = ({
  index,
  issues,
  status,
  refetch,
  setCreateOpen,
  setModalIssue,
}: Props) => {
  const { t } = useTranslation();

  const tempIssue: Issue = {
    id: "",
    name: "",
    description: "",
    status: status,
    priority: "",
    project: null,
    parentIssueId: "",
    startDate: "2022-01-01",
    endDate: "2022-01-01",
    estimatedTime: 0,
    company: null,
    completionPercentage: 0,
    contract: null,
    responsibleUser: null,
    offer: null,
    issueTemplateId: 0,
    issueType: null,
    spentTime: 0,
    serialNumber: "",
    relIssueIssues: [],
    worksheetPath: "",
  };

  function length() {
    let maxLength = 0;
    STATUSES.forEach(s => {
      let issueLength = issues?.filter(issue => issue.status === s).length;
      if (issueLength && maxLength < issueLength) {
        maxLength = issueLength;
      }
    });
    return maxLength;
  }

  const filteredIssues = issues?.filter(issue => issue.status === status);

  return (
    <Grid
      key={index}
      item
      xs={2}
      style={{
        minWidth: 200,
        borderRight: index !== 0 || index !== STATUSES.length - 1 ? `1px solid #d3d3d3` : 0,
        borderLeft: index === 0 ? "1px solid #d3d3d3" : "0px",
        borderBottom: "1px solid #d3d3d3",
        marginBottom: 16,
        marginTop: 16,
        backgroundColor: "rgba(255, 255, 255, 0.6)",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: COLORS.mainGrey, //#d3d3d3
          borderBottom: `1px solid #d3d3d3`,
          borderTop: "1px solid #d3d3d3",
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            textAlign: "center",
            padding: 10,
          }}
        >
          {t(`common:statuses.${status}`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <>
          {!filteredIssues?.length ? (
            <KanbanItem
              issue={tempIssue}
              refetch={refetch}
              setCreateOpen={setCreateOpen}
              setModalIssue={setModalIssue}
              length={length()}
            />
          ) : (
            <>
              {filteredIssues?.map(issue => (
                <KanbanItem
                  key={issue.id}
                  issue={issue}
                  setCreateOpen={setCreateOpen}
                  setModalIssue={setModalIssue}
                  refetch={refetch}
                  length={length()}
                />
              ))}
            </>
          )}
        </>
      </Grid>
    </Grid>
  );
};

export default KanbanItemList;
