import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Redirect, Switch, useLocation } from "react-router-dom";
import { hasAnyAuthority } from "shared/authorization";
import TenantAllInvoice from "views/Admin/Tenants/TenantAllInvoice";
import IncomingInvoiceList from "./IncomingInvoice/IncomingInvoiceList";
import InvoiceGenerate from "./InvoiceGenerate";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";

export const INVOICES_TABS = [
  `/invoices/invoices-list`,
  `/invoices/incoming-list`,
  `/invoices/invoice-generate`,
];

const InvoicesTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/invoices" && <Redirect to={`/invoices/invoices-list`} />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "INVOICE_ADMIN",
          "INVOICE_GET",
        ]) && (
          <Tab
            label={t("invoices.tabs.invoices")}
            component={Link}
            to={INVOICES_TABS[0]}
            value={INVOICES_TABS[0]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "INCOMING_INVOICE_ADMIN",
          "INCOMING_INVOICE_GET",
        ]) && (
          <Tab
            label={t("invoices.tabs.incoming")}
            component={Link}
            to={INVOICES_TABS[1]}
            value={INVOICES_TABS[1]}
          />
        )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "INVOICE_ADMIN",
        ]) && (
          <Tab
            label={t("invoices.tabs.invoiceGenerate")}
            component={Link}
            to={INVOICES_TABS[2]}
            value={INVOICES_TABS[2]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={INVOICES_TABS[0]}
              component={TenantAllInvoice}
              permissions={["INVOICE_ADMIN", "INVOICE_GET"]}
            />
            <PrivateRoute
              path={INVOICES_TABS[1]}
              component={IncomingInvoiceList}
              permissions={["INCOMING_INVOICE_ADMIN", "INCOMING_INVOICE_GET"]}
            />
            <PrivateRoute
              path={INVOICES_TABS[2]}
              component={InvoiceGenerate}
              permissions={["INVOICE_ADMIN"]}
            />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default InvoicesTabs;
