import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PdfTemplateList from "components/PdfTemplate/PdfTemplateList";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link, Redirect, Switch } from "react-router-dom";
import { hasAnyAuthority } from "shared/authorization";
import TenantProfileDetails from "views/Admin/Tenants/TenantProfileDetails";
import TenantProfileSettings from "views/Admin/Tenants/TenantProfileSettings";
import DepartmentList from "views/Department/DepartmentList";
import EmployeeList from "views/Employee/EmployeeList";
import FailReasonList from "views/FailReason/FailReasonList";
import ToolList from "views/Tools/ToolList/ToolList";
import AbsenceList from "./Absence/AbsenceList";
import UserList from "./UserList";
import { useEffect } from "react";
import { useHeader } from "components/Layout/HeaderContext";
import DailyReportList from "./DailyReportList";

export const USER_TABS = [
  `/my-company/details`,
  "/my-company/users",
  "/my-company/employees",
  "/my-company/departments",
  "/my-company/absences",
  "/my-company/tools",
  "/my-company/fail-reason",
  `/my-company/settings`,
  `/my-company/pdf-template`,
  `/my-company/daily-report`,
];

const MyCompanyTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/my-company" && <Redirect to="/my-company/details" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        <Tab
          label={t("tenant.tabs.details")}
          component={Link}
          to={USER_TABS[0]}
          value={USER_TABS[0]}
        />
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
          "USER_ADMIN",
          "USER_GET",
        ]) && (
          <Tab
            label={t("drawer./my-company/users")}
            component={Link}
            to={USER_TABS[1]}
            value={USER_TABS[1]}
          />
        )}
        {selectedRelTenant.tenant.isUseDefault &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
            "EMPLOYEE_ADMIN",
            "EMPLOYEE_GET",
          ]) && (
            <Tab
              label={t("drawer./my-company/employees")}
              component={Link}
              to={USER_TABS[2]}
              value={USER_TABS[2]}
            />
          )}
        {selectedRelTenant.tenant.isUseDefault &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
            "DEPARTMENT_ADMIN",
            "DEPARTMENT_GET",
          ]) && (
            <Tab
              label={t("drawer./my-company/departments")}
              component={Link}
              to={USER_TABS[3]}
              value={USER_TABS[3]}
            />
          )}
        {selectedRelTenant.tenant.isUseDefault &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
            "ABSENCE_GET",
          ]) && (
            <Tab
              label={t("drawer./my-company/absences")}
              component={Link}
              to={USER_TABS[4]}
              value={USER_TABS[4]}
            />
          )}
        {selectedRelTenant.tenant.isUseDefault &&
          hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
            "TOOL_ADMIN",
            "TOOL_GET",
          ]) && (
            <Tab
              label={t("drawer./my-company/tools")}
              component={Link}
              to={USER_TABS[5]}
              value={USER_TABS[5]}
            />
          )}
        {selectedRelTenant.tenant.isUseDefault &&
          (selectedRelTenant?.isTenantAdmin || account.user.isSuperAdmin) && (
            <Tab
              label={t("tenant.tabs.failReason")}
              component={Link}
              to={USER_TABS[6]}
              value={USER_TABS[6]}
            />
          )}
        {selectedRelTenant.tenant.isUseDefault &&
          (selectedRelTenant?.isTenantAdmin || account.user.isSuperAdmin) && (
            <Tab
              label={t("tenant.tabs.settings")}
              component={Link}
              to={USER_TABS[7]}
              value={USER_TABS[7]}
            />
          )}
        {selectedRelTenant.tenant.isUseDefault && selectedRelTenant.tenant.pdfTemplateEnabled && (
          <Tab
            label={t("tenant.tabs.pdfTemplate")}
            component={Link}
            to={USER_TABS[8]}
            value={USER_TABS[8]}
          />
        )}
        {selectedRelTenant.tenant.isUseDefault && (
          <Tab label={"Napi jelentés"} component={Link} to={USER_TABS[9]} value={USER_TABS[9]} />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute path={USER_TABS[0]} component={TenantProfileDetails} />
            <PrivateRoute
              path={USER_TABS[1]}
              component={UserList}
              permissions={["USER_ADMIN", "USER_GET"]}
            />
            <PrivateRoute
              path={USER_TABS[2]}
              component={EmployeeList}
              permissions={["EMPLOYEE_ADMIN", "EMPLOYEE_GET"]}
            />
            <PrivateRoute
              path={USER_TABS[3]}
              component={DepartmentList}
              permissions={["DEPARTMENT_ADMIN", "DEPARTMENT_GET"]}
            />
            <PrivateRoute
              path={USER_TABS[4]}
              component={AbsenceList}
              permissions={["ABSENCE_GET"]}
            />
            <PrivateRoute path={USER_TABS[5]} component={ToolList} permissions={["TOOL_GET"]} />
            {(selectedRelTenant?.isTenantAdmin || account.user.isSuperAdmin) && (
              <PrivateRoute path={USER_TABS[6]} component={FailReasonList} />
            )}
            {(selectedRelTenant?.isTenantAdmin || account.user.isSuperAdmin) && (
              <PrivateRoute path={USER_TABS[7]} component={TenantProfileSettings} />
            )}
            {(selectedRelTenant?.isTenantAdmin || account.user.isSuperAdmin) &&
              !!selectedRelTenant?.tenant?.pdfTemplateEnabled && (
                <PrivateRoute path={USER_TABS[8]} component={PdfTemplateList} />
              )}
            {selectedRelTenant.tenant.isUseDefault && (
              <PrivateRoute path={USER_TABS[9]} component={DailyReportList} />
            )}
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default MyCompanyTabs;
