import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { listItems } from "shared/network/item.api";
import {
  createRelCompanySiteItems,
  RelItemCompanySiteEntry,
} from "shared/network/rel-item-company-site";
import RelCompanyItemForm, {
  ItemCompanyFormValues,
} from "./RelCompanyItemForm";

const RelCompanyItemCreate = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const idNullable = query.get("id");
  const companyId = idNullable ? idNullable : "";
  const { t } = useTranslation();
  const history = useHistory();
  const form = useForm<ItemCompanyFormValues>();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );
  const tenantId = tenant.id;
  const [status, setStatus] = useState<SliceStatus>("idle");
  const itemListQuery = useQuery(["itemListForItemCompany"], async () => {
    const { data } = await listItems(0, 1000000, tenant.id, "");
    return data.page.content;
  });

  const onSubmitCreate = async (values: ItemCompanyFormValues) => {
    setStatus("pending");
    try {
      await createRelCompanySiteItems(
        values.relItemCompanies.map(entry => {
          return {
            item: entry.item,
            companySite: values.companySite,
            quantity: entry.quantity,
          } as RelItemCompanySiteEntry;
        }),
        companyId,
        tenantId,
      );
      setStatus("success");
      enqueueSnackbar(
        t("common:notification.add.success", {
          subject: t("item.subject"),
        }),
        {
          variant: "success",
        },
      );
      history.goBack();
    } catch (e) {
      const error = e as any;
      if (error.data?.status === "UNIQUE_KEY_ERROR") {
        enqueueSnackbar(
          t("common:notification.delete.failure", {
            subject: t("item.category.subject"),
          }) +
            " " +
            t("common:notification.UNIQUE_KEY_ERROR"),
          {
            variant: "error",
          },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.add.failure", {
            subject: t("item.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
      setStatus("failure");
    }
  };
  return (
    <>
      <Container maxWidth="lg">
        <form onSubmit={form.handleSubmit(onSubmitCreate)}>
          {status === "pending" ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="300px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <FormProvider {...form}>
              <RelCompanyItemForm
                items={itemListQuery.data}
                companyId={companyId}
              />
            </FormProvider>
          )}
        </form>
      </Container>
    </>
  );
};

export default RelCompanyItemCreate;
