import { GridColDef } from "@mui/x-data-grid";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listPermissionGroupByEmployee } from "shared/network/employee.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

type Props = {
  employeeId: string | null;
  style?: CSSProperties;
};

const EmployeePermissionList = ({ employeeId, style }: Props) => {
  const { t } = useTranslation();
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-employee-permissions-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-employee-permissions-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const permissionQuery = useQuery(
    ["employeePermission", tenant.id, employeeId],
    async () => {
      const { data } = await listPermissionGroupByEmployee(employeeId || "", tenant.id);
      return data;
    },
    { enabled: !!employeeId },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("permissionGroup.name"),
      flex: 1,
    },
  ];

  return (
    <SingleQueryTable
      query={permissionQuery}
      columns={columns}
      setTableState={setTableState}
      tableState={tableState}
      sessionStorageKey={`employeePermissionsList_${tenant.id}`}
    />
  );
};

export default EmployeePermissionList;
