import { Box, Button, Container, Grid, TextField } from "@material-ui/core";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FailedOrderReason } from "shared/types";

export type FailReasonFormValues = { name: string; id?: number; tenantId?: number };

type Props = {
  failReason?: FailedOrderReason;
  setOpen?: any;
  setSelectedReason?: any;
};

const FailReasonForm = ({ failReason, setOpen, setSelectedReason }: Props) => {
  const { t } = useTranslation();
  const { control, reset } = useFormContext<FailReasonFormValues>();

  function onClose() {
    setOpen(false);
    setSelectedReason(null);
  }

  useEffect(() => {
    reset({ name: failReason?.name || "", id: failReason?.id || undefined, tenantId: undefined });
  }, [failReason]); //eslint-disable-line

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="name"
            defaultValue={failReason?.name || ""}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("failReason.form.name")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" m={2} gridGap={8} style={{ width: "100%" }}>
            <Button color="primary" variant="text" onClick={() => onClose()}>
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" color="primary">
              {failReason ? t("common:button.save") : t("common:button.create")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default FailReasonForm;
