import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { RelItemDelivery } from "shared/types";

const useStyles = makeStyles({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const MyDeliveryItemRow = ({ deliveryItem }: { deliveryItem: RelItemDelivery }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" style={{ borderBottom: `1px solid ${COLORS.mainGrey}` }}>
      <Grid item xs={8} style={{ padding: 8 }}>
        <Typography className={classes.text}>
          {`${deliveryItem.item?.name} (${deliveryItem.item?.productCode})`}
        </Typography>
        {!!deliveryItem?.itemSerial?.factoryNumber && (
          <Typography className={classes.text} style={{ fontWeight: "bold" }}>
            {deliveryItem.itemSerial.factoryNumber}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2} style={{ padding: 8 }}>
        <Typography className={classes.text}>
          {deliveryItem?.type === "NORMAL" ? (
            <ArrowDownward fontSize="small" />
          ) : (
            <ArrowUpward fontSize="small" />
          )}
        </Typography>
      </Grid>
      <Grid item xs={2} style={{ display: "flex", alignItems: "center", padding: 8, gridGap: 8 }}>
        <>
          <Typography className={classes.text}>
            {t("common:number", { num: deliveryItem.quantity })}
          </Typography>
        </>
      </Grid>
    </Grid>
  );
};

export default MyDeliveryItemRow;
