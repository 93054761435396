import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { User } from "shared/types";

export type SterilizationItem = {
  id: number;
  tenantId: number;
  name: string;
  serialNumber: string;
  isPieceQuantity: boolean;
  createdBy: User;
  updatedBy: User;
  createdOn: string;
  updatedOn: string;
};

const ENDPOINT = "/sterilization-item";

export const createSterilizationItem = (param: any, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

export const modifySterilizationItem = (param: any, tenantId: number) =>
  axios.put(ENDPOINT, { param, tenantId });

export const getSterilizationItemPage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.get<GenericPageResponse<any>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search || ""}&sort=${
      sort || ""
    }`,
  );

export const getSterilizationItem = (id: number, tenantId: number) =>
  axios.get<GenericResponse<any>>(`${ENDPOINT}/get-by-id?id=${id}&tenantId=${tenantId}`);
