import { Box, Button, Container, Grid, MenuItem, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import FormCard from "components/FormCard";
import { ACTION_TYPES, FILE_TYPES } from "config/constants";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { listCompanyForMonitoringByTenant } from "shared/network/company.api";
import { Company, MonitoringData } from "shared/types";
import { getMonitoringDataPageable } from "shared/network/monitoring.api";
import { sendMonitoringReports } from "shared/network/monitoring-transactions.api";
import { csvDownload } from "shared/util/fileDownload";
import { Info } from "@material-ui/icons";
import { COLORS } from "config/theme";

export type ReportsFormValues = {
  id: number | string;
  name: string;
  company: Company | null;
  fromDate: Date;
  toDate: Date;

  clientId: string;
  machineId: MonitoringData | null;
  type: string | null;
  fileType: string;
};

const ReportsDataCard = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch, handleSubmit, setValue } = useForm<ReportsFormValues>();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const ownCompany = account.user.relUserCompany;

  const company = watch("company");

  const companyListQuery = useQuery(
    ["companyListForContact", tenant.id],
    async () => {
      const { data } = await listCompanyForMonitoringByTenant(0, 1000000, tenant.id);
      return data;
    },
    { enabled: !id },
  );

  const MonitoringMachineListQuery = useQuery(
    ["MonitoringMachineListQuery", tenant.id, company],
    async () => {
      const { data } = await getMonitoringDataPageable(
        0,
        1000000,
        tenant.id,
        `company.id:${company?.id}`,
      );
      return data;
    },
    { enabled: !!company?.id },
  );

  const onSubmitCreate = async (values: ReportsFormValues) => {
    try {
      const { data } = await sendMonitoringReports(
        values.company?.id.toString() || "",
        values.machineId?.machineId?.toString() || "",
        values.type || "",
        values.fromDate.toISOString(),
        values.toDate.toISOString(),
        values.fileType,
        tenant.id,
      );
      if (data) {
        csvDownload(
          data.contentBase64,
          `${t("common:csvDownload.title")}-${t("common:csvDownload.REPORT")}-${data.fileName}`,
        );
      }
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("monitoring.reportsDetails.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e: any) {
      if (e.data?.status === "NOT_FOUND") {
        enqueueSnackbar(t("monitoring.reportsDetails.missing"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("monitoring.reportsDetails.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
    }
  };

  useEffect(() => {
    if (ownCompany) {
      setValue("company", ownCompany?.[0]?.company);
      setValue("type", ACTION_TYPES[0]);
    }
  }, [ownCompany, setValue]);

  return (
    <Container maxWidth="md">
      <form onSubmit={handleSubmit(onSubmitCreate)}>
        <FormCard title={t("monitoring.reportsDetails.general")}>
          <Grid item xs={12} container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Controller
                control={control}
                name="company"
                defaultValue={null}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    disabled={!!ownCompany?.[0]?.company}
                    onChange={(_, value) => {
                      field.onChange(value);
                      setValue("machineId", null);
                    }}
                    options={companyListQuery.data?.page?.content || []}
                    getOptionLabel={(option: Company) => option.name}
                    getOptionSelected={option => option.id === field.value?.id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("tooltip.company").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        label={t("monitoring.reportsDetails.companyId")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="machineId"
                defaultValue={null}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    disabled={!!id}
                    onChange={(_, value) => field.onChange(value)}
                    options={MonitoringMachineListQuery.data?.page.content || []}
                    getOptionLabel={(option: MonitoringData) => option.machineId}
                    getOptionSelected={option => option.id === field.value?.id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("tooltip.tool").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          ),
                        }}
                        label={t("monitoring.machineDetails.machineId")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                control={control}
                name="type"
                defaultValue={""}
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("monitoring.reportsDetails.type")}
                    SelectProps={{ displayEmpty: true }}
                    disabled={!!ownCompany?.[0]?.company}
                    select
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    <MenuItem value="" disabled>
                      {t("common:choose")}
                    </MenuItem>
                    {ACTION_TYPES.map(percent => (
                      <MenuItem key={percent} value={percent}>
                        {t(`common:actiontypes.${percent}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="fromDate"
                defaultValue={new Date()}
                render={({ field, fieldState }) => (
                  <KeyboardDateTimePicker
                    {...field}
                    ref={undefined}
                    className="validTo"
                    variant="inline"
                    label={t("invoice.csvStartDate")}
                    format="yyyy.MM.dd - HH:mm"
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoOk
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="toDate"
                defaultValue={new Date()}
                render={({ field, fieldState }) => (
                  <KeyboardDateTimePicker
                    {...field}
                    ref={undefined}
                    className="validTo"
                    variant="inline"
                    label={t("invoice.csvEndDate")}
                    format="yyyy.MM.dd - HH:mm"
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoOk
                  />
                )}
              />
            </Grid>
            <Grid item sm={12}>
              <Controller
                control={control}
                name="fileType"
                defaultValue={""}
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("monitoring.reportsDetails.filtertype")}
                    SelectProps={{ displayEmpty: true }}
                    select
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    <MenuItem value="" disabled>
                      {t("common:choose")}
                    </MenuItem>
                    {FILE_TYPES.map(percent => (
                      <MenuItem key={percent} value={percent}>
                        {t(`common:filetypes.${percent}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </FormCard>

        <Grid item container xs={12}>
          <Box display="flex" justifyContent="center" width="100%" m={2} gridGap={8}>
            <Button type="submit" color="primary">
              {t("monitoring.reportsDetails.generateReport")}
            </Button>
          </Box>
        </Grid>
      </form>
    </Container>
  );
};

export default ReportsDataCard;
