import { Box, Button, Checkbox, IconButton, TextField, Tooltip } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Food, getFoodPage } from "shared/network/food.api";
import { useDebouncedCallback } from "use-debounce";
import { useStyles } from "../HaccpServingArriving";
import { GoodsFormValues } from "../HaccpServingGoods";

const HaccpGoodsNewRow = ({ setOpenNew }: { setOpenNew: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);

  const form = useFormContext<GoodsFormValues>();
  const { control } = form;

  const [itemSearch, setItemSearch] = useState<string>("");
  const foodPageQuery = useQuery(["foodPageQuery", tenant.id, itemSearch], async () => {
    const { data } = await getFoodPage(0, 100, tenant.id, "foodType=MATERIAL");
    return data?.page?.content || [];
  });

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= 1 || value.length === 0) {
      setItemSearch(value);
    }
  }, 500);

  return (
    <tr>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="number"
          defaultValue={""}
          rules={{
            required: t("validation.requiredShort").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="delivererName"
          defaultValue={""}
          rules={{
            required: t("validation.requiredShort").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="deliveryNoteNumber"
          defaultValue={""}
          rules={{
            required: t("validation.requiredShort").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name={`food`}
          rules={{
            required: t("validation.requiredShort").toString(),
          }}
          defaultValue={null}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              disableClearable
              freeSolo
              autoSelect
              onChange={(_, value) => {
                field.onChange(value);
                handleItemSearchChange("");
              }}
              onInputChange={(_, newInputValue) => {
                handleItemSearchChange(newInputValue);
              }}
              options={foodPageQuery.data || []}
              getOptionLabel={(option: Food | string) =>
                `${typeof option === "string" ? option : option.name}`
              }
              getOptionSelected={option =>
                typeof option !== "string" ? option.id === field.value.id : !!option
              }
              renderInput={params => (
                <TextField
                  {...params}
                  onFocus={event => event.target.select()}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputProps={{
                    ...params.inputProps,
                    style: { padding: 0 },
                  }}
                />
              )}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="amount"
          defaultValue={""}
          rules={{
            required: t("validation.requiredShort").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="temperature"
          defaultValue={""}
          rules={{
            required: t("validation.requiredShort").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          control={control}
          name="isOk"
          defaultValue={false}
          render={props => (
            <Checkbox
              {...props.field}
              size="small"
              checked={props.field.value}
              onChange={e => {
                props.field.onChange(e.target.checked);
                form.setValue("isNotOk", false);
              }}
              style={{ padding: 0 }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          control={control}
          name="isNotOk"
          defaultValue={false}
          render={props => (
            <Checkbox
              {...props.field}
              size="small"
              checked={props.field.value}
              onChange={e => {
                props.field.onChange(e.target.checked);
                if (e.target.checked) {
                  form.setValue("isOk", false);
                }
              }}
              style={{ padding: 0 }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}></td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Box display="flex" flexWrap="nowrap">
          <Button type="submit" size="small" startIcon={<Check />}>
            {t("common:button.save")}
          </Button>
          <Tooltip title={t("common:button.cancel")}>
            <IconButton
              style={{ marginLeft: 4 }}
              size="small"
              color="primary"
              onClick={() => {
                setOpenNew(false);
                form.reset();
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </td>
    </tr>
  );
};

export default HaccpGoodsNewRow;
