import { Box, Card, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Assignment } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Loading from "components/Loading";
import PageableTable from "components/PageableTable/PageableTable";
import { MONITORING_TRANSACTION_TYPE } from "config/constants";
import { RootState } from "config/store";
import { format } from "date-fns";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import {
  getMonitoringDepositorPage,
  getMonitoringTransactionDataPageable,
} from "shared/network/monitoring-transactions.api";
import { MonitoringData } from "shared/types";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

type Props = {
  monitoringData?: MonitoringData;
  setSelectedTransactionId: Dispatch<SetStateAction<number | null>>;
};

const MonitoringTransactionList = ({ monitoringData, setSelectedTransactionId }: Props) => {
  const { t } = useTranslation();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-monitoring-transaction-list-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-monitoring-transaction-list-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const companyId = account?.user?.relUserCompany?.[0]?.company.id;
  const isDepositor =
    companyId &&
    hasAuthority(account.user, account.permissions, selectedRelTenant, [
      "MONITORING_DEPOSITOR_GET",
    ]);

  const columns: GridColDef[] = [
    {
      field: "transactionNumber",
      headerName: t("monitoring.transactionData.transactionNumber"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.transactionNumber ? "#" + row.transactionNumber : "-",
      type: "stringContainsNumber",
    },
    {
      field: "dateTime",
      headerName: t("monitoring.transactionData.dateTime"),
      flex: 1.2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.dateTime ? format(new Date(row.dateTime), "yyyy.MM.dd. HH:mm") : "-",
      type: "date",
    },
    {
      field: "companyName",
      headerName: t("monitoring.transactionData.companyName"),
      flex: 1,
    },
    {
      field: "userId",
      headerName: t("monitoring.transactionData.userId"),
      flex: 1,
    },
    {
      field: "totalAmount",
      headerName: t("monitoring.transactionData.totalAmount"),
      flex: 1.5,
      valueGetter: ({ row }: GridValueGetterParams) =>
        (monitoringData?.currency.code || "") +
        " " +
        t(`common:number`, {
          num: row?.totalAmount,
        }),
      type: "stringContainsNumber",
    },
    {
      field: "transactionType",
      headerName: t("monitoring.transactionData.transactionType"),
      flex: 0.8,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t(`monitoring.transaction_type.${row?.transactionType}`),
      type: "select",
    },
    {
      field: "actions",
      headerName: " ",
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "MONITORING_GET",
          ]) && (
            <Tooltip title={t("monitoring.machineDetails.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => setSelectedTransactionId(row.id)}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    setSelectedTransactionId(params.row.id);
  }

  const monitoringTransactionList = useQuery(
    [
      "monitoringTransactionListQuerry",
      tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
      monitoringData,
    ],
    async () => {
      const { data } = await getMonitoringTransactionDataPageable(
        page,
        pageSize,
        tenant.id,
        `machineId:$${monitoringData?.machineId};companyId:${monitoringData?.company.id};` +
          filterValue,
        "transactionNumber,desc" + sortState,
      );
      return data;
    },
    { enabled: !!monitoringData },
  );

  const monitoringDepositorDataQuerry = useQuery(
    [
      "monitoringDepositorDataQuerry",
      tenant.id,
      page,
      pageSize,
      companyId,
      filterValue,
      sortState,
      monitoringData,
    ],
    async () => {
      const { data } = await getMonitoringDepositorPage(
        page,
        pageSize,
        companyId,
        tenant.id,
        filterValue,
        "transactionNumber,desc" + sortState,
      );
      return data;
    },
    { enabled: !!isDepositor },
  );

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      <Loading open={monitoringTransactionList.isFetching} />
      <Typography variant="h2" style={{ padding: "16px 16px 0 16px" }}>
        {t("monitoring.transactionData.listTitle")}
      </Typography>
      <PageableTable
        transparent
        filterOptions={[
          {
            columnName: "transactionType",
            options: MONITORING_TRANSACTION_TYPE.map(type => {
              return {
                translated: t(`monitoring.transaction_type.${type}`),
                value: type,
              };
            }),
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="monitoring-transaction-list"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={isDepositor ? monitoringDepositorDataQuerry : monitoringTransactionList}
        onCellClick={onCellClick}
      />
    </Card>
  );
};

export default MonitoringTransactionList;
