import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { Vehicle } from "shared/types";

const ENDPOINT = "/vehicle-status-history";

export type VehicleStatusHistoryEntry = {
  id: number;
  tenantId: number;
  status: "START";
  vehicle: Vehicle;
  date: string;
  dateTime: string;
  numberAtDate: number;
};

export const getVehicleStatusHistoryListPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<VehicleStatusHistoryEntry>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //VEHICLE_GET
