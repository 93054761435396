import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Block, CheckCircleOutline, Edit, Warning } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAnyAuthority, hasAuthority } from "shared/authorization";
import { getVehicleByUserId } from "shared/network/vehicle.api";
import { listWarehousePageable } from "shared/network/warehouse.api";
import displayAddress from "shared/util/displayAddress";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import { useGetSessionStorageKey } from "views/Comment/function";
import { COLORS } from "config/theme";

const WarehouseList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-warehouse-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-warehouse-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const [userVehicleId, setUserVehicleId] = useState<number | undefined>(undefined);

  //eslint-disable-next-line
  const vehicleQuery = useQuery(
    ["vehicleByUserQueryForMyDelivery", account, selectedRelTenant],
    async () => {
      const { data } = await getVehicleByUserId(account.user.id, selectedRelTenant.tenant.id);
      return data.item.id;
    },
    {
      onSuccess: data => {
        setUserVehicleId(data);
      },
    },
  );

  const warehouseListQuery = useQuery(
    ["warehouseListPageable", page, pageSize, selectedRelTenant.tenant.id, filterValue, sortState],
    async () => {
      const { data } = await listWarehousePageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );

      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("warehouse.formValues.name"),
      flex: 2,
    },
    {
      field: "address",
      headerName: t("warehouse.formValues.address.title"),
      flex: 4,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) => displayAddress(row.address),
    },
    {
      field: "address.city",
      headerName: t("company.formValues.address.city"),
      flex: 1,
      hide: true,
      type: "stringContainsNumber",
      valueGetter: ({ row }: GridValueGetterParams) => row.address.city,
    },
    {
      field: "address.zipCode",
      headerName: t("company.formValues.address.zipCode"),
      flex: 1,
      hide: true,
      type: "stringContainsNumber",
      valueGetter: ({ row }: GridValueGetterParams) => row.address.zipCode,
    },
    {
      field: "address.areaName",
      headerName: t("company.formValues.address.areaName"),
      flex: 1,
      hide: true,
      type: "areaNameAndType",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.address.areaName + " " + row.address.areaType,
    },
    {
      field: "isActive",
      headerName: t("issueTemplate.formValues.isActive"),
      flex: 1,
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isActive ? (
          <CheckCircleOutline style={{ color: COLORS.green }} />
        ) : (
          <Block style={{ color: COLORS.red }} />
        ),
    },
    {
      field: "isUnderOverFlow",
      headerName: "Termék túlcsordulás",
      flex: 1,
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isUnderOverFlow ? <Warning color="error" /> : <span />,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
            "WAREHOUSE_GET",
            "WAREHOUSE_ADMIN",
          ]) && (
            <Tooltip title={t("warehouse.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/warehouse/list/warehouse-details/details?id=${row.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "WAREHOUSE_GET",
            "WAREHOUSE_ADMIN",
          ]) && (
            <Tooltip title={t("warehouse.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/warehouse/list/modify?id=${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/warehouse/list/create"
            variant="contained"
            style={{ margin: "auto" }}
            startIcon={<AddBox />}
          >
            {t("warehouse.create")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (
      (selectedRelTenant.isTenantAdmin || account.user.isSuperAdmin || !userVehicleId) &&
      params.field !== "actions"
    ) {
      history.push(`/warehouse/list/warehouse-details/details?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="warehouse"
      filterable={[
        "name",
        "address.zipCode",
        "address.city",
        "address.areaName",
        "isActive",
        "isUnderOverFlow",
      ]}
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={warehouseListQuery}
      onCellClick={onCellClick}
      minWidth={{ value: "sm" }}
    />
  );
};

export default WarehouseList;
