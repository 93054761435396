import { Box, Button, Container } from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { GridCellParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getReportPage } from "shared/network/report.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";

const ReportList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef<null | HTMLSpanElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );
  const { tenant } = selectedRelTenant;

  const account = useSelector((state: RootState) => state.authentication.account);

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-cargo-details-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-cargo-details-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const getReportPageQuery = useQuery(
    ["getReportPageQuery", tenant.id, page, pageSize, filterValue, sortState],
    async () => {
      const { data } = await getReportPage(
        page,
        pageSize,
        filterValue +
          (!hasAuthority(account.user, account.permissions, selectedRelTenant, ["REPORT_ADMIN"])
            ? `isVisibleForCompany:true`
            : ""),
        tenant.id,
        sortState,
      );
      return data;
    },
  );

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        <Button
          startIcon={<Share />}
          onClick={() => {
            const element = ref.current;
            const range = document.createRange();
            const selection = window.getSelection();
            if (selection && element) {
              selection.empty();
              range.setStartBefore(element);
              range.setEndAfter(element);
              selection.addRange(range);
              document.execCommand("copy");
              enqueueSnackbar("Link kimásolva a vágólapra!", { variant: "success" });
            }
          }}
        >
          Megosztás
        </Button>
        <span ref={ref} style={{ opacity: 0, height: 0, width: 0 }}>
          {`${window.location.origin}/new-report?companyId=${tenant?.id}`}
        </span>
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  const columns: GridColDef[] = [
    {
      field: "identifier",
      headerName: "#",
      flex: 0.75,
    },
    {
      field: "status",
      headerName: t("report.reportStatus"),
      flex: 1,
      type: "select",
      renderCell: ({ row }: GridRenderCellParams) => t(`report.status.${row.status}`) || "-",
    },
    {
      field: "name",
      headerName: t("report.name"),
      flex: 2,
    },
    {
      field: "createdOn",
      headerName: t("report.createdOn"),
      flex: 2,
      type: "dateTime",
      renderCell: ({ row }: GridRenderCellParams) =>
        format(new Date(row.createdOn), "Pp", { locale: hu }) || "-",
    },
    {
      field: "updatedOn",
      headerName: t("report.updatedOn"),
      flex: 2,
      type: "dateTime",
      renderCell: ({ row }: GridRenderCellParams) =>
        format(new Date(row.createdOn), "Pp", { locale: hu }) || "-",
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params?.row?.id) {
      history.push(`/report/details?id=${params.row.id}`);
    }
  }

  return (
    <Container maxWidth="xl">
      <PageableTable
        filterOptions={[
          {
            columnName: "status",
            options: ["NEW", "EXAMINATION_IN_PROGRESS", "CLOSED"].map(value => {
              return {
                translated: t(`report.status.${value}`),
                value,
              };
            }),
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="report-list"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={getReportPageQuery}
        onCellClick={onCellClick}
        minWidth={{ value: "md" }}
      />
    </Container>
  );
};

export default ReportList;
