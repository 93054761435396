import axios from "config/axios";
import { GenericPageResponse } from "shared/common";

export type ImportedDocumentType = {
  id: number;
  tenantId: number;
  name: string;
  status: string;
};

const ENDPOINT = "/imported-document-type";

export const createImportedDocumentType = (param: any, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param, tenantId });

export const modifyImportedDocumentType = (param: any, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId });

export const getImportedDocumentTypePage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.post<GenericPageResponse<ImportedDocumentType>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${
      search || ""
    }&sort=${sort || ""}`,
  );
