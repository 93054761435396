import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FormCard from "components/FormCard";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { permissionGroupList } from "shared/network/permission-group.api";
import { modifyTenant } from "shared/network/tenant.api";
import { listWarehousePageable } from "shared/network/warehouse.api";
import { fetchAccount } from "shared/reducers/authentication";
import { PermissionGroup, Warehouse } from "shared/types";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";

type TenantProfileSettingsFormValues = {
  invoiceCreditingIsRequired: boolean;
  isTaxCashFlow: boolean;
  schMonitoring: boolean;
  defaultWarehouse: any; //Warehouse | null;
  isSignatureRequired: boolean;
  isScrapApproveRequired: boolean;
  autoContractNumber: boolean;
  accessToDeliveryPoint: boolean;
  cautionContract: boolean;
  defaultPartnerPermissionGroup: PermissionGroup | null;
  accountantName: string;
  accountantEmail: string;
  accountantCsv: boolean;
  accountantXls: boolean;
  accountantInvoiceLink: boolean;
  isDefaultContactForCompany: boolean;
  cashCounting: boolean;
  useWrapping: boolean;
  isMarketingEmailEnable: boolean;
  isPriceOnDeliveryNote: boolean;
  isUseManufacturer: boolean;
  // isProjectTemplateEnabled: boolean;
};

const useStyles = makeStyles({
  switch: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: "row-reverse",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },
});

const TenantProfileSettings = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<TenantProfileSettingsFormValues>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const classes = useStyles();

  const permissionGroupQuery = useQuery(["permissionGroupList", tenant.id], async () => {
    const { data } = await permissionGroupList(tenant.id);
    return data.items;
  });

  const warehouseListQuery = useQuery(["warehouseListQuery", tenant.id], async () => {
    const { data } = await listWarehousePageable(0, 100000, tenant.id, "", "");
    return data.page.content;
  });

  async function saveTenantSettings(values: TenantProfileSettingsFormValues) {
    setLoading(true);
    try {
      if (selectedRelTenant.tenant) {
        await modifyTenant({
          ...removeEmptyStrings(selectedRelTenant.tenant),
          isTaxCashFlow: values.isTaxCashFlow,
          invoiceCreditingIsRequired: values.invoiceCreditingIsRequired,
          isSignatureRequired: values.isSignatureRequired,
          isScrapApproveRequired: values.isScrapApproveRequired,
          accessToDeliveryPoint: values.accessToDeliveryPoint,
          cautionContract: values.cautionContract,
          autoContractNumber: values.autoContractNumber,
          defaultPartnerPermissionGroup: values.defaultPartnerPermissionGroup?.id,
          accountantCsv: values.accountantCsv,
          accountantEmail: values.accountantEmail,
          accountantName: values.accountantName,
          accountantInvoiceLink: values.accountantInvoiceLink,
          accountantXls: values.accountantXls,
          cashCounting: values.cashCounting,
          useWrapping: values.useWrapping,
          schMonitoring: !!selectedRelTenant?.tenant?.schMonitoring,
          defaultWarehouse: values.defaultWarehouse,
          isDefaultContactForCompany: values.isDefaultContactForCompany,
          isMarketingEmailEnable: values.isMarketingEmailEnable,
          isPriceOnDeliveryNote: values.isPriceOnDeliveryNote,
          isUseManufacturer: values.isUseManufacturer,
        });
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("tenant.settingsSubject"),
          }),
          {
            variant: "success",
          },
        );
        dispatch(fetchAccount());
        history.push("/my-company/settings");
      }
    } catch {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("tenant.settingsSubject"),
        }),
        {
          variant: "error",
        },
      );
    }
    setLoading(false);
  }

  useEffect(() => {
    if (tenant.defaultPartnerPermissionGroup) {
      setValue(
        "defaultPartnerPermissionGroup",
        permissionGroupQuery.data?.find(
          group => group.id === tenant.defaultPartnerPermissionGroup,
        ) || null,
      );
    }
  }, [tenant, permissionGroupQuery, setValue]);

  return (
    <form onSubmit={handleSubmit(saveTenantSettings)}>
      <Loading open={loading} />
      <Container maxWidth="sm">
        <Box pb={2}>
          <FormCard title="Beállítások">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="isTaxCashFlow"
                  control={control}
                  defaultValue={tenant.isTaxCashFlow}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.isTaxCashFlow")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="invoiceCreditingIsRequired"
                  control={control}
                  defaultValue={tenant.invoiceCreditingIsRequired}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.invoiceCreditingIsRequired")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="isSignatureRequired"
                  control={control}
                  defaultValue={tenant.isSignatureRequired}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.isSignatureRequired")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="isScrapApproveRequired"
                  control={control}
                  defaultValue={tenant.isScrapApproveRequired}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.isScrapApproveRequired")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="accessToDeliveryPoint"
                  control={control}
                  defaultValue={tenant.accessToDeliveryPoint}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.accessToDeliveryPoint")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="cautionContract"
                  control={control}
                  defaultValue={tenant.cautionContract}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.cautionContract")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="cashCounting"
                  control={control}
                  defaultValue={tenant.cashCounting}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.cashCounting")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="useWrapping"
                  control={control}
                  defaultValue={tenant.useWrapping || false}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.wrappingHandle")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <Controller
                  name="schMonitoring"
                  control={control}
                  defaultValue={tenant.schMonitoring || false}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.schMonitoring")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Controller
                  name="autoContractNumber"
                  control={control}
                  defaultValue={tenant.autoContractNumber || false}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.autoContractNumber")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="isDefaultContactForCompany"
                  control={control}
                  defaultValue={tenant.isDefaultContactForCompany || false}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.isDefaultContactForCompany")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="isPriceOnDeliveryNote"
                  control={control}
                  defaultValue={tenant.isPriceOnDeliveryNote || false}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.isPriceOnDeliveryNote")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="isUseManufacturer"
                  control={control}
                  defaultValue={tenant.isUseManufacturer || false}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.isUseManufacturer")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              {/*<Grid item xs={12}>
                <Controller
                  name="isMarketingEmailEnable"
                  control={control}
                  defaultValue={tenant.isMarketingEmailEnable || false}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.isMarketingEmailEnable")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
                    </Grid>*/}
              {/* <Grid item xs={12}>
                <Controller
                  name="isProjectTemplateEnabled"
                  control={control}
                  defaultValue={tenant.isProjectTemplateEnabled || false}
                  render={({ field: { onChange, value, ref }, fieldState }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("tenant.formValues.isProjectTemplateEnabled")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12} container>
                <Grid item xs={6}>
                  <Typography>{t("tenant.formValues.defaultPartnerPermissionGroup")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="defaultPartnerPermissionGroup"
                    defaultValue={null}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, value) => field.onChange(value)}
                        options={permissionGroupQuery.data || []}
                        getOptionLabel={(option: PermissionGroup) => option.name}
                        getOptionSelected={option => option.id === field.value?.id}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("permissionGroup.name")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={6}>
                  <Typography>{t("tenant.formValues.defaultWareHouse")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="defaultWarehouse"
                    defaultValue={tenant.defaultWarehouse || null}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        onChange={(_, value) => field.onChange(value)}
                        options={warehouseListQuery.data || []}
                        getOptionLabel={(option: Warehouse) => option.name}
                        getOptionSelected={option => option.id === field.value?.id}
                        renderInput={params => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true, required: true }}
                            label={t("tenant.formValues.WareHouse")}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormCard>
        </Box>
        <FormCard title="Könyvelői beállítások">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="accountantName"
                defaultValue={tenant?.accountantName || ""}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("tenant.formValues.accountantName")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="accountantEmail"
                defaultValue={tenant?.accountantEmail || ""}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("tenant.formValues.accountantEmail")}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="accountantCsv"
                control={control}
                defaultValue={tenant.accountantCsv}
                render={({ field: { onChange, value, ref }, fieldState }) => (
                  <FormControlLabel
                    className={classes.switch}
                    label={t("tenant.formValues.accountantCsv")}
                    labelPlacement="start"
                    control={
                      <Switch
                        onChange={(e, checked) => {
                          onChange(checked);
                        }}
                        checked={value}
                        inputRef={ref}
                        color="primary"
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="accountantXls"
                control={control}
                defaultValue={tenant.accountantXls}
                render={({ field: { onChange, value, ref }, fieldState }) => (
                  <FormControlLabel
                    className={classes.switch}
                    label={t("tenant.formValues.accountantXls")}
                    labelPlacement="start"
                    control={
                      <Switch
                        onChange={(e, checked) => {
                          onChange(checked);
                        }}
                        checked={value}
                        inputRef={ref}
                        color="primary"
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="accountantInvoiceLink"
                control={control}
                defaultValue={tenant.accountantInvoiceLink}
                render={({ field: { onChange, value, ref }, fieldState }) => (
                  <FormControlLabel
                    className={classes.switch}
                    label={t("tenant.formValues.accountantInvoiceLink")}
                    labelPlacement="start"
                    control={
                      <Switch
                        onChange={(e, checked) => {
                          onChange(checked);
                        }}
                        checked={value}
                        inputRef={ref}
                        color="primary"
                      />
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormCard>
      </Container>
      <Box display="flex" justifyContent="center" p={2}>
        <Button type="submit">{t("common:button.save")}</Button>
      </Box>
    </form>
  );
};

export default TenantProfileSettings;
