import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { User, Vehicle } from "shared/types";

export type RelDailyReportVehicle = {
  id: number;
  tenantId: number;
  dailyReportId: number;
  driverUserId: number;
  vehicle: Vehicle;
  driverUser: User;
  invoiceId: number;
  mileage: number;
  isRefuelled: boolean;
  fuelQuantity: number;
  fuelPrice: number;
};

const ENDPOINT = "/rel-daily-report-vehicle";

export const createRelDailyReportVehicle = (
  param: Partial<RelDailyReportVehicle>,
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}`, {
    param,
    tenantId,
  });

export const modifyRelDailyReportVehicle = (param: RelDailyReportVehicle, tenantId: number) =>
  axios.put(`${ENDPOINT}`, {
    param,
    tenantId: tenantId,
  }); //ISTENANTADMIN ISSUPERADMIN

export const deleteRelDailyReportVehicle = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?param=${param}&tenantId=${tenantId}`);

export const listRelDailyReportVehicleByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<RelDailyReportVehicle>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //LOGIN_REQUIRED

export const getRelDailyReportVehicleById = (param: number | string, tenantId: number) =>
  axios.post<GenericResponse<RelDailyReportVehicle>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //LOGIN_REQUIRED
