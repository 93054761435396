import { Box, Button, IconButton, MenuItem, TextField, Tooltip } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardTimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Food, getFoodPage } from "shared/network/food.api";
import { ServingCheck } from "shared/network/serving-check.api";
import { useDebouncedCallback } from "use-debounce";
import { HaccpServingFormValues, useStyles } from "../HaccpServingArriving";

const HaccpServingModifyRow = ({
  selectedServingCheck,
  setSelectedServingCheck,
}: {
  selectedServingCheck: ServingCheck | null;
  setSelectedServingCheck: Dispatch<SetStateAction<ServingCheck | null>>;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const form = useFormContext<HaccpServingFormValues>();
  const [itemSearch, setItemSearch] = useState<string>("");

  const foodPageQuery = useQuery(["foodPageQuery", tenant.id, itemSearch], async () => {
    const { data } = await getFoodPage(0, 100, tenant.id, "foodType=FOOD");
    return data?.page?.content || [];
  });

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= 1 || value.length === 0) {
      setItemSearch(value);
    }
  }, 100);

  return (
    <tr>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="modify.food"
          rules={{
            required: t("validation.required").toString(),
          }}
          defaultValue={selectedServingCheck?.food || null}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              freeSolo
              autoSelect
              disableClearable
              onChange={(_, value) => {
                field.onChange(value);
                handleItemSearchChange("");
              }}
              onInputChange={(_, newInputValue) => {
                handleItemSearchChange(newInputValue);
              }}
              options={foodPageQuery.data || []}
              getOptionLabel={(option: Food | string) =>
                `${typeof option === "string" ? option : option.name}`
              }
              getOptionSelected={option =>
                typeof option !== "string" ? option.id === field.value.id : !!option
              }
              renderInput={params => (
                <TextField
                  {...params}
                  onFocus={event => event.target.select()}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputProps={{
                    ...params.inputProps,
                    style: { padding: 0 },
                  }}
                />
              )}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="modify.portion"
          defaultValue={selectedServingCheck?.portion || ""}
          rules={{
            required: t("validation.required").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
              style={{ width: 50 }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="qualified"
          defaultValue={
            selectedServingCheck?.isQualified === true
              ? "OK"
              : selectedServingCheck?.isQualified === false
              ? "NOT_OK"
              : ""
          }
          rules={{ required: t("validation.requiredShort").toString() }}
          render={({ field }) => (
            <TextField
              select
              {...field}
              variant="standard"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem disabled value="">
                {t("common:choose")}
              </MenuItem>
              {["OK", "NOT_OK"].map(type => (
                <MenuItem key={type} value={type}>
                  {t(`haccpServing.${type}`)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="modify.arrivalTime"
          defaultValue={selectedServingCheck?.arrivalTime || null}
          rules={{ required: t("validation.required").toString() }}
          render={({ field, fieldState }) => (
            <KeyboardTimePicker
              {...field}
              ref={undefined}
              size="small"
              inputVariant="standard"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true, required: true }}
              ampm={false}
              autoOk
              style={{ width: 70 }}
              InputAdornmentProps={{
                className: classes.datepicker,
                style: { width: 20 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name={`modify.arrivalTemperature`}
          defaultValue={selectedServingCheck?.arrivalTemperature || null}
          rules={{
            required: t("validation.required").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
              style={{ width: 50 }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="modify.servingStartTime"
          defaultValue={selectedServingCheck?.servingStartTime || null}
          render={({ field, fieldState }) => (
            <KeyboardTimePicker
              {...field}
              ref={undefined}
              size="small"
              inputVariant="standard"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true, required: true }}
              ampm={false}
              autoOk
              style={{ width: 70 }}
              InputAdornmentProps={{
                className: classes.datepicker,
                style: { width: 20 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name={`modify.servingStartTemperature`}
          defaultValue={selectedServingCheck?.servingStartTemperature || null}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
              style={{ width: 50 }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="modify.servingMidTime"
          defaultValue={selectedServingCheck?.servingMidTime || null}
          render={({ field, fieldState }) => (
            <KeyboardTimePicker
              {...field}
              ref={undefined}
              size="small"
              inputVariant="standard"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true, required: true }}
              ampm={false}
              autoOk
              style={{ width: 70 }}
              InputAdornmentProps={{
                className: classes.datepicker,
                style: { width: 20 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name={`modify.servingMidTemperature`}
          defaultValue={selectedServingCheck?.servingMidTemperature || null}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
              style={{ width: 50 }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="modify.servingEndTime"
          defaultValue={selectedServingCheck?.servingEndTime || null}
          render={({ field, fieldState }) => (
            <KeyboardTimePicker
              {...field}
              ref={undefined}
              size="small"
              inputVariant="standard"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true, required: true }}
              ampm={false}
              autoOk
              style={{ width: 70 }}
              InputAdornmentProps={{
                className: classes.datepicker,
                style: { width: 20 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name={`modify.servingEndTemperature`}
          defaultValue={selectedServingCheck?.servingEndTemperature || null}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
              style={{ width: 50 }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}></td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name="modify.foodReheatingTime"
          defaultValue={selectedServingCheck?.foodReheatingTime || null}
          render={({ field, fieldState }) => (
            <KeyboardTimePicker
              {...field}
              ref={undefined}
              size="small"
              inputVariant="standard"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{ shrink: true, required: true }}
              ampm={false}
              autoOk
              style={{ width: 70 }}
              InputAdornmentProps={{
                className: classes.datepicker,
                style: { width: 20 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Controller
          name={`modify.foodReheatingTemperature`}
          defaultValue={selectedServingCheck?.foodReheatingTemperature || null}
          render={({ field, fieldState }) => (
            <TextField
              onFocus={event => event.target.select()}
              {...field}
              type="number"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                step: "0.01",
                min: "0",
                style: { textAlign: "right", padding: 0 },
              }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              onChange={event => {
                field.onChange(event.target.value);
              }}
              style={{ width: 50 }}
            />
          )}
        />
      </td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}></td>
      <td className={clsx(classes.tg, classes.td, classes.lax)}>
        <Box display="flex" flexWrap="nowrap">
          <Button type="submit" size="small" startIcon={<Check />}>
            {t("common:button.save")}
          </Button>
          <Tooltip title={t("common:button.cancel")}>
            <IconButton
              style={{ marginLeft: 4 }}
              size="small"
              color="primary"
              onClick={() => {
                setSelectedServingCheck(null);
                form.reset();
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </td>
    </tr>
  );
};

export default HaccpServingModifyRow;
