import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";

type SubDepartment = {
  id: number;
  tenantId: number;
  name: string;
  departmentId: number;
};

const ENDPOINT = "/sub-department";

export const createSubDepartment = (param: any, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

export const modifySubDepartment = (param: any, tenantId: number) =>
  axios.put(ENDPOINT, { param, tenantId });

export const getSubDepartmentPage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.get<GenericPageResponse<SubDepartment>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search || ""}&sort=${
      sort || ""
    }`,
  );

export const getSubDepartment = (id: number, tenantId: number) =>
  axios.get<GenericResponse<SubDepartment>>(`${ENDPOINT}/get-by-id?id=${id}&tenantId=${tenantId}`);

export const subDepartmentPdfExport = (year: string, month: string, tenantId: number) =>
  axios.get(`${ENDPOINT}/cleaning-log?tenantId=${tenantId}&year=${year}&month=${month}`);
