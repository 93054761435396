import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { AttachFile, Check } from "@material-ui/icons";
import Loading from "components/Loading";
import { COMMENT_TYPE } from "config/constants";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { SliceStatus } from "shared/common";
import { createComment } from "shared/network/comment.api";

export type CommentFormValues = {
  tenantId: number;
  type: string;
  originalFileName: string;
  subjectType: string;
  subjectId: number;
  note: string;
  document: FileList;
  isReportVisibleByReporter?: boolean;
  isReportVisibleByCompany?: boolean;
};
type Props = {
  subjectType:
    | "USER"
    | "TOOL"
    | "CONTACT"
    | "CONTRACT"
    | "COMMENT"
    | "ISSUE"
    | "ITEM"
    | "USER_PROFILE"
    | "COMPANY"
    | "PROJECT"
    | "VEHICLE"
    | "EMPLOYEE"
    | "INCOMING_INVOICE"
    | "INVOICE"
    | "MILESTONE"
    | "OFFER"
    | "WAREHOUSE"
    | "REPORT"
    | "HACCP_CHECK"
    | "";
  subjectId: string | null | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  refetch: () => void;
};

const CommentCreate = ({ subjectType, subjectId, open, setOpen, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<CommentFormValues>();
  const [status, setStatus] = useState<SliceStatus>("idle");

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const account = useSelector((state: RootState) => state.authentication?.account);

  const tenant = selectedRelTenant.tenant;
  const type = form.watch("type");

  const onSubmitCreate = async (values: CommentFormValues) => {
    setStatus("pending");

    try {
      if (subjectId && subjectType) {
        await createComment(
          tenant.id,
          values.type,
          subjectType,
          subjectId,
          values.note,
          values.document?.[0],
          selectedRelTenant?.tenant?.isUseReport ? values.isReportVisibleByReporter : false,
          selectedRelTenant?.tenant?.isUseReport &&
            hasAuthority(account.user, account.permissions, selectedRelTenant, ["REPORT_ADMIN"])
            ? values.isReportVisibleByCompany
            : true,
        );
        setStatus("success");
        setOpen(false);
        refetch();
        form.reset();
        queryClient.invalidateQueries("issueCommentList");
      }
    } catch (e: any) {
      if (e.data?.status === "FILE_NOT_FOUND") {
        enqueueSnackbar(t("common:notification.FILE_NOT_FOUND"), {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("comment.subject"),
          }),
          { variant: "error" },
        );
      }
      setStatus("failure");
    }
    setStatus("success");
  };

  return (
    <>
      <Loading open={status === "pending"} />
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle>{t("common:comment")}</DialogTitle>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="type"
                    defaultValue="TEXT"
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        select
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("comment.type.title")}
                        error={!!fieldState.error}
                        SelectProps={{ displayEmpty: true }}
                        helperText={fieldState.error?.message}
                      >
                        <MenuItem disabled value="">
                          {t("common:choose")}
                        </MenuItem>
                        {COMMENT_TYPE.map(type => (
                          <MenuItem key={type} value={type}>
                            {t(`comment.type.${type}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                {type === "DOCUMENT" && (
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                  >
                    {form.watch("document")?.[0]?.name}
                    <Button
                      variant="text"
                      component="label"
                      htmlFor="noteFile"
                      startIcon={
                        !form.watch("document")?.length ? (
                          <AttachFile />
                        ) : (
                          <Check style={{ color: COLORS.green }} />
                        )
                      }
                    >
                      {t("personalData.formValues.chooseFile")}
                      <input
                        id="noteFile"
                        style={{ display: "none" }}
                        type="file"
                        {...form.register("document", {
                          required: {
                            value: false,
                            message: "Fájl kiválasztása kötelező",
                          },
                          validate: value => {
                            if (value?.[0]?.size >= 5242880) {
                              return t("validation.maxSize", {
                                size: 5,
                                unit: "MB",
                              }).toString();
                            }
                          },
                        })}
                      />
                    </Button>
                    {"Maximum 5 MB"}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    name="note"
                    defaultValue=""
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        multiline
                        minRows={5}
                        label={t("comment.note")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                {selectedRelTenant?.tenant?.isUseReport && (
                  <Grid item xs={12}>
                    <Controller
                      name="isReportVisibleByReporter"
                      defaultValue={false}
                      render={props => (
                        <FormControlLabel
                          labelPlacement="end"
                          label={"Bejelentő által látható"}
                          control={
                            <Checkbox
                              {...props.field}
                              checked={props.field.value}
                              size="small"
                              onChange={e => props.field.onChange(e.target.checked)}
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
                {selectedRelTenant?.tenant?.isUseReport &&
                  hasAuthority(account.user, account.permissions, selectedRelTenant, [
                    "REPORT_ADMIN",
                  ]) && (
                    <Grid item xs={12}>
                      <Controller
                        name="isReportVisibleByCompany"
                        defaultValue={false}
                        render={props => (
                          <FormControlLabel
                            labelPlacement="end"
                            label={"Cég által látható"}
                            control={
                              <Checkbox
                                {...props.field}
                                checked={props.field.value}
                                size="small"
                                onChange={e => props.field.onChange(e.target.checked)}
                                color="primary"
                              />
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box display="flex" justifyContent="center" gridGap={8}>
                <Button color="primary" variant="text" onClick={() => setOpen(false)}>
                  {t("common:button.cancel")}
                </Button>
                <Button type="submit" color="primary">
                  {t("common:button.create")}
                </Button>
              </Box>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default CommentCreate;
