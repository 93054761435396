import { Box, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import queryClient from "config/query";
import { RootState } from "config/store";
import { getHours, getMinutes, isBefore, set, setDate, subMinutes } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createChecklistItem } from "shared/network/haccp-checklist.api";

type Props = {
  checkTime: any;
  index: number;
  date: Date;
  checkListItem: any;
};

type FormValues = {
  realMeasurement: string;
};

const HaccpItemCheckInputCell = ({ checkTime, index, date, checkListItem }: Props) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<FormValues>();
  const { handleSubmit, watch } = form;
  const { enqueueSnackbar } = useSnackbar();

  const realMeasurement = watch("realMeasurement");

  async function onSubmit(values: FormValues) {
    const realCheckTime = setDate(new Date(date), index + 1).toISOString();
    try {
      await createChecklistItem({ ...values, realCheckTime, checkTime }, tenant?.id);
      queryClient.refetchQueries("checkListItemPageQuery");
      form.setValue("realMeasurement", "");
      enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
    }

    setFocused(false);
  }

  return (
    <Box
      style={{
        borderBottom: "1px solid lightgrey",
        padding: 8,
        height: 57,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {checkListItem ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography style={{ fontSize: 14, fontWeight: "bold", lineHeight: 1 }}>
            {checkListItem.realMeasurement}°C
          </Typography>
          <Typography style={{ fontSize: 12, textAlign: "center" }}>
            {checkListItem?.createdBy?.name}
          </Typography>
        </Box>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="realMeasurement"
              defaultValue=""
              rules={{ required: t("validation.required").toString() }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label={"°C"}
                  disabled={isBefore(
                    new Date(),
                    subMinutes(
                      set(new Date(date), {
                        date: index + 1,
                        hours: getHours(new Date(checkTime.checkAt)),
                        minutes: getMinutes(new Date(checkTime.checkAt)),
                      }),
                      5,
                    ),
                  )}
                  error={
                    !realMeasurement &&
                    !isBefore(
                      new Date(),
                      subMinutes(
                        set(new Date(date), {
                          date: index + 1,
                          hours: getHours(new Date(checkTime.checkAt)),
                          minutes: getMinutes(new Date(checkTime.checkAt)),
                        }),
                        5,
                      ),
                    )
                  }
                  onFocus={() => setFocused(true)}
                  onBlur={() => {
                    if (!realMeasurement) {
                      setFocused(false);
                    }
                  }}
                  InputProps={{
                    endAdornment: focused && (
                      <InputAdornment position="end">
                        <IconButton type="submit" size="small" color="primary">
                          <Check />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
        </FormProvider>
      )}
    </Box>
  );
};
export default HaccpItemCheckInputCell;
