import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { PERIODIC_CYCLE, PRIORITY } from "config/constants";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listIssueTemplate } from "shared/network/issue-template.api";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

const IssueTemplateList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-issue-template-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-issue-template-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const issueTemplateList = useQuery(
    ["listIssueTemplateQuery", page, pageSize, selectedRelTenant.tenant.id, filterValue, sortState],
    async () => {
      const { data } = await listIssueTemplate(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("issueTemplate.formValues.name"),
      flex: 1,
    },
    {
      field: "company.name",
      headerName: t("issueTemplate.formValues.company"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => (row.company ? row.company.name : ""),
    },
    {
      field: "project.name",
      headerName: t("issueTemplate.formValues.project"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.project ? row.project.name : t("common:notification.NO_PROJECT"),
    },
    {
      field: "deadlineDays",
      headerName: t("issueTemplate.formValues.deadlineDays"),
      flex: 1,
    },
    {
      field: "priority",
      headerName: t("issueTemplate.formValues.priority"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t(`common:priority.${row.priority}`),
      type: "select",
    },
    {
      field: "period",
      headerName: t("issueTemplate.formValues.period"),
      flex: 1,
      filterable: false,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.periodicCycleAmount
          ? row.periodicCycleAmount + " " + t(`common:periodicCycle.${row.periodicCycleType}`)
          : "-",
    },
    {
      field: "periodicCycleAmount",
      headerName: t("issueTemplate.formValues.periodicCycleAmount"),
      flex: 1,
      hide: true,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.periodicCycleAmount ? row.periodicCycleAmount : "-",
    },
    {
      field: "periodicCycleType",
      headerName: t("issueTemplate.formValues.periodicCycleType"),
      flex: 1,
      type: "select",
      hide: true,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.periodicCycleType ? t(`common:periodicCycle.${row.periodicCycleType}`) : "-",
    },
    {
      field: "isActive",
      headerName: t("issueTemplate.formValues.isActive"),
      flex: 1,
      type: "select",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.isActive ? t("common:YES") : t("common:NO"),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ISSUE_TEMPLATE_GET",
          ]) && (
            <Tooltip title={t("issues.issueDetails").toString()}>
              <IconButton
                component={Link}
                size="small"
                style={{ margin: "0 8px" }}
                color="primary"
                to={`/issues/templates/issue-template-details?id=${row.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ISSUE_TEMPLATE_ADMIN",
          ]) && (
            <Tooltip title={t("issues.modifyIssue").toString()}>
              <IconButton
                size="small"
                style={{ margin: "0 8px" }}
                component={Link}
                color="primary"
                to={`/issues/templates/issue-template-modify?id=${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "ISSUE_TEMPLATE_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/issues/templates/issue-template-create"
            startIcon={<AddBox />}
          >
            {t("issueTemplate.createTemplate")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/issues/templates/issue-template-details?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      filterable={[
        "name",
        "company.name",
        "project.name",
        "deadlineDays",
        "priority",
        "periodicCycleType",
        "periodicCycleAmount",
        "isActive",
      ]}
      filterOptions={[
        {
          columnName: "priority",
          options: PRIORITY.map(value => {
            return {
              translated: t(`common:priority.${value}`),
              value,
            };
          }),
        },
        {
          columnName: "periodicCycleType",
          options: PERIODIC_CYCLE.map(value => {
            return {
              translated: t(`common:periodicCycle.${value}`),
              value,
            };
          }),
        },
        {
          columnName: "isActive",
          options: [
            {
              translated: t(`common:YES`),
              value: "true",
            },
            {
              translated: t(`common:NO`),
              value: "false",
            },
          ],
        },
      ]}
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="issue-template"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={issueTemplateList}
      onCellClick={onCellClick}
    />
  );
};

export default IssueTemplateList;
