import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listWorksheet, modifyWorksheet } from "shared/network/worksheet.api";
import { Worksheet } from "shared/types";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";
import TenantWorksheetForm, { WorksheetFormValues } from "./TenantWorksheetForm";

const TenantWorksheetList = () => {
  const { t } = useTranslation();

  const [selectedReason, setSelectedReason] = useState<Worksheet | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [modifyOpen, setModifyOpen] = useState<boolean>(false);
  const form = useForm<WorksheetFormValues>();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-worksheet-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-worksheet-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const worksheetListQuery = useQuery(
    [`worksheetList_${selectedRelTenant.tenant.id}`, selectedRelTenant.tenant.id],
    async () => {
      const { data } = await listWorksheet(selectedRelTenant.tenant.id);
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("issues.worksheet.new"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {(selectedRelTenant?.isTenantAdmin || account?.user?.isSuperAdmin) && (
            <>
              <Tooltip title={t("common:button.modify").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => {
                    setModifyOpen(row.id);
                    setSelectedReason(row as Worksheet);
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* törlést erősen átgondolni
              <Tooltip title={t("department.delete").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => deleteReason(row.id)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>*/}
            </>
          )}
        </Box>
      ),
    },
  ];

  // async function deleteReason(id: number) {
  //   try {
  //     await deleteWorksheet(id, selectedRelTenant.tenant.id);
  //     worksheetListQuery.refetch();
  //     enqueueSnackbar(
  //       t("common:notification.delete.success", {
  //         subject: t("failReason.subject"),
  //       }),
  //       { variant: "success" },
  //     );
  //   } catch (e) {
  //     enqueueSnackbar(t("failReason.delete.failure"), { variant: "error" });
  //   }
  // }

  const onSubmitCreate = async (values: WorksheetFormValues) => {
    try {
      await modifyWorksheet(values, selectedRelTenant.tenant.id);
      worksheetListQuery.refetch();
      setModifyOpen(false);
      setSelectedReason(null);
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("issues.worksheet.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("issues.worksheet.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  };

  return (
    <Container maxWidth="sm">
      <SingleQueryTable
        sessionStorageKey="worksheet"
        columns={columns}
        query={worksheetListQuery}
        tableState={tableState}
        setTableState={setTableState}
      />
      <Dialog
        open={modifyOpen}
        onClose={() => {
          setModifyOpen(false);
          setSelectedReason(null);
          form.reset({ name: "", id: undefined, tenantId: undefined });
        }}
      >
        <DialogTitle>{t("issues.worksheet.modify")}</DialogTitle>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmitCreate)}>
            <FormProvider {...form}>
              <TenantWorksheetForm
                setOpen={setModifyOpen}
                worksheet={selectedReason || undefined}
                setSelectedReason={setSelectedReason}
              />
            </FormProvider>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default TenantWorksheetList;
