import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import queryClient from "config/query";
import { RootState } from "config/store";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  forwardRef,
  useCallback,
  useState,
} from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { listOfferByTenant } from "shared/network/offer.api";
import { createRelProjectOffer } from "shared/network/rel-project-offer.api";
import { Offer, Project } from "shared/types";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  projectId: string | number | undefined;
  addOfferOpen: boolean;
  setAddOfferOpen: Dispatch<SetStateAction<boolean>>;
};

type FormValues = {
  offer: Offer | null;
};

export const ListboxComponent = forwardRef<
  HTMLUListElement,
  PropsWithChildren<{ hasNextPage?: boolean; fetchNextPage: any }>
>(({ children, hasNextPage, fetchNextPage, ...props }, ref) => {
  return (
    <Box>
      <List ref={ref} {...props}>
        {children}
        {hasNextPage && (
          <Box textAlign="center" pt={1}>
            <Button
              size="small"
              variant="text"
              onClick={event => {
                fetchNextPage();
              }}
            >
              {i18next.t("loadMore").toString()}
            </Button>
          </Box>
        )}
      </List>
    </Box>
  );
});

const ProjectOfferAddDialog = ({ projectId, addOfferOpen, setAddOfferOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<FormValues>();
  const { control, handleSubmit } = form;
  const [userSearch, setUserSearch] = useState<string>("");

  const { data: dataUser, fetchNextPage: fetchNextPageUser } = useInfiniteQuery(
    [`getOfferList`, tenant.id, userSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listOfferByTenant(
        pageParam,
        20,
        tenant.id,
        userSearch ? `isIncoming:true;name:${userSearch}` : "isIncoming:true",
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatPagesUser = dataUser?.pages.map(page => page.page?.content).flat();
  const hasNextPageUser =
    dataUser && flatPagesUser && dataUser?.pages?.[0]?.page?.totalElements > flatPagesUser?.length;

  const handleUserSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setUserSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  async function onSubmit(values: FormValues) {
    if (values.offer) {
      try {
        await createRelProjectOffer(
          {
            offer: values.offer,
            projectId: projectId,
          },
          tenant?.id,
        );
        queryClient.refetchQueries("getRelProjectOfferPageQuery");
        setAddOfferOpen(false);
        enqueueSnackbar("Sikeres projekthez rendelés!", { variant: "success" });
      } catch {
        enqueueSnackbar("Sikertelen projekthez rendelés!", { variant: "error" });
      }
    }
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={addOfferOpen} onClose={() => setAddOfferOpen(false)}>
      <DialogTitle>asd</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="project-offer-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="offer"
              defaultValue={null}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, value) => {
                    field.onChange(value);
                    handleUserSearchChange("");
                  }}
                  onInputChange={(event, newInputValue) => {
                    handleUserSearchChange(newInputValue);
                  }}
                  options={flatPagesUser || []}
                  getOptionLabel={(option: Offer) => option.name}
                  getOptionSelected={option => option.id === field.value?.id}
                  ListboxComponent={props => (
                    <ListboxComponent
                      {...props}
                      hasNextPage={hasNextPageUser}
                      fetchNextPage={fetchNextPageUser}
                    />
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true, required: true }}
                      label={t("common:offer")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button form="project-offer-form" type="submit">
          {t("common:button.submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectOfferAddDialog;
