import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import NumberField from "components/NumberField";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SliceStatus } from "shared/common";
import { updateVehicleCashStatus } from "shared/network/deliveryNote.api";
import { VehicleCashData } from "./VehicleCashByVehicle";

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  row: {
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
});

type Props = {
  item: VehicleCashData | null;
  date: Date;
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  setStatus: Dispatch<SetStateAction<SliceStatus>>;
  refetch: () => void;
};

const VechileRefuseDialog = ({
  item,
  date,
  openDialog,
  setOpenDialog,
  setStatus,
  refetch,
}: Props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const form = useForm<VehicleCashData>();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const rejectCashValue = async (values: VehicleCashData) => {
    try {
      setStatus("pending");
      await updateVehicleCashStatus(
        item?.vehicle.id,
        format(date, "yyyy-MM-dd"),
        "DENIED",
        tenant.id,
        values.sumofCash,
        values.cashOnRefuse,
      );
      enqueueSnackbar("Pénzösszeg megadása sikeres!", {
        variant: "success",
      });

      setOpenDialog(false);
      form.reset();
      setStatus("success");
      refetch();
    } catch (e: any) {
      if (e.data?.status === "ERROR_WHILE_SAVING_THE_ISSUE") {
        enqueueSnackbar(t("common:notification.issue.failure"), {
          variant: "error",
        });
      } else if (e.data?.status === "ERROR_WHILE_SAVING_THE_OFFER") {
        enqueueSnackbar(t("common:notification.offer.failure"), {
          variant: "error",
        });
      }
      setStatus("failure");
    }
  };

  useEffect(() => {
    form.setValue("sumofCash", item?.sumofCash || 0);
  }, [item]); //eslint-disable-line

  return (
    <>
      {item && (
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle>
            <Typography variant="h2" align="center">
              {t("vehicle.cashExchange")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={form.handleSubmit(rejectCashValue)}>
              <Grid
                container
                className={classes.header}
                style={{ padding: "8px 0 8px 0" }}
                spacing={2}
              >
                <Grid item xs={4}>
                  <Controller
                    control={form.control}
                    name="sumofCash"
                    defaultValue={item?.sumofCash || 0}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <NumberField
                        field={field}
                        fieldState={fieldState}
                        disabled
                        label={t("vehicle.expectedCash")}
                        InputLabelProps={{ shrink: true, required: true }}
                        numberLimits={{ step: "0.01", min: "0" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={form.control}
                    name="cashOnRefuse"
                    defaultValue={item?.cashOnRefuse || 0}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <NumberField
                        field={field}
                        fieldState={fieldState}
                        label={t("vehicle.handedCash")}
                        InputLabelProps={{ shrink: true, required: true }}
                        numberLimits={{ step: "0.01", min: "0" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center">
                <Button type="submit" variant="text">
                  <Typography className={classes.text}>{t("common:button.ok")}</Typography>
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default VechileRefuseDialog;
