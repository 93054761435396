import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { AddBox, ArrowBack, ArrowForward } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import FormCard from "components/FormCard";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { DailyReport } from "shared/network/daily-report.api";
import { RelDailyReportIssue } from "shared/network/rel-daily-report-issue.api";
import { listRelDailyReportVehicleByTenant } from "shared/network/rel-daily-report-vehicle.api";
import DailyReportCreateVehicleRow from "./DailyReportCreateVehicleRow";
import DailyReportVehicleDialog from "./DailyReportVehicleDialog";

type Props = {
  dailyReport: DailyReport | null;
  relDailyReportIssues: RelDailyReportIssue[] | null;
  setDailyReport: Dispatch<SetStateAction<DailyReport | null>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isClosed: boolean;
};

const DailyReportCreatePage4 = ({ dailyReport, setActiveStep, isClosed }: Props) => {
  const { t } = useTranslation();
  const form = useFormContext();
  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const listRelDailyReportVehicleQuery = useQuery(
    [`listRelDailyReportVehicleQuery`, tenant?.id, page, dailyReport?.id],
    async () => {
      if (tenant?.id && dailyReport?.id) {
        const { data } = await listRelDailyReportVehicleByTenant(
          page,
          20,
          tenant.id,
          `dailyReportId:${dailyReport?.id}`,
        );
        return data?.page;
      }
      return Promise.reject();
    },
  );
  const totalElements = listRelDailyReportVehicleQuery?.data?.totalElements || 0;

  async function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  async function handleNext() {
    const result = await form.trigger();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  return (
    <Box>
      <FormCard title="Jármű használat">
        <Loading open={listRelDailyReportVehicleQuery.isFetching} />

        {totalElements > 0 ? (
          <Box overflow="auto">
            <Box minWidth={450} m={0.5}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: "bold" }}>
                    {t("dailyReport.licensePlateNumber")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: "bold" }}>{t("dailyReport.user")}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: "bold" }}>{t("dailyReport.mileage")}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ fontWeight: "bold" }}>{t("dailyReport.fuel")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ marginBottom: 4 }} />
                </Grid>
              </Grid>
              {listRelDailyReportVehicleQuery?.data?.content?.map((rel, index) => (
                <DailyReportCreateVehicleRow
                  key={rel.id}
                  index={index}
                  dailyReport={dailyReport}
                  relDailyReportVehicle={rel}
                  refetch={listRelDailyReportVehicleQuery.refetch}
                  isClosed={isClosed}
                />
              ))}{" "}
            </Box>
            {listRelDailyReportVehicleQuery.data &&
              listRelDailyReportVehicleQuery?.data?.totalPages > 1 && (
                <Pagination
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "8px 0 0 0",
                  }}
                  count={listRelDailyReportVehicleQuery?.data?.totalPages}
                  color="primary"
                  page={listRelDailyReportVehicleQuery.data?.number + 1}
                  onChange={(e, page) => {
                    sessionStorage.setItem(
                      `IssueTypePageNumber-${tenant?.id}`,
                      JSON.stringify(page - 1),
                    );
                    setPage(page - 1);
                  }}
                />
              )}
          </Box>
        ) : (
          <Box style={{ textAlign: "center", width: "100%", paddingBottom: 8 }}>
            <Typography>{t("dailyReport.noVehicle")}</Typography>
          </Box>
        )}

        {!isClosed && (
          <Box display="flex" justifyContent="center" pt={2}>
            <Button startIcon={<AddBox />} onClick={() => setOpen(true)}>
              {t("dailyReport.vehicle")}
            </Button>
          </Box>
        )}
        <DailyReportVehicleDialog
          dailyReportId={dailyReport?.id}
          open={open}
          onClose={() => setOpen(false)}
        />
      </FormCard>
      <Box display="flex" justifyContent="center" gridGap={8} p={1.5}>
        <Button startIcon={<ArrowBack />} variant="outlined" onClick={handleBack}>
          {t("common:button.prev")}
        </Button>
        <Button endIcon={<ArrowForward />} variant="contained" color="primary" onClick={handleNext}>
          {t("common:button.next")}
        </Button>
      </Box>
    </Box>
  );
};

export default DailyReportCreatePage4;
