import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listCompanyByTenant } from "shared/network/company.api";
import { listIssueTypePageable } from "shared/network/issue-type.api";
import IssueTypeRow from "./IssueTypeRow";
import { useGetSessionStorageKey } from "views/Comment/function";

const useStyles = makeStyles({
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    marginTop: 8,
  },
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const IssueTypeList = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [page, setPage] = useState(
    parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("IssueTypePageNumber")) ||
        JSON.stringify(0),
    ),
  );

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const companyId = account?.user?.relUserCompany?.[0]?.company?.id;
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyId || "");

  const issueTemplateListQuery = useQuery(
    ["issueTypeList", tenant.id, page, selectedCompanyId],
    async () => {
      const { data } = await listIssueTypePageable(
        page,
        10,
        tenant.id,
        `${selectedCompanyId ? "companyId:" + selectedCompanyId : ""}`,
      );
      return data;
    },
  );

  const companyListQuery = useQuery(["companyListQuery", tenant.id], async () => {
    const { data } = await listCompanyByTenant(0, 1000000, tenant.id);

    return data;
  });

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      <Loading open={issueTemplateListQuery.isFetching} />
      {issueTemplateListQuery.isError ? (
        <Box style={{ margin: "20px auto", textAlign: "center" }}>
          <Typography
            variant="h5"
            align="center"
            color="secondary"
            style={{ marginBottom: "20px" }}
          >
            {t("common:errorWhileFetching")}
          </Typography>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "ISSUE_TYPE_ADMIN",
          ]) && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                component={Link}
                to={`/issues/issue-type/issue-type-create?id=${selectedCompanyId}`}
                startIcon={<AddBox />}
              >
                {t("issueType.create")}
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <>
          <Box width="100%" display="flex" justifyContent="center" style={{ margin: "8px" }}>
            <TextField
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
              label={t("companySite.formValues.company")}
              style={{ width: 300, marginBottom: 12 }}
              value={selectedCompanyId}
              onChange={event => {
                setSelectedCompanyId(event.target.value);
              }}
            >
              <MenuItem value="">{t("common:allType")}</MenuItem>
              {companyListQuery.data?.page?.content.map(company => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {issueTemplateListQuery.data?.page.numberOfElements === 0 ? (
            <Box style={{ margin: "20px auto", textAlign: "center" }}>
              <Typography
                variant="h5"
                align="center"
                color="secondary"
                style={{ marginBottom: "20px" }}
              >
                {t("common:noData")}
              </Typography>
              <Button
                component={Link}
                to={`/issues/issue-type/issue-type-create?id=${selectedCompanyId}`}
                startIcon={<AddBox />}
              >
                {t("issueType.create")}
              </Button>
            </Box>
          ) : (
            <Container maxWidth="sm">
              <Grid container className={classes.header}>
                <Grid item xs={6} className={classes.cell}>
                  <Typography variant="body2" className={classes.text}>
                    {t("issueType.formValues.type")}
                  </Typography>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end" className={classes.cell}>
                  <Button
                    component={Link}
                    to={`/issues/issue-type/issue-type-create?id=${selectedCompanyId}`}
                    startIcon={<AddBox />}
                  >
                    {t("issueType.create")}
                  </Button>
                </Grid>
              </Grid>
              {issueTemplateListQuery.data?.page.numberOfElements ? (
                issueTemplateListQuery.data.page.content.map(issueType => (
                  <IssueTypeRow key={issueType.id} issueType={issueType} />
                ))
              ) : (
                <Grid item container xs={12} justifyContent="center" style={{ margin: 12 }}>
                  <Box textAlign="center">
                    <Typography color="secondary" style={{ fontSize: 20, width: "100%" }}>
                      {t("common:emptyIssueTypeList")}
                    </Typography>
                  </Box>
                </Grid>
              )}
              {/*!!issueTypeListQuery?.data?.length ? (
                <>
                  {issueTypeListQuery?.data?.map((issueType: IssueType) => {
                    return (
                      <IssueTypeRow key={issueType.id} issueType={issueType} />
                    );
                  })}
                </>
              ) : (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="center"
                  style={{ margin: 12 }}
                >
                  <Box textAlign="center">
                    <Typography
                      color="secondary"
                      style={{ fontSize: 20, width: "100%" }}
                    >
                      {t("common:emptyIssueTypeList")}
                    </Typography>
                  </Box>
                </Grid>
              )*/}
              {issueTemplateListQuery.data && issueTemplateListQuery?.data?.page.totalPages > 1 && (
                <Pagination
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "24px 0",
                  }}
                  count={issueTemplateListQuery?.data?.page.totalPages}
                  color="primary"
                  page={issueTemplateListQuery.data.page.number + 1}
                  onChange={(e, page) => {
                    sessionStorage.setItem(
                      `IssueTypePageNumber-${tenant?.id}`,
                      JSON.stringify(page - 1),
                    );
                    setPage(page - 1);
                  }}
                />
              )}
            </Container>
          )}
        </>
      )}
    </Card>
  );
};

export default IssueTypeList;
