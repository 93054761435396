import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { DeliveryRound } from "shared/types";

const ENDPOINT = "/delivery-round";

type IssueForDeliveryPoint = {
  deliveryRoundId: number;
  vehicleId: number;
  date: string;
};

export const getDeliveryRoundById = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-id`, { param, tenantId }); //DELIVERY_ROUND_GET

export const createDeliveryRound = (param: DeliveryRound, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId }); //DELIVERY_ROUND_ADMIN

export const modifyDeliveryRound = (param: DeliveryRound, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param, tenantId }); //DELIVERY_ROUND_ADMIN*/

export const createIssueForDeliveryPoint = (param: IssueForDeliveryPoint, tenantId: number) =>
  axios.post(`${ENDPOINT}/create-issue-for-delivery-point`, {
    ...param,
    tenantId,
  }); //DELIVERY_ROUND_ADMIN*/

export const getDeliveryRoundPriceById = (
  deliveryRoundId: number,
  date: string,
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}/get-price-by-id-and-date`, {
    deliveryRoundId,
    date,
    tenantId,
  }); //DELIVERY_ROUND_ADMIN*/

export const getDeliveryRoundList = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<DeliveryRound>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //DELIVERY_ROUND_GET

export const getPlannedDeliveryRoundList = (
  page: number,
  size: number,
  tenantId: number,
  sort: string = "",
  date: string,
  vehicleId: string,
) =>
  axios.get<GenericPageResponse<DeliveryRound>>(
    `${ENDPOINT}/get-all-planned?page=${page}&size=${size}&tenantId=${tenantId}&sort=${sort}&date=${date}&vehicleId=${vehicleId}`,
  ); //DELIVERY_ROUND_GET
