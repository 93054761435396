import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, GetApp } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getFileByPath } from "shared/network/file.api";
import { importedDocumentList } from "shared/network/imported-document.api";
import { fileDownload } from "shared/util/fileDownload";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";

const HaccpDocuments = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication?.selectedRelTenant);
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const viewType = location.pathname?.includes("/offer/pdf-template")
    ? "OFFER"
    : location.pathname?.includes("/contract-pdf-template")
    ? "CONTRACT"
    : "";
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(
          `rap-pdf-template${viewType === "" ? "" : `-${viewType}`}-page-number`,
        ),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey(
          `rap-pdf-template${viewType === "" ? "" : `-${viewType}`}-page-size`,
        ),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;
  const importedDocumentListQuery = useQuery(
    [
      "importedDocumentListQuery",
      selectedRelTenant.tenant.id,
      page,
      pageSize,
      filterValue,
      sortState,
      viewType,
    ],
    async () => {
      const { data } = await importedDocumentList(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Megnevezés",
      flex: 1,
    },
    {
      field: "createdBy.name",
      headerName: "Létrehozta",
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.createdBy?.name,
    },
    {
      field: "issuedAt",
      headerName: "Kiállítás dátuma",
      valueGetter: ({ row }: GridValueGetterParams) =>
        format(new Date(row.issuedAt), "yyyy. MM. dd"),
      flex: 1,
    },
    {
      field: "shortDescription",
      headerName: "Rövid leírás",
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={t("comment.download").toString()}>
          <IconButton
            size="small"
            color="primary"
            onClick={async event => {
              event.preventDefault();
              if (row.id) {
                try {
                  const { data } = await getFileByPath(row.filePath, tenant.id);
                  fileDownload(data, `${row.title}_${row.id}`);
                } catch (e) {
                  enqueueSnackbar(
                    t("common:notification.download.failure", {
                      subject: t("comment.subject"),
                    }),
                    {
                      variant: "error",
                    },
                  );
                }
              }
            }}
          >
            <GetApp />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Container>
      <Box style={{ textAlign: "center", paddingBottom: 16 }}>
        <Button onClick={() => history.push("/haccp-documents/create")} startIcon={<AddBox />}>
          Új fájl feltöltése
        </Button>
      </Box>
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="pdf-template-offer"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={importedDocumentListQuery}
        minWidth={{ value: "sm" }}
      />
    </Container>
  );
};

export default HaccpDocuments;
