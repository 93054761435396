import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { RootState } from "config/store";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getQuestionnaireById } from "shared/network/questionnaire.api";

const MolEnergeticDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const [open, setOpen] = useState(false);

  const getQuestionnaireByIdQuery = useQuery(["getQuestionnaireById", tenant?.id], async () => {
    if (id) {
      const { data } = await getQuestionnaireById(id, tenant?.id);
      return data.item;
    }
    return Promise.reject();
  });
  const completedQuestionnaire = getQuestionnaireByIdQuery.data;

  return (
    <Container>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t("Hiba")}</DialogTitle>
        <DialogContent>
          <Grid style={{ fontSize: 16, fontWeight: "bold" }}>
            Vegye fel a kapcsolatot a kollégákkal
          </Grid>
          <Grid style={{ fontSize: 16, marginTop: 12 }}>
            molcontrol@molcontrol.com vagy 06 99/999-9999
          </Grid>
        </DialogContent>
      </Dialog>
      <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
        <CardHeader title="Cég adatok" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              Cég neve
            </Grid>
            <Grid item xs={6}>
              <Typography>{completedQuestionnaire?.companyName || "-"}</Typography>
            </Grid>
            <Grid item xs={6}>
              Cég címe
            </Grid>
            <Grid item xs={6}>
              <Typography>{completedQuestionnaire?.companyAddress || "-"}</Typography>
            </Grid>
            <Grid item xs={6}>
              Telephely neve
            </Grid>

            <Grid item xs={6}>
              <Typography>{completedQuestionnaire?.siteName || "-"}</Typography>
            </Grid>
            <Grid item xs={6}>
              Telephely címe
            </Grid>
            <Grid item xs={6}>
              <Typography>{completedQuestionnaire?.siteAddress || "-"}</Typography>
            </Grid>
            <Grid item xs={6}>
              Dátum
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {completedQuestionnaire?.date
                  ? new Date(completedQuestionnaire.date).toLocaleDateString()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              Kitöltő neve
            </Grid>
            <Grid item xs={6}>
              <Typography>{completedQuestionnaire?.name || "-"}</Typography>
            </Grid>

            <Grid item xs={6}>
              Elérhetőség
            </Grid>
            <Grid item xs={6}>
              <Typography>{completedQuestionnaire?.contact || "-"}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
        <CardHeader title="Épület" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              Falszerkezet anyaga
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {completedQuestionnaire?.wallMaterial === "egyéb"
                  ? completedQuestionnaire.wallMaterialOtherData
                  : completedQuestionnaire?.wallMaterial || "-"}
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                Falszerkezet vastagsága (cm)
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.wallThickness || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                Tető kialakítása
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.roofDesign || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                Szintek száma
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.allFloorsWithBasements || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                Északi határoló felület típusa
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.northSurfaceType || "-"}</Typography>
              </Grid>

              <Grid item xs={6}>
                Nagysága (m2)
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.northSurfaceSize || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                Ha a határoló felület vegyes, tömör fal aránya a teljesfelülethez képest
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.ifNorthSurfaceIsMixed || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                Déli határoló felület típusa
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.southSurfaceType || "-"}</Typography>
              </Grid>

              <Grid item xs={6}>
                Nagysága (m2)
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.southSurfaceSize || "-"}</Typography>
              </Grid>

              <Grid item xs={6}>
                Ha a határoló felület vegyes, tömör fal aránya a teljesfelülethez képest
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.ifSouthSurfaceIsMixed || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                A teljes külső ÜVEG felületek nagysága (m2)
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.glassSurfaceSize || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                A teljes FAL üvegfelületek nagysága (m2)
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.wallSurfaceSize || "-"}</Typography>
              </Grid>

              <Grid item xs={6}>
                A szintek ÁTLAG területe (m2)
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.avgAreaOfFloors || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item container xs={6} spacing={2}>
                <Grid item xs={12}>
                  Alkalmazott külső hőszigetelő anyag típusa
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.externalInsulationMaterialType1 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.externalInsulationMaterialType2 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.externalInsulationMaterialType3 || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={6} spacing={1}>
                <Grid item xs={12}>
                  Vastagsága cm
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.thicknessOfExternalInsulationMaterial1 || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.thicknessOfExternalInsulationMaterial2 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.thicknessOfExternalInsulationMaterial3 || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                Beépített nyílászárók típusa
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.fenestrationType || "-"}</Typography>
              </Grid>

              <Grid item xs={6}>
                Hőátadási tényező
              </Grid>

              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.fenestrationHeatFactor || "-"}</Typography>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              Egyéb adatok
            </Grid>
            <Grid item xs={6}>
              <Typography>{completedQuestionnaire?.otherData || "-"}</Typography>
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={6}>
                Megújuló energia források használata
              </Grid>
              <Grid item container xs={6} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    {completedQuestionnaire?.renewableEnergySources1 === "egyéb"
                      ? completedQuestionnaire.renewableEnergySourcesOtherData1
                      : completedQuestionnaire?.renewableEnergySources1 || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    {completedQuestionnaire?.renewableEnergySources2 === "egyéb"
                      ? completedQuestionnaire.renewableEnergySourcesOtherData2
                      : completedQuestionnaire?.renewableEnergySources2 || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    {completedQuestionnaire?.renewableEnergySources3 === "egyéb"
                      ? completedQuestionnaire.renewableEnergySourcesOtherData3
                      : completedQuestionnaire?.renewableEnergySources3 || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      <Card style={{ backgroundColor: "rgba(255, 160, 82, 0.6)" }}>
        <CardHeader title="Fűtés" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={3}>
                Alkalmazott fűtés típusai
              </Grid>
              <Grid item container xs={3} spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    {completedQuestionnaire?.heatingType1 === "egyéb"
                      ? completedQuestionnaire.heatingTypeOtherData1
                      : completedQuestionnaire?.heatingType1 || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    {completedQuestionnaire?.heatingType2 === "egyéb"
                      ? completedQuestionnaire.heatingTypeOtherData2
                      : completedQuestionnaire?.heatingType2 || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    {completedQuestionnaire?.heatingType3 === "egyéb"
                      ? completedQuestionnaire.heatingTypeOtherData3
                      : completedQuestionnaire?.heatingType3 || "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item container xs={3} spacing={2}>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.heatingPerformance1 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.heatingPerformance2 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.heatingPerformance3 || "-"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={3}>
                Fűtési berendezések életkora
              </Grid>
              <Grid item container xs={1} spacing={2}>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.heatingAge1 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.heatingAge2 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.heatingAge3 || "-"}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={1} spacing={2}>
                <Grid item xs={12} style={{ marginTop: 8 }}>
                  év
                </Grid>
                <Grid item xs={12} style={{ marginTop: 8 }}>
                  év
                </Grid>
                <Grid item xs={12} style={{ marginTop: 8 }}>
                  év
                </Grid>
              </Grid>
              <Grid item container xs={3}>
                {/* <Grid item xs={12}>
                            ?
                          </Grid>
                          <Grid item xs={12}>
                            ?
                          </Grid>
                          <Grid item xs={12}>
                            ?
                          </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={3}>
                Alkalmazott HMV típusai
              </Grid>
              <Grid item container xs={3} spacing={2}>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.HMWType1 || "-"}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.HMWType2 || "-"}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.HMWType3 || "-"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                Szükgséges fűtőteljesítmény kW
              </Grid>
              <Grid item container xs={3} spacing={2}>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.HMWPerformance1 || "-"}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.HMWPerformance2 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.HMWPerformance3 || "-"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={3}>
                fűtési kör 01
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit1 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData1
                    : completedQuestionnaire?.heatingCircuit1 || "-"}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance1 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 02
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit2 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData2
                    : completedQuestionnaire?.heatingCircuit2 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance2 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 03
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit3 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData3
                    : completedQuestionnaire?.heatingCircuit3 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance3 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 04
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit4 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData4
                    : completedQuestionnaire?.heatingCircuit4 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance4 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 05
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit5 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData5
                    : completedQuestionnaire?.heatingCircuit5 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance5 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 06
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit6 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData6
                    : completedQuestionnaire?.heatingCircuit6 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance6 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 07
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit7 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData7
                    : completedQuestionnaire?.heatingCircuit7 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance7 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 08
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit8 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData8
                    : completedQuestionnaire?.heatingCircuit8 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance8 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 09
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit9 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData9
                    : completedQuestionnaire?.heatingCircuit9 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.heatingCircuitPerformance9 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                fűtési kör 10
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuit10 === "egyéb"
                    ? completedQuestionnaire.heatingCircuitOtherData10
                    : completedQuestionnaire?.heatingCircuit10 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Fűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.heatingCircuitPerformance10 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Fűtési körök működtetése
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.heatingCircuitOperation || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                Hőszivattyú fajtája
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.heatingHeatPumpType || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                Alkalmaznek-e termosztatikus szelepfejeket
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.useThermostaticHeads || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                Alkalmaznak-e időprogramozást
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.useTimeProgramming || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                Időprogramozás esetén komfort üzem időtartama naponta hétköznap
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.heatTimeProgrammingDailyOnWeekdays || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Időprogramozás esetén komfort üzem időtartama naponta hétvégente
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.heatTimeProgrammingDailyOnWeekends || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Alkalmazott komfort alapjel fűtésre vonatkozó belső hömérséklet komfort alapján
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.heatTimeProgrammingComfortStandard || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Alkalmazott CSÖKKENTETT alapjel
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.heatTimeProgrammingReducedStandard || "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                Fan-Coil-os helyiségek működtetése
              </Grid>

              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.heatingFanCoilRooms || "-"}</Typography>
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Van e kiemelt a fűtési körök között?
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.priorityHeatingInCircuit || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                Melyik fűtési kört érinti?
              </Grid>
              <Grid item xs={6}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityHeatingSystemsWhichCircuit1 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityHeatingSystemsWhichCircuit2 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityHeatingSystemsWhichCircuit3 || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                Van e kiemelt működtetésű rendszer a légkezelők között?
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.priorityAirHandlers || "-"}</Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Melyik légkezelőt érinti?
              </Grid>
              <Grid item container xs={6} spacing={2}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityAirHandlersWhichCircuit1 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityAirHandlersWhichCircuit2 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityAirHandlersWhichCircuit3 || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Alkalmazott komfort alapjel fűtésre vonatkozó belső hömérséklet komfort alapján
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.priorityHeatingSystemsComfortStandard || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Alkalmazott CSÖKKENTETT alapjel
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.priorityHeatingSystemsReducedStandard || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider style={{ marginTop: 24, marginBottom: 24 }} />
      <Card style={{ backgroundColor: "rgba(181, 226, 248, 0.6)", marginBottom: 16 }}>
        <CardHeader title="Hűtés" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={3}>
                Alkalmazott hűtés típusai
              </Grid>
              <Grid item container xs={3} spacing={2}>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingType1 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingType2 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingType3 || "-"}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item container xs={3} spacing={2}>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingTypePerformance1 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingTypePerformance2 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingTypePerformance3 || "-"}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={3}>
                Hűtési berendezések életkora
              </Grid>
              <Grid item container xs={1} spacing={2}>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingTypeAge1 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingTypeAge2 || "-"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{completedQuestionnaire?.coolingTypeAge3 || "-"}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={1} spacing={2}>
                <Grid item xs={12}>
                  év
                </Grid>
                <Grid item xs={12}>
                  év
                </Grid>
                <Grid item xs={12}>
                  év
                </Grid>
              </Grid>
              <Grid item container xs={3}></Grid>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={3}>
                hűtési kör 01
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit1 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData1
                    : completedQuestionnaire?.coolingCircuit1 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance1 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                hűtési kör 02
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit2 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData2
                    : completedQuestionnaire?.coolingCircuit2 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance2 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                hűtési kör 03
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit3 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData3
                    : completedQuestionnaire?.coolingCircuit3 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance3 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                hűtési kör 04
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit4 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData4
                    : completedQuestionnaire?.coolingCircuit4 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance4 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                hűtési kör 05
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit5 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData5
                    : completedQuestionnaire?.coolingCircuit5 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance5 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                hűtési kör 06
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit6 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData6
                    : completedQuestionnaire?.coolingCircuit6 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance6 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                hűtési kör 07
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit7 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData7
                    : completedQuestionnaire?.coolingCircuit7 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance7 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                hűtési kör 08
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit8 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData8
                    : completedQuestionnaire?.coolingCircuit8 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance8 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                hűtési kör 09
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit9 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData9
                    : completedQuestionnaire?.coolingCircuit9 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>{completedQuestionnaire?.coolingCircuitPerformance9 || "-"}</Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtési kör 10
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuit10 === "egyéb"
                    ? completedQuestionnaire.coolingCircuitOtherData10
                    : completedQuestionnaire?.coolingCircuit10 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                Hűtőteljesítmény kW
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  {completedQuestionnaire?.coolingCircuitPerformance10 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Hűtési körök működtetése
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.coolingCircuitOperation || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                hőszivattyú fajtája
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.heatPumpType || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                Időprogramozás esetén komfort üzem időtartama naponta hétköznap
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.coolingTimeProgrammingDailyOnWeekdays || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Időprogramozás esetén komfort üzem időtartama naponta hétvégente
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.coolingTimeProgrammingDailyOnWeekends || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Alkalmazott komfort alapjel hűtésre vonatkozó belső levegő komfort alapján
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.coolingTimeProgrammingComfortStandard || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Alkalmazott CSÖKKENTETT alapjel
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.coolingTimeProgrammingReducedStandard || "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                Fan-Coil-os helyiségek működtetése
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.coolingFanCoilRooms || "-"}</Typography>
              </Grid>

              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Van e kiemelt a hűtési körök között?
              </Grid>
              <Grid item xs={6}>
                <Typography>{completedQuestionnaire?.priorityCoolingInCircuit || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                Melyik hűtési kört érinti?
              </Grid>
              <Grid item xs={2}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityCoolingSystemsWhichCircuit1 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityCoolingSystemsWhichCircuit2 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityCoolingSystemsWhichCircuit3 || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                Van e kiemelt működtetésű rendszer a légkezelők között?
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.priorityCoolingInAirhandlerCircuit || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Melyik légkezelőt érinti?
              </Grid>
              <Grid item container xs={6}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityCoolingSystemsWhichAirHandlers1 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityCoolingSystemsWhichAirHandlers2 || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {completedQuestionnaire?.priorityCoolingSystemsWhichAirHandlers3 || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                Alkalmazott komfort alapjel fűtésre vonatkozó belső hömérséklet komfort alapján
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.priorityCoolingSystemsComfortStandard || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                Alkalmazott CSÖKKENTETT alapjel
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {completedQuestionnaire?.priorityCoolingSystemsReducedStandard || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};
export default MolEnergeticDetails;
