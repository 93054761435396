import { Box, Button, Grid, Typography } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import FormCard from "components/FormCard";
import { Dispatch, SetStateAction } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DailyReport } from "shared/network/daily-report.api";
import { RelDailyReportIssue } from "shared/network/rel-daily-report-issue.api";
import DailyReportCreateItemRow from "./DailyReportCreateItemRow";

type Props = {
  dailyReport: DailyReport | null;
  relDailyReportIssues: RelDailyReportIssue[] | null;
  setDailyReport: Dispatch<SetStateAction<DailyReport | null>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isClosed: boolean;
};

const DailyReportCreatePage3 = ({
  dailyReport,
  relDailyReportIssues,
  setActiveStep,
  isClosed,
}: Props) => {
  const { t } = useTranslation();
  const form = useFormContext();

  async function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  async function handleNext() {
    const result = await form.trigger();
    if (result) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  return (
    <Box>
      <FormCard title="Felhasznált anyagok">
        <Grid container spacing={2}>
          {!!relDailyReportIssues?.length ? (
            relDailyReportIssues?.map((rel, index) => (
              <DailyReportCreateItemRow
                key={rel.id}
                index={index}
                dailyReport={dailyReport}
                relDailyReportIssue={rel}
                isClosed={isClosed}
              />
            ))
          ) : (
            <Box style={{ textAlign: "center", width: "100%", paddingBottom: 8 }}>
              <Typography style={{ paddingBottom: 16 }}>{t("dailyReport.noIssue")}</Typography>
              {!isClosed && (
                <Button variant="outlined" onClick={() => handleBack()}>
                  Vissza a feladatok felvételéhez
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </FormCard>
      <Box display="flex" justifyContent="center" gridGap={8} p={1.5}>
        <Button startIcon={<ArrowBack />} variant="outlined" onClick={handleBack}>
          {t("common:button.prev")}
        </Button>
        <Button endIcon={<ArrowForward />} variant="contained" color="primary" onClick={handleNext}>
          {t("common:button.next")}
        </Button>
      </Box>
    </Box>
  );
};

export default DailyReportCreatePage3;
