import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { Transaction, Warehouse } from "shared/types";

export type ScrapTransactionEntry = {
  warehouseEntry: Warehouse;
  transactionsEntry: Transaction;
  comment: string;
  tenantId: number;
};

export type WrappingsToVehicleFromItemRequest = {
  wrappingId?: string;
  itemId?: string;
  itemSerialId?: string;
  vehicleId?: string;
  companySiteId?: string;
  quantity?: string;
  tenantId?: string;
  userId?: string;
  deliveryId?: string;
};

const ENDPOINT = "/transactions";

export const createTransaction = (transactionsEntries: Transaction[], tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, {
    transactionsEntries,
    tenantId,
  }); //TRANSACTIONS_ADMIN

export const listByToWarehousePageable = (
  page: number,
  size: number,
  tenantId: number,
  warehouseId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Transaction>>(
    `${ENDPOINT}/by-to-warehouse?page=${page}&size=${size}&tenantId=${tenantId}&warehouseId=${warehouseId}&search=${search}&sort=${sort}`,
  ); //TRANSACTIONS_GET

export const listByFromWarehousePageable = (
  page: number,
  size: number,
  tenantId: number,
  warehouseId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Transaction>>(
    `${ENDPOINT}/by-from-warehouse?page=${page}&size=${size}&tenantId=${tenantId}&warehouseId=${warehouseId}&search=${search}&sort=${sort}`,
  ); //TRANSACTIONS_GET

export const listAllTransactionsPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Transaction>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //TRANSACTIONS_GET

export const acceptTransaction = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/acception`, { param, tenantId }); //TRANSACTIONS_ADMIN

export const createScrapTransaction = (params: ScrapTransactionEntry) =>
  axios.post(`${ENDPOINT}/scrap-create`, {
    warehouseEntry: params.warehouseEntry,
    transactionsEntry: params.transactionsEntry,
    comment: params.comment,
    tenantId: params.tenantId,
  }); //TRANSACTIONS_ADMIN

export const saveWrappingsToVehicleFromItem = (
  params: WrappingsToVehicleFromItemRequest[],
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}/wrappings-to-vehicle-from-item`, {
    params,
    tenantId,
  }); //TRANSACTIONS_ADMIN
