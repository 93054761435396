import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSubDepartment, modifySubDepartment } from "shared/network/sub-department.api";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultValues: any | null;
  isModify: boolean;
};

type FormValues = {
  name: string;
};

const HaccpDepartmentDialog = ({ open, onClose, defaultValues, isModify }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormValues>();
  const { handleSubmit, setValue } = form;
  const [loading, setLoading] = useState(false);

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      if (!defaultValues) {
        await createSubDepartment(values, tenant?.id);
      } else {
        await modifySubDepartment({ ...defaultValues, ...values }, tenant?.id);
      }
      onClose();
      queryClient.refetchQueries("haccpDepartmentPageQuery");
      if (!defaultValues) {
        enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
      } else {
        enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
      }
    } catch (error: any) {
      if (!defaultValues) {
        enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
      } else {
        enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    setValue("name", defaultValues?.name || "");
  }, [defaultValues?.name, setValue]);

  return (
    <>
      <Loading open={loading} />
      <Dialog maxWidth="xs" fullWidth open={!!open} onClose={onClose}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
              {isModify && t("haccpDepartment.modify")}
              {!isModify && t("haccpDepartment.create")}
            </DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" gridGap={16}>
                <Controller
                  name="name"
                  defaultValue=""
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("haccpDepartment.name")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={onClose}>
                {t("common:button.cancel")}
              </Button>
              <Button variant="contained" type="submit">
                {t("common:button.save")}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default HaccpDepartmentDialog;
