import { Container } from "@material-ui/core";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { modifyBundle } from "shared/network/bundle.api";
import { Bundle } from "shared/types";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import TenantBundleForm from "./TenantBundleForm";

type Params = {
  bundle?: Bundle;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
};

const TenantBundleModify = ({ bundle, setOpen, refetch }: Params) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<Bundle>();

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const onSubmitModify: SubmitHandler<Bundle> = async values => {
    try {
      await modifyBundle(
        {
          ...values,
          price: bundle?.price,
          name: bundle?.name,
          description: bundle?.description,
        },
        tenant.id,
      );
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("bundle.subject"),
        }),
        {
          variant: "success",
        },
      );
      setOpen(false);
      refetch();
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("bundle.subject"),
        }),
        { variant: "error" },
      );
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={form.handleSubmit(onSubmitModify)}>
        <FormProvider {...form}>
          <TenantBundleForm bundle={bundle} setOpen={setOpen} />
        </FormProvider>
      </form>
    </Container>
  );
};
export default TenantBundleModify;
