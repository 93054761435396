import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Grid, Box, TextField, Button, Popover, MenuItem } from "@material-ui/core";
import { PRIORITY } from "config/constants";
import { COLORS } from "config/theme";
import { Dispatch, SetStateAction } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type Props = {
  anchorEl: HTMLDivElement | HTMLButtonElement | null;
  onClose: () => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
};

const KanbanSearchModal = ({ setSearchValue, anchorEl, onClose }: Props) => {
  const { control, watch, setValue } = useForm();
  const searchValue = watch("searchValue");
  const value = watch("value");
  const { t } = useTranslation();

  const formReset = () => {
    onClose();
    setValue("searchValue", "");
    setValue("value", "");
  };

  return (
    <Popover open={!!anchorEl} onClose={formReset} anchorEl={anchorEl}>
      <Box display="flex" alignItems="center" style={{ margin: 10 }} gridGap={12}>
        <FontAwesomeIcon
          icon={faFilter}
          style={{
            fontSize: 20,
            width: 20,
            color: COLORS.main,
          }}
        />
        <Typography variant="h6" style={{ color: COLORS.main }}>
          {t("common:button.filter")}
        </Typography>
      </Box>
      <Grid container spacing={2} style={{ width: 250, margin: 10, marginBottom: 0 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="value"
            rules={{ required: t("validation.required").toString() }}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("common:filter.formValues.value")}
                InputLabelProps={{ shrink: true, required: true }}
                SelectProps={{
                  displayEmpty: true,
                }}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem disabled value="">
                  {t("common:choose")}
                </MenuItem>
                <MenuItem value="priority">{t("issues.formValues.priority")}</MenuItem>
                <MenuItem value={`assignedUser.name`}>
                  {t("issues.formValues.assignedUser")}
                </MenuItem>
              </TextField>
            )}
          />
        </Grid>
        {value === "assignedUser.name" ? (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="searchValue"
              defaultValue={""}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("issues.formValues.assignedUser")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="searchValue"
              rules={{ required: t("validation.required").toString() }}
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("issues.formValues.priority")}
                  InputLabelProps={{ shrink: true, required: true }}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  select
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                >
                  <MenuItem disabled value="">
                    {t("common:choose")}
                  </MenuItem>
                  {PRIORITY.map(priority => (
                    <MenuItem key={priority} value={priority}>
                      {t(`common:priority.${priority}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box width="100%" display="flex" justifyContent="space-around">
          <Button
            disableElevation
            onClick={formReset}
            style={{
              color: COLORS.mainLight,
              backgroundColor: COLORS.mainGrey,
              width: "100%",
              borderRadius: "unset",
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button
            disableElevation
            onClick={() => setSearchValue(`${value}:${searchValue};`)}
            style={{
              width: "100%",
              backgroundColor: COLORS.mainLight,
              borderRadius: "unset",
            }}
          >
            {t("common:button.filter")}
          </Button>
        </Box>
      </Grid>
    </Popover>
  );
};

export default KanbanSearchModal;
