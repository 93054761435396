import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, Backspace, Delete, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import FormCard from "components/FormCard";
import NumberField from "components/NumberField";
import {
  ITEM_TYPES,
  NUMBER_FORMAT,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PRODUCT_UNIT,
  SUB_ITEM_TYPE,
  VTSZ_SZJ_TYPE,
} from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { isEmpty, uniqBy } from "lodash";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { getAllCategories } from "shared/network/category.api";
import { listCurrencyByTenant } from "shared/network/currency.api";
import {
  addItemCategory,
  inactivatePriceHistory,
  removeItemCategory,
} from "shared/network/item.api";
import { getTaxEntryListPageable } from "shared/network/tax-api";
import {
  BaseCurrency,
  Company,
  CompositeItem,
  Item,
  ItemCategory,
  ItemPriceHistory,
  ItemTaxHistory,
  LedgerNumber,
  TaxEntry,
} from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import CompositeItemForm from "../CompositeItemForm";
import SupplierPriceHistoryForm from "./SupplierPriceHistoryForm";

const useStyles = makeStyles({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  switch: {
    "&.MuiFormControlLabel-labelPlacementStart": {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: "row-reverse",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  dataContainer: {
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    padding: 8,
    marginBottom: 10,
    width: "100%",
  },
  cell: {
    padding: 8,
  },
});

export type ItemFormValues = {
  item: any; // Item;
  itemTaxHistoryEntry: ItemTaxHistory;
  itemPriceHistoryEntries: ItemPriceHistory[];
  ledgerNumberEntry: LedgerNumber;
  wrappingId: string;
  categories: ItemCategory[];
  compositeItems: any; // CompositeItem[];
  useCompositePrice?: boolean;
  showInvoiceSubItems?: boolean;
  ledgerNumbersOpen: boolean;
  supplierPriceHistoryEntries: {
    company: Company | null;
    price: string;
    currency: BaseCurrency | null;
  }[];
  serviceDetails: any;
};

type Props = {
  item?: Item;
  isLoading?: boolean;
  isModal?: boolean;
};

const ItemForm = ({ item, isModal }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch, register, setValue } = useFormContext<ItemFormValues>();

  const { append, fields, remove, replace } = useFieldArray({
    control,
    name: "itemPriceHistoryEntries",
    keyName: "key",
  });

  const productUnit = watch("item.productUnit");

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const { control: categoryControl, getValues } = useForm<{ categoryId: number | string }>();

  const useCompositePrice = watch("useCompositePrice");
  const isComposite = watch("item.isComposite");
  const compositeItems = watch("compositeItems");
  const itemPriceHistoryEntries = watch("itemPriceHistoryEntries");
  const futurePriceHandling = watch("item.futurePriceHandling");
  const isScalingPricing = watch("item.isScalingPricing");

  const [open, setOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<ItemCategory | null>(null);
  const [currencySearch, setCurrencySearch] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<ItemCategory[]>([]);

  const hasAnySellPrice =
    !isEmpty(itemPriceHistoryEntries) && !!itemPriceHistoryEntries?.find(entry => entry.price);

  const currencyListQuery = useQuery(["currencyList", tenant.id, currencySearch], async () => {
    const { data } = await listCurrencyByTenant(
      0,
      10,
      tenant.id,
      currencySearch ? `name:${currencySearch}` : "",
    );
    return data.page;
  });

  const avaibleTaxTypeQuery = useQuery(
    ["avaibleTaxTypeQuery", tenant.id],
    async () => {
      const { data } = await getTaxEntryListPageable(0, 100000000, tenant.id);
      return data.page.content;
    },
    {
      onSuccess: data => {
        if (!item) {
          setValue(
            "itemTaxHistoryEntry.tax",
            data.find(entry => entry.percent === 27) || data.find(entry => entry.id || 1) || null,
          );
        }
      },
    },
  );

  const categoryListQuery = useQuery(["caregoryListQuery", tenant.id], async () => {
    const { data } = await getAllCategories(tenant.id);
    return data;
  });

  const onSubmitAddCategory = async (id: number | string = getValues("categoryId")) => {
    if (id) {
      let item = categoryListQuery.data?.items.find(category => category?.id === id);
      if (item) {
        selectedCategories.push(item);
        setSelectedCategories(selectedCategories);
      }
      if (item?.parentId != null) {
        onSubmitAddCategory(item.parentId);
      }
    }
    if (item) {
      try {
        await addItemCategory(Number.parseInt(id.toString()), item.id.toString(), tenant.id);
      } catch {}
    }
    setValue("categories", selectedCategories);
  };

  const removeCategory = async () => {
    if (deleteTarget !== null) {
      selectedCategories.splice(selectedCategories.indexOf(deleteTarget), 1);
      setSelectedCategories(selectedCategories);
    }
    setDeleteTarget(null);
    if (item) {
      try {
        await removeItemCategory(
          Number.parseInt(deleteTarget?.id.toString() || ""),
          item.id.toString(),
          tenant.id,
        );
      } catch {}
    }
    setValue("categories", selectedCategories);
  };

  const inactivatePrice = async (param: ItemPriceHistory, index: number) => {
    try {
      if (param.id) {
        await inactivatePriceHistory(tenant.id, param);
      }
      remove(index);
      setValue(
        "itemPriceHistoryEntries",
        fields.filter((_, fieldIndex) => index !== fieldIndex),
      );
    } catch (error: any) {
      if (error.data.status === "AT_LEAST_ONE_ACTIVE") {
        enqueueSnackbar("Legalább egy ár megadása kötelező!", {
          variant: "error",
        });
      } else {
        enqueueSnackbar("Sikertelen törlés!", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    register("categories");
  }, [register]);

  useEffect(() => {
    let array: ItemCategory[] = [];
    item?.categories.forEach(link => {
      array.push(link.category);
    });
    setSelectedCategories(array);
    setValue("categories", selectedCategories);
  }, [item, setValue]); //eslint-disable-line

  const handleCurrencySearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setCurrencySearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const calculatePriceFromComposites = useCallback(() => {
    const distinctCurrencies = uniqBy(
      (compositeItems as CompositeItem[])
        ?.map(item => item.buildingItem?.itemPriceHistories)
        .flat()
        .filter(item => item?.isActive),
      "currency.id",
    ).map(history => history?.currency);

    const pricesByCurrency = distinctCurrencies.map(currency => {
      return {
        currency: currency,
        price: (compositeItems as CompositeItem[])
          .map(item => {
            let temp = item.buildingItem?.itemPriceHistories.find(history => {
              return history?.currency?.id === currency?.id && history.isActive;
            });
            if (temp) {
              return ((temp.price as number) || 0) * (item.quantity || 0);
            } else {
              return null;
            }
          })
          // @ts-ignore
          .reduce((acc, a) => acc + a, 0),
      };
    });
    if (useCompositePrice) {
      pricesByCurrency.forEach((value, index) => {
        setValue(`itemPriceHistoryEntries.${index}.currency`, value?.currency || null);
        setValue(`itemPriceHistoryEntries.${index}.price`, value?.price || "");
      });
      replace(
        pricesByCurrency.map(value => {
          return { currency: value?.currency || null, price: value?.price || "" };
        }),
      );
    }
  }, [compositeItems, replace, setValue, useCompositePrice]);

  useEffect(() => {
    setValue(
      "itemPriceHistoryEntries",
      item?.itemPriceHistories?.filter(history => history.isActive) || [],
    );
    replace(item?.itemPriceHistories?.filter(history => history.isActive) || []);
  }, [item?.itemPriceHistories, replace, setValue]);

  useEffect(() => {
    if (!hasAnySellPrice && !item?.purchasable) {
      setValue("item.purchasable", false);
    }
  }, [hasAnySellPrice, itemPriceHistoryEntries, fields, setValue]); //eslint-disable-line

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
        <Grid container item xs={12} sm={12} md={6} style={{ marginBottom: 12 }}>
          <FormCard title={t("common:itemData")}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {!!item?.id && <input type="hidden" value={item?.id} {...register("item.id")} />}
                <Controller
                  control={control}
                  name="item.name"
                  defaultValue={item?.name || ""}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.name")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {tenant.isUseManufacturer && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="item.manufacturer"
                    defaultValue={item?.manufacturer || ""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("item.manufacturer")}
                        InputLabelProps={{ shrink: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="item.type"
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  defaultValue={item?.type || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.type")}
                      InputLabelProps={{ shrink: true, required: true }}
                      select
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    >
                      <MenuItem value="" disabled>
                        {t("common:choose")}
                      </MenuItem>
                      {ITEM_TYPES.map(type => (
                        <MenuItem
                          key={type}
                          value={type}
                          style={{
                            marginLeft: SUB_ITEM_TYPE.includes(type) ? 20 : undefined,
                          }}
                        >
                          {t(`item.types.${type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="item.invoiceItemName"
                  defaultValue={item?.invoiceItemName || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.invoiceItemName")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="item.productCode"
                  defaultValue={item?.productCode || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.productCode")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="item.oldItem"
                  defaultValue={item?.oldItem || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("item.oldItem")}
                      InputLabelProps={{ shrink: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Collapse in={productUnit !== "OWN"}>
                  <Controller
                    control={control}
                    name="item.productUnit"
                    defaultValue={item?.productUnit || "PIECE"}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("item.productUnit")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        select
                      >
                        <MenuItem value="" disabled>
                          {t("common:choose")}
                        </MenuItem>
                        {PRODUCT_UNIT.map(unit => (
                          <MenuItem key={unit} value={unit}>
                            {t(`item.unit.${unit}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Collapse>
                <Collapse in={productUnit === "OWN"}>
                  <Controller
                    control={control}
                    name="item.customUnitTypeName"
                    defaultValue={item?.customUnitTypeName || ""}
                    rules={{
                      required:
                        productUnit === "OWN" ? t("validation.required").toString() : undefined,
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("item.customUnitTypeName")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputProps={{
                          endAdornment: (
                            <Tooltip title={t("common:button.back").toString()}>
                              <IconButton
                                onClick={() => {
                                  setValue("item.productUnit", "");
                                  setValue("item.customUnitTypeName", "");
                                }}
                              >
                                <Backspace />
                              </IconButton>
                            </Tooltip>
                          ),
                        }}
                      />
                    )}
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="itemTaxHistoryEntry.tax"
                  defaultValue={item?.itemTaxHistories.find(item => item.isActive)?.tax || null}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={avaibleTaxTypeQuery.data || []}
                      getOptionLabel={(option: TaxEntry) => `${option.name} (${option.percent})`}
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t("item.tax")}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`item.VtszSzjType`}
                  defaultValue={item?.vtszSzjType || ""}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                      label={t("invoice.invoiceItem.vtszSzj.title")}
                    >
                      <MenuItem disabled value="">
                        {t("common:choose")}
                      </MenuItem>
                      {VTSZ_SZJ_TYPE.map(type => (
                        <MenuItem key={type} value={type}>
                          {t(`invoice.invoiceItem.vtszSzj.${type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`item.vtszSzjTeszorNumber`}
                  defaultValue={item?.vtszSzjTeszorNumber || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      label={t("item.vtszSzjTeszorNumber")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Controller
                  name="ledgerNumbersOpen"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={"Főkönyvi számok megadása"}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              {!!watch("ledgerNumbersOpen") && (
                <>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="ledgerNumberEntry.inlandLedgerNumber"
                      defaultValue={item?.ledgerNumber?.inlandLedgerNumber || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.inlandLedgerNumber")}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="ledgerNumberEntry.foreignLedgerNumber"
                      defaultValue={item?.ledgerNumber?.foreignLedgerNumber || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.foreignLedgerNumber")}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="ledgerNumberEntry.acquisitionLedgerNumber"
                      defaultValue={item?.ledgerNumber?.acquisitionLedgerNumber || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.acquisitionLedgerNumber")}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Controller
                      control={control}
                      name="ledgerNumberEntry.stockReductionLedgerNumber"
                      defaultValue={item?.ledgerNumber?.stockReductionLedgerNumber || ""}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label={t("item.stockReductionLedgerNumber")}
                          InputLabelProps={{ shrink: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="item.description"
                  defaultValue={item?.description || ""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={4}
                      InputLabelProps={{ shrink: true }}
                      label={t("item.description")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Controller
                  name="item.purchasable"
                  control={control}
                  defaultValue={item?.purchasable}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("item.purchasable.title")}
                      labelPlacement="start"
                      disabled={!hasAnySellPrice}
                      control={
                        <Switch
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Controller
                  name="item.isSerialItem"
                  control={control}
                  defaultValue={item?.isSerialItem || false}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("item.isSerialItem")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Controller
                  name="item.futurePriceHandling"
                  control={control}
                  defaultValue={item?.futurePriceHandling}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      className={classes.switch}
                      label={t("item.futurePriceHandling")}
                      labelPlacement="start"
                      control={
                        <Switch
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              {!isModal && (
                <Grid item xs={12} container justifyContent="center">
                  <Controller
                    name="item.isComposite"
                    control={control}
                    defaultValue={item?.isComposite || false}
                    render={({ field: { onChange, value, ref } }) => (
                      <FormControlLabel
                        className={classes.switch}
                        label={
                          <Box display="flex" alignItems="center" gridGap={4}>
                            {t("item.isComposite")}
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("item.compositeDescription").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          </Box>
                        }
                        labelPlacement="start"
                        control={
                          <Switch
                            onChange={onChange}
                            checked={value}
                            inputRef={ref}
                            color="primary"
                          />
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              {isComposite && (
                <>
                  <Grid item xs={12} container justifyContent="center">
                    <Controller
                      name="useCompositePrice"
                      control={control}
                      defaultValue={item?.useCompositePrice || false}
                      render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                          className={classes.switch}
                          label={t("item.useCompositePrice")}
                          labelPlacement="start"
                          control={
                            <Switch
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <Controller
                      name="showInvoiceSubItems"
                      control={control}
                      defaultValue={item?.showInvoiceSubItems || false}
                      render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                          className={classes.switch}
                          label={t("item.showInvoiceSubItems")}
                          labelPlacement="start"
                          control={
                            <Switch
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </FormCard>
        </Grid>
        <Grid container item xs={12} sm={12} md={6} justifyContent="flex-start">
          <Box pb={2} width="100%">
            <FormCard title="Árak">
              <Grid container spacing={2} style={{ paddingBottom: 16 }}>
                <Grid item xs={12} container justifyContent="center">
                  <Controller
                    name="item.isScalingPricing"
                    control={control}
                    defaultValue={item?.isScalingPricing || false}
                    render={({ field: { onChange, value, ref } }) => (
                      <FormControlLabel
                        className={classes.switch}
                        label={
                          <Box display="flex" alignItems="center" gridGap={4}>
                            {t("item.isScalingPricing")}
                            <Tooltip
                              style={{
                                paddingRight: "2px",
                              }}
                              title={t("item.isScalingPricingHelp").toString()}
                            >
                              <Info style={{ color: COLORS.lightBlue }} />
                            </Tooltip>
                          </Box>
                        }
                        labelPlacement="start"
                        control={
                          <Switch
                            onChange={onChange}
                            checked={value}
                            inputRef={ref}
                            color="primary"
                          />
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Collapse in={isScalingPricing}>
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="item.scalingXName"
                      defaultValue={item?.scalingXName || ""}
                      rules={{ required: isScalingPricing && t("validation.required").toString() }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          margin="none"
                          label={t("item.scalingXName")}
                          InputLabelProps={{ shrink: true, required: isScalingPricing }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="item.scalingYName"
                      defaultValue={item?.scalingYName || ""}
                      rules={{ required: isScalingPricing && t("validation.required").toString() }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          margin="none"
                          label={t("item.scalingYName")}
                          InputLabelProps={{ shrink: true, required: isScalingPricing }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <Divider style={{ marginTop: 16, marginBottom: 16 }} />
              {fields.map((field, index) => (
                <Grid key={field.key} container spacing={2} style={{ marginBottom: 8 }}>
                  <Grid item xs={6}>
                    <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
                      {index + 1}.
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    <IconButton
                      onClick={() => inactivatePrice(field, index)}
                      color="primary"
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={futurePriceHandling ? 10 : 12} sm={futurePriceHandling ? 5 : 4}>
                    <Controller
                      control={control}
                      name={`itemPriceHistoryEntries.${index}.price`}
                      defaultValue=""
                      rules={{
                        required: t("validation.required").toString(),
                        validate: value => {
                          if (value && !value.toString().match(NUMBER_FORMAT)) {
                            return t("common:validation.numberFormat").toString();
                          }
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <NumberField
                          field={field}
                          disabled={useCompositePrice}
                          fieldState={fieldState}
                          label={t("item.price")}
                          InputLabelProps={{ shrink: true, required: true }}
                          numberLimits={{ step: "0.01", min: "0" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={futurePriceHandling ? 10 : 12} sm={futurePriceHandling ? 5 : 3}>
                    <Controller
                      control={control}
                      name={`itemPriceHistoryEntries.${index}.monthlyRentalPrice`}
                      defaultValue=""
                      rules={{
                        validate: value => {
                          if (value && !value.toString().match(NUMBER_FORMAT)) {
                            return t("common:validation.numberFormat").toString();
                          }
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <NumberField
                          field={field}
                          fieldState={fieldState}
                          label={t("item.monthlyRentalPrice")}
                          InputLabelProps={{ shrink: true }}
                          numberLimits={{ step: "0.01", min: "0" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={futurePriceHandling ? 10 : 12} sm={futurePriceHandling ? 5 : 4}>
                    <Controller
                      control={control}
                      name={`itemPriceHistoryEntries.${index}.currency`}
                      defaultValue={null}
                      rules={{
                        required: t("validation.required").toString(),
                      }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          {...field}
                          disabled={useCompositePrice}
                          onChange={(_, value) => {
                            field.onChange(value);
                            handleCurrencySearchChange("");
                          }}
                          onInputChange={(event, newInputValue) => {
                            handleCurrencySearchChange(newInputValue);
                          }}
                          options={currencyListQuery.data?.content || []}
                          getOptionLabel={(option: BaseCurrency) => option.name}
                          getOptionSelected={option => option.id === field.value?.id}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={t("currency.title")}
                              InputLabelProps={{
                                shrink: true,
                                required: true,
                              }}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {futurePriceHandling && (
                    <Grid item xs={futurePriceHandling ? 10 : 12} sm={5}>
                      <Controller
                        control={control}
                        name={`itemPriceHistoryEntries.${index}.validFrom`}
                        defaultValue={(item && item?.itemPriceHistories[index]?.validFrom) || null}
                        render={({ field, fieldState }) => (
                          <KeyboardDateTimePicker
                            {...field}
                            ref={undefined}
                            variant="inline"
                            label={t("supplierPriceHistory.formValues.validFrom")}
                            format="yyyy. MM. dd. HH:mm:ss"
                            InputLabelProps={{
                              shrink: true,
                              required: true,
                            }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
              <Box display="flex" justifyContent="center">
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<AddBox />}
                  onClick={() => append({ currency: null, monthlyRentalPrice: "", price: "" })}
                  disabled={useCompositePrice}
                >
                  {t("common:button.add")}
                </Button>
              </Box>
            </FormCard>
          </Box>
          {!isModal && (
            <>
              <FormCard title={t("item.categories")} style={{ marginBottom: 16 }}>
                <Grid container spacing={1}>
                  {selectedCategories.map(category => (
                    <Grid item xs={12} md={6} container key={category.id}>
                      <Box
                        className={classes.dataContainer}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography className={classes.text}>{category.name}</Typography>
                        <Tooltip
                          title={t("companyCategory.delete").toString()}
                          className={classes.cell}
                        >
                          <IconButton
                            size="small"
                            color="primary"
                            style={{ margin: "0 8px" }}
                            onClick={() => setDeleteTarget(category)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                  {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                    "COMPANY_ADMIN",
                  ]) && (
                    <Button
                      onClick={() => setOpen(true)}
                      variant="outlined"
                      size="small"
                      color="primary"
                      startIcon={<AddBox />}
                    >
                      {t("common:button.add")}
                    </Button>
                  )}
                </Grid>
              </FormCard>
            </>
          )}
          {!item && <SupplierPriceHistoryForm />}
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Collapse in={isComposite} style={{ width: "100%" }}>
          <CompositeItemForm
            item={item}
            calculatePriceFromComposites={calculatePriceFromComposites}
          />
        </Collapse>
      </Grid>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={() => setOpen(false)}>
        <DialogTitle>{t("item.category.add")}</DialogTitle>
        <DialogContent>
          <form id="category_form" action="/">
            <Controller
              control={categoryControl}
              name="categoryId"
              defaultValue=""
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  select
                  label={t("item.form.category")}
                  InputLabelProps={{ shrink: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                >
                  <MenuItem value="" disabled>
                    {t("common:choose")}
                  </MenuItem>
                  {categoryListQuery.data?.items?.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Box style={{ margin: "20px auto" }}>
              <Typography>{t("item.category.addWarning")}</Typography>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Box display="flex" gridGap={8}>
            <Button onClick={() => setOpen(false)} variant="text">
              {t("common:button.cancel")}
            </Button>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "COMPANY_ADMIN",
            ]) && (
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  onSubmitAddCategory();
                }}
              >
                {t("common:button.add")}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog open={!!deleteTarget} maxWidth="sm" fullWidth onClose={() => setDeleteTarget(null)}>
        <DialogTitle>{t("item.category.deleteCategoryTitle")}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography>{t("item.category.deleteWarning")}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display="flex" gridGap={8}>
            <Button variant="text" onClick={() => setDeleteTarget(null)}>
              {t("common:button.cancel")}
            </Button>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "COMPANY_ADMIN",
            ]) && (
              <Button onClick={removeCategory} variant="contained">
                {t("common:button.delete")}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemForm;
