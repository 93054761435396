import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getVehicleListPageable } from "shared/network/vehicle.api";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

const VehicleList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-vehicle-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-vehicle-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const listVehiclesQuery = useQuery(
    [
      "vehiclePageableListQuery",
      page,
      pageSize,
      selectedRelTenant.tenant.id,
      filterValue,
      sortState,
    ],
    async () => {
      const { data } = await getVehicleListPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "licensePlateNumber",
      headerName: t("vehicle.formValues.licensePlateNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "brand",
      headerName: t("vehicle.formValues.brand"),
      flex: 1,
    },
    {
      field: "type",
      headerName: t("vehicle.formValues.type"),
      flex: 1,
    },
    {
      field: "user.name",
      headerName: t("vehicle.formValues.user"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.user?.name,
    },
    {
      field: "volume",
      headerName: t("vehicle.formValues.volume"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.volume }) + " m³",
    },
    {
      field: "weightCapacity",
      headerName: t("vehicle.formValues.weightCapacity"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        t("common:number", { num: row.weightCapacity }) + " kg",
    },
    {
      field: "actions",
      headerName: " ",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["VEHICLE_GET"]) && (
            <Tooltip title={t("vehicle.details").toString()}>
              <IconButton
                component={Link}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                to={`/vehicles/list/vehicle-details?id=${row.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "VEHICLE_GET",
            "VEHICLE_ADMIN",
          ]) && (
            //listas
            <Tooltip title={t("vehicle.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/vehicles/list/vehicle-modify?id=${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["VEHICLE_ADMIN"]) && (
          <Button
            variant="contained"
            to={`/vehicles/list/vehicle-create`}
            component={Link}
            startIcon={<AddBox />}
          >
            {t("vehicle.create")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/vehicles/list/vehicle-details?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="vehicle"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={listVehiclesQuery}
      onCellClick={onCellClick}
    />
  );
};

export default VehicleList;
