import { Box, CircularProgress, Grid, IconButton, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import ConfirmationButton from "components/ConfirmationButton";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getIssueById } from "shared/network/issues.api";
import { deleteRelIssueItem } from "shared/network/rel-issue-item.api";
import { Issue, Item } from "shared/types";

type Props = {
  issue?: Issue;
  openTime: boolean;
  setOpenTime: Dispatch<SetStateAction<boolean>>;
  reportedDate?: string;
  isClosed: boolean;
};

const DailyReportCreateItem = ({ issue, openTime, setOpenTime, reportedDate, isClosed }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);
  const [loading, setLoading] = useState(false);

  const getIssueByIdQuery = useQuery(
    [`getIssueByIdQuery`, issue?.id, tenant.id],
    async () => {
      if (issue?.id) {
        const { data } = await getIssueById(Number(issue.id), tenant.id);
        return data?.item?.items;
      }
      return Promise.reject();
    },
    { enabled: openTime },
  );

  async function onDelete(id: number) {
    setLoading(true);
    try {
      await deleteRelIssueItem(id, tenant?.id);
      getIssueByIdQuery?.refetch();
      enqueueSnackbar(t("dailyReport.deleteSuccess"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("dailyReport.deleteError"), {
        variant: "error",
      });
    }
    setLoading(false);
  }

  return (
    <Box
      style={{
        border: "1px solid rgba(0,0,0,0.22)",
        borderRadius: 10,
        marginBottom: 16,
        overflow: "hidden",
      }}
    >
      <Loading open={loading} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setOpenTime(!openTime)}
        p={1}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="h3" style={{ paddingLeft: 8 }}>
          {t("dailyReport.itemTitle")}
        </Typography>
      </Box>
      <Box p={2} overflow="auto">
        <Box>
          {getIssueByIdQuery?.isFetching ? (
            <Box display="flex" justifyContent="center" width="100%">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!getIssueByIdQuery?.data?.length ? (
                <Typography>{t("dailyReport.noItem")}</Typography>
              ) : (
                <Box minWidth={"350px"}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    style={{ borderBottom: `1px solid ${COLORS.mainGrey}` }}
                  >
                    <Grid item xs={5}>
                      <Typography variant="body2">{t("dailyReport.name")}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">{t("dailyReport.productCode")}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2">{t("dailyReport.quantity")}</Typography>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                  {getIssueByIdQuery?.data?.map(item => (
                    <Grid
                      key={item.id}
                      container
                      alignItems="center"
                      spacing={1}
                      style={{ borderBottom: `1px solid ${COLORS.mainGrey}`, paddingTop: 4 }}
                    >
                      <Grid item xs={5}>
                        <Typography>{item?.item?.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>{item?.item?.productCode}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography>
                          {item?.quantity} {t(`item.unit.${item?.item?.productUnit}`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1} container justifyContent="flex-end">
                        {!isClosed && (
                          <ConfirmationButton
                            buttonType="ICON"
                            size="small"
                            color="primary"
                            onSubmit={() => onDelete(item?.id)}
                            title={t("dailyReport.removeItemTitle")}
                            body={t("dailyReport.removeItemBody")}
                          >
                            <Delete fontSize="small" color="primary" />
                          </ConfirmationButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DailyReportCreateItem;
