import { Container } from "@material-ui/core";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { createLightOrder, fastModifyOrder } from "shared/network/order.api";
import { Order } from "shared/types";
import { removeEmptyStrings } from "shared/util/removeEmptyStrings";
import { OrderFormValues } from "../components/OrderForm";
import FastOrderForm from "./components/FastOrderForm";
import FastOrderStatusModifyDialog from "./components/FastOrderStatusModifyDialog";

type Props = {
  isFast: boolean;
};

const FastOrderCreate = ({ isFast }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<OrderFormValues>({ mode: "onBlur" });
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const [status, setStatus] = useState<SliceStatus>("idle");
  const [order, setOrder] = useState<Order | null>(null);

  const onSubmitCreate = async (values: OrderFormValues) => {
    setStatus("pending");
    try {
      const request = {
        ...removeEmptyStrings(values),
        order: {
          ...values.order,
          deliveryFrom: values.order.deliveryFrom
            ? format(new Date(values.order.deliveryFrom), "yyyy-MM-dd")
            : undefined,
          deliveryTo: values.order.deliveryFrom
            ? format(new Date(values.order.deliveryFrom), "yyyy-MM-dd")
            : undefined,
          deliveryName: values.order.deliveryName || null,
          deliveryComment: values.order.deliveryComment || null,
          deliveryAddress: values?.companySite?.address || null,
          paymentType: values.paymentType,
        },
        offer: values.offer || undefined,
        project: values.project || undefined,
        contract: values.contract || undefined,
        fullGrossPrice: undefined,
        fullNetPrice: undefined,
        companySiteId: values?.companySite?.id || null,
        relOrderItem: values.relOrderItem.map(item => {
          return {
            ...item,
            quantity: Number(item.quantity || 1) * Number(item.amountType?.quantity || 1),
          };
        }),
        companySite: values.companySite,
      };

      const { data } = await createLightOrder(request, tenant.id);
      setOrder(data?.item);

      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("order.subject"),
        }),
        {
          variant: "success",
        },
      );
      setStatus("success");
      history.goBack();
    } catch (e: any) {
      if (e.data?.status === "EMAIL_NOT_SENT") {
        enqueueSnackbar(
          t("common:notification.send.failure", {
            subject: t("commmon:email.subject"),
          }),
          {
            variant: "error",
          },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("order.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
      setStatus("failure");
    }
  };

  const onSubmitModify = async (values: OrderFormValues) => {
    setStatus("pending");
    try {
      if (order?.id) {
        await fastModifyOrder(
          {
            ...removeEmptyStrings(values),
            order: {
              ...values.order,
              id: order?.id,
              deliveryFrom:
                values.order.deliveryFrom &&
                format(new Date(values.order.deliveryFrom), "yyyy-MM-dd"),
              deliveryTo:
                values.order.deliveryFrom &&
                format(new Date(values.order.deliveryFrom), "yyyy-MM-dd"),
              deliveryName: values.order.deliveryName || null,
              deliveryComment: values.order.deliveryComment || null,
              deliveryAddress: values?.companySite?.address || null,
              paymentType: values.paymentType,
            },
            company: values.company,
            offer: values.offer || undefined,
            project: values.project || undefined,
            contract: values.contract || undefined,
            fullGrossPrice: undefined,
            fullNetPrice: undefined,
            companySite: undefined,
            companySiteId: values?.companySite?.id || null,
            relOrderItem: values.relOrderItem.map(item => {
              return {
                quantity: Number(item.quantity || 1) * Number(item.amountType?.quantity || 1),
                netPrice: Number.parseInt(item.netPrice?.toString()),
                tax: item.tax,
                amountType: item.amountType,
                item: item.item,
                serialNumber: item.serialNumber,
              };
            }),
          },
          tenant.id,
        );
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("order.subject"),
          }),
          {
            variant: "success",
          },
        );
        setStatus("success");
      }
    } catch (e: any) {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("order.subject"),
        }),
        {
          variant: "error",
        },
      );
      setStatus("failure");
    }
  };

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  return (
    <Container maxWidth="lg">
      <Loading open={status === "pending"} />
      <form onSubmit={form.handleSubmit(order ? onSubmitModify : onSubmitCreate)}>
        <FormProvider {...form}>
          <FastOrderForm
            isLoading={status !== "idle" && status !== "failure"}
            order={order}
            setOrder={setOrder}
            setStatus={setStatus}
            setStatusDialogOpen={setStatusDialogOpen}
          />
        </FormProvider>
      </form>
      {order && (
        <FastOrderStatusModifyDialog
          order={order}
          open={statusDialogOpen}
          setOpen={setStatusDialogOpen}
        />
      )}
    </Container>
  );
};

export default FastOrderCreate;
