import { lazy } from "react";

const ImportIncomingInvoiceCreate = lazy(
  () => import("views/Invoice/ImportIncomingInvoice/ImportIncomingInvoiceCreate"),
);

const SupplierPriceHistoryCreate = lazy(
  () => import("views/SupplierPriceHistory/SupplierPriceHistoryCreate"),
);

const SupplierPriceHistoryDetails = lazy(
  () => import("views/SupplierPriceHistory/SupplierPriceHistoryDetails"),
);

const OwnWarehouse = lazy(() => import("views/Warehouse/OwnWarehouse"));

const WarehouseDetails = lazy(() => import("views/Warehouse/WarehouseDetails"));

const InvoicesTabs = lazy(() => import("views/Invoice/InvoicesTabs"));

const ItemModify = lazy(() => import("views/Items/ItemModify/ItemModify"));
const ItemDetailsTabs = lazy(() => import("views/Items/ItemDetails/ItemDetailsTabs"));
const MyDeliveryTabs = lazy(() => import("views/MyDelivery/MyDeliveryTabs"));
const DeliveryPointList = lazy(() => import("views/DeliveryPoint/DeliveryPointList"));
const DeliveryRoundModify = lazy(() => import("views/DeliveryRound/DeliveryRoundModify"));
const DeliveryRoundItemModify = lazy(() => import("views/DeliveryRound/DeliveryRoundItemModify"));
const DeliveryRoundCreate = lazy(() => import("views/DeliveryRound/DeliveryRoundCreate"));
const MonitoringEntryCreate = lazy(() => import("views/Monitoring/MonitoringEntryCreate"));
const MonitoringEntryModifiy = lazy(() => import("views/Monitoring/MonitoringEntryModify"));

const MonitoringDetails = lazy(() => import("views/Monitoring/MonitoringDetails"));
const MonitoringTabs = lazy(() => import("views/Monitoring/MonitoringTabs"));
const ItemCreate = lazy(() => import("views/Items/ItemCreate/ItemCreate"));
const Items = lazy(() => import("views/Items/ItemsTabs"));
const InvoiceCreate = lazy(() => import("views/Invoice/InvoiceCreate"));
const InvoiceDetails = lazy(() => import("views/Invoice/InvoiceDetails"));
const InvoiceModify = lazy(() => import("views/Invoice/InvoiceModify"));
const IncomingInvoiceCreate = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceCreate"),
);
const IncomingInvoiceDetails = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceDetails"),
);
const IncomingInvoiceModify = lazy(
  () => import("views/Invoice/IncomingInvoice/IncomingInvoiceModify"),
);
const ProfileTabs = lazy(() => import("views/Profile/Profile"));
const Logout = lazy(() => import("views/Logout/Logout"));
const SerialItemCreate = lazy(() => import("views/Items/SerialItems/SerialItemCreate"));
const SerialItemModify = lazy(() => import("views/Items/SerialItems/SerialItemModify"));
const StatisticsTabs = lazy(() => import("views/Statistics/StatisticsTabs"));

export const BASIC_PRIVATE_ROUTES_II = [
  {
    path: "/mydelivery",
    component: MyDeliveryTabs,
    permissions: ["VEHICLE_GET", "DELIVERY_GET", "DELIVERY_ROUND_GET"],
    routes: [
      {
        path: "/delivery-list",
        component: MyDeliveryTabs,
        permissions: ["VEHICLE_GET", "DELIVERY_GET"],
      },
      {
        path: "/delivery-round-list",
        component: MyDeliveryTabs,
        permissions: ["VEHICLE_GET", "DELIVERY_ROUND_GET"],
        routes: [
          {
            path: "/deliveryround-create",
            component: DeliveryRoundCreate,
            permissions: ["DELIVERY_ROUND_ADMIN"],
          },
          {
            path: "/deliverypoint-list",
            component: DeliveryPointList,
            permissions: ["DELIVERY_ROUND_GET"],
          },
          {
            path: "/deliveryround-modify",
            component: DeliveryRoundModify,
            permissions: ["DELIVERY_ROUND_ADMIN"],
          },
          {
            path: "/deliveryround-item-modify",
            component: DeliveryRoundItemModify,
            permissions: ["DELIVERY_ROUND_ADMIN"],
          },
        ],
      },
    ],
  },
  {
    path: "/monitoring",
    component: MonitoringTabs,
    permissions: ["MONITORING_GET", "MONITORING_DEPOSITOR_GET"],
    routes: [
      {
        path: "/dashboard",
        component: MonitoringTabs,
        permissions: ["MONITORING_GET"],
      },
      {
        path: "/machines",
        component: MonitoringTabs,
        permissions: ["MONITORING_GET"],
        routes: [
          {
            path: "/machine-details",
            component: MonitoringDetails,
            permissions: ["MONITORING_GET"],
          },
          {
            path: "/monitoring-entry-create",
            component: MonitoringEntryCreate,
            permissions: ["MONITORING_ADMIN"],
          },
          {
            path: "/monitoring-entry-modify",
            component: MonitoringEntryModifiy,
            permissions: ["MONITORING_ADMIN"],
          },
        ],
      },
      {
        path: "/reports",
        component: MonitoringTabs,
        permissions: ["MONITORING_GET"],
      },
    ],
  },
  {
    path: "/statistics",
    component: StatisticsTabs,
    permissions: ["ITEM_STATISTIC_GET"],
    routes: [
      {
        path: "/item",
        component: StatisticsTabs,
        permissions: ["ITEM_STATISTIC_GET"],
      },
      {
        path: "/delivery-round",
        component: StatisticsTabs,
        permissions: ["ITEM_STATISTIC_GET"],
      },
      {
        path: "/offer",
        component: StatisticsTabs,
        permissions: ["ITEM_STATISTIC_GET"],
      },
      {
        path: "/contract",
        component: StatisticsTabs,
        permissions: ["ITEM_STATISTIC_GET"],
      },
      {
        path: "/order",
        component: StatisticsTabs,
        permissions: ["ITEM_STATISTIC_GET"],
      },
    ],
  },
  {
    path: "/items",
    component: Items,
    permissions: ["ITEM_GET"],
    routes: [
      {
        path: "/serial-item-modify",
        component: SerialItemModify,
        permissions: ["ITEM_ADMIN"],
      },
      {
        path: "/list",
        component: Items,
        permissions: ["ITEM_GET", "ITEM_ADMIN"],
        routes: [
          {
            path: "/item-create",
            component: ItemCreate,
            permissions: ["ITEM_GET", "ITEM_ADMIN"],
          },
          {
            path: "/item-modify",
            component: ItemModify,
            permissions: ["ITEM_GET", "ITEM_ADMIN"],
          },
          {
            path: "/item-details",
            component: ItemDetailsTabs,
            permissions: ["ITEM_GET"],
            routes: [
              {
                path: "/supplier-price-history-create",
                component: SupplierPriceHistoryCreate,
              },
              {
                path: "/supplier-price-history-modify",
                component: SupplierPriceHistoryDetails,
              },
              {
                path: "/details",
                component: ItemDetailsTabs,
                permissions: ["ITEM_GET"],
              },
              {
                path: "/serial-item-list",
                component: ItemDetailsTabs,
                permissions: ["ITEM_GET"],
                routes: [
                  {
                    path: "/serial-item-create",
                    component: SerialItemCreate,
                    permissions: ["ITEM_ADMIN"],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/categories",
        component: Items,
        permissions: ["CATEGORY_ADMIN"],
      },
    ],
  },
  {
    path: "/profile",
    component: ProfileTabs,
    routes: [
      {
        path: "/details",
        component: ProfileTabs,
      },
      {
        path: "/modify",
        component: ProfileTabs,
      },
      {
        path: "/password-modify",
        component: ProfileTabs,
      },
    ],
  },
  {
    path: "/invoices",
    component: InvoicesTabs,
    routes: [
      {
        path: "/incoming-invoice",
        component: InvoicesTabs,
        routes: [
          {
            path: "/invoice-create",
            component: IncomingInvoiceCreate,
            permissions: ["INCOMING_INVOICE_ADMIN"],
          },
          {
            path: "/invoice-details",
            component: IncomingInvoiceDetails,
            permissions: ["INCOMING_INVOICE_ADMIN"],
          },
          {
            path: "/invoice-modify",
            component: IncomingInvoiceModify,
            permissions: ["INCOMING_INVOICE_ADMIN"],
          },
          {
            path: "/import-incoming-invoice-create",
            component: ImportIncomingInvoiceCreate,
            permissions: ["INCOMING_INVOICE_ADMIN"],
          },
        ],
      },
      {
        path: "/invoices-list",
        component: InvoicesTabs,
        routes: [
          {
            path: "/invoice-create",
            component: InvoiceCreate,
            permissions: ["INVOICE_ADMIN"],
          },
          {
            path: "/invoice-details",
            component: InvoiceDetails,
            permissions: ["INVOICE_ADMIN"],
          },
          {
            path: "/invoice-modify",
            component: InvoiceModify,
            permissions: ["INVOICE_ADMIN"],
          },
        ],
      },
    ],
  },
  { path: "/logout", component: Logout },
  {
    path: "/ownwarehouse",
    component: OwnWarehouse,
    routes: [
      {
        path: "/warehouse-details",
        component: WarehouseDetails,
      },
    ],
  },
];
