import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { Report } from "../ReportDetails";
import StatusModify from "./StatusModify";
import { useEffect, useState } from "react";
import { hasAuthority } from "shared/authorization";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import { modifyVisibility } from "shared/network/report.api";
import queryClient from "config/query";
import { useSnackbar } from "notistack";
import Loading from "components/Loading";

type Props = {
  report: Report;
  tenantId?: number;
};

const ReportDetailsCard = ({ report, tenantId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(report.isVisibleForCompany);
  }, [report.isVisibleForCompany]);

  async function onIsVisibleForCompanyChange(isVisibleForCompany: boolean) {
    setLoading(true);
    try {
      await modifyVisibility({ id: report.id, isVisibleForCompany }, selectedRelTenant.tenant?.id);
      queryClient.refetchQueries("getReportByIdQuery");
      enqueueSnackbar(t(`report.visibilityModifySuccess`), { variant: "success" });
    } catch {
      enqueueSnackbar(t(`report.visibilityModifyError`), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Card>
      <Loading open={loading} />
      {tenantId && (
        <StatusModify
          open={open}
          setOpen={setOpen}
          id={report.id}
          tenantId={tenantId}
          defaultValue={report.status}
        />
      )}
      <CardHeader
        title={
          <Box display="flex" justifyContent="space-between">
            <span>{"#" + report?.identifier}</span>
            <FormControlLabel
              label={"Cég által látható"}
              labelPlacement="start"
              control={
                <Checkbox
                  onChange={(e, checked) => {
                    setChecked(checked);
                    onIsVisibleForCompanyChange(checked);
                  }}
                  checked={checked}
                  color="primary"
                />
              }
            />
          </Box>
        }
        subheader={report?.name}
      ></CardHeader>
      <CardContent>
        <Grid container spacing={1}>
          {!!report?.createdOn && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">{t("report.createdOn")}:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>{format(new Date(report.createdOn), "Pp", { locale: hu })} </Typography>
              </Grid>
            </>
          )}
          {!!report?.status && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">{t("report.reportStatus")}:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" gridGap={8}>
                  <Typography>{t(`report.status.${report?.status}`)} </Typography>
                  {!!tenantId &&
                    hasAuthority(account.user, account.permissions, selectedRelTenant, [
                      "REPORT_ADMIN",
                    ]) && (
                      <IconButton size="small" color="primary" onClick={() => setOpen(true)}>
                        <Edit fontSize="small" />
                      </IconButton>
                    )}
                </Box>
              </Grid>
            </>
          )}
          {!!(report?.lastName || report?.firstName) && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">{t("report.firstLastName")}:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  {report?.lastName} {report?.firstName}
                </Typography>
              </Grid>
            </>
          )}
          {!!report?.email && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">{t("report.email")}:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>{report?.email}</Typography>
              </Grid>
            </>
          )}
          {!!report?.phone && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">{t("report.phone")}:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>{report?.phone}</Typography>
              </Grid>
            </>
          )}
          {report?.hasOlderReportSameIssue !== null && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  A bejelentő tudomása szerint volt-e, van-e a konkrét ügyben bírósági, hatósági
                  eljárás folyamatban?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>{report?.hasOlderReportSameIssue ? "Igen" : "Nem"}</Typography>
              </Grid>
            </>
          )}
          {report?.hasAnyOtherKnownCourtCase !== null && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  A bejelentőnek volt-e korábban azonos ügyben bejelentése?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>{report?.hasAnyOtherKnownCourtCase ? "Igen" : "Nem"}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
          {!!report?.name && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">{t("report.name")}:</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>{report?.name}</Typography>
              </Grid>
            </>
          )}
          {!!report?.description && (
            <>
              <Grid item xs={12} sm={12}>
                <Typography variant="body2">{t("report.description")}:</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography>{report?.description}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12}>
            <Typography>{report?.description}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>{report?.description}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReportDetailsCard;
