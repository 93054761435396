import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";

import { COLORS } from "config/theme";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DeliverySerialItemEntry } from "./DeliverySerialItemDialog";

type Props = {
  deliveryItem: DeliverySerialItemEntry;
  index: number;
};

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
    height: 50,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const DeliverySerialItemRow = ({ deliveryItem, index }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.row}>
      <Grid item xs={3} className={classes.cell}>
        <Typography className={classes.text}>
          {`${deliveryItem?.item?.name}`}
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.cell}>
        <Typography className={classes.text}>
          {deliveryItem?.item?.productCode}
        </Typography>
      </Grid>
      <Grid item xs={3} className={classes.cell}>
        <Controller
          name={`itemList.${index}.serialNumber`}
          defaultValue={""}
          rules={{
            required: t("validation.required").toString(),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              hiddenLabel
              InputLabelProps={{ shrink: true, required: true }}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} className={classes.cell}>
        <Typography className={classes.text}>
          {t("common:number", { num: deliveryItem.price }) + " Ft"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DeliverySerialItemRow;
