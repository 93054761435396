import { faCheckCircle, faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, makeStyles, Tooltip } from "@material-ui/core";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listCurrencyByTenant } from "shared/network/currency.api";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

const useStyles = makeStyles({
  divider: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
  },
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const CurrencyList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-warehouse-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-warehouse-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const currencyListByTenant = useQuery(
    ["currencyListByTenant", page, pageSize, selectedRelTenant.tenant.id, filterValue, sortState],
    async () => {
      const { data } = await listCurrencyByTenant(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: t("currency.formValues.code"),
      flex: 1,
    },
    {
      field: "name",
      headerName: t("currency.formValues.name"),
      flex: 1,
    },
    {
      field: "prefix",
      headerName: t("currency.formValues.prefix"),
      flex: 1,
    },
    {
      field: "postfix",
      headerName: t("currency.formValues.postfix"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          {row.id === selectedRelTenant.tenant.baseCurrency.id && (
            <Tooltip title={t("currency.isBaseCurrency").toString()} className={classes.text}>
              <div style={{ display: "flex", height: 20 }}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    fontSize: "20px",
                    marginRight: 8,
                    color: COLORS.green,
                  }}
                />
              </div>
            </Tooltip>
          )}
          {/*hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "CURRENCY_ADMIN",
          ]) && (
            <Tooltip
              title={t("currency.modify").toString()}
              className={classes.text}
            >
              <IconButton
                component={Link}
                to={`/currency-modify/${row.id}`}
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
          )*/}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["CURRENCY_ADMIN"]) && (
          <Button component={Link} to="/admin/currencies/currency-create">
            <FontAwesomeIcon
              icon={faCoins}
              style={{
                fontSize: "20px",
                marginRight: 4,
              }}
            />
            {t("currency.addCurrency")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  return (
    <Container maxWidth="lg">
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="warehouse"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={currencyListByTenant}
        minWidth={{ value: "sm" }}
      />
    </Container>
  );
};
export default CurrencyList;
