import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Check, Close, Edit } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { parseInt } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { modifyDelivery } from "shared/network/delivery.api";
import { listSerialItemsForWarehouse } from "shared/network/serial-item.api";
import { Delivery, RelItemDelivery, SerialItemEntry } from "shared/types";
import { DeliveryItemFormValues } from "./DeliveryItemDialog";

type Props = {
  deliveryItem: RelItemDelivery;
  deliveryStatus: string;
  refetch: () => void;
  warehouseId: string;
  itemList: RelItemDelivery[];
  selectedDelivery: Delivery;
  queryLoading?: boolean;
};

const useStyles = makeStyles({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const DeliveryItemsRow = ({
  deliveryItem,
  deliveryStatus,
  refetch,
  queryLoading,
  warehouseId,
  itemList,
  selectedDelivery,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [rowModified, setRowModified] = useState<number | string | null>(null);
  const [newQuantity, setNewQuantity] = useState<number | string>(deliveryItem.quantity);
  const [loading, setLoading] = useState(false);
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const isSerialItem = deliveryItem?.item?.type === "SERIAL_NUMBER";
  const form = useForm<DeliveryItemFormValues>();

  const avaibleSerialItemList = useQuery(
    ["avaibleSerialItemListQueryForDeliveryItemRow", tenant.id, deliveryItem, rowModified],
    async () => {
      const { data } = await listSerialItemsForWarehouse(
        0,
        100000,
        tenant.id,
        deliveryItem?.item?.id.toString() || "0",
        warehouseId,
      );
      return data.page.content;
    },
    { enabled: !!rowModified && isSerialItem },
  );

  async function saveRow() {
    setLoading(true);
    try {
      await modifyDelivery(
        {
          deliveryEntry: selectedDelivery,
          relList: [
            ...itemList.filter(item => item.id !== deliveryItem?.id),
            { ...deliveryItem, quantity: parseInt(newQuantity?.toString()) },
          ],
        },
        tenant.id,
      );
      refetch();
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("order.quantity").toLowerCase(),
        }),
        {
          variant: "success",
        },
      );
    } catch (error: any) {
      if (error.data.status === "DELIVERY_CANNOT_BE_MODIFIED") {
        enqueueSnackbar("A szállítást már nem lehet módosítani!", { variant: "error" });
      } else if ((error.data.status as string)?.includes("INSUFFICIENT_QUANTITY")) {
        enqueueSnackbar("Nincs elég termék!", { variant: "error" });
      } else {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("deliveryItem.subject"),
          }),
          { variant: "error" },
        );
      }
    }
    setLoading(false);
  }

  const saveSerialNumber = async (values: DeliveryItemFormValues) => {
    setLoading(true);
    try {
      if (!!form.getValues("factoryNumber")) {
        await modifyDelivery(
          {
            deliveryEntry: selectedDelivery,
            relList: [
              ...itemList?.map(item => {
                return item.id !== deliveryItem?.id
                  ? { ...item, deliveryId: selectedDelivery.id }
                  : {
                      ...deliveryItem,
                      item: values.item,
                      quantity: isSerialItem ? 1 : values.quantity,
                      price: values.price,
                      deliveryId: selectedDelivery.id,
                      itemSerial: values.factoryNumber || undefined,
                    };
              }),
            ],
          },
          tenant.id,
        );
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("deliveryItem.subject"),
          }),
          {
            variant: "success",
          },
        );
        refetch();
      } else {
        throw new Error();
      }
    } catch (error: any) {
      if (error.data?.status === "QUANTITY-NOT-NUMBER") {
        form.setError("quantity", new Error(t("common:validation.numberFormat").toString()));
      } else {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("deliveryItem.subject"),
          }),
          { variant: "error" },
        );
      }
    }
    setLoading(false);
  };

  return (
    <Grid container alignItems="center" style={{ borderBottom: `1px solid ${COLORS.mainGrey}` }}>
      <Loading open={loading} />
      <Grid item xs={4} style={{ padding: 8 }}>
        <Typography className={classes.text}>
          {`${deliveryItem.item.name} (${deliveryItem.item.productCode})`}
        </Typography>
      </Grid>
      <Grid item xs={2} style={{ padding: 8 }}>
        <Typography className={classes.text}>
          {deliveryItem?.type === "NORMAL" ? "Leadás" : "Felvétel" || "-"}
        </Typography>
      </Grid>
      <Grid item xs={2} style={{ padding: 8 }}>
        <Typography className={classes.text}>
          {deliveryItem?.itemSerial?.factoryNumber || "-"}
        </Typography>
      </Grid>
      <Grid item xs={1} style={{ display: "flex", alignItems: "center", padding: 8, gridGap: 8 }}>
        <>
          <Typography className={classes.text}>
            {t("common:number", { num: deliveryItem.quantity })}
          </Typography>
        </>
      </Grid>
      <Grid item xs={3}>
        {rowModified === deliveryItem.id ? (
          <Box display="flex" gridGap={8} p={1}>
            {rowModified === deliveryItem.id && !isSerialItem && (
              <TextField
                defaultValue={deliveryItem.quantity || "-"}
                onChange={event => setNewQuantity(event.target.value)}
                disabled={loading || queryLoading}
              />
            )}
            {deliveryItem?.item?.type === "SERIAL_NUMBER" && (
              <Controller
                control={form.control}
                name="factoryNumber"
                rules={{
                  required: t("validation.required").toString(),
                }}
                defaultValue={deliveryItem?.itemSerial || null}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    onChange={(_, value) => field.onChange(value)}
                    options={avaibleSerialItemList.data || []}
                    getOptionLabel={(option: SerialItemEntry) => option.factoryNumber}
                    getOptionSelected={option => option.id === field.value?.id}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                          required: true,
                        }}
                      />
                    )}
                  />
                )}
              />
            )}
            <Tooltip title={t("common:button.save").toString()}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  setRowModified(null);
                  !isSerialItem
                    ? saveRow()
                    : saveSerialNumber({
                        item: deliveryItem.item,
                        quantity: deliveryItem.quantity,
                        price: deliveryItem.price,
                        factoryNumber: form.watch("factoryNumber"),
                      });
                }}
                disabled={loading || queryLoading}
              >
                <Check color="primary" />
              </IconButton>
            </Tooltip>
            <IconButton
              size="small"
              color="primary"
              onClick={() => setRowModified(null)}
              disabled={loading || queryLoading}
            >
              <Close color="primary" />
            </IconButton>
          </Box>
        ) : (
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Tooltip
              title={t(
                isSerialItem ? "delivery.modifyQuantity" : "delivery.modifyFactoryNumber",
              ).toString()}
              className={classes.text}
            >
              <span>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ margin: "0 8px" }}
                  onClick={() => setRowModified(deliveryItem.id)}
                  disabled={
                    ["BILLABLE", "BILLED", "DELIVERED", "WITHDRAWN"].includes(deliveryStatus) ||
                    loading ||
                    queryLoading
                  }
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DeliveryItemsRow;
