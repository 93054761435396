import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAnyAuthority } from "shared/authorization";
import OfferList from "./OfferList";
import { isEmpty } from "lodash";
import PdfTemplateList from "components/PdfTemplate/PdfTemplateList";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";

export const OFFER_TABS = ["/offer/out", "/offer/in", "/offer/pdf-template"];

const OfferTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/offer" &&
        (account.user.relUserCompany?.[0]?.company?.isSupplier ? (
          <Redirect to="/offer/out?isIncoming=false" />
        ) : account.user.relUserCompany?.[0]?.company?.isCustomer ? (
          <Redirect to="/offer/in?isIncoming=true" />
        ) : (
          <Redirect to="/offer/out?isIncoming=false" />
        ))}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_GET"]) &&
          (isEmpty(account.user.relUserCompany) ||
            account.user.relUserCompany?.[0]?.company?.isSupplier) && (
            <Tab
              label={t("offer.out.title")}
              component={Link}
              to={`${OFFER_TABS[0]}?isIncoming=false`}
              value={OFFER_TABS[0]}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_GET"]) &&
          (isEmpty(account.user.relUserCompany) ||
            account.user.relUserCompany?.[0]?.company?.isCustomer) && (
            <Tab
              label={t("offer.in.title")}
              component={Link}
              to={`${OFFER_TABS[1]}?isIncoming=true`}
              value={OFFER_TABS[1]}
            />
          )}
        {hasAnyAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) &&
          !!selectedRelTenant?.tenant?.pdfTemplateEnabled &&
          (isEmpty(account.user.relUserCompany) ||
            account.user.relUserCompany?.[0]?.company?.isCustomer) && (
            <Tab
              label={t("offer.pdf.title")}
              component={Link}
              to={`${OFFER_TABS[2]}`}
              value={OFFER_TABS[2]}
            />
          )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute path={OFFER_TABS[0]} component={OfferList} permissions={["OFFER_GET"]} />
            <PrivateRoute path={OFFER_TABS[1]} component={OfferList} permissions={["OFFER_GET"]} />
            {!!selectedRelTenant?.tenant?.pdfTemplateEnabled && (
              <PrivateRoute
                path={OFFER_TABS[2]}
                component={PdfTemplateList}
                permissions={["OFFER_ADMIN"]}
              />
            )}
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default OfferTabs;
