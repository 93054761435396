import { Container } from "@material-ui/core";
import TaxTypeForm, { TaxTypeFormValues } from "./TaxTypeForm";
import { FormProvider, useForm } from "react-hook-form";
import { createTaxEntry } from "shared/network/tax-api";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { SliceStatus } from "shared/common";
import { useState } from "react";
import Loading from "components/Loading";

const TaxTypeCreate = () => {
  const form = useForm<TaxTypeFormValues>();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();

  const [status, setStatus] = useState<SliceStatus>("idle");

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  async function onSubmitCreate(values: TaxTypeFormValues) {
    try {
      setStatus("pending");
      await createTaxEntry(values, tenant.id);
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("taxType.subject"),
        }),
        {
          variant: "success",
        },
      );
      history.goBack();
      setStatus("success");
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("taxType.subject"),
        }),
        {
          variant: "error",
        },
      );
      setStatus("failure");
    }
  }

  return (
    <Container maxWidth="md">
      <Loading open={status === "pending"} />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmitCreate)}>
          <TaxTypeForm />
        </form>
      </FormProvider>
    </Container>
  );
};

export default TaxTypeCreate;
