import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, GridCellParams, GridColDef, MuiEvent } from "@mui/x-data-grid";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { Dispatch, SetStateAction, SyntheticEvent } from "react";
import { UseQueryResult } from "react-query";
import { useSelector } from "react-redux";
import { GenericListResponse } from "shared/common";
import { TableState } from "views/Company/CompanyList";
import { FilterOptionsEntry } from "./FilterBar";
import { huHU } from "./huHU";

export type FilterOptionsType = {
  translated: string;
  value: string;
};

type Props = {
  transparent?: boolean;
  columns: GridColDef[];
  query: UseQueryResult<GenericListResponse<any>, unknown>;
  setTableState: Dispatch<SetStateAction<TableState>>;
  onCellClick?: (params: GridCellParams, event: MuiEvent<SyntheticEvent<Element, Event>>) => void;
  noSearch?: boolean;
  filterable?: string[];
  filterOptions?: FilterOptionsEntry[];
  sessionStorageKey: string;
  tableState: TableState;
};

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      background: COLORS.mainGrey,
    },
    "& .MuiDataGrid-footerContainer": {
      background: COLORS.mainGrey,
    },
    "& .MuiDataGrid-windowContainer": {
      oveflow: "hidden",
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "unset",
    },
  },
});

const SingleQueryTable = ({
  transparent,
  query,
  columns,
  onCellClick,
  noSearch,
  filterOptions,
  filterable,
  setTableState,
  sessionStorageKey,
  tableState,
}: Props) => {
  const classes = useStyles();
  const { id } = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);

  function onPageChange(newPage: number) {
    sessionStorage.setItem(`rap-${sessionStorageKey}-page-number-${id}`, JSON.stringify(newPage));
    setTableState(prevState => {
      return { ...prevState, page: newPage };
    });
  }

  function onPageSizeChange(pageSize: number) {
    sessionStorage.setItem(`rap-${sessionStorageKey}-page-size-${id}`, JSON.stringify(pageSize));
    setTableState(prevState => {
      return {
        ...prevState,
        page: 0,
        pageSize,
      };
    });
  }

  return (
    <Card
      style={{
        width: "100%",
        backgroundColor: transparent ? "unset" : "rgba(255, 255, 255, 0.6)",
        border: transparent ? "unset" : undefined,
      }}
    >
      {/* <FilterBar
          filterOptions={filterOptions}
          columns={columns}
          filterable={filterable}
          setTableState={setTableState}
        /> */}
      <DataGrid
        className={classes.root}
        autoHeight
        localeText={huHU}
        loading={query.isFetching}
        error={query.isError ? true : undefined}
        rows={query.data?.items || []}
        columns={columns}
        rowCount={query.data?.items?.length || 0}
        rowsPerPageOptions={[10, 20, 50, 100]}
        pagination
        paginationMode="client"
        disableColumnMenu
        disableSelectionOnClick
        onCellClick={onCellClick}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        page={tableState.page}
        pageSize={tableState.pageSize}
      />
    </Card>
  );
};

export default SingleQueryTable;
