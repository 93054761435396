import { RootState } from "config/store";
import { useSelector } from "react-redux";

export function getCommentSubjectType(pathname: string) {
  if (pathname.includes("/my-company/users/user-details")) {
    return "USER";
  } else if (pathname.includes("/my-company/tools/tool-details")) {
    return "TOOL";
  } else if (pathname.includes("/contact-details")) {
    return "CONTACT";
  } else if (pathname.includes("/issue-details")) {
    return "ISSUE";
  } else if (pathname.includes("item-details/details")) {
    return "ITEM";
  } else if (pathname.includes("/incoming-invoice-details")) {
    return "INCOMING_INVOICE";
  } else if (pathname.includes("/invoice-details")) {
    return "INVOICE";
  } else if (pathname.includes("/vehicles/list/vehicle-details")) {
    return "VEHICLE";
  } else if (pathname.includes("my-company/employees/employee-details")) {
    return "EMPLOYEE";
  } else if (pathname.includes("contract/details")) {
    return "CONTRACT";
  } else if (pathname.includes("haccp-check")) {
    return "HACCP_CHECK";
  } else if (pathname.includes("companies/list-all/company-details/details")) {
    return "COMPANY";
  } else if (pathname.includes("report")) {
    return "REPORT";
  } else if (pathname.includes("warehouse-details")) {
    return "WAREHOUSE";
  } else if (
    pathname.includes("/project/details/milestone/details") ||
    pathname.includes("project/details/data/milestone-details")
  ) {
    return "MILESTONE";
  } else if (pathname.includes("offer/out/details") || pathname.includes("offer/in/details")) {
    return "OFFER";
  }

  switch (pathname) {
    case "/profile/details":
      return "USER_PROFILE";
    case "/project/details/data":
      return "PROJECT";
    default:
      return "";
  }
}

export function getSubjectId(
  subjectType: string,
  accountId: string,
  queryId?: string | null,
  notQueryId?: string | null,
) {
  switch (subjectType) {
    case "USER_PROFILE":
      return accountId;
    case "USER":
      return notQueryId;
    case "TOOL":
      return notQueryId;
    case "CONTACT":
      return notQueryId;
    case "ISSUE":
      return notQueryId;
    case "ITEM":
      return notQueryId;
    case "INCOMING_INVOICE":
      return notQueryId;
    case "INVOICE":
      return notQueryId;
    case "WAREHOUSE":
      return notQueryId;
    case "HACCP_CHECK":
      return notQueryId;
    default:
      return queryId;
  }
}

export function useGetSessionStorageKey(text: string) {
  const tenantId = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant?.tenant,
  )?.id;
  return `${text}-${tenantId}`;
}
