import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import { useHeader } from "components/Layout/HeaderContext";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import BankAccountList from "./BankAccountList";

const useStyles = makeStyles({
  cardHeader: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
  },
  cardContainer: {
    padding: 12,
    width: "100%",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    marginBottom: 16,
  },
});

type Props = {
  isCompany: boolean;
  isAll?: boolean;
  isCustomer?: boolean;
};

const BankAccountListPage = ({ isCompany = false, isAll = false, isCustomer = false }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { t } = useTranslation();
  const from = query.get("from");

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const { setHeaderButtons } = useHeader();

  useEffect(() => {
    !isCompany &&
      setHeaderButtons(
        <Box display="flex" gridGap={12}>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "BANK_ACCOUNT_ADMIN",
          ]) && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                style={{ marginBottom: 8 }}
                component={Link}
                to={
                  id
                    ? isCompany
                      ? `/companies/${
                          isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                        }/company-details/details/bank-account-create?id=${id}&from=${from}`
                      : `/admin/tenants/tenant-details/bank-accounts/bank-account-create`
                    : `/admin/tenants/tenant-details/bank-accounts/bank-account-create`
                }
                startIcon={<AddBox />}
              >
                {t("bankAccount.create")}
              </Button>
            </Box>
          )}
        </Box>,
      );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  return (
    <>
      {isCompany && (
        <Box className={classes.cardHeader}>
          <Typography variant="h2">{t("company.tabs.bankAccount")}</Typography>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "BANK_ACCOUNT_ADMIN",
          ]) && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                size="small"
                variant="text"
                component={Link}
                to={
                  id
                    ? isCompany
                      ? `/companies/${
                          isAll ? "list-all" : isCustomer ? "list-customer" : "list-supplier"
                        }/company-details/details/bank-account-create?id=${id}${
                          from ? `&from=${from}` : ""
                        }`
                      : `/admin/tenants/tenant-details/bank-accounts/bank-account-create`
                    : `/admin/tenants/tenant-details/bank-accounts/bank-account-create`
                }
                startIcon={<AddBox />}
              >
                {t("bankAccount.create")}
              </Button>
            </Box>
          )}
        </Box>
      )}
      {from === "tenant" ? (
        <BankAccountList tenantId={id} transparent />
      ) : (
        <BankAccountList id={id} isAll={isAll} isCustomer={isCustomer} transparent />
      )}
    </>
  );
};

export default BankAccountListPage;
