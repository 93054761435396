import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PasswordTextField from "components/PasswordTextField";
import { Link as RouterLink } from "components/Router";
import { PASSWORD_POLICY_REGEX } from "config/constants";
import { COLORS, GRADIENT } from "config/theme";
import { useSnackbar } from "notistack";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { getSystemProperties } from "shared/network/properties.api";
import { forgottenPasswordFinish } from "shared/network/user.api";
import { getPageName } from "shared/util/getPageName";

type FormValues = {
  newPassword: string;
  newPasswordConfirm: string;
};

const useStyles = makeStyles(
  {
    root: {
      display: "flex",
      justifyContent: "center",
      color: COLORS.white,
      background: GRADIENT,
    },
  },
  {
    name: "Login",
  },
);

const REDIRECT_TIME = 10000;

const ResetPassword = ({ match: { params } }: RouteComponentProps<{ token: string }>) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const classes = useStyles();

  const { control, handleSubmit, watch } = useForm<FormValues>();

  const { newPassword, newPasswordConfirm } = watch();

  const [status, setStatus] = React.useState<SliceStatus>("idle");

  const query = useQuery("version", async () => {
    const { data } = await getSystemProperties();
    return data.items;
  });
  const version = query.data?.find(property => property.name === "version")?.value;

  async function onSubmit(values: FormValues) {
    setStatus("pending");
    try {
      await forgottenPasswordFinish({
        newPassword: values.newPassword,
        newPassword2: values.newPasswordConfirm,
        token: params.token,
      });
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("forgottenPassword.password"),
        }),
        {
          variant: "success",
        },
      );
      setStatus("success");
      history.push("/login");
    } catch {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("forgottenPassword.password"),
        }),
        {
          variant: "error",
        },
      );
      setStatus("failure");
    }
  }

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        height="100vh"
        width={500}
      >
        <Typography variant="h1">{getPageName(window.location.hostname)}</Typography>
        <Card elevation={10} style={{ borderRadius: 10, width: 400 }}>
          <CardHeader title={t("forgottenPassword.newTitle")} />
          <CardContent style={{ paddingTop: 0 }}>
            {status === "success" && (
              <>
                <Alert severity="success">
                  {t("signUp.finish.success", { time: REDIRECT_TIME / 1000 })}
                </Alert>
                <Box p={1}>
                  <Link component={RouterLink} to="/login">
                    {t("common:button.login")}
                  </Link>
                </Box>
              </>
            )}
            {status === "failure" && <Alert severity="error">{t("newTitle.finish.error")}</Alert>}
            {status !== "success" && (
              <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Controller
                    control={control}
                    name="newPassword"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: t("validation.required"),
                      },
                      validate: value => {
                        if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                          return t("common:passwordPolicy").toString();
                        }
                        if (value !== newPasswordConfirm) {
                          return t("common:passwordMismatch").toString();
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <PasswordTextField
                        {...field}
                        ref={undefined}
                        type="password"
                        label={t("registration.formValues.password")}
                        style={{ maxWidth: 400 }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="newPasswordConfirm"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: t("validation.required"),
                      },
                      validate: value => {
                        if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                          return t("common:passwordPolicy").toString();
                        }
                        if (value !== newPassword) {
                          return t("common:passwordMismatch").toString();
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <PasswordTextField
                        {...field}
                        ref={undefined}
                        type="passwordConfirmation"
                        label={t("registration.formValues.passwordConfirm")}
                        style={{ maxWidth: 400 }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
                <Box display="flex" pt={2} justifyContent="space-evenly">
                  <Button type="submit">{t("common:button.submit")}</Button>
                </Box>
              </form>
            )}
          </CardContent>
        </Card>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography style={{ fontSize: 12, fontWeight: 300 }}>
            {t("login.supportedBrowsers")}: Mozilla Firefox, Microsoft Edge, Google Chrome
          </Typography>
          <Typography style={{ fontSize: 12 }}>
            ©&nbsp;
            {new Date().getFullYear() === 2021
              ? new Date().getFullYear()
              : `2021 - ${new Date().getFullYear()}`}
            &nbsp;
            <a
              style={{ color: COLORS.white, fontWeight: 300 }}
              href="https://promera.hu"
              target="_blank"
              rel="noopener noreferrer"
            >
              Promera Menedzsment Kft.
            </a>
            &nbsp;
            {!!version && t("login.version", { version })}
            {t("login.allRightsReserved")}
          </Typography>
          <Typography style={{ fontSize: 12, fontWeight: 300 }}>
            <RouterLink to="/tos" style={{ color: COLORS.white }}>
              {t("login.tos")}
            </RouterLink>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default ResetPassword;
