import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { SerialItem } from "shared/types";
import { SerialItemFormValues } from "views/Items/SerialItems/SerialItemForm";

const ENDPOINT = "/item-serial";

export const serialItemCreate = (
  tenantId: number,
  param: SerialItemFormValues,
) =>
  axios.post(ENDPOINT, {
    tenantId,
    param,
  }); //ITEM_ADMIN

export const serialItemModify = (
  tenantId: number,
  param: SerialItemFormValues,
) =>
  axios.put(ENDPOINT, {
    tenantId,
    param,
  }); //ITEM_ADMIN*/

export const getSerialItemById = (tenantId: number, param: string) =>
  axios.post<{ item: SerialItem }>(`${ENDPOINT}/get-by-id`, {
    tenantId,
    param,
  }); //ITEM_GET

export const listSerialItems = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<SerialItem>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //ITEM_GET

export const listSerialItemsForWarehouse = (
  page: number,
  size: number,
  tenantId: number,
  itemID: string,
  warehouseId: string,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<SerialItem>>(
    `${ENDPOINT}/page-for-warehouse?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}&itemId=${itemID}&warehouseId=${warehouseId}`,
  ); //ITEM_GET
