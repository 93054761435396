import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { updateOrderStatus } from "shared/network/order.api";
import { Order } from "shared/types";

export type InvoiceStatusFormValues = {
  tenantId: string;
  status: string;
  invoiceId: string;
};

type Props = {
  order: Order | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ORDER_STATUSES = [
  "NEW", //felvéve
  "SCHEDULED", //betervezve
  "IN_PROGRESS", //Folyamatban
  "DELIVERED", //kiszállítva
  "CANCELLED",
];

const FastOrderStatusModifyDialog = ({ order, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<InvoiceStatusFormValues>();

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [loading, setLoading] = useState(false);

  const onSubmitCreate = async (values: InvoiceStatusFormValues) => {
    setLoading(true);
    if (order) {
      try {
        await updateOrderStatus(order?.id, values.status, tenant.id);
        enqueueSnackbar(t("common:notification.save.success"), { variant: "success" });
        queryClient.refetchQueries("getOrderByIdQuery");
        setOpen(false);
      } catch (e: any) {
        enqueueSnackbar(t("common:notification.save.failure"), { variant: "error" });
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Loading open={loading} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t("invoice.modifyStatus")}</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form id="fast-order-status" onSubmit={form.handleSubmit(onSubmitCreate)}>
              <Grid container spacing={2} style={{ width: 250 }}>
                <Grid item xs={12}>
                  <Controller
                    name="status"
                    defaultValue={order?.status || ""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        select
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("invoice.formValues.status.title")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      >
                        {ORDER_STATUSES.map((status, index) => (
                          <MenuItem key={index} value={status}>
                            {t(`order.statuses.${status}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" gridGap={8}>
            <Button color="primary" variant="text" onClick={() => setOpen(false)}>
              {t("common:button.cancel")}
            </Button>
            <Button form="fast-order-status" type="submit" color="primary">
              {t("common:button.save")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FastOrderStatusModifyDialog;
