import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { User } from "shared/types";

export type CheckSubDepartment = {
  id: number;
  tenantId: number;
  subDepartmentId: number;
  date: Date;
  validator: User;
  createdBy: User;
  updatedBy: number;
};

const ENDPOINT = "/check-sub-department";

export const createCheckSubDepartment = (param: any, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

export const modifyCheckSubDepartment = (param: any, tenantId: number) =>
  axios.put(ENDPOINT, { param, tenantId });

export const getCheckSubDepartmentPage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.get<GenericPageResponse<CheckSubDepartment>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search || ""}&sort=${
      sort || ""
    }`,
  );

export const getCheckSubDepartment = (id: number, tenantId: number) =>
  axios.get<GenericResponse<CheckSubDepartment>>(
    `${ENDPOINT}/get-by-id?id=${id}&tenantId=${tenantId}`,
  );
export const setValidator = (date: any, tenantId: number) =>
  axios.post(`${ENDPOINT}/validator-set`, {
    param: { date },
    tenantId,
  });
