import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loading from "components/Loading";
import { PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY } from "config/constants";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  RelDailyReportVehicle,
  createRelDailyReportVehicle,
} from "shared/network/rel-daily-report-vehicle.api";
import { listUsers } from "shared/network/user.api";
import { getVehicleListPageable } from "shared/network/vehicle.api";
import { User, Vehicle } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import { ListboxComponent } from "views/Project/components/ProjectOfferAddDialog";

type Props = {
  dailyReportId?: number;
  open: boolean;
  onClose: () => void;
};

type FormValues = {
  id: number;
  tenantId: number;
  dailyReportId: number;
  driverUser: User | null;
  vehicle: any; //Vehicle;
  invoiceId: number;
  mileage: string | number;
  isRefuelled: boolean;
  fuelQuantity: string | number;
  fuelPrice: string | number;
};

const DailyReportVehicleDialog = ({ dailyReportId, open, onClose }: Props) => {
  const { t } = useTranslation();
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const form = useForm<FormValues>();
  const { control, handleSubmit, watch } = form;

  const [itemSearch, setItemSearch] = useState<string>("");
  const [userSearch, setUserSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createRelDailyReportVehicle(
        { ...values, dailyReportId } as RelDailyReportVehicle,
        tenant?.id,
      );
      queryClient.invalidateQueries([`listRelDailyReportVehicleQuery`, tenant?.id]);
      onClose();
    } catch {}
    setLoading(false);
  }

  const { data, fetchNextPage } = useInfiniteQuery(
    [`getVehicleListPageableQuery`, tenant.id, itemSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await getVehicleListPageable(
        pageParam,
        20,
        tenant.id,
        itemSearch ? `;licensePlateNumber:${itemSearch}` : "",
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatVehiclePages = data?.pages.map(page => page.page?.content).flat();
  const hasVehicleNextPage =
    data && flatVehiclePages && data?.pages?.[0]?.page?.totalElements > flatVehiclePages?.length;

  const handleVehicleSearchChange = useDebouncedCallback((value: string) => {
    setItemSearch(value);
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const { data: userData, fetchNextPage: fetchUserNextPage } = useInfiniteQuery(
    [`getUserListPageableQuery`, tenant.id, userSearch],
    async ({ pageParam = 0 }) => {
      const { data } = await listUsers(
        pageParam,
        20,
        tenant.id,
        userSearch ? `;name:${userSearch}` : "",
      );
      return data;
    },
    {
      getNextPageParam: lastGroup => {
        const nextPage = lastGroup.page.number + 1;
        if (nextPage <= lastGroup.page.totalPages - 1) {
          return nextPage;
        }
        return false;
      },
    },
  );

  const flatUserPages = userData?.pages.map(page => page.page?.content).flat();
  const hasUserNextPage =
    data &&
    flatUserPages &&
    (userData?.pages?.[0]?.page?.totalElements || 0) > flatUserPages?.length;

  const handleUserSearchChange = useDebouncedCallback((value: string) => {
    setUserSearch(value);
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const isRefuelled = watch("isRefuelled");

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <Loading open={loading} />
      <DialogTitle>{t("dailyReport.addVehicle")}</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <form id="project-item-use-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="vehicle"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        handleVehicleSearchChange("");
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleVehicleSearchChange(newInputValue);
                      }}
                      options={flatVehiclePages || []}
                      getOptionLabel={(option: Vehicle) => option.licensePlateNumber}
                      getOptionSelected={option => option.id === field.value?.id}
                      ListboxComponent={props => (
                        <ListboxComponent
                          {...props}
                          hasNextPage={hasVehicleNextPage}
                          fetchNextPage={fetchNextPage}
                        />
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("cargo.formValues.vehicle")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="driverUser"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        handleUserSearchChange("");
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleUserSearchChange(newInputValue);
                      }}
                      options={flatUserPages || []}
                      getOptionLabel={(option: User) => {
                        return option?.name || "";
                      }}
                      getOptionSelected={option => option.id === field.value?.id}
                      ListboxComponent={props => (
                        <ListboxComponent
                          {...props}
                          hasNextPage={hasUserNextPage}
                          fetchNextPage={fetchUserNextPage}
                        />
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("vehicle.formValues.user")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="mileage"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      margin="none"
                      type="number"
                      label={t("dailyReport.mileage")}
                      InputLabelProps={{ shrink: true, required: true }}
                      InputProps={{
                        endAdornment: <Typography>{"km"}</Typography>,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="isRefuelled"
                  defaultValue={false}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      label={t("dailyReport.isRefuelled")}
                      control={
                        <Checkbox
                          onChange={(_, checked) => onChange(checked)}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Collapse in={isRefuelled}>
                  <Controller
                    control={control}
                    name="fuelQuantity"
                    defaultValue=""
                    rules={{ required: isRefuelled && t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        margin="none"
                        type="number"
                        label={t("dailyReport.fuelQuantity")}
                        InputLabelProps={{ shrink: true, required: isRefuelled }}
                        InputProps={{
                          endAdornment: <Typography>{t("dailyReport.liter")}</Typography>,
                        }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Collapse in={isRefuelled}>
                  <Controller
                    control={control}
                    name="fuelPrice"
                    defaultValue=""
                    rules={{ required: isRefuelled && t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        margin="none"
                        type="number"
                        label={t("dailyReport.fuelPrice")}
                        InputLabelProps={{ shrink: true, required: isRefuelled }}
                        InputProps={{
                          endAdornment: <Typography>{tenant.baseCurrency?.code}</Typography>,
                        }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Collapse>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => onClose()}>
          {t("common:button.cancel")}
        </Button>
        <Button form="project-item-use-form" type="submit">
          {t("common:button.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DailyReportVehicleDialog;
