import axios from "config/axios";
import { SPECIAL_PDF_PIECE_FIELDS } from "config/constants";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { PdfTemplate } from "shared/types";

const ENDPOINT = "/pdftemplate";

export const createPdfTemplate = (pdfTempalte: Partial<PdfTemplate>, tenantId: number) =>
  axios.post(`${ENDPOINT}`, {
    param: {
      ...pdfTempalte,
      templatePieces: pdfTempalte.templatePieces?.map(piece => {
        return {
          ...piece,
          position: SPECIAL_PDF_PIECE_FIELDS?.includes(piece.type) ? 0 : piece.position,
        };
      }),
    },
    tenantId,
  });

export const modifyPdfTemplate = (pdfTempalte: Partial<PdfTemplate>, tenantId: number) =>
  axios.post(`${ENDPOINT}/modify`, {
    param: {
      ...pdfTempalte,
      templatePieces: pdfTempalte.templatePieces?.map(piece => {
        return {
          ...piece,
          position: SPECIAL_PDF_PIECE_FIELDS?.includes(piece.type) ? 0 : piece.position,
        };
      }),
    },
    tenantId,
  });

export const previewPdfTemplate = (pdfTempalte: Partial<PdfTemplate>, tenantId: number) =>
  axios.post(
    `${ENDPOINT}/preview`,
    {
      param: {
        ...pdfTempalte,
        templatePieces: pdfTempalte.templatePieces?.map(piece => {
          return {
            ...piece,
            position: SPECIAL_PDF_PIECE_FIELDS?.includes(piece.type) ? 0 : piece.position,
          };
        }),
      },
      tenantId,
    },
    {
      responseType: "blob",
    },
  );

export const getPdfTemaplateList = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<PdfTemplate>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  );

export const getPdfTemplateById = (param: string, tenantId: number) =>
  axios.post<GenericResponse<PdfTemplate>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  });
