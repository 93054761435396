import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { modifyHaccpItem } from "shared/network/haccp-item.api";
import {
  createSterilizationItem,
  modifySterilizationItem,
} from "shared/network/sterilization-item.api";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultValues: any | null;
  isModify: boolean;
};

type FormValues = {
  name: string;
  serialNumber: string;
};

const SterilizationItemDialog = ({ open, onClose, defaultValues, isModify }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormValues>();
  const { handleSubmit, setValue } = form;
  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const [loading, setLoading] = useState(false);

  async function onCreateSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createSterilizationItem({ ...values, isPieceQuantity: true }, tenant?.id);
      onClose();
      queryClient.refetchQueries("sterilizationItemListQuery");
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  async function onModifySubmit(values: FormValues) {
    setLoading(true);
    try {
      await modifySterilizationItem({ ...defaultValues, ...values }, tenant?.id);
      onClose();
      queryClient.refetchQueries("sterilizationItemListQuery");
      enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  useEffect(() => {
    setValue("name", defaultValues?.name || "");
    setValue("serialNumber", defaultValues?.serialNumber || "");
  }, [defaultValues?.name, defaultValues?.serialNumber, open, setValue]);

  return (
    <>
      <Loading open={loading} />
      <Dialog maxWidth="xs" fullWidth open={!!open} onClose={onClose}>
        <DialogTitle>
          {isModify && "Anyag, eszköz módosítása"}
          {!isModify && "Új anyag, eszköz"}
        </DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form
              id="haccp-item-form"
              onSubmit={handleSubmit(!defaultValues ? onCreateSubmit : onModifySubmit)}
            >
              <Box display="flex" flexDirection="column" gridGap={16}>
                <Controller
                  name="name"
                  defaultValue=""
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("haccpItem.name")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name="serialNumber"
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label="Gyártási szám"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            {t("common:button.cancel")}
          </Button>
          <Button form="haccp-item-form" variant="contained" type="submit">
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SterilizationItemDialog;
