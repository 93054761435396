import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { User } from "shared/types";
import { Food } from "./food.api";

export type ServingCheck = {
  id: number;
  tenantId: number;
  food: Food;
  date: string;
  portion: number;
  isQualified: boolean;
  arrivalTime: string;
  arrivalTemperature: number;
  arrivalSignerUser: User;
  arrivalSignPath: string;
  arrivalLongitude: number;
  arrivalLatitude: number;
  servingStartTime: string;
  servingStartTemperature: number;
  servingStartSignerUser: User;
  servingStartSignPath: string;
  servingStartLongitude: number;
  servingStartLatitude: number;
  servingMidTime: string;
  servingMidTemperature: number;
  servingMidSignerUser: User;
  servingMidSignPath: string;
  servingMidLongitude: number;
  servingMidLatitude: number;
  servingEndTime: string;
  servingEndTemperature: number;
  servingEndSignerUser: User;
  servingEndSignPath: string;
  servingEndLongitude: number;
  servingEndLatitude: number;
  foodReheatingTime: string;
  foodReheatingTemperature: number;
  foodReheatingSignerUser: User;
  foodReheatingSignPath: string;
  foodReheatingLongitude: number;
  foodReheatingLatitude: number;
  isLocalServing: boolean;
};

const ENDPOINT = "/serving-check";

export const createServingCheck = (param: any, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param, tenantId });

export const modifyServingCheck = (param: any, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId });

export const getServingCheckPage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.post<GenericPageResponse<ServingCheck>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${
      search || ""
    }&sort=${sort || ""}`,
  );

export const servingSignature = (
  id: string,
  tenantId: string,
  types: string[], // ("arrival" | "start" | "mid" | "end" | "reheat")[],
  file: File,
) => {
  const formData = new FormData();
  formData.set("id", id);
  formData.set("tenantId", tenantId);
  formData.set("types", types.toString());
  file && formData.set("file", file);

  return axios.put(`${ENDPOINT}/add-signature-file`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //DELIVERY_ADMIN
