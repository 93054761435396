import { Box, Button, Container, Grid, TextField, Tooltip } from "@material-ui/core";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { listWarehousePageable } from "shared/network/warehouse.api";
import { Item, SerialItemEntry, Transaction, Warehouse } from "shared/types";
import { useSelector } from "react-redux";
import { RootState } from "config/store";
import { useQuery } from "react-query";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import { AddBox, Info } from "@material-ui/icons";
import { listItems } from "shared/network/item.api";
import FormCard from "components/FormCard";
import { useSnackbar } from "notistack";
import { useDebouncedCallback } from "use-debounce";
import {
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
} from "config/constants";
import ItemTransferRow from "./ItemTransferRow";
import { createTransaction } from "shared/network/transactions.api";
import { format } from "date-fns";
import { COLORS } from "config/theme";

export type ItemTransferValues = {
  fromWarehouse: Warehouse;
  toWarehouse: Warehouse;
  itemEntries: TransferItemEntry[];
};

export type TransferItemEntry = {
  quantity: string;
  itemEntry: Item | null;
  factoryNumber: SerialItemEntry | null;
};

const ItemTransfer = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const form = useForm<ItemTransferValues>();
  const fromWarehouse = form.watch("fromWarehouse");
  const toWarehouse = form.watch("toWarehouse");

  const [warehouseSearch, setWarehouseSearch] = useState<string>("");
  const [itemSearch, setItemSearch] = useState<string>("");

  const warehouseListQuery = useQuery(
    ["itemTransferWarehouseListPageable", tenant.id, warehouseSearch],
    async () => {
      const { data } = await listWarehousePageable(
        0,
        10,
        tenant.id,
        warehouseSearch ? `name:${warehouseSearch}` : ``,
      );

      return data.page.content;
    },
  );

  const { append, fields, remove } = useFieldArray({
    control: form.control,
    name: "itemEntries",
    keyName: "key",
  });

  const itemListQuery = useQuery(["itemTransferItemListQuery", tenant.id, itemSearch], async () => {
    const { data } = await listItems(
      0,
      10,
      tenant.id,
      (itemSearch ? `(name:${itemSearch};(OR)productCode:${itemSearch};)` : ``) +
        "purchasable:true",
    );
    return data.page.content;
  });

  const onSubmitCreate = async (values: ItemTransferValues) => {
    try {
      // transaction create
      await createTransaction(
        values.itemEntries.map(entry => {
          return {
            item: entry.itemEntry,
            type: "TRANSFER",
            quantity: entry.quantity || 1,
            isIntake: true,
            date: format(new Date(), "yyyy-MM-dd"),
            itemSerial: {
              id: entry.factoryNumber?.id,
            },
            fromWarehouse: { id: Number.parseInt(fromWarehouse.id.toString()) },
            toWarehouse: { id: Number.parseInt(toWarehouse.id.toString()) },
          } as Transaction;
        }),
        tenant.id,
      );
      enqueueSnackbar(t("warehouse.itemTransfer.success"), {
        variant: "success",
      });
      form.reset();
    } catch {
      enqueueSnackbar(t("warehouse.itemTransfer.failure"), {
        variant: "error",
      });
    }
  };

  const handleWarehouseSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setWarehouseSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  return (
    <Container maxWidth="md">
      <FormCard title={t("warehouse.itemTransfer.warehouseTitle")}>
        <form onSubmit={form.handleSubmit(onSubmitCreate)}>
          <FormProvider {...form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="fromWarehouse"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        handleWarehouseSearchChange("");
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleWarehouseSearchChange(newInputValue);
                      }}
                      options={warehouseListQuery.data || []}
                      getOptionLabel={(option: Warehouse) => option.name}
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Tooltip
                                style={{
                                  paddingRight: "2px",
                                }}
                                title={t("tooltip.fromWarehouse").toString()}
                              >
                                <Info style={{ color: COLORS.lightBlue }} />
                              </Tooltip>
                            ),
                          }}
                          label={t("warehouse.itemTransfer.warehouseFrom")}
                          InputLabelProps={{ shrink: true, required: true }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="toWarehouse"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => {
                        field.onChange(value);
                        handleWarehouseSearchChange("");
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleWarehouseSearchChange(newInputValue);
                      }}
                      options={warehouseListQuery.data || []}
                      getOptionLabel={(option: Warehouse) => option.name}
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Tooltip
                                style={{
                                  paddingRight: "2px",
                                }}
                                title={t("tooltip.toWarehouse").toString()}
                              >
                                <Info style={{ color: COLORS.lightBlue }} />
                              </Tooltip>
                            ),
                          }}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("warehouse.itemTransfer.warehouseTo")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {fields.map((field, index) => (
                  <ItemTransferRow
                    key={index}
                    itemList={itemListQuery.data || []}
                    index={index}
                    remove={remove}
                    itemSearch={{
                      loading: itemListQuery.isFetching,
                      setSearch: setItemSearch,
                    }}
                    warehouseId={fromWarehouse?.id.toString()}
                  />
                ))}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" mt={2} p={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={!fromWarehouse || !toWarehouse}
                      onClick={event => {
                        event.stopPropagation();
                        append({
                          itemEntry: null,
                          quantity: "",
                        });
                      }}
                      startIcon={<AddBox />}
                    >
                      {t("offer.relOfferItem.add")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="center" width="100%" p={2} gridGap={8}>
              <Button type="submit" color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </FormProvider>
        </form>
      </FormCard>
    </Container>
  );
};

export default ItemTransfer;
