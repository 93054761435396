import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { endOfDay, format, getDaysInMonth, isValid, setDate, startOfDay } from "date-fns";
import hu from "date-fns/esm/locale/hu/index.js";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getCheckTimePage } from "shared/network/check-time.api";
import { getChecklistItemPage } from "shared/network/haccp-checklist.api";
import { getHaccpItemDateCommentPage } from "shared/network/haccp-item-date-comment.api";
import { getHaccpItemPage } from "shared/network/haccp-item.api";
import HaccpItemCheckComment from "./HaccpItemCheckComment";
import HaccpItemCheckInputCell from "./HaccpItemCheckInputCell";
import { useHistory } from "react-router";

const HaccpItemDaily = () => {
  const { t } = useTranslation();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<any>();
  const { control, watch } = form;
  const date = watch("date");
  const history = useHistory();

  const haccpItemPageQuery = useQuery(["haccpItemPageQuery", tenant.id], async () => {
    const { data } = await getHaccpItemPage(0, 2000, tenant.id);
    return data?.page?.content || [];
  });

  const checkListItemPageQuery = useQuery(
    ["checkListItemPageQuery", tenant.id, date],
    async () => {
      const { data } = await getChecklistItemPage(
        0,
        2000,
        tenant.id,
        `realCheckTime>=${startOfDay(date).toISOString()};realCheckTime<=${endOfDay(
          date,
        ).toISOString()}`,
      );
      return data?.page?.content || [];
    },
    { enabled: !!date },
  );

  const checkTimePageQuery = useQuery(["checkTimePageQuery", tenant.id], async () => {
    const { data } = await getCheckTimePage(0, 2000, tenant.id, ` isActive=true`, "checkAt,asc");
    return data.page.content;
  });

  const haccpItemDateCommentQuery = useQuery(
    ["haccpItemDateCommentQuery", date, tenant.id],
    async () => {
      if (isValid(date)) {
        const { data } = await getHaccpItemDateCommentPage(
          0,
          2000,
          tenant.id,
          `date>=${format(startOfDay(date), "yyyy-MM-dd")};date<=${format(
            endOfDay(date),
            "yyyy-MM-dd",
          )}`,
        );
        return data?.page?.content || [];
      }
      return Promise.reject();
    },
  );

  const haccpItemDateComments = haccpItemDateCommentQuery.data || [];

  return (
    <Box>
      <Loading
        open={
          checkTimePageQuery?.isFetching ||
          checkListItemPageQuery?.isFetching ||
          haccpItemPageQuery?.isFetching ||
          haccpItemDateCommentQuery?.isFetching
        }
      />
      <Card style={{ padding: 16 }}>
        <FormProvider {...form}>
          <form>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  control={control}
                  name="date"
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="date"
                      variant="inline"
                      label={t("haccpItem.date")}
                      format="yyyy.MM.dd."
                      InputLabelProps={{ shrink: true }}
                      autoOk
                      views={["year", "month", "date"]}
                      openTo="date"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Typography
          style={{
            textAlign: "center",
            paddingTop: 24,
            fontSize: 16,
            fontWeight: "bold",
            color: COLORS.mainLight,
          }}
        >
          Hűtő hőmérséklet ellenőrzés:
        </Typography>
        <Typography
          style={{
            textAlign: "center",
            fontSize: 16,
            fontWeight: "bold",
            color: COLORS.mainLight,
            paddingBottom: 16,
          }}
        >
          A megfelelő időponthoz a hűtő aktuális hőmérsékletét írja be!
        </Typography>
        <Box style={{ overflow: "auto" }}>
          <Box style={{ minWidth: 780 }}>
            {haccpItemPageQuery?.data?.map((option, index) => (
              <Box key={option.id}>
                {!!getDaysInMonth(date) &&
                  !!option?.id &&
                  !!checkTimePageQuery.data?.filter(value => value?.haccpItem?.id === option?.id)
                    .length && (
                    <Box display="flex" justifyContent="center">
                      <Box width={200}>
                        {index === 0 && (
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            p={1}
                            width="100%"
                            style={{
                              borderBottom: "1px solid lightgrey",
                            }}
                          >
                            <Typography noWrap style={{ fontWeight: "bold", fontSize: 18 }}>
                              Hűtő neve
                            </Typography>
                          </Box>
                        )}
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{
                            borderBottom: "1px solid lightgrey",
                            padding: 8,
                            height: 57,
                          }}
                        >
                          <Typography style={{ fontSize: 18 }}>{option.name}</Typography>
                        </Box>
                      </Box>
                      {checkTimePageQuery.data
                        ?.filter(value => value?.haccpItem?.id === option?.id)
                        ?.map(checkTime => {
                          const checkListItems = checkListItemPageQuery.data?.filter(
                            value => value.checkTime.id === checkTime.id,
                          );

                          return (
                            <Box
                              key={checkTime.id}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              width={110}
                            >
                              {index === 0 && (
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  p={1}
                                  width="100%"
                                  style={{
                                    borderBottom: "1px solid lightgrey",
                                  }}
                                >
                                  <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
                                    {format(new Date(checkTime.checkAt), "p", { locale: hu })}
                                  </Typography>
                                </Box>
                              )}
                              <HaccpItemCheckInputCell
                                index={Number(format(date, "d")) - 1}
                                checkTime={checkTime}
                                date={date}
                                checkListItem={checkListItems?.find(value => {
                                  return (
                                    format(
                                      setDate(new Date(date), Number(format(date, "d"))),
                                      "yyyy-MM-dd",
                                    ) === format(new Date(value.realCheckTime), "yyyy-MM-dd")
                                  );
                                })}
                              />
                            </Box>
                          );
                        })}
                      <Box width={360}>
                        <HaccpItemCheckComment
                          date={date}
                          haccpItemId={option?.id}
                          haccpItemDateComments={haccpItemDateComments}
                          width={360}
                          isDaily
                          noHeader={index !== 0}
                        />
                      </Box>
                    </Box>
                  )}
              </Box>
            ))}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
export default HaccpItemDaily;
