import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  SterilizationItem,
  getSterilizationItemPage,
  modifySterilizationItem,
} from "shared/network/sterilization-item.api";
import { createSterilization, getSterilizationPage } from "shared/network/sterilization.api";
import { useDebouncedCallback } from "use-debounce";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultValues?: any | null;
  isModify?: boolean;
};

type FormValues = {
  sterilizationItem: SterilizationItem;
  sterilizationTime: Date | null;
  quantity: number;
  fluidQuantity: string;
};

const SterilizationDialog = ({ open, onClose, defaultValues, isModify }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormValues>();
  const { handleSubmit, setValue, watch } = form;
  const [quantity, fluidQuantity] = watch(["quantity", "fluidQuantity"]);

  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");

  const onSearchChange = useDebouncedCallback((value: string) => {
    setFilter(value);
  }, 300);

  const sterilizationItemListQuery = useQuery(
    ["itemAutocompleteQuery", filter, tenant.id],
    async () => {
      const { data } = await getSterilizationItemPage(0, 2000, tenant.id, filter, "name");
      return data;
    },
  );

  async function onCreateSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createSterilization(
        { ...values, sterilizationTime: values.sterilizationTime?.toISOString() },
        tenant?.id,
      );
      onClose();
      queryClient.refetchQueries("sterilizationListQuery");
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  async function onModifySubmit(values: FormValues) {
    setLoading(true);
    try {
      await modifySterilizationItem({ ...defaultValues, ...values }, tenant?.id);
      onClose();
      queryClient.refetchQueries("sterilizationListQuery");
      enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  useEffect(() => {
    setValue("sterilizationItem", defaultValues?.sterilizationItem || null);
    setValue("fluidQuantity", defaultValues?.fluidQuantity || "");
    setValue("quantity", defaultValues?.quantity || "");
  }, [
    defaultValues?.fluidQuantity,
    defaultValues?.quantity,
    defaultValues?.sterilizationItem,
    setValue,
    open,
  ]);

  return (
    <>
      <Loading open={loading} />
      <Dialog maxWidth="xs" fullWidth open={!!open} onClose={onClose}>
        <DialogTitle>
          {isModify && "Sterilezés módosítása"}
          {!isModify && t("Sterilezés felvétele")}
        </DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form
              id="sterilization-form"
              onSubmit={handleSubmit(!defaultValues ? onCreateSubmit : onModifySubmit)}
            >
              <Box display="flex" flexDirection="column" gridGap={16}>
                <Controller
                  name="sterilizationItem"
                  defaultValue={null}
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      options={sterilizationItemListQuery?.data?.page?.content || []}
                      onChange={(_, value) => {
                        field.onChange(value);
                        onSearchChange("");
                      }}
                      onInputChange={(_, newInputValue) => onSearchChange(newInputValue)}
                      getOptionSelected={option => option.id === field.value?.id}
                      getOptionLabel={(option: any) => {
                        return `${option.name} (${option.serialNumber})`;
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={"Anyag, eszköz"}
                          InputLabelProps={{
                            shrink: true,
                            required: true,
                          }}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
                <Box>
                  <Box display="flex" alignItems="center" gridGap={8}>
                    <Controller
                      name="fluidQuantity"
                      defaultValue=""
                      rules={{
                        required: {
                          value: !quantity,
                          message: "A mennyiség vagy eszköz darabszámának kitöltése kötelező!",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="Mennyiség"
                          InputLabelProps={{ shrink: true, required: !quantity }}
                          error={!!fieldState.error}
                        />
                      )}
                    />
                    <Box>
                      <Typography
                        noWrap
                        style={{
                          color:
                            form.formState.errors?.quantity?.message ||
                            form.formState.errors?.fluidQuantity?.message,
                        }}
                      >
                        vagy
                      </Typography>
                    </Box>
                    <Controller
                      name="quantity"
                      defaultValue=""
                      rules={{
                        required: {
                          value: !fluidQuantity,
                          message: "A mennyiség vagy eszköz darabszámának kitöltése kötelező!",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          type="number"
                          label="Eszköz darabszáma"
                          InputLabelProps={{ shrink: true, required: !fluidQuantity }}
                          error={!!fieldState.error}
                        />
                      )}
                    />
                  </Box>

                  {!quantity && !fluidQuantity && (
                    <Typography
                      style={{ fontSize: 12, color: COLORS.red, padding: 4, paddingLeft: 16 }}
                    >
                      A mennyiség vagy eszköz darabszámának kitöltése kötelező!
                    </Typography>
                  )}
                  {quantity && fluidQuantity && (
                    <Typography
                      style={{ fontSize: 12, color: COLORS.red, padding: 4, paddingLeft: 16 }}
                    >
                      Csak a mennyiséget vagy eszköz darabszámát adja meg!
                    </Typography>
                  )}
                </Box>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            {t("common:button.cancel")}
          </Button>
          <Button
            form="sterilization-form"
            variant="contained"
            type="submit"
            disabled={!!fluidQuantity && !!quantity}
          >
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SterilizationDialog;
