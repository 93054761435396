import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { RootState } from "config/store";
import { sha512 } from "js-sha512";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SliceStatus } from "shared/common";
import {
  addTechnicalUserRequest,
  technicalUserValidator,
} from "shared/network/tenant.api";
import { fetchAccount } from "shared/reducers/authentication";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

type AddTechUserFormValues = {
  login: string;
  passwordHash: string;
  signatureKey: string;
  exchangeKey: string;
  userValid: boolean;
};

const OwnCompanyAddTechUser = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const form = useForm<AddTechUserFormValues>();
  const { control, watch, setValue } = form;

  const { login, passwordHash, signatureKey, exchangeKey, userValid } = watch();

  const [status, setStatus] = useState<SliceStatus>("idle");

  const { tenant } = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  const onSubmitModify = async (values: AddTechUserFormValues) => {
    setStatus("pending");
    try {
      await addTechnicalUserRequest(
        tenant.id,
        values.login,
        sha512(values.passwordHash).toUpperCase(),
        values.signatureKey,
        values.exchangeKey,
      );
      setStatus("success");
      dispatch(fetchAccount());
      enqueueSnackbar(
        t("common:notification.add.success", {
          subject: t("ownCompany.technicalUser.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e: any) {
      if (e.data?.status === "TECHNICAL_USER_NOT_VALID") {
        enqueueSnackbar(
          t("common:notification.TECHNICAL_USER_NOT_VALID", {
            subject: t("commmon:notification.TECHNICAL_USER_NOT_VALID"),
          }),
          {
            variant: "error",
          },
        );
      }else if (e.data?.status === "NAV_MAINTENANCE_MODE") {
        enqueueSnackbar(
          t("common:notification.NAV_MAINTENANCE_MODE", {
            subject: t("commmon:notification.NAV_MAINTENANCE_MODE"),
          }),
          {
            variant: "error",
          },
        );
      }
      setStatus("failure");
    }
  };

  async function testTechnicalUser() {
    try {
      await technicalUserValidator(
        tenant.id,
        login,
        sha512(passwordHash).toUpperCase(),
        signatureKey,
        exchangeKey,
      );
      setValue("userValid", true);
      enqueueSnackbar("A megadott adatok megfelelőek.", {
        variant: "success",
      });
    } catch (e: any) {
      if (e.data?.status === "TECHNICAL_USER_NOT_VALID") {
        enqueueSnackbar(
          t("common:notification.TECHNICAL_USER_NOT_VALID", {
            subject: t("commmon:notification.TECHNICAL_USER_NOT_VALID"),
          }),
          {
            variant: "error",
          },
        );
      } else if (e.data?.status === "NAV_MAINTENANCE_MODE") {
        enqueueSnackbar(
          t("common:notification.NAV_MAINTENANCE_MODE", {
            subject: t("commmon:notification.NAV_MAINTENANCE_MODE"),
          }),
          {
            variant: "error",
          },
        );
      }
      setValue("userValid", false);
      enqueueSnackbar("A megadott adatok hibásak.", {
        variant: "error",
      });
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>NAV technikai felhasználó</DialogTitle>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmitModify)}>
          <DialogContent>
            {status === "pending" ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="300px"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid
                container
                spacing={2}
                alignContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="login"
                    defaultValue={tenant?.technicalLogin || ""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("ownCompany.login")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="passwordHash"
                    defaultValue=""
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("ownCompany.password")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="signatureKey"
                    defaultValue={tenant?.technicalSignatureKey || ""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("ownCompany.signatureKey")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="exchangeKey"
                    defaultValue={tenant?.technicalExchangeKey || ""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("ownCompany.exchangeKey")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <DialogActions>
              <Box display="flex" gridGap={2}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => setOpen(false)}
                >
                  {t("common:button.cancel")}
                </Button>
                {!userValid ? (
                  <Controller
                    control={control}
                    name="userValid"
                    render={() => (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={async () => await testTechnicalUser()}
                      >
                        {t("common:button.test")}
                      </Button>
                    )}
                  />
                ) : (
                  <Button type="submit" color="primary" disabled={!userValid}>
                    {t("common:button.save")}
                  </Button>
                )}
              </Box>
            </DialogActions>
          </DialogContent>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default OwnCompanyAddTechUser;
