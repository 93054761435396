import axios from "config/axios";
import { GenericListResponse } from "shared/common";

export type ScalingX = {
  id: number;
  valueFrom: string;
  valueTo: string;
  isActive: boolean;
  itemId: number;
  tenantId: number;
};

const ENDPOINT = "/scaling-x";

export const createScalingX = (param: Partial<ScalingX>, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

export const deleteScalingX = (id: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?id=${id}&tenantId=${tenantId}`);

export const getAllScalingX = (itemId: number, isActive: boolean, tenantId: number) =>
  axios.get<GenericListResponse<ScalingX>>(
    `${ENDPOINT}/all?itemId=${itemId}&tenantId=${tenantId}&${
      isActive ? `isActive=${isActive}` : ""
    }`,
  );
