import axios from "config/axios";
import { Offer } from "shared/types";
import { GenericPageResponse, GenericResponse } from "../common";

export type RelProjectOffer = {
  id?: number;
  tenantId?: number;
  projectId: string | number | undefined;
  offer: Offer;
};

const ENDPOINT = "/rel-project-offer";

export const createRelProjectOffer = (param: RelProjectOffer, tenantId: number) =>
  axios.post<GenericResponse<RelProjectOffer>>(`${ENDPOINT}`, { param, tenantId });

export const deleteRelProjectOffer = (id: number, tenantId: number) =>
  axios.delete<GenericResponse<RelProjectOffer>>(`${ENDPOINT}?id=${id}&tenantId=${tenantId}`);

export const getRelProjectOfferPage = (
  tenantId: number,
  page: number = 0,
  size: number = 20,
  search: string = "",
) =>
  axios.post<GenericPageResponse<RelProjectOffer>>(
    `${ENDPOINT}/all?tenantId=${tenantId}&page=${page}&size=${size}&search=${search}`,
  );
