import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { COLORS } from "config/theme";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { listSerialItemsForWarehouse } from "shared/network/serial-item.api";
import { RelItemDelivery, SerialItemEntry } from "shared/types";
import { useQuery } from "react-query";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import Loading from "components/Loading";

type Props = {
  index: number;
  itemEntry: RelItemDelivery;
};

const DailyDeliveryRow = ({ itemEntry, index }: Props) => {
  const { t } = useTranslation();

  const warehouseId = itemEntry?.delivery?.fromWarehouse?.id;
  const itemId = itemEntry?.item?.id;

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const avaibleSerialItemList = useQuery(
    ["avaibleSerialItemListQueryForWarehouseTransaction", tenant.id, itemId],
    async () => {
      const { data } = await listSerialItemsForWarehouse(
        0,
        100000,
        tenant.id,
        itemId.toString() || "0",
        warehouseId,
      );
      return data.page.content;
    },
    { enabled: !!itemId },
  );

  return (
    <>
      <Loading open={avaibleSerialItemList.isFetching} />
      <Grid
        item
        xs={12}
        key={index}
        container
        style={{
          borderBottom: `1px solid ${COLORS.mainGrey}`,
          margin: "8px 0 0 0",
        }}
      >
        <Grid item xs={4}>
          {itemEntry?.item?.name}
        </Grid>
        <Grid item xs={4}>
          {itemEntry?.delivery?.order?.company?.name}
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <Controller
            name={`serialItemList.${index}.itemSerial`}
            rules={{
              required: t("validation.required").toString(),
            }}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                onChange={(_, value) => field.onChange(value)}
                options={avaibleSerialItemList.data || []}
                getOptionLabel={(option: SerialItemEntry) => option.factoryNumber}
                getOptionSelected={option => option.id === field.value?.id}
                renderInput={params => (
                  <TextField
                    {...params}
                    hiddenLabel
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DailyDeliveryRow;
