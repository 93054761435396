import { Box, IconButton, Typography } from "@material-ui/core";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import queryClient from "config/query";
import { RootState } from "config/store";
import { format, setDate } from "date-fns";
import { useSelector } from "react-redux";
import {
  CheckSubDepartment,
  createCheckSubDepartment,
  setValidator,
} from "shared/network/check-sub-department.api";

type Props = {
  index: number;
  date: Date;
  subDepartment?: any;
  checkSubDepartment?: CheckSubDepartment | null;
};

const HaccpDepartmentCheckInputCell = ({
  index,
  date,
  subDepartment,
  checkSubDepartment,
}: Props) => {
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  async function onClick() {
    try {
      await createCheckSubDepartment(
        {
          date: format(setDate(date, index + 1), "yyyy-MM-dd"),
          subDepartmentId: subDepartment?.id,
        },
        tenant?.id,
      );
      queryClient.refetchQueries("checkSubDepartmentPageQuery");
    } catch {}
  }

  async function onValidateClick() {
    try {
      await setValidator(format(setDate(date, index + 1), "yyyy-MM-dd"), tenant?.id);
      queryClient.refetchQueries("checkSubDepartmentPageQuery");
    } catch {}
  }

  return (
    <Box
      style={{
        borderBottom: "1px solid lightgrey",
        padding: 8,
        height: 57,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {(subDepartment && checkSubDepartment) ||
      (!subDepartment && checkSubDepartment?.validator) ? (
        <Typography style={{ textAlign: "center" }}>
          {subDepartment
            ? checkSubDepartment?.createdBy?.name
            : checkSubDepartment?.validator?.name}
        </Typography>
      ) : (
        <IconButton
          color="primary"
          size="small"
          onClick={() => {
            if (subDepartment) {
              onClick();
            } else {
              onValidateClick();
            }
          }}
        >
          <CheckBoxOutlineBlank />
        </IconButton>
      )}
    </Box>
  );
};
export default HaccpDepartmentCheckInputCell;
