import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { useHeader } from "components/Layout/HeaderContext";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import HaccpServingArriving from "./HaccpServingArriving";
import HaccpServingSettings from "./HaccpServingSettings";
import { useTranslation } from "react-i18next";
import HaccpServingGoods from "./HaccpServingGoods";

export const HACCP_SERVING_TABS = [
  "/haccp-serving/arriving",
  "/haccp-serving/locally",
  "/haccp-serving/goods",
  "/haccp-serving/settings",
];

const HaccpServingTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setTopPadding } = useHeader();
  const account = useSelector((state: RootState) => state.authentication.account);
  const selectedRelTenant = useSelector(
    (state: RootState) => state.authentication.selectedRelTenant,
  );

  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {location.pathname === "/haccp-serving" && <Redirect to="/haccp-serving/arriving" />}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "HACCP_NOT_LOCAL_SERVING",
        ]) && (
          <Tab
            label={t("drawer./haccp-serving/arriving")}
            component={Link}
            to={HACCP_SERVING_TABS[0]}
            value={HACCP_SERVING_TABS[0]}
          />
        )}

        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "HACCP_LOCAL_SERVING",
        ]) && (
          <Tab
            label={t("drawer./haccp-serving/locally")}
            component={Link}
            to={HACCP_SERVING_TABS[1]}
            value={HACCP_SERVING_TABS[1]}
          />
        )}

        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["HACCP_GOODS"]) && (
          <Tab
            label={t("drawer./haccp-serving/goods")}
            component={Link}
            to={HACCP_SERVING_TABS[2]}
            value={HACCP_SERVING_TABS[2]}
          />
        )}
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["HACCP_ADMIN"]) && (
          <Tab
            label="Beállítások"
            component={Link}
            to={HACCP_SERVING_TABS[3]}
            value={HACCP_SERVING_TABS[3]}
          />
        )}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "HACCP_NOT_LOCAL_SERVING",
            ]) && (
              <PrivateRoute
                path={HACCP_SERVING_TABS[0]}
                component={() => <HaccpServingArriving isLocalServing={false} />}
              />
            )}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "HACCP_LOCAL_SERVING",
            ]) && (
              <PrivateRoute
                path={HACCP_SERVING_TABS[1]}
                component={() => <HaccpServingArriving isLocalServing={true} />}
              />
            )}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "HACCP_GOODS",
            ]) && <PrivateRoute path={HACCP_SERVING_TABS[2]} component={HaccpServingGoods} />}
            {hasAuthority(account.user, account.permissions, selectedRelTenant, [
              "HACCP_ADMIN",
            ]) && <PrivateRoute path={HACCP_SERVING_TABS[3]} component={HaccpServingSettings} />}
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default HaccpServingTabs;
