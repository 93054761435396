import { Box, Button, Container, IconButton } from "@material-ui/core";
import { AddBox, Edit } from "@material-ui/icons";
import { GridCellParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import HaccpDepartmentModal from "./HaccpDepartmentDialog";
import { useHistory } from "react-router";
import { getSubDepartmentPage } from "shared/network/sub-department.api";

const HaccpDepartment = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-haccp-department-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-haccp-department-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("haccpDepartment.name"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 68,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          <IconButton color="primary" onClick={() => setSelectedRow(row)}>
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const haccpDepartmentPageQuery = useQuery(
    ["haccpDepartmentPageQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await getSubDepartmentPage(
        page,
        pageSize,
        tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap={16}
      >
        <Box>
          <Button startIcon={<AddBox />} onClick={() => setOpen(true)}>
            {t("haccpDepartment.create")}
          </Button>
        </Box>
        <HaccpDepartmentModal
          open={open}
          onClose={() => setOpen(false)}
          defaultValues={selectedRow}
          isModify={false}
        />
        <HaccpDepartmentModal
          open={selectedRow}
          onClose={() => setSelectedRow(null)}
          defaultValues={selectedRow}
          isModify
        />
        <PageableTable
          sortState={sortState}
          setSortState={setSortState}
          sessionStorageKey="haccp-department"
          tableState={tableState}
          setTableState={setTableState}
          columns={columns}
          query={haccpDepartmentPageQuery}
          minWidth={{ value: "sm" }}
        />
      </Box>
    </Container>
  );
};

export default HaccpDepartment;
