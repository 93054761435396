import ConfirmationButton from "components/ConfirmationButton";
import {
  Box,
  Button,
  Tooltip,
  Collapse,
  TextField,
  Accordion,
  makeStyles,
  Typography,
  IconButton,
  InputAdornment,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import {
  createItemCategory,
  deleteItemCategory,
  listItemCategory,
  modifyItemCategory,
} from "shared/network/category.api";
import { useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { RootState } from "config/store";
import { useSelector } from "react-redux";
import { ItemCategory } from "shared/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Check, Close } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { AddBox, Delete, Edit, ExpandMore } from "@material-ui/icons";

type Props = {
  category: ItemCategory;
  refetch?: () => void;
};

type CreateFormValues = {
  name: string;
  parentId: number;
};
type ModifyFormValues = {
  name: string;
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    flexDirection: "column",
    "&.Mui-expanded": {
      "&:before": {
        opacity: "unset",
      },
      "& > .MuiAccordionSummary-root": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& + .MuiAccordion-root": {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
  },
  accordionDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 0 0 16px",
  },
  createButton: {
    height: 36,
    margin: "16px",
  },
  subCategoryCount: {
    fontSize: 14,
  },
});

const ItemCategoryRow = ({ category, refetch }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [openId, setOpenId] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<ItemCategory | null>(null);

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const createForm = useForm<CreateFormValues>();
  const modifyForm = useForm<ModifyFormValues>();

  const categoriesQuery = useQuery(
    ["categoriesQuery", tenant.id, category.id],
    async () => {
      const { data } = await listItemCategory(tenant.id, category.id);
      return data.items;
    },
    {
      enabled: open,
    },
  );
  const categories = categoriesQuery.data;

  useEffect(() => {
    modifyForm.setValue("name", selectedCategory?.name || "");
  }, [modifyForm, selectedCategory?.name]);

  async function onSubmitCreate(values: CreateFormValues) {
    try {
      await createItemCategory(tenant.id, values);
      await queryClient.invalidateQueries(["categoriesQuery", tenant.id, category.parentId]);
      await queryClient.invalidateQueries(["categoriesQuery", tenant.id]);
      setOpenId(null);
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("item.category.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("item.category.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  async function onSubmitModify(values: ModifyFormValues) {
    try {
      await modifyItemCategory({ ...category, ...values }, tenant.id);
      await queryClient.invalidateQueries(
        category.parentId
          ? ["categoriesQuery", tenant.id, category.parentId]
          : ["categoriesQuery", tenant.id],
      );
      setSelectedCategory(null);
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("item.category.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("item.category.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  async function onDelete(id: number) {
    try {
      await deleteItemCategory(id, tenant.id);
      await queryClient.invalidateQueries(["categoriesQuery", tenant.id, category.parentId]);
      await queryClient.invalidateQueries(["categoriesQuery", tenant.id]);
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("item.category.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      const error = e as any;
      if (error.data?.status === "CONFLICT") {
        enqueueSnackbar(
          t("common:notification.delete.failure", {
            subject: t("item.category.subject"),
          }) +
            " " +
            t("common:notification.CONFLICT"),
          {
            variant: "error",
          },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.delete.failure", {
            subject: t("item.category.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
    }
  }

  return (
    <Accordion
      elevation={0}
      expanded={open}
      className={classes.root}
      onChange={() => setOpen(!open)}
    >
      <form onSubmit={modifyForm.handleSubmit(onSubmitModify)}>
        {selectedCategory?.id !== category?.id ? (
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                <Typography variant="body2">{category.name}</Typography>
                <Typography className={classes.subCategoryCount}>
                  {t("item.category.subCategoryCount", {
                    count:
                      category.childrenCount > 0 ? category.childrenCount : t("item.category.none"),
                  } as any)}
                </Typography>
              </Box>
              <Box>
                <Tooltip title={t("common:button.modify").toString()}>
                  <IconButton
                    size="small"
                    color="primary"
                    style={{ margin: "0 8px" }}
                    onClick={event => {
                      event.stopPropagation();
                      setSelectedCategory(category);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    !!category.childrenCount
                      ? t("item.category.noDelete").toString()
                      : t("common:button.delete").toString()
                  }
                >
                  <span>
                    <ConfirmationButton
                      size="small"
                      color="primary"
                      style={{ margin: "0 8px" }}
                      disabled={!!category.childrenCount}
                      buttonType="ICON"
                      onSubmit={() => {
                        onDelete(category.id);
                      }}
                      title={t("item.category.deleteCategoryTitle")}
                      body={t("item.category.deleteCategoryBody")}
                    >
                      <Delete />
                    </ConfirmationButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </AccordionSummary>
        ) : (
          <Box maxWidth={450} display="flex" margin="auto" alignItems="center" height={72}>
            <Controller
              control={modifyForm.control}
              name="name"
              defaultValue=""
              rules={{
                required: t("validation.required").toString(),
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("item.name")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  onClick={event => event.stopPropagation()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={t("common:button.create").toString()}>
                          <IconButton
                            size="small"
                            color="primary"
                            style={{ margin: "0 8px" }}
                            type="submit"
                            onClick={event => event.stopPropagation()}
                          >
                            <Check />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("common:button.cancel").toString()}>
                          <IconButton
                            size="small"
                            color="primary"
                            style={{ margin: "0 8px" }}
                            onClick={event => {
                              event.stopPropagation();
                              setSelectedCategory(null);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        )}
      </form>
      <AccordionDetails className={classes.accordionDetails}>
        <Collapse in={openId !== 0} style={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="contained"
            startIcon={<AddBox />}
            className={classes.createButton}
            onClick={() => setOpenId(prevState => (prevState === null ? 0 : null))}
          >
            {t("item.category.subCreate")}
          </Button>
        </Collapse>
        <Collapse in={openId === 0} style={{ width: "100%" }}>
          <form onSubmit={createForm.handleSubmit(onSubmitCreate)}>
            <Box pb={2} maxWidth={450} display="flex" margin="auto">
              <input type="hidden" value={category.id} {...createForm.register("parentId")} />
              <Controller
                control={createForm.control}
                name="name"
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("item.name")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={t("common:button.create").toString()}>
                            <IconButton
                              type="submit"
                              size="small"
                              color="primary"
                              style={{ margin: "0 8px" }}
                            >
                              <Check color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("common:button.cancel").toString()}>
                            <IconButton
                              size="small"
                              color="primary"
                              style={{ margin: "0 8px" }}
                              onClick={() => setOpenId(null)}
                            >
                              <Close color="primary" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </form>
        </Collapse>
        {!!categories?.length &&
          categories.map(category => {
            return <ItemCategoryRow key={category.id} category={category} />;
          })}
      </AccordionDetails>
    </Accordion>
  );
};

export default ItemCategoryRow;
