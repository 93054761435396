import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createFood, modifyFood } from "shared/network/food.api";
import {
  createImportedDocumentType,
  modifyImportedDocumentType,
} from "shared/network/imported-document-type.api";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultValues: any | null;
  isModify: boolean;
};

type FormValues = {
  name: string;
  foodType: string;
  workingTemperature: string;
  status: string;
  haccpDefaultMorningTime: Date | null;
  haccpDefaultAfternoonTime: Date | null;
};

export type HaccpServingStatus = "ACTIVE" | "INACTIVE";

export const FOOD_STATUS = ["ACTIVE", "INACTIVE"];

const HaccpDocumentsSettingsDialog = ({ open, onClose, defaultValues, isModify }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormValues>();
  const { handleSubmit, setValue } = form;
  const tenant = useSelector((state: RootState) => state.authentication?.selectedRelTenant?.tenant);
  const [loading, setLoading] = useState(false);

  async function onCreateSubmit(values: FormValues) {
    setLoading(true);
    try {
      await createImportedDocumentType({ ...values, tenantId: tenant?.id }, tenant?.id);
      onClose();
      queryClient.refetchQueries("documentsPageQuery");
      enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  async function onModifySubmit(values: FormValues) {
    setLoading(true);
    try {
      await modifyImportedDocumentType(
        { ...defaultValues, ...values, tenantId: tenant?.id },
        tenant?.id,
      );
      onClose();
      queryClient.refetchQueries("documentsPageQuery");
      form.reset();
      enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
    }
    setLoading(false);
  }

  useEffect(() => {
    setValue("name", defaultValues?.name || "");
    setValue("status", defaultValues?.status || "ACTIVE");
  }, [defaultValues?.foodType, defaultValues?.name, defaultValues?.status, setValue]);

  return (
    <>
      <Loading open={loading} />
      <Dialog
        maxWidth="xs"
        fullWidth
        open={!!open}
        onClose={() => {
          onClose();
          form.reset();
        }}
      >
        <DialogTitle>
          {isModify ? t("haccpDocuments.modify") : t("haccpDocuments.create")}
        </DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form
              id="haccp-item-form"
              onSubmit={handleSubmit(!defaultValues ? onCreateSubmit : onModifySubmit)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("haccpDocuments.name")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="status"
                    defaultValue="ACTIVE"
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t("haccpItem.status.label")}
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        select
                      >
                        <MenuItem value="" disabled>
                          {t("common:choose")}
                        </MenuItem>
                        {FOOD_STATUS.map(value => (
                          <MenuItem key={value} value={value}>
                            {t(`haccpItem.status.${value}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              onClose();
              form.reset();
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button form="haccp-item-form" variant="contained" type="submit" disabled={loading}>
            {t("common:button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default HaccpDocumentsSettingsDialog;
