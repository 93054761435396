import axios from "config/axios";
import { GenericPageResponse } from "shared/common";

export type Food = {
  id: number;
  tenantId: number;
  name: string; //100
  description: string; //100
};

const ENDPOINT = "/food";

export const createFood = (param: any, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param, tenantId });

export const modifyFood = (param: any, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId });

export const getFoodPage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.post<GenericPageResponse<Food>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${
      search || ""
    }&sort=${sort || ""}`,
  );
