import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { CompanySite, Item, SerialItemEntry } from "shared/types";

const ENDPOINT = "/rel-item-company-site";

export type RelItemCompanySiteEntry = {
  id: number;
  tenantId: number;
  item: Item;
  companySite: CompanySite;
  quantity: number;
  itemSerial: SerialItemEntry;
  updatedOn: string;
};

export const createRelCompanySiteItems = (
  relList: RelItemCompanySiteEntry[],
  companyId: string,
  tenantId: number,
) => axios.post(`${ENDPOINT}`, { relList, companyId, tenantId }); //COMPANY_ADMIN;

export const getItemListByCompanySite = (
  page: number,
  size: number,
  companySite: CompanySite,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<RelItemCompanySiteEntry>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=companySite.id:${companySite.id};${search}&sort=${sort}`,
  ); //COMPANY_GET

export const getItemListWithWrappingByCompanySite = (
  page: number,
  size: number,
  companySite: CompanySite,
  tenantId: number,
  search: string = "",
  sort: string = "",
  distinct?: boolean,
  hasWrapping: boolean = true,
) =>
  axios.get<GenericPageResponse<RelItemCompanySiteEntry>>(
    `${ENDPOINT}/get-page-with-wrapping?page=${page}&size=${size}&tenantId=${tenantId}&search=companySite.id:${
      companySite.id
    };${search}&sort=${sort}&hasWrapping=${hasWrapping}&distinct=${!!distinct}`,
  ); //COMPANY_GET
