import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { RootState } from "config/store";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getAllProperties } from "shared/network/properties.api";
import { Property } from "shared/types";
import PropertiesRow from "./PropertiesRow";

const PropertiesList = () => {
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const query = useQuery("properties", async () => {
    if (account.user.isSuperAdmin) {
      const { data } = await getAllProperties(selectedRelTenant.tenant.id);
      return data.items;
    }
    return Promise.reject();
  });

  const categorized = useMemo(() => {
    return query.data?.reduce((acc: { [key: string]: Property[] }, property) => {
      if (!acc[property.moduleName]) {
        acc[property.moduleName] = [];
      }
      acc[property.moduleName].push(property);
      return acc;
    }, {});
  }, [query.data]);

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      {categorized &&
        Object.entries(categorized).map(([module, properties]) => (
          <Accordion elevation={0} style={{ background: "transparent" }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">{t(`property.modules.${module}`)}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column", gap: 16 }}>
              {properties.map(property => (
                <PropertiesRow property={property} onSubmitSuccess={query.refetch} />
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
    </Card>
  );
};

export default PropertiesList;
