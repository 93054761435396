import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import background from "components/Layout/background.png";
import PasswordTextField from "components/PasswordTextField";
import { RootState } from "config/store";
import { trim } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { getSystemProperties } from "shared/network/properties.api";
import { login } from "shared/reducers/authentication";
import magero from "./magero.png";

type LoginForm = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export type LocationState = {
  from: {
    pathname: string;
    search: any;
  };
};

type Props = RouteComponentProps<undefined, any, LocationState>;

const MageroLogin = ({ location }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { status, isAuthenticated, error, account } = useSelector(
    (state: RootState) => state.authentication,
  );
  const { control, handleSubmit } = useForm<LoginForm>();

  const query = useQuery("version", async () => {
    const { data } = await getSystemProperties();
    return data.items;
  });
  const version = query.data?.find(property => property.name === "version")?.value;

  async function onSubmit(values: LoginForm) {
    dispatch(login(trim(values.email), values.password, values.rememberMe));
  }

  if (status === "success" && isAuthenticated) {
    const localVersion = localStorage.getItem("version");
    if ((localVersion && localVersion !== version) || !localVersion) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("version", version || "");
    }
    if (location?.state?.from && location?.state?.from?.pathname !== "/") {
      return <Redirect to={location.state.from} />;
    } else if (account.user.homePage) {
      return <Redirect to={{ pathname: account.user.homePage }} />;
    } else {
      return <Redirect to={{ pathname: "/" }} />;
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      height="100vh"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
        <img src={magero} alt="mag ems" style={{ margin: 32 }} />
        <Typography style={{ fontFamily: "Rubik One", fontSize: 18, color: "#2F586B" }}>
          Mol-Control Kft.
        </Typography>
        <Typography style={{ fontWeight: 500, color: "#2F586B" }}>
          {/* Energia menedzsment rendszer */}
        </Typography>
      </Box>
      <Box>
        <Card elevation={0} style={{ width: 300, padding: 16, borderRadius: 10, margin: 16 }}>
          <Typography
            variant="h5"
            style={{
              marginBottom: 8,
              fontFamily: "Rubik",
              fontWeight: "400",
              fontSize: 18,
              paddingBottom: 8,
            }}
          >
            {t("login.title")}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {status === "failure" && error && (
                <Grid item xs={12}>
                  <Alert severity="error" style={{ borderRadius: 10 }}>
                    {t([`login.${error}`, "login.failure"])}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("fields.email")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <PasswordTextField
                      {...field}
                      ref={undefined}
                      label={t("fields.password")}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="rememberMe"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value, ref } }) => (
                    <FormControlLabel
                      label={t("login.rememberMe")}
                      labelPlacement="end"
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={value}
                          inputRef={ref}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Link to="/reset">{t("login.forgottenPassword")}</Link>
                  <Button variant="contained" type="submit" color="primary" style={{ height: 35 }}>
                    {t("login.title")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default MageroLogin;
