import { Box, Button } from "@material-ui/core";
import { Description, Note } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import FormCard from "components/FormCard";
import { useHeader } from "components/Layout/HeaderContext";
import { RootState } from "config/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import useInvoiceGenerate from "shared/hooks/useInvoiceGenerate";
import IssueList from "views/Issues/IssueList";

export const InvoiceGenerate = () => {
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [invoiceSource, setInvoiceSource] = useState<string>("issues");

  const { generate, add, remove, isSelected } = useInvoiceGenerate([], "INVOICE");

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["INVOICE_ADMIN"]) && (
          <Button
            startIcon={<Description />}
            onClick={() => {
              generate();
            }}
          >
            {t("invoices.generate")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant]); //eslint-disable-line

  const handleSourceChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    setInvoiceSource(newValue);
  };

  return (
    <>
      <FormCard title={t(`inVoiceGenerate.selectTitle`)} style={{ marginBottom: 16 }}>
        <Box display="flex" alignItems="center">
          <ToggleButtonGroup
            value={invoiceSource}
            exclusive
            size={"small"}
            onChange={handleSourceChange}
            style={{ alignItems: "center" }}
          >
            <ToggleButton color="primary" value="issues" aria-label="address">
              <Note /> {" " + t("inVoiceGenerate.issues")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </FormCard>
      <IssueList
        fromIssueGenerate={true}
        isSelected={isSelected}
        addToActiveList={add}
        removeFromActiveList={remove}
      />
    </>
  );
};

export default InvoiceGenerate;
