import axios from "config/axios";
import { GenericListResponse, GenericPageResponse } from "shared/common";
import { QuestionnaireEntry } from "shared/types";

const ENDPOINT = "/questionnaire";

export const createQuestionnaire = (param: QuestionnaireEntry, tenantId: string | number) =>
  axios.post(`${ENDPOINT}`, {
    param,
    tenantId,
  });

export const getQuestionnaireById = (param: string, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  });

export const listQuestionnaire = (tenantId: number) =>
  axios.post<GenericListResponse<QuestionnaireEntry>>(
    `${ENDPOINT}/all-by-tenant?tenantId=${tenantId}`,
  );

export const checkQuestionnaire = ({
  siteName,
  siteAddress,
  tenantId,
}: {
  siteName?: string;
  siteAddress?: string;
  tenantId: string | number;
}) => {
  let params = "";
  if (siteName) {
    params += `siteName=${siteName}`;
  } else if (siteAddress) {
    params += `siteAddress=${siteAddress}`;
  }

  return axios.get<GenericPageResponse<QuestionnaireEntry>>(
    `${ENDPOINT}/check-site?${params}&tenantId=${tenantId}`,
  );
};

export const listQuestionnaireByCompany = (
  page: number,
  size: number,
  tenantId: number,
  companyId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<QuestionnaireEntry>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&companyId=${companyId}&search=${search}&sort=${sort}`,
  ); //COMPANY_GET
