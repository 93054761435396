import {
  faArrowsRotate,
  faBan,
  faCirclePause,
  faFileCircleCheck,
  faFileCircleExclamation,
  faRotateLeft,
  faSquareCheck,
  faTruck,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import { Check, Close, GetApp, Visibility } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listDeliveryNotePageable } from "shared/network/deliveryNote.api";
import { getDeliveryNoteFile, getDeliveryNoteFileURL } from "shared/network/files.api";
import { deliveryNoteLinkOpen, pdfDownload } from "shared/util/fileDownload";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const DeliveryNoteList = () => {
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-deliverynote-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-deliverynote-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const deliveryNoteListQuery = useQuery(
    ["deliveryNoteListQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await listDeliveryNotePageable(
        page,
        pageSize,
        tenant.id,
        id ? "companyId:" + id + ";" + filterValue : filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "delivery.status",
      headerName: t("deliveryNote.formValues.status"),
      flex: 0.3,
      type: "select",
      renderCell: ({ row }: GridRenderCellParams) => {
        let icon = null;
        let materialIcon = null;

        if (row.delivery.status === "ACTIVE") {
          icon = faSquareCheck;
        } else if (row.delivery.status === "DELIVERED") {
          materialIcon = <Check style={{ color: COLORS.green }} />;
        } else if (row.delivery.status === "BILLABLE") {
          icon = faFileCircleExclamation;
        } else if (row.delivery.status === "BILLED") {
          icon = faFileCircleCheck;
        } else if (row.delivery.status === "SUSPENDED") {
          icon = faCirclePause;
        } else if (row.delivery.status === "WITHDRAWN") {
          icon = faRotateLeft;
        } else if (row.delivery.status === "IN_PROGRESS") {
          icon = faTruckFast;
        } else if (row.delivery.status === "IN_CARGO") {
          icon = faTruck;
        } else if (row.delivery.status === "FAILED") {
          materialIcon = <Close style={{ color: COLORS.red }} />;
        } else if (row.delivery.status === "CANCELLED") {
          icon = faBan;
        } else if (row.delivery.status === "DELIVERY_ROUND") {
          icon = faArrowsRotate;
        }

        if (icon || materialIcon) {
          return (
            <Tooltip title={t(`delivery.status.${row.delivery.status}`).toString()}>
              <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                {icon ? (
                  <FontAwesomeIcon
                    icon={icon}
                    style={{
                      fontSize: 20,
                      width: 20,
                      color: COLORS.statusBlue,
                    }}
                  />
                ) : (
                  materialIcon
                )}
              </Box>
            </Tooltip>
          );
        } else {
          return null;
        }
      },
    },
    {
      field: "deliveryNoteNumber",
      headerName: t("deliveryNote.formValues.deliveryNoteNumber"),
      flex: 1,
      type: "stringContainsNumber",
    },
    {
      field: "delivery.company.name",
      type: "stringContainsNumber",
      headerName: t("deliveryNote.formValues.contactName"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.delivery.company
          ? row.delivery.company.name
          : row.delivery.deliveryRound
          ? row.delivery.deliveryRound.name
          : "",
    },
    {
      field: "date",
      headerName: t("deliveryNote.formValues.date"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => format(new Date(row.date), "yyyy. MM. dd."),
      type: "date",
    },
    {
      field: "cashInHand",
      headerName: t("deliveryNote.formValues.cashInHand"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        account.user.isSuperAdmin ||
        selectedRelTenant.isTenantAdmin ||
        hasAuthority(account.user, account.permissions, selectedRelTenant, ["PRICE_WATCHER"])
          ? row.cashInHand
            ? row.currency.prefix +
              " " +
              t("common:number", { num: row.cashInHand }) +
              " " +
              row.currency.postfix
            : ""
          : "-",
    },

    {
      field: "delivery.vehicle.licensePlateNumber",
      headerName: t("deliveryNote.formValues.vehicle"),
      flex: 1,
      type: "stringContainsNumber",
      valueGetter: ({ row }: GridValueGetterParams) =>
        row?.delivery?.company ? row?.delivery?.vehicle?.licensePlateNumber : "-",
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_NOTE_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryNote.deliveryNoteView").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={async () => {
                  if (row.id) {
                    try {
                      deliveryNoteLinkOpen(getDeliveryNoteFileURL(row.id, tenant.id));
                    } catch {
                      enqueueSnackbar(
                        t("common:notification.download.failure", {
                          subject: t("deliveryNote.subject"),
                        }),
                        {
                          variant: "error",
                        },
                      );
                    }
                  }
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_NOTE_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryNote.deliveryNoteDownload").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={async () => {
                  if (row.id) {
                    try {
                      const { data } = await getDeliveryNoteFile(row.id, tenant.id);
                      pdfDownload(
                        data,
                        row?.deliveryNoteNumber,
                        t("common:pdfDownload.DELIVERYNOTE"),
                      );
                      enqueueSnackbar(
                        t("common:notification.download.success", {
                          subject: t("deliveryNote.subject"),
                        }),
                        {
                          variant: "success",
                        },
                      );
                    } catch {
                      enqueueSnackbar(
                        t("common:notification.download.failure", {
                          subject: t("deliveryNote.subject"),
                        }),
                        {
                          variant: "error",
                        },
                      );
                    }
                  }
                }}
              >
                <GetApp />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box mb={2.5}>
      <PageableTable
        filterOptions={[
          {
            columnName: "delivery.status",
            options: [
              "ACTIVE",
              "DELIVERED",
              "BILLABLE",
              "BILLED",
              "SUSPENDED",
              "WITHDRAWN",
              "IN_PROGRESS",
              "IN_CARGO",
              "FAILED",
              "CANCELLED",
              "DELIVERY_ROUND",
            ].map(value => {
              return {
                translated: t(`delivery.status.${value}`),
                value,
              };
            }),
          },
        ]}
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="deliverynote"
        filterable={[
          "delivery.status",
          "deliveryNoteNumber",
          "delivery.company.name",
          "cashInHand",
          "delivery.vehicle.licensePlateNumber",
          "date",
        ]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={deliveryNoteListQuery}
      />
    </Box>
  );
};

export default DeliveryNoteList;
