import { lazy } from "react";
import TaxTypeCreate from "views/Admin/TaxType/TaxTypeCreate";
import TaxTypeModify from "views/Admin/TaxType/TaxTypeModify";

const AdminTabs = lazy(() => import("views/Admin/AdminTabs"));
const BundleCreate = lazy(() => import("views/Admin/Bundles/BundleCreate"));
const BundleDetails = lazy(() => import("views/Admin/Bundles/BundleDetails"));
const BundleModify = lazy(() => import("views/Admin/Bundles/BundleModify"));
const TenantCreate = lazy(() => import("views/Admin/Tenants/TenantCreate"));
const TenantModify = lazy(() => import("views/Admin/Tenants/TenantModify"));
const TenantDetailsTab = lazy(() => import("views/Admin/Tenants/TenantDetailsTabs"));
const SubscripitonCreate = lazy(() => import("views/Subscription/SubscriptionCreate"));
const BankAccountCreate = lazy(
  () => import("views/Company/CompanyDetailsTabs/BankAccount/BankAccountCreate"),
);
const BankAccountModify = lazy(
  () => import("views/Company/CompanyDetailsTabs/BankAccount/BankAccountModify"),
);
const DepartmentModify = lazy(() => import("views/Department/DepartmentModify"));
const CurrencyCreate = lazy(() => import("views/Admin/Currency/CurrencyCreate"));
const CreateArticle = lazy(() => import("views/Admin/Article/Create/CreateArticle"));
const ModifyArticle = lazy(() => import("views/Admin/Article/Modify/ModifyArticle"));
const CompanyCategoryCreate = lazy(
  () => import("views/Admin/CompanyCategory/CompanyCategoryCreate"),
);

export const ADMIN_PRIVATE_ROUTES = [
  {
    path: "/admin",
    component: AdminTabs,
    routes: [
      {
        path: "/bundles",
        component: AdminTabs,
        permissions: ["SUPER_ADMIN"],
        routes: [
          {
            path: "/bundle-create",
            component: BundleCreate,
            permissions: ["SUPER_ADMIN"],
          },
          {
            path: "/bundle-details",
            component: BundleDetails,
            permissions: ["SUPER_ADMIN"],
          },
          {
            path: "/bundle-modify",
            component: BundleModify,
            permissions: ["SUPER_ADMIN"],
          },
        ],
      },
      {
        path: "/tenants",
        component: AdminTabs,
        permissions: ["ADMIN"],
        routes: [
          {
            path: "/tenant-create",
            component: TenantCreate,
            permissions: ["SUPER_ADMIN"],
          },
          {
            path: "/tenant-modify",
            component: TenantModify,
            permissions: ["ADMIN"],
          },
          {
            path: "/tenant-details",
            component: TenantDetailsTab,
            permissions: ["SUPER_ADMIN"],
            routes: [
              {
                path: "/details",
                component: TenantDetailsTab,
                permissions: ["SUPER_ADMIN"],
              },
              {
                path: "/departments",
                component: TenantDetailsTab,
                permissions: ["SUPER_ADMIN"],
                routes: [
                  {
                    path: "/department-modify",
                    component: DepartmentModify,
                    permissions: ["DEPARTMENT_ADMIN"],
                  },
                ],
              },
              {
                path: "/bank-accounts",
                component: TenantDetailsTab,
                permissions: ["SUPER_ADMIN"],
                routes: [
                  {
                    path: "/bank-account-create",
                    component: BankAccountCreate,
                    permissions: ["BANK_ACCOUNT_ADMIN"],
                  },
                  {
                    path: "/bank-account-modify",
                    component: BankAccountModify,
                    permissions: ["BANK_ACCOUNT_ADMIN"],
                  },
                ],
              },
            ],
          },
          {
            path: "/subscription-create",
            component: SubscripitonCreate,
            permissions: ["SUPER_ADMIN"],
          },
        ],
      },
      {
        path: "/currencies",
        component: AdminTabs,
        routes: [
          {
            path: "/currency-create",
            component: CurrencyCreate,
          },
        ],
      },
      {
        path: "/permission-groups",
        component: AdminTabs,
      },
      {
        path: "/company-category",
        component: AdminTabs,
        routes: [
          {
            path: "/company-category-create",
            component: CompanyCategoryCreate,
          },
        ],
      },
      {
        path: "/audit-log",
        component: AdminTabs,
        permissions: ["SUPER_ADMIN"],
      },
      {
        path: "/properties",
        component: AdminTabs,
        permissions: ["SUPER_ADMIN"],
      },
      {
        path: "/articles",
        component: AdminTabs,
        routes: [
          {
            path: "/article-create",
            component: CreateArticle,
          },
          {
            path: "/article-modify",
            component: ModifyArticle,
          },
        ],
      },
      {
        path: "/packaging",
        component: AdminTabs,
      },
      {
        path: "/tax-create",
        component: TaxTypeCreate,
        permissions: ["ADMIN"],
      },
      {
        path: "/tax-modify",
        component: TaxTypeModify,
        permissions: ["ADMIN"],
      },
    ],
  },
];
