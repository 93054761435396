import { Divider, Grid, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import ConfirmationButton from "components/ConfirmationButton";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DailyReport } from "shared/network/daily-report.api";
import {
  RelDailyReportVehicle,
  deleteRelDailyReportVehicle,
} from "shared/network/rel-daily-report-vehicle.api";
import DailyReportVehicleDialog from "./DailyReportVehicleDialog";

type Props = {
  index: number;
  dailyReport?: DailyReport | null;
  relDailyReportVehicle: RelDailyReportVehicle;
  refetch: () => void;
  isClosed: boolean;
};

const DailyReportCreateVehicleRow = ({
  index,
  dailyReport,
  relDailyReportVehicle,
  refetch,
  isClosed,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);
  const [open, setOpen] = useState(false);

  async function onDelete(id: number) {
    try {
      if (dailyReport?.id) {
        await deleteRelDailyReportVehicle(id, tenant?.id);
        refetch();
      }
      enqueueSnackbar(t("dailyReport.deleteSuccess"), {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(t("dailyReport.deleteError"), {
        variant: "error",
      });
    }
  }

  return (
    <Grid container spacing={1} alignItems="center">
      <DailyReportVehicleDialog
        dailyReportId={dailyReport?.id}
        open={open}
        onClose={() => setOpen(false)}
      />
      <Grid item xs={3}>
        <Typography>{relDailyReportVehicle?.vehicle?.licensePlateNumber}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>{relDailyReportVehicle?.driverUser?.name}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>
          {relDailyReportVehicle?.mileage}
          {" km"}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>
          {relDailyReportVehicle?.isRefuelled ? relDailyReportVehicle?.fuelQuantity + " l" : "-"}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {!isClosed && (
          <ConfirmationButton
            buttonType="ICON"
            size="small"
            color="primary"
            style={{ margin: "0 8px" }}
            onSubmit={() => onDelete(relDailyReportVehicle?.id)}
            title={t("dailyReport.removeVehicleTitle")}
            body={t("dailyReport.removeVehicleBody")}
          >
            <Delete fontSize="small" color="primary" />
          </ConfirmationButton>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ marginBottom: 4 }} />
      </Grid>
    </Grid>
  );
};

export default DailyReportCreateVehicleRow;
