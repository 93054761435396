import {
  Box,
  Button,
  Card,
  Collapse,
  Container,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { DOCUMENT_TYPES, PERSONAL_DATA_STATUS_TYPES } from "config/constants";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { PersonalData } from "shared/types";

type Props = {
  personalData?: PersonalData;
};

export type PersonalDataFormValues = {
  id?: number;
  type: string;
  comment: string;
  status: string;
  validTo: Date;
  files: FileList;
  name: string;
  timeOfObtaining: Date | null;
  placeOfObtaining: string;
};

const PersonalDataForm = ({ personalData }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { formState, register, control, watch, setValue } =
    useFormContext<PersonalDataFormValues>();
  const personalDataType = watch("type");

  useEffect(() => {
    if (personalData) {
      setValue("type", personalData.type);
      setValue("status", personalData.status);
      setValue("validTo", new Date(personalData.validTo));
      setValue("comment", personalData.comment);
      if (personalData.type === "QUALIFICATION") {
        setValue("name", personalData.name);
        setValue("timeOfObtaining", new Date(personalData.timeOfObtaining));
        setValue("placeOfObtaining", personalData.placeOfObtaining);
      }
    }
  }, [personalData, setValue]);

  return (
    <Container maxWidth="sm">
      <Card style={{ padding: "20px 16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="type"
              defaultValue={personalData?.type || ""}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  select
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("personalData.formValues.type")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                >
                  {DOCUMENT_TYPES.map(type => (
                    <MenuItem key={type} value={type}>
                      {t(`common:documentTypes.${type}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="status"
              defaultValue={personalData?.status || ""}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  select
                  InputLabelProps={{ shrink: true, required: true }}
                  label={t("personalData.formValues.status")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                >
                  {PERSONAL_DATA_STATUS_TYPES.map(status => (
                    <MenuItem value={status} key={status}>
                      {t(`personalData.statuses.${status}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="validTo"
              defaultValue={personalData?.validTo ? new Date(personalData.validTo) : new Date()}
              rules={{ required: t("validation.required").toString() }}
              render={({ field, fieldState }) => (
                <KeyboardDatePicker
                  {...field}
                  ref={undefined}
                  className="validTo"
                  variant="inline"
                  label={t("personalData.formValues.validTo")}
                  format="yyyy.MM.dd"
                  InputLabelProps={{ shrink: true, required: true }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  autoOk
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="comment"
              defaultValue={personalData?.comment || ""}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  minRows={4}
                  InputLabelProps={{ shrink: true }}
                  label={t("personalData.formValues.comment")}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <Collapse in={personalDataType === "QUALIFICATION"}>
          <Grid container spacing={2} style={{ paddingTop: 16 }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                defaultValue={personalData?.name || ""}
                rules={{
                  required:
                    personalDataType === "QUALIFICATION" && t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("qualification.formValues.name")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="timeOfObtaining"
                defaultValue={
                  personalData?.timeOfObtaining ? new Date(personalData?.timeOfObtaining) : null
                }
                rules={{
                  required:
                    personalDataType === "QUALIFICATION" && t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <KeyboardDatePicker
                    {...field}
                    ref={undefined}
                    variant="inline"
                    className="timeOfObtaining"
                    label={t("qualification.formValues.timeOfObtaining")}
                    format="yyyy.MM.dd"
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    autoOk
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="placeOfObtaining"
                defaultValue={personalData?.placeOfObtaining || ""}
                rules={{
                  required:
                    personalDataType === "QUALIFICATION" && t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("qualification.formValues.placeOfObtaining")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Collapse>
        {!personalData && (
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ paddingTop: 8 }}
          >
            <Button
              variant="text"
              component="label"
              htmlFor="personalDataFiles"
              startIcon={<AttachFile />}
            >
              {t("personalData.formValues.chooseFiles")}
              <input
                id="personalDataFiles"
                style={{ display: "none" }}
                type="file"
                multiple
                {...register("files", {
                  required: {
                    value: false,
                    message: "Fájl kiválasztása kötelező",
                  },
                  validate: value => {
                    if (Array.from(watch("files"))?.find((file: any) => file.size >= 5242880)) {
                      return t("validation.maxSize", {
                        size: 5,
                        unit: "MB",
                      }).toString();
                    }
                  },
                })}
              />
            </Button>
            {formState.errors.files?.message ? (
              <FormHelperText error>{formState.errors.files?.message}</FormHelperText>
            ) : (
              "Fájlonként maximum 5 MB"
            )}
            {!!watch("files")?.length && (
              <Box mt={1}>
                <Typography style={{ fontWeight: "bold" }}>Kiválasztott fájlok:</Typography>
                {Array.from(watch("files"))?.map((file: any, index) => (
                  <Typography key={index}>{file.name}</Typography>
                ))}
              </Box>
            )}
          </Grid>
        )}
        <Box display="flex" justifyContent="center" pt={2} gridGap={8}>
          <Button color="primary" variant="text" onClick={history.goBack}>
            {t("common:button.cancel")}
          </Button>
          <Button type="submit" color="primary">
            {personalData ? t("common:button.save") : t("common:button.create")}
          </Button>
        </Box>
      </Card>
    </Container>
  );
};

export default PersonalDataForm;
