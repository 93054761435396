import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, AddCircle, Delete, Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import NumberField from "components/NumberField";
import {
  NUMBER_FORMAT,
  PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY,
  PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH,
} from "config/constants";
import queryClient from "config/query";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { trim } from "lodash";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { hasAuthority } from "shared/authorization";
import { getItemListByCompanySite } from "shared/network/rel-item-company-site";
import { createTransaction } from "shared/network/transactions.api";
import { listItemsInWarehouse, listWarehousePageable } from "shared/network/warehouse.api";
import { CompanySite, Item, Transaction, Warehouse } from "shared/types";
import { useDebouncedCallback } from "use-debounce";
import ItemCreate from "views/Items/ItemCreate/ItemCreate";

type Props = {
  open: "IN" | "OUT" | "";
  setOpen: Dispatch<SetStateAction<"IN" | "OUT" | "">>;
  companySite: CompanySite;
};

export type TransactionFormValues = {
  warehouse: any; //Warehouse;
  itemEntries: any[]; //TransactionItemEntry[];
};

const TransactionDialog = ({ open, setOpen, companySite }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [itemSearch, setItemSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [itemCreateState, setItemCreateState] = useState<number | null>(null);

  const form = useForm<TransactionFormValues>();
  const { control, handleSubmit, reset, setValue, watch } = form;
  const warehouse = watch("warehouse");

  const { append, fields, remove } = useFieldArray({
    control,
    name: "itemEntries",
    keyName: "key",
    shouldUnregister: true,
  });

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  const warehouseListQuery = useQuery(["warehouseListQuery", tenant.id], async () => {
    const { data } = await listWarehousePageable(0, 10, tenant.id);
    return data.page.content;
  });

  const itemListByCompanySiteQuery = useQuery(
    ["itemListByCompanySiteQuery", tenant.id, companySite, itemSearch],
    async () => {
      if (companySite) {
        const { data } = await getItemListByCompanySite(
          0,
          20,
          companySite,
          tenant.id,
          itemSearch ? `item.name:${itemSearch}` : "",
        );
        return data;
      }
      return Promise.reject();
    },
  );

  const listItemsInWarehouseForTransactionQuery = useQuery(
    ["listItemsInWarehouseForTransactionQuery", warehouse?.id],
    async () => {
      if (warehouse?.id) {
        const { data } = await listItemsInWarehouse(warehouse.id, tenant.id);

        return data.items.filter(item => item.quantity > 0).map(item => item.item);
      }
      return Promise.reject();
    },
  );

  useEffect(() => {
    if (fields.length === 0) {
      append({});
    }
  }, [append, fields.length]);

  const handleItemSearchChange = useDebouncedCallback((value: string) => {
    if (value.length >= PAGEABLE_AUTOCOMPLETE_MIN_STRING_LENGTH || value.length === 0) {
      setItemSearch(value);
    }
  }, PAGEABLE_AUTOCOMPLETE_CALLBACK_DELAY);

  const onSubmit = async (values: TransactionFormValues) => {
    setLoading(true);
    try {
      await createTransaction(
        values.itemEntries.map((entry: any) => {
          return {
            item: entry.item,
            quantity: entry.quantity,
            type: "TRANSFER",
            isIntake: false,
            currency: selectedRelTenant.tenant.baseCurrency,
            unitPrice: entry.unitPrice,
            company: companySite.company,
            date: format(new Date(), "yyyy-MM-dd"),
            itemSerial: {
              factoryNumber: trim(entry.factoryNumberOnIncome) || undefined,
              serialNumber: trim(entry.serialNumber) || undefined,
            },
            toWarehouse: open === "IN" ? values.warehouse : undefined,
            fromWarehouse: open === "OUT" ? values.warehouse : undefined,
            toCompanySite: open === "OUT" ? companySite : undefined,
            fromCompanySite: open === "IN" ? companySite : undefined,
          } as Transaction;
        }),
        tenant.id,
      );
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("warehouse.transaction.subject"),
        }),
        { variant: "success" },
      );
      onClose();
      queryClient.invalidateQueries("underOverFlowQuery");
      queryClient.invalidateQueries("itemListByCompanySiteQuerry");
    } catch (e) {
      const error = e as any;
      if (
        error.data?.status === "INSUFFICIENT_QUANTITY" ||
        error.data?.status === "INSUFFICIENT_QUANTITY_COMPANY_SITE"
      ) {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("warehouse.transaction.subject"),
          }) +
            " " +
            t("common:notification.INSUFFICIENT_QUANTITY"),
          { variant: "error" },
        );
      } else if (error.data?.status === "MISSING_SERIAL_NUMBER_PARAMETERS") {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("warehouse.transaction.subject"),
          }),
          { variant: "error" },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.create.failure", {
            subject: t("warehouse.transaction.subject"),
          }),
          { variant: "error" },
        );
      }
    }
    setLoading(false);
  };

  function onClose() {
    setOpen("");
    reset();
  }

  return (
    <Dialog fullWidth maxWidth="md" open={!!open} onClose={() => onClose()}>
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{open === "OUT" ? "Árukihelyezés" : "Áruvisszavétel"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="warehouse"
                  defaultValue={null}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      style={{ maxWidth: 400, width: "100%" }}
                      onChange={(_, value) => field.onChange(value)}
                      options={warehouseListQuery.data || []}
                      getOptionLabel={(option: Warehouse) => option.name}
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("tenant.formValues.WareHouse")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              {fields.map((entry, index) => {
                const isSerial = watch(`itemEntries.${index}.item`)?.type === "SERIAL_NUMBER";
                return (
                  <Grid item xs={12} key={entry.key}>
                    <Dialog
                      open={itemCreateState !== null}
                      fullWidth
                      maxWidth="md"
                      onClose={() => setItemCreateState(null)}
                    >
                      <DialogTitle>Új termék</DialogTitle>
                      <ItemCreate setItemCreateState={setItemCreateState} />
                    </Dialog>
                    <Grid container spacing={2} key={index}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2" style={{ paddingLeft: 8 }}>{`${
                          index + 1
                        }. termék`}</Typography>
                        <Tooltip title={t("common:button.delete").toString()}>
                          <IconButton
                            disabled={fields.length === 1}
                            size="small"
                            color="primary"
                            onClick={() => remove(index)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6} md={isSerial ? 4 : 6}>
                        {open === "OUT" && (
                          <Controller
                            control={control}
                            name={`itemEntries.${index}.item`}
                            defaultValue={null}
                            rules={{ required: t("validation.required").toString() }}
                            render={({ field, fieldState }) => (
                              <Autocomplete
                                {...field}
                                onChange={(_, value) => {
                                  field.onChange(value);
                                  handleItemSearchChange("");
                                  if (value?.type === "SERIAL_NUMBER") {
                                    setValue(`itemEntries.${index}.quantity`, "1");
                                  }
                                }}
                                onInputChange={(event, newInputValue) => {
                                  handleItemSearchChange(newInputValue);
                                }}
                                options={listItemsInWarehouseForTransactionQuery.data || []}
                                getOptionLabel={(option: Item) => {
                                  if (option.name && option.productCode) {
                                    return `${option.name} (${option.productCode})`;
                                  }
                                  return "";
                                }}
                                getOptionSelected={option => option.id === field.value?.id}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <Tooltip
                                          style={{
                                            paddingRight: "2px",
                                          }}
                                          title={t("warehouse.itemTransfer.itemName").toString()}
                                        >
                                          <Info style={{ color: COLORS.lightBlue }} />
                                        </Tooltip>
                                      ),
                                      endAdornment: (
                                        <>
                                          {params.InputProps.endAdornment}
                                          <InputAdornment position="end">
                                            <Tooltip title="Új termék felvétele">
                                              <IconButton
                                                size="small"
                                                onClick={() => setItemCreateState?.(index)}
                                              >
                                                <AddCircle color="primary" />
                                              </IconButton>
                                            </Tooltip>
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    label={t("warehouse.transaction.formValues.item")}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                  />
                                )}
                              />
                            )}
                          />
                        )}
                        {open === "IN" && (
                          <Controller
                            control={control}
                            name={`itemEntries.${index}.item`}
                            defaultValue={null}
                            rules={{ required: t("validation.required").toString() }}
                            render={({ field, fieldState }) => (
                              <Autocomplete
                                {...field}
                                onChange={(_, value) => {
                                  field.onChange(value);
                                  handleItemSearchChange("");
                                  if (value?.type === "SERIAL_NUMBER") {
                                    setValue(`itemEntries.${index}.quantity`, "1");
                                  }
                                }}
                                onInputChange={(event, newInputValue) => {
                                  handleItemSearchChange(newInputValue);
                                }}
                                options={
                                  itemListByCompanySiteQuery.data?.page?.content?.map(
                                    rel => rel.item,
                                  ) || []
                                }
                                getOptionLabel={(option: Item) => {
                                  if (option.name && option.productCode) {
                                    return `${option.name} (${option.productCode})`;
                                  }
                                  return "";
                                }}
                                getOptionSelected={option => option.id === field.value?.id}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <Tooltip
                                          style={{
                                            paddingRight: "2px",
                                          }}
                                          title={t("warehouse.itemTransfer.itemName").toString()}
                                        >
                                          <Info style={{ color: COLORS.lightBlue }} />
                                        </Tooltip>
                                      ),
                                      endAdornment: (
                                        <>
                                          {params.InputProps.endAdornment}
                                          <InputAdornment position="end">
                                            <Tooltip title="Új termék felvétele">
                                              <IconButton
                                                size="small"
                                                onClick={() => setItemCreateState?.(index)}
                                              >
                                                <AddCircle color="primary" />
                                              </IconButton>
                                            </Tooltip>
                                          </InputAdornment>
                                        </>
                                      ),
                                    }}
                                    label={t("warehouse.transaction.formValues.item")}
                                    InputLabelProps={{ shrink: true, required: true }}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                  />
                                )}
                              />
                            )}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={isSerial ? 2 : 3}>
                        <Controller
                          control={control}
                          name={`itemEntries.${index}.quantity`}
                          defaultValue={"1"}
                          rules={{
                            required: t("validation.required").toString(),
                            validate: value => {
                              if (value && !value.toString().match(NUMBER_FORMAT)) {
                                return t("common:validation.numberFormat").toString();
                              }
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <NumberField
                              field={field}
                              fieldState={fieldState}
                              disabled={isSerial}
                              numberLimits={{ step: "0.01", min: "0.01" }}
                              label={t("warehouse.transaction.formValues.quantity")}
                              InputLabelProps={{ shrink: true, required: true }}
                            />
                          )}
                        />
                      </Grid>
                      {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                        "ACCOUNTANT",
                      ]) && (
                        <Grid item xs={12} sm={6} md={isSerial ? 2 : 3}>
                          <Controller
                            control={control}
                            name={`itemEntries.${index}.unitPrice`}
                            defaultValue={""}
                            rules={{
                              validate: value => {
                                if (value && !value.toString().match(NUMBER_FORMAT)) {
                                  return t("common:validation.numberFormat").toString();
                                }
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <NumberField
                                field={field}
                                fieldState={fieldState}
                                numberLimits={{ step: "0.01", min: "0" }}
                                label={t("warehouse.transaction.formValues.unitPrice")}
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                      )}
                      {isSerial && (
                        <>
                          <Grid item xs={12} sm={6} md={2}>
                            <Controller
                              control={control}
                              name={`itemEntries.${index}.factoryNumberOnIncome`}
                              defaultValue={""}
                              rules={{
                                required: t("validation.required").toString(),
                              }}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  label={t("warehouse.transaction.formValues.factoryNumber")}
                                  InputLabelProps={{
                                    shrink: true,
                                    required: isSerial,
                                  }}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={2}>
                            <Controller
                              control={control}
                              name={`itemEntries.${index}.serialNumber`}
                              defaultValue={""}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  label={t("warehouse.transaction.formValues.serialNumber")}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              <Box display="flex" justifyContent="center" width="100%">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginBottom: 8, marginTop: 16 }}
                  onClick={event => {
                    event.stopPropagation();
                    append({});
                  }}
                  startIcon={<AddBox />}
                >
                  {t("offer.relOfferItem.add")}
                </Button>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={() => onClose()} style={{ marginRight: 8 }}>
              {t("common:button.close")}
            </Button>
            <Button type="submit">{t("common:button.ok")}</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default TransactionDialog;
