import { faStore, faStoreSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddBox, Assignment, Block, CheckCircleOutline, Edit } from "@material-ui/icons";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { Link as RouterLink } from "components/Router";
import { ITEM_TYPES, SUB_ITEM_TYPE } from "config/constants";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { listItemCategory } from "shared/network/category.api";
import { listItems } from "shared/network/item.api";
import { CategoryToItemLink, ItemPriceHistory, ItemTaxHistory } from "shared/types";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/Company/CompanyList";

const ItemList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-itemlist-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-itemlist-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const tenant = selectedRelTenant.tenant;

  const listItemsQuery = useQuery(
    ["listItemsQueryItemList", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const filters = filterValue.split(";");
      const categoryName = filters
        ?.find(filter => filter.startsWith("categories"))
        ?.split(":")?.[1];
      let temp = "";
      filters.forEach(filter => {
        if (filter.length >= 1 && !filter.startsWith("categories")) {
          temp += filter + ";";
        }
      });

      const { data } = await listItems(
        page,
        pageSize,
        tenant.id,
        temp,
        sortState,
        categoryName || "",
      );
      return data;
    },
  );

  const itemCategoriesQuery = useQuery(["itemCategoriesQuery", tenant.id], async () => {
    const { data } = await listItemCategory(tenant.id);
    return data.items;
  });
  const categories = itemCategoriesQuery.data;

  const columns: GridColDef[] = [
    {
      field: "manufacturer",
      headerName: t("item.manufacturer"),
      flex: 2,
      hide: !tenant.isUseManufacturer,
    },
    {
      field: "name",
      headerName: t("item.name"),
      flex: 2,
    },
    {
      field: "productCode",
      headerName: t("item.productCode"),
      flex: 2,
      type: "stringContainsNumber",
    },
    {
      field: "type",
      headerName: t("item.type"),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) => t(`item.types.${row.type}`),
      type: "select",
    },
    {
      field: "categories",
      headerName: t("item.categories"),
      flex: 2,
      type: "select",
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <Tooltip
            title={
              <Box>
                {row.categories.map((category: CategoryToItemLink) => (
                  <Typography key={category.id}>{category.category.name}</Typography>
                ))}
              </Box>
            }
          >
            <Typography style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {row.categories.map((category: CategoryToItemLink, index: number) => (
                <Fragment key={index}>
                  {category.category.name}
                  {index + 1 !== row.categories?.length && (
                    <span style={{ paddingRight: 6 }}>,</span>
                  )}
                </Fragment>
              ))}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "price",
      headerName: t("item.currentPrice"),
      flex: 2,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) => {
        const price = row.itemPriceHistories.find(
          (price: ItemPriceHistory) =>
            price.isActive && tenant.baseCurrency.id === price?.currency?.id,
        );
        if (
          account.user.isSuperAdmin ||
          selectedRelTenant.isTenantAdmin ||
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["PRICE_WATCHER"])
        ) {
          if (price) {
            return `${price?.currency?.prefix ? `${price?.currency?.prefix} ` : ""}${t(
              "common:number",
              { num: price.price },
            )}${price?.currency?.postfix ? ` ${price?.currency?.postfix}` : ""}`;
          } else {
            return "Nincs alapár";
          }
        } else return "Nincs jogosultsága";
      },
    },
    {
      field: "tax",
      headerName: t("item.tax"),
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) => {
        const tax =
          row.itemTaxHistories?.find((tax: ItemTaxHistory) => tax.isActive)?.tax?.percent || 0;

        if (
          account.user.isSuperAdmin ||
          selectedRelTenant.isTenantAdmin ||
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["PRICE_WATCHER"])
        ) {
          return tax ? `${t("common:number", { num: tax })} %` : "-";
        } else return "-";
      },
    },
    {
      field: "grossPrice",
      headerName: t("item.grossPrice"),
      flex: 2,
      sortable: false,
      valueGetter: ({ row }: GridValueGetterParams) => {
        const price = row.itemPriceHistories.find(
          (price: ItemPriceHistory) =>
            price.isActive && tenant.baseCurrency.id === price?.currency?.id,
        );
        const tax =
          row.itemTaxHistories?.find((tax: ItemTaxHistory) => tax.isActive)?.tax?.percent / 100 ||
          0;
        if (
          account.user.isSuperAdmin ||
          selectedRelTenant.isTenantAdmin ||
          hasAuthority(account.user, account.permissions, selectedRelTenant, ["PRICE_WATCHER"])
        ) {
          if (price) {
            return `${price?.currency?.prefix ? `${price?.currency?.prefix} ` : ""}${t(
              "common:number",
              { num: price.price * (1 + tax) },
            )}${price?.currency?.postfix ? ` ${price?.currency?.postfix}` : ""}`;
          } else {
            return "Nincs alapár";
          }
        } else return "-";
      },
    },
    {
      field: "purchasable",
      headerName: t("item.purchasable.title"),
      flex: 1,
      align: "center",
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) => {
        return row.purchasable ? (
          <Tooltip title={"Megvásárolható"}>
            <div>
              <FontAwesomeIcon
                icon={faStore}
                style={{
                  fontSize: "20px",
                  color: COLORS.green,
                }}
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={"Nem vásárolható meg"}>
            <div>
              <FontAwesomeIcon
                icon={faStoreSlash}
                style={{
                  fontSize: "20px",
                  color: COLORS.red,
                }}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "isComposite",
      headerName: t("item.isComposite"),
      flex: 1,
      type: "boolean",
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isComposite ? (
          <CheckCircleOutline style={{ color: COLORS.green }} />
        ) : (
          <Block style={{ color: COLORS.red }} />
        ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      align: "right",
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ITEM_GET"]) && (
            <Tooltip title={t("item.itemDetails").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={RouterLink}
                to={`/items/list/item-details?id=${row.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ITEM_ADMIN"]) && (
            <Tooltip title={t(`item.itemModify`).toString()}>
              <IconButton
                size="small"
                color="primary"
                component={RouterLink}
                to={`/items/list/item-modify?id=${row.id}`}
                style={{ margin: "0 8px" }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" alignItems="center" gridGap={8}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ITEM_ADMIN"]) && (
          <Button
            variant="contained"
            to="/items/list/item-create"
            component={Link}
            startIcon={<AddBox />}
          >
            {t("item.itemCreate")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions" && params.field !== "purchasable") {
      history.push(`/items/list/item-details?id=${params.row.id}`);
    }
  }

  return (
    <PageableTable
      filterOptions={[
        {
          columnName: "type",
          options: ITEM_TYPES.map(value => {
            return {
              translated: t(`item.types.${value}`),
              value,
              subItem: SUB_ITEM_TYPE.includes(value),
            };
          }),
        },
        {
          columnName: "categories",
          options: categories?.map(category => {
            return {
              translated: category.name,
              value: category.name,
            };
          }),
        },
      ]}
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="itemlist"
      filterable={
        tenant.isUseManufacturer
          ? [
              "manufacturer",
              "name",
              "productCode",
              "type",
              "isComposite",
              "categories",
              "purchasable",
            ]
          : ["name", "productCode", "type", "isComposite", "categories", "purchasable"]
      }
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={listItemsQuery}
      onCellClick={onCellClick}
    />
  );
};
export default ItemList;
