import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { AddBox, Assignment, Edit, Note } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { GridCellParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import ItemIcon from "components/Layout/SideBar/icons/ItemIcon";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getVehicleByUserId } from "shared/network/vehicle.api";
import { SliceStatus } from "shared/common";
import {
  createIssueForDeliveryPoint,
  getPlannedDeliveryRoundList,
} from "shared/network/delivery-round.api";
import { getVehicleListPageable } from "shared/network/vehicle.api";
import { Delivery, DeliveryRound, Vehicle } from "shared/types";
import Loading from "components/Loading";
import { useGetSessionStorageKey } from "views/Comment/function";

export type Props = {
  selectedDelivery: Delivery | null;
  setSelectedDelivery: Dispatch<SetStateAction<Delivery | null>>;
  refetch: () => void;
  selectedStatus?: string;
  fromStatusCell?: boolean;
};

export type DeliveryRoundIssueFormValues = {
  vehicle: any; //Vehicle | null;
  date: Date;
  deliveryRound: DeliveryRound | null;
  deliveryRoundId: number | string;
};

export type TableState = {
  page: number;
  pageSize: number;
  filterOpen: boolean;
  filterValue: string;
};

const PlannedDeliveryRoundList = () => {
  const { t } = useTranslation();
  const { setHeaderButtons } = useHeader();
  const history = useHistory();
  const { control, handleSubmit, register } = useForm<DeliveryRoundIssueFormValues>();
  const [issueCreateOpen, setIssueCreateOpen] = useState<number | string>("");
  const [sortState, setSortState] = useState("");
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState<SliceStatus>("idle");
  const { enqueueSnackbar } = useSnackbar();
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-delivery-round-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-delivery-round-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const vehicleQuery = useQuery(
    ["vehicleByUserQueryForMyDelivery", account, selectedRelTenant],
    async () => {
      const { data } = await getVehicleByUserId(account.user.id, selectedRelTenant.tenant.id);
      return data.item.id;
    },
  );

  const vehicleId = vehicleQuery.data;

  const deliveryRoundListQuery = useQuery(
    ["deliveryRoundListQuery", tenant.id, filterValue, page, pageSize, sortState, date, vehicleId],
    async () => {
      if (vehicleId) {
        const { data } = await getPlannedDeliveryRoundList(
          page,
          pageSize,
          tenant.id,
          sortState,
          date ? format(date, "yyyy-MM-dd") : "",
          vehicleId.toString(),
        );
        return data;
      }
      return Promise.reject();
    },
    { enabled: !!vehicleId },
  );

  const vehicleListQuery = useQuery(["vehicleListQuery", selectedRelTenant.tenant.id], async () => {
    const { data } = await getVehicleListPageable(0, 1000000, selectedRelTenant.tenant.id);

    return data;
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("deliveryRound.name"),
      flex: 1,
    },
    {
      field: "settlement",
      headerName: t("deliveryRound.settlement"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: " ",
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ROUND_GET",
          ]) && (
            <Tooltip title={t("deliveryRound.details").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/companies/delivery-round/deliverypoint-list?id=${row.id}`}
              >
                <Assignment />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ROUND_GET",
          ]) && (
            <Tooltip title={t("deliveryRound.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/companies/delivery-round/deliveryround-modify?id=${row.id}`}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ROUND_GET",
            "DELIVERY_ROUND_ADMIN",
          ]) && (
            <Tooltip title={t("deliveryRound.issue").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => {
                  setIssueCreateOpen(row.id);
                }}
              >
                <Note />
              </IconButton>
            </Tooltip>
          )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_POINT_PRICE",
          ]) && (
            <Tooltip title={t("deliveryRound.addItem").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                component={Link}
                to={`/companies/delivery-round/deliveryround-item-modify?id=${row.id}`}
              >
                <ItemIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  async function onSubmitCreate(values: DeliveryRoundIssueFormValues) {
    setStatus("pending");
    try {
      if (values.vehicle?.id && values.deliveryRoundId) {
        await createIssueForDeliveryPoint(
          {
            deliveryRoundId: parseInt(values.deliveryRoundId.toString()),
            vehicleId: values.vehicle?.id,
            date: format(values.date, "yyyy-MM-dd").toString(),
          },
          tenant.id,
        );
        setStatus("success");
        enqueueSnackbar(
          t("common:notification.create.success", {
            subject: t("deliveryRound.subject"),
          }),
          {
            variant: "success",
          },
        );
        history.goBack();
      } else {
        throw new Error();
      }
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("deliveryRound.subject"),
        }),
        {
          variant: "error",
        },
      );
      setStatus("failure");
    }
  }

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DELIVERY_ROUND_ADMIN",
        ]) && (
          <Button
            component={Link}
            to="/companies/delivery-round/deliveryround-create"
            variant="contained"
            style={{ margin: "auto" }}
            startIcon={<AddBox />}
          >
            {t("deliveryRound.create")}
          </Button>
        )}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(`/companies/delivery-round/deliverypoint-list?id=${params.row.id}`);
    }
  }

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
      <Loading open={status === "pending"} />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={3} style={{ margin: 16 }}>
          <KeyboardDatePicker
            size="small"
            value={date}
            onChange={date => {
              date && setDate(date);
            }}
            label={t("delivery.formValues.date")}
            format="yyyy.MM.dd."
            InputLabelProps={{ shrink: true, required: true }}
            variant="inline"
            autoOk
            ref={undefined}
          />
        </Grid>
      </Grid>
      <PageableTable
        noSearch
        transparent
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="delivery-round"
        filterable={["name", "settlement"]}
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={deliveryRoundListQuery}
        onCellClick={onCellClick}
      />
      <Dialog open={!!issueCreateOpen}>
        <DialogTitle>{t("invoice.addIssue")}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmitCreate)}>
          <DialogContent>
            {!!issueCreateOpen && (
              <input type="hidden" value={issueCreateOpen} {...register("deliveryRoundId")} />
            )}
            <Grid container spacing={2} style={{ width: 350 }}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="vehicle"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={vehicleListQuery.data?.page?.content || []}
                      getOptionLabel={(option: Vehicle) => option.licensePlateNumber}
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("tool.formValues.vehiclePlateNumber")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="date"
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      variant="inline"
                      className="localDate"
                      label={t("planning.date")}
                      format="yyyy.MM.dd"
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      autoOk
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="center" m={2} gridGap={8}>
              <Button color="primary" variant="text" onClick={() => setIssueCreateOpen("")}>
                {t("common:button.cancel")}
              </Button>
              <Button type="submit" color="primary">
                {t("common:button.save")}
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </Card>
  );
};
export default PlannedDeliveryRoundList;
