import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { User } from "shared/types";

export type HaccpItemDateComment = {
  id: number;
  tenantId: number;
  haccpItemId: number;
  date: string;
  comment: string;
  createdBy: User;
  updatedBy: User;
  createdOn: string;
  updatedOn: string;
};

const ENDPOINT = "/haccp-item-date-comment";

export const createHaccpItemDateComment = (
  param: Partial<HaccpItemDateComment>,
  tenantId: number,
) => axios.post(ENDPOINT, { param, tenantId });

export const modifyHaccpItemDateComment = (
  param: Partial<HaccpItemDateComment>,
  tenantId: number,
) => axios.put(ENDPOINT, { param, tenantId });

export const getHaccpItemDateCommentPage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.get<GenericPageResponse<HaccpItemDateComment>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search || ""}&sort=${
      sort || ""
    }`,
  );
