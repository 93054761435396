import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { RelIssueItemEntry } from "shared/types";

const ENDPOINT = "/rel-issue-item";

export const createRelIssueItem = (param: Partial<RelIssueItemEntry>, tenantId: number) =>
  axios.post(`${ENDPOINT}/add`, { param, tenantId });

export const modifyRelIssueItem = (param: RelIssueItemEntry, tenantId: number) =>
  axios.post(`${ENDPOINT}/modify`, { param, tenantId });

export const deleteRelIssueItem = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?param=${param}&tenantId=${tenantId}`);

export const listRelIssueItems = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<RelIssueItemEntry>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //INVOICE_GET
