import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Loading from "components/Loading";
import SignatureCanvas from "components/SignatureCanvas";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SliceStatus } from "shared/common";
import { updateForTodayDeliveres } from "shared/network/delivery.api";
import { RelItemDelivery, Vehicle } from "shared/types";

const useStyles = makeStyles({
  formCardHeader: {
    height: "fit-content",
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
    textAlign: "center",
    padding: "8px 8px 8px 8px",
    width: "100%",
    fontWeight: "bold",
    background: COLORS.mainGrey,
  },
});

type Props = {
  vehicle: Vehicle | null;
  relItemList?: RelItemDelivery[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
  cargoRefetch: () => void;
  statusRefetch: () => void;
};

const DailyDeliverySignatureDialog = ({
  vehicle,
  relItemList,
  open,
  setOpen,
  refetch,
  cargoRefetch,
  statusRefetch,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const [transferorSignatureFile, setTransferorSignatureFile] = useState<File>(new File([], ""));

  const [recipientSignatureFile, setRecipientSignatureFile] = useState<File>(new File([], ""));

  const [status, setStatus] = useState<SliceStatus>("idle");

  const onSubmitCreate = async () => {
    try {
      setStatus("pending");
      const transferor = document.getElementById("signatureWarehouse") as HTMLCanvasElement;
      const recipient = document.getElementById("signatureVehicleDriver") as HTMLCanvasElement;
      if (transferor && recipient) {
        transferor.toBlob(blob => {
          if (blob) {
            setTransferorSignatureFile(
              new File([blob], "transferorSignature.png", {
                type: "image/png",
              }),
            );
          }
        });
        recipient.toBlob(blob => {
          if (blob) {
            setRecipientSignatureFile(
              new File([blob], "recipientSignature.png", {
                type: "image/png",
              }),
            );
          }
        });
      }
      if (vehicle?.id && relItemList) {
        await updateForTodayDeliveres(
          vehicle?.id.toString(),
          relItemList,
          transferorSignatureFile,
          recipientSignatureFile,
          tenant.id.toString(),
        );
      }
      setOpen(false);
      refetch();
      cargoRefetch();
      statusRefetch();
      setStatus("success");
      enqueueSnackbar(
        t("common:notification.dailyDelivery.success", {
          subject: t("delivery.subject"),
        }),
        { variant: "success" },
      );
    } catch (e: any) {
      enqueueSnackbar(
        t("common:notification.dailyDelivery.failure", {
          subject: t("delivery.subject"),
        }),
        { variant: "error" },
      );
      setStatus("failure");
    }
  };

  const ref = useRef<HTMLDivElement | null>();
  const [offset, setOffset] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  return (
    <>
      <Loading open={status === "pending"} />
      <Dialog open={open}>
        <DialogContent
          ref={ref}
          onScroll={() => {
            setOffset({ top: ref.current?.scrollTop || 0, left: ref.current?.scrollLeft || 0 });
          }}
        >
          <Grid container justifyContent="center">
            <Box className={classes.formCardHeader} mb={1}>
              <Typography variant="h3">{t("common:signature.warehouse")}</Typography>
            </Box>
            <Grid item xs={6} container justifyContent="center" style={{ margin: "0 0 8px 0" }}>
              <SignatureCanvas
                id={"signatureWarehouse"}
                offsetTop={offset.top}
                offsetLeft={offset.left}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Box className={classes.formCardHeader} mb={1}>
              <Typography variant="h3">{t("common:signature.vehicleDriver")}</Typography>
            </Box>
            <Grid item xs={6} container justifyContent="center" style={{ margin: "0 0 8px 0" }}>
              <SignatureCanvas
                id={"signatureVehicleDriver"}
                offsetTop={offset.top}
                offsetLeft={offset.left}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" m={2.5} gridGap={8}>
            <Button color="primary" variant="text" onClick={() => setOpen(false)}>
              {t("common:button.cancel")}
            </Button>
            <Button color="primary" onClick={() => onSubmitCreate()}>
              {t("common:button.save")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DailyDeliverySignatureDialog;
