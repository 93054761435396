import { Box, Button, Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import Loading from "components/Loading";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { listPublicComment } from "shared/network/comment.api";
import { Comment } from "shared/types";
import PublicCommentCreate from "./PublicCommentCreate";
import PublicCommentRow from "./PublicCommentRow";

type Props = {
  token: string;
  email: string;
  tenantId: number;
  subjectId: number;
};

const PublicCommentList = ({ token, email, tenantId, subjectId }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const commentListQuery = useQuery(["issueCommentList"], async () => {
    const { data } = await listPublicComment(
      token,
      email,
      tenantId,
      "REPORT",
      subjectId,
      subjectId,
    );
    return data.items;
  });
  return (
    <>
      <Loading open={commentListQuery.isFetching} />
      <PublicCommentCreate
        token={token}
        email={email}
        tenantId={tenantId}
        subjectType="REPORT"
        subjectId={subjectId.toString()}
        open={open}
        setOpen={setOpen}
        refetch={commentListQuery.refetch}
      />
      <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
        <CardHeader
          style={{ paddingBottom: 0 }}
          disableTypography
          title={
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" gridGap={8}>
              <Typography variant="h2">{t("comment.title")}</Typography>
              <Button
                color="primary"
                size="small"
                variant="text"
                startIcon={<AddBox />}
                onClick={() => setOpen(true)}
              >
                {t("comment.create")}
              </Button>
            </Box>
          }
        />
        <CardContent>
          {commentListQuery.isError ? (
            <Typography>{t("common:errorWhileFetching")}</Typography>
          ) : commentListQuery.data?.length === 0 ? (
            <Typography>{t("comment.noComment")}</Typography>
          ) : (
            commentListQuery.data?.map((comment: Comment) => (
              <PublicCommentRow
                key={comment.id}
                comment={comment}
                subjectType="REPORT"
                email={email}
                token={token}
                tenantId={tenantId}
                subjectId={subjectId}
                reportId={subjectId}
              />
            ))
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default PublicCommentList;
