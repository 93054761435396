import { faAddressCard, faCommentDots, faTruckLoading } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateDeliveryStatus } from "shared/network/delivery.api";
import {
  Box,
  Card,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  CancelOutlined,
  Check,
  CheckCircleOutline,
  Close,
  Forward,
  HelpOutline,
  HomeWork,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Map,
  Visibility,
} from "@material-ui/icons";
import { TITLE_FONT } from "config/roraTheme";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { getDeliveryNoteByDeliveryId } from "shared/network/deliveryNote.api";
import { getDeliveryNoteFileURL } from "shared/network/files.api";
import { Delivery, RelItemDelivery } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import { deliveryNoteLinkOpen } from "shared/util/fileDownload";
import DeliveryStatusDialog from "views/Delivery/DeliveryStatusDialog";
import MyDeliveryItemDialog from "./MyDeliveryItemDialog";
import MyDeliveryItemRow from "./MyDeliveryItemRow";

const useStyles = makeStyles({
  row: {
    border: `2px solid ${COLORS.main}`,
    margin: "0 16px 16px 16px",
  },
});

type Props = {
  index: number;
  delivery: Delivery;
  deliveryListRefetch: () => void;
};

const MyDeliveryCard = ({ index, delivery, deliveryListRefetch }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [deliveryId, setDeliveryId] = useState<number | null>(null);
  const [selectedDelivery, setSelectedDelivery] = useState<Delivery | null>(null);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  async function viewDeliveryNote(deliveryId: number) {
    try {
      const { data } = await getDeliveryNoteByDeliveryId(deliveryId, tenant.id);
      try {
        if (data?.item?.id) {
          deliveryNoteLinkOpen(getDeliveryNoteFileURL(data.item.id.toString(), tenant.id));
        } else {
          enqueueSnackbar(
            t("common:notification.deliveryNote.notExist", {
              subject: t("deliveryNote.subject"),
            }),
            { variant: "error" },
          );
        }
      } catch (e: any) {
        if (e.data?.status === "RESOURCE_ERROR") {
          enqueueSnackbar(
            t("common:notification.resource.failure", {
              subject: t("commmon:resource"),
            }),
            { variant: "error" },
          );
        } else {
          enqueueSnackbar(
            t("common:notification.download.failure", {
              subject: t("deliveryNote.subject"),
            }),
            { variant: "error" },
          );
        }
      }
    } catch (e: any) {
      if (e.data?.status === "RESOURCE_ERROR") {
        enqueueSnackbar(
          t("common:notification.resource.failure", {
            subject: t("commmon:resource"),
          }),
          { variant: "error" },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.deliveryNote.notExist", {
            subject: t("deliveryNote.subject"),
          }),
          { variant: "error" },
        );
      }
    }
  }

  const isSignatureRequired = !!delivery?.order?.company?.isSignatureRequired;
  const [loading, setLoading] = useState(false);

  const onSubmitCreate = async (status: string) => {
    setLoading(true);
    try {
      await updateDeliveryStatus(status, delivery?.id.toString(), tenant?.id.toString());
      deliveryListRefetch();
      enqueueSnackbar("Sikeres módosítás.", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar("Sikertelen módosítás.", { variant: "error" });
    }
    setLoading(false);
  };

  return (
    <Card variant="outlined" className={classes.row}>
      <Loading open={loading} />
      <DeliveryStatusDialog
        selectedDelivery={selectedDelivery}
        setSelectedDelivery={setSelectedDelivery}
        refetch={deliveryListRefetch}
        selectedStatus={selectedStatus}
        fromStatusCell
      />
      <MyDeliveryItemDialog deliveryId={deliveryId} setDeliveryId={setDeliveryId} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="8px 16px"
        style={{
          borderBottom: `1px solid ${COLORS.mainGrey}`,
        }}
      >
        <Typography
          color="primary"
          style={{
            fontSize: "1rem",
            fontFamily: TITLE_FONT,
            fontWeight: 500,
            color: COLORS.main,
          }}
        >
          {`${index + 1}. ${t("delivery.title")}`}
        </Typography>
        <Box display="flex" gridGap={4}>
          {(selectedRelTenant.isTenantAdmin || account.user.isSuperAdmin) && (
            <Tooltip title={t("deliveryNote.deliveryNoteView").toString()}>
              <IconButton
                size="small"
                color="primary"
                onClick={async () => {
                  viewDeliveryNote(delivery.id);
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          {delivery.status !== "DELIVERED" &&
          delivery.status !== "FAILED" &&
          delivery.status !== "CANCELLED" &&
          delivery.status !== "SUSPENDED" ? (
            <>
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "DELIVERY_ADMIN",
              ]) && (
                <Tooltip title="Kiszállítva">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      if (isSignatureRequired) {
                        setSelectedDelivery(delivery);
                        setSelectedStatus("DELIVERED");
                      } else {
                        onSubmitCreate("DELIVERED");
                      }
                    }}
                    disabled={delivery.status === "ACTIVE" || delivery.status === "WITHDRAWN"}
                  >
                    <CheckCircleOutline />
                  </IconButton>
                </Tooltip>
              )}
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "DELIVERY_ADMIN",
              ]) && (
                <Tooltip title="Meghiúsult">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setSelectedDelivery(delivery);
                      setSelectedStatus("FAILED");
                    }}
                    disabled={delivery.status === "ACTIVE" || delivery.status === "WITHDRAWN"}
                  >
                    <CancelOutlined />
                  </IconButton>
                </Tooltip>
              )}
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "DELIVERY_ADMIN",
              ]) && (
                <Tooltip title={t("delivery.showItems").toString()}>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => setDeliveryId(delivery.id)}
                    disabled={delivery.status !== "IN_PROGRESS"}
                  >
                    <FontAwesomeIcon icon={faTruckLoading} style={{ width: 24, height: 24 }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              {delivery.status === "DELIVERED" && (
                <Box display="flex" alignItems="center" justifyContent="center" p={0.5}>
                  <Tooltip title="Kiszállítva">
                    <Check style={{ color: COLORS.green }} />
                  </Tooltip>
                </Box>
              )}
              {(delivery.status === "FAILED" ||
                delivery.status === "CANCELLED" ||
                delivery.status === "SUSPENDED") && (
                <Box display="flex" alignItems="center" justifyContent="center" p={0.5}>
                  <Tooltip title={delivery.status === "SUSPENDED" ? "Felfüggesztve" : "Meghiúsult"}>
                    <Close style={{ color: COLORS.red }} />
                  </Tooltip>
                </Box>
              )}
            </>
          )}
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {!open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="8px 8px 8px 16px"
        gridGap={8}
        style={{ borderBottom: `1px solid ${COLORS.mainGrey}` }}
      >
        <Grid container>
          {delivery.order?.orderId && (
            <Grid item xs={12} sm={6} lg={3} container style={{ gridGap: 8, flexWrap: "nowrap" }}>
              <Tooltip title={t("delivery.formValues.orderIdCode").toString()}>
                <HelpOutline style={{ fontSize: 20, width: 20 }} />
              </Tooltip>
              <Typography>{delivery.order?.orderId}</Typography>
            </Grid>
          )}
          {delivery.company && (
            <Grid item xs={12} sm={6} lg={3} container style={{ gridGap: 8, flexWrap: "nowrap" }}>
              <Tooltip title={t("delivery.formValues.partnerName").toString()}>
                <div>
                  <FontAwesomeIcon icon={faAddressCard} style={{ fontSize: 20, width: 20 }} />
                </div>
              </Tooltip>
              <Typography style={{ fontWeight: "bold" }}>{delivery.company?.name}</Typography>
            </Grid>
          )}
          {delivery.order?.companySite?.name && (
            <Grid item xs={12} sm={6} lg={3} container style={{ gridGap: 8, flexWrap: "nowrap" }}>
              <Tooltip title={t("delivery.formValues.toCompanySite").toString()}>
                <div>
                  <Forward style={{ fontSize: 20, width: 20 }} />
                  <HomeWork style={{ fontSize: 20, width: 20 }} />
                </div>
              </Tooltip>
              <Typography style={{ fontWeight: "bold" }}>
                {delivery.order?.companySite?.name}
              </Typography>
            </Grid>
          )}
          {delivery.targetAddress && (
            <Grid item xs={12} sm={6} lg={3} container style={{ gridGap: 8, flexWrap: "nowrap" }}>
              <Tooltip title={t("delivery.formValues.destinationAddress").toString()}>
                <div>
                  <Forward style={{ fontSize: 20, width: 20 }} />
                  <Map style={{ fontSize: 20, width: 20 }} />
                </div>
              </Tooltip>
              <Typography>{displayAddress(delivery.targetAddress)}</Typography>
            </Grid>
          )}
          {delivery.fromWarehouse && (
            <Grid item xs={12} sm={6} lg={3} container style={{ gridGap: 8, flexWrap: "nowrap" }}>
              <Tooltip title={t("delivery.formValues.fromWarehouse").toString()}>
                <div>
                  <HomeWork style={{ fontSize: 20, width: 20 }} />
                  <Forward style={{ fontSize: 20, width: 20 }} />
                </div>
              </Tooltip>
              <Typography>{delivery.fromWarehouse.name}</Typography>
            </Grid>
          )}
          {delivery.order?.deliveryComment && (
            <Grid item xs={12} sm={6} lg={3} container style={{ gridGap: 8, flexWrap: "nowrap" }}>
              <Tooltip title={t("delivery.formValues.comment").toString()}>
                <div>
                  <FontAwesomeIcon icon={faCommentDots} style={{ fontSize: 20, width: 20 }} />
                </div>
              </Tooltip>
              <Typography>{delivery.order?.deliveryComment}</Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      <Collapse in={open}>
        <Box>
          {!delivery?.relItemDeliveryItems?.length ? (
            <Box display="flex" justifyContent="center">
              <Typography>{t("delivery.noItem")}</Typography>
            </Box>
          ) : (
            <>
              <Grid container style={{ borderBottom: `1px solid ${COLORS.mainGrey}` }}>
                <Grid item xs={8} style={{ padding: 8 }}>
                  <Typography variant="body2">{t("item.name")}</Typography>
                </Grid>
                <Grid item xs={2} style={{ padding: 8 }}>
                  <Typography variant="body2">{t("article.formValues.subjectType")}</Typography>
                </Grid>
                <Grid item xs={2} style={{ padding: 8 }}>
                  <Typography variant="body2">{t("delivery.pcs")}</Typography>
                </Grid>
              </Grid>
              {delivery?.relItemDeliveryItems?.map((relItem: RelItemDelivery) => (
                <MyDeliveryItemRow
                  key={relItem.id}
                  deliveryItem={{
                    ...relItem,
                    deliveryId: delivery.id,
                  }}
                />
              ))}
            </>
          )}
        </Box>
      </Collapse>
    </Card>
  );
};

export default MyDeliveryCard;
