import { Box, Container, Tab, Tabs } from "@material-ui/core";
import PrivateRoute from "components/PrivateRoute";
import { RootState } from "config/store";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router";
import { Link, Switch } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { getVehicleByUserId } from "shared/network/vehicle.api";
import PlannedDeliveryRoundList from "views/DeliveryRound/PlannedDeliveryRoundList";

import MyDeliveryList from "./MyDeliveryList";
import { useHeader } from "components/Layout/HeaderContext";
import { useEffect } from "react";

export const MYDELIVERY_TABS = [
  "/mydelivery/delivery-list",
  "/mydelivery/delivery-round-list",
  "/mydelivery/daily-delivery",
];

const MyDeliveryTabs = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const vehicleQuery = useQuery(
    ["vehicleByUserQueryForMyDelivery", account, selectedRelTenant],
    async () => {
      const { data } = await getVehicleByUserId(account.user.id, selectedRelTenant.tenant.id);
      return data.item.id;
    },
  );

  const vehicleId = vehicleQuery.data;

  const { setTopPadding } = useHeader();
  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <>
      {(location.pathname === "/mydelivery/delivery-list" ||
        location.pathname === "/mydelivery") && (
        <Redirect to={`/mydelivery/delivery-list?id=${vehicleId}`} />
      )}
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={location.pathname}
        indicatorColor="primary"
        textColor="primary"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", paddingLeft: 24, paddingRight: 24 }}
      >
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DELIVERY_ADMIN",
          "DELIVERY_GET",
        ]) && (
          <Tab
            label={t("vehicle.tabs.delivery")}
            component={Link}
            to={`${MYDELIVERY_TABS[0]}?id=${vehicleId}`}
            value={MYDELIVERY_TABS[0]}
          />
        )}
        {selectedRelTenant.tenant.accessToDeliveryPoint &&
          hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "DELIVERY_ROUND_GET",
          ]) && (
            <Tab
              label={t("company.titles.deliveryRound")}
              component={Link}
              to={MYDELIVERY_TABS[1]}
              value={MYDELIVERY_TABS[1]}
            />
          )}
        {/* {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "DELIVERY_ROUND_GET",
        ]) && (
          <Tab
            label={t("company.titles.dailyDelivery")}
            component={Link}
            to={MYDELIVERY_TABS[2]}
            value={MYDELIVERY_TABS[2]}
          />
        )} */}
      </Tabs>
      <Container maxWidth="xl">
        <Box pt={2}>
          <Switch location={location}>
            <PrivateRoute
              path={MYDELIVERY_TABS[0]}
              component={MyDeliveryList}
              permissions={["VEHICLE_GET"]}
            />
            <PrivateRoute
              path={MYDELIVERY_TABS[1]}
              component={PlannedDeliveryRoundList}
              permissions={["DELIVERY_ROUND_GET"]}
            />
            {/* <PrivateRoute
            path={MYDELIVERY_TABS[2]}
            component={DailyDeliveryList}
            permissions={["DELIVERY_ROUND_GET"]}
          /> */}
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default MyDeliveryTabs;
