import MonitoringAlertList from "./components/MonitoringAlertLlist";
import { useTranslation } from "react-i18next";

const MonitoringDashboard = () => {
  const { t } = useTranslation();

  return (
    <MonitoringAlertList
      title={t("monitoring.alert.dashboardTitle")}
      isDashboard={true}
    />
  );
};

export default MonitoringDashboard;
