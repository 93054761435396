import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { User } from "shared/types";

export type RelDailyReportCashFlow = {
  id: number;
  tenantId: number;
  dailyReportId: number;
  user: User;
  invoiceId: number;
  cashAmount: number;
};

const ENDPOINT = "/daily-report-cash-flow";

export const createRelDailyReportCashFlow = (
  param: Partial<RelDailyReportCashFlow>,
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}`, {
    param,
    tenantId,
  });

export const modifyRelDailyReportCashFlow = (param: RelDailyReportCashFlow, tenantId: number) =>
  axios.put(`${ENDPOINT}`, {
    param,
    tenantId: tenantId,
  }); //ISTENANTADMIN ISSUPERADMIN

export const deleteRelDailyReportCashFlow = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?param=${param}&tenantId=${tenantId}`);

export const listRelDailyReportCashFlowByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<RelDailyReportCashFlow>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //LOGIN_REQUIRED

export const getRelDailyReportCashFlowById = (param: number | string, tenantId: number) =>
  axios.post<GenericResponse<RelDailyReportCashFlow>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //LOGIN_REQUIRED
