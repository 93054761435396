import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { Item, User } from "shared/types";

export type ImportedDocument = {
  id: number;
  tenantId: number;
  title: string;
  type: string;
  shortDescription: string;
  description: string;
  filePath: string;
  issuedAt: string;
  createdOn: string;
  updatedOn: string;
  createdBy: User;
  updatedBy: User;
};

const ENDPOINT = "/imported-document";

export const IMPORTED_DOCUMENT_TYPES = ["CHRONOLOGICAL_LIST"];

export const importedDocumentCreate = ({
  file,
  tenantId,
  title,
  type,
  shortDescription,
  description,
  issuedAt,
}: {
  file?: File | null;
  tenantId: number;
  title: string;
  type: number;
  shortDescription: string;
  description: string;
  issuedAt: string;
}) => {
  const formData = new FormData();
  !!file && formData.set("file", file);
  !!tenantId && formData.set("tenantId", tenantId.toString());
  !!title && formData.set("title", title);
  !!type && formData.set("type", type.toString());
  !!shortDescription && formData.set("shortDescription", shortDescription);
  !!description && formData.set("description", description);
  !!issuedAt && formData.set("issuedAt", issuedAt);

  return axios.post(`${ENDPOINT}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const importedDocumentList = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<Item>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //ITEM_GET
