import { Box, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import DetailsCard from "components/DetailsCard";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { Employee } from "shared/types";
import displayAddress from "shared/util/displayAddress";
import CommentList from "views/Comment/CommentList";
import EmployeePermissionList from "./EmployeePermissionList";

type Props = {
  employee: Employee | null;
  loading: boolean;
};

const EmployeeData = ({ employee, loading }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  return (
    <Box display="flex" flexDirection="column" gridGap={12} pb={2}>
      <Loading open={loading} />
      <DetailsCard
        title={t("employee.data")}
        headerButtons={
          <Button
            color="primary"
            variant="text"
            component={Link}
            to={`/my-company/employees/employee-modify?id=${employee?.id}`}
            startIcon={<Edit />}
            style={{ height: 24 }}
          >
            {t("common:button.modify")}
          </Button>
        }
        columns={3}
        dataList={[
          { name: t("userList.name"), value: employee?.name || "-" },
          {
            name: t("userList.expenseIdentifier"),
            value: employee?.expenseIdentifier || "-",
          },
          {
            name: t("userList.birthName"),
            value: employee?.birthName || "-",
          },
          { name: t("userList.email"), value: employee?.email || "-" },
          { name: t("userList.phone"), value: employee?.phone || "-" },
          {
            name: t("userList.tenant"),
            value: employee?.tenant?.name || "-",
          },
          {
            name: t("userList.entranceDate"),
            value: employee?.entranceDate
              ? format(new Date(employee?.entranceDate), "yyyy.MM.dd.")
              : "-",
          },
          {
            name: t("userList.taxCardNumber"),
            value: employee?.taxCardNumber || "-",
          },
          {
            name: t("userList.socialSecurityCardNumber"),
            value: employee?.socialSecurityCardNumber || "-",
          },
          {
            name: t("userList.identityCardNumber"),
            value: employee?.identityCardNumber || "-",
          },
          {
            name: t("userList.addressCardNumber"),
            value: employee?.addressCardNumber || "-",
          },
          {
            name: t("userList.birthdate"),
            value: employee?.birthDate ? format(new Date(employee?.birthDate), "yyyy.MM.dd.") : "-",
          },
          {
            name: t("userList.mothersName"),
            value: employee?.mothersName || "-",
          },
          {
            name: t("userList.holidayCount"),
            value: employee?.holidayCount || "-",
          },
          {
            name: t("userList.gender"),
            value: employee?.gender ? t(`common:gender.${employee?.gender}`) : "-",
          },
          {
            name: t("userList.drivingLicenseNumber"),
            value: employee?.drivingLicenseNumber || "-",
          },
        ]}
      />
      <DetailsCard
        title={t("employee.addressData")}
        columns={1}
        dataList={[
          {
            name: t("employee.permanentAddress"),
            value: employee?.permanentAddress
              ? employee?.permanentAddress?.country +
                  ", " +
                  displayAddress(employee?.permanentAddress) || "-"
              : t("employee.noAddress"),
          },
          {
            name: t("employee.temporaryAddress"),
            value: employee?.temporaryAddress
              ? employee?.temporaryAddress?.country +
                  ", " +
                  displayAddress(employee?.temporaryAddress) || "-"
              : t("employee.noAddress"),
          },
          {
            name: t("employee.postAddress"),
            value: employee?.postAddress
              ? employee?.postAddress?.country + ", " + displayAddress(employee?.postAddress) || "-"
              : t("employee.noAddress"),
          },
        ]}
      />
      {account.user.isSuperAdmin &&
        hasAuthority(account.user, account.permissions, selectedRelTenant, ["EMPLOYEE_ADMIN"]) && (
          <EmployeePermissionList employeeId={id} />
        )}
      {hasAuthority(account.user, account.permissions, selectedRelTenant, ["COMMENT_ADMIN"]) &&
        !!id && <CommentList paramId={id} />}
    </Box>
  );
};

export default EmployeeData;
