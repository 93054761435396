import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Assignment, Check, Close, Delete, Edit, FileCopy, GroupAdd } from "@material-ui/icons";
import ConfirmationButton from "components/ConfirmationButton";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { permissionListByGroup } from "shared/network/permission";
import {
  deletePermissionGroup,
  permissionGroupCreate,
  permissionGroupList,
  permissionGroupModify,
} from "shared/network/permission-group.api";
import { relatePermissionPermissionGroup } from "shared/network/rel-permission-permission-group";
import { PermissionGroup } from "shared/types";
import PermissionModal from "./PermissionModal";

type CreateFormValues = {
  name: string;
};

type ModifyFormValues = {
  id: number;
  name: string;
};

const useStyles = makeStyles({
  userListTitle: {
    color: "secondary",
    fontSize: "16px",
    fontWeight: "bold",
  },
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {},
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const PermissionGroupList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<number | null>(null);
  const [openId, setOpenId] = useState<number | null>(null);
  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState<PermissionGroup | null>(
    null,
  );

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const createForm = useForm<CreateFormValues>();
  const modifyForm = useForm<ModifyFormValues>();

  const permissionGroupQuery = useQuery(["permissionGroupList", tenant.id], async () => {
    const { data } = await permissionGroupList(tenant.id);
    return data.items;
  });

  useEffect(() => {
    modifyForm.setValue("name", selectedPermissionGroup?.name || "");
  }, [modifyForm, selectedPermissionGroup?.name]);

  const onSubmitCreate = async (values: CreateFormValues) => {
    try {
      await permissionGroupCreate(values, tenant.id);
      permissionGroupQuery.refetch();
      setOpenId(null);
      createForm.setValue("name", "");
      enqueueSnackbar(
        t("common:notification.create.success", {
          subject: t("permissionGroup.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.create.failure", {
          subject: t("permissionGroup.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  };

  const onSubmitModify = async (values: ModifyFormValues) => {
    try {
      await permissionGroupModify(values, tenant.id);
      permissionGroupQuery.refetch();
      setSelectedPermissionGroup(null);
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("permissionGroup.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e: any) {
      if (e.message === "CONFLICT") {
        enqueueSnackbar(
          t("common:notification.delete.failure", {
            subject: t("item.category.subject"),
          }) +
            " " +
            t("common:notification.CONFLICT"),
          {
            variant: "error",
          },
        );
      } else {
        enqueueSnackbar(
          t("common:notification.modify.failure", {
            subject: t("permissionGroup.subject"),
          }),
          {
            variant: "error",
          },
        );
      }
    }
  };
  const onDelete = async (id: number) => {
    try {
      await deletePermissionGroup(id, tenant.id);
      permissionGroupQuery.refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("permissionGroup.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("permissionGroup.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  };

  async function CopyGroup(baseGroup: PermissionGroup) {
    try {
      setLoading(true);
      const { data } = await permissionGroupCreate(
        { name: baseGroup.name + " (Másolat)" },
        tenant.id,
      );
      const copyGroup = data.item;
      const { data: dataByGroup } = await permissionListByGroup(baseGroup.id, tenant.id);
      const copyGroupData = dataByGroup.items;
      if (copyGroup && copyGroupData) {
        await relatePermissionPermissionGroup(copyGroupData, copyGroup.id, tenant.id);
      }
      setLoading(false);
      permissionGroupQuery.refetch();
      enqueueSnackbar(
        t("common:notification.copy.success", {
          subject: t("permissionGroup.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch {
      setLoading(false);
      enqueueSnackbar(
        t("common:notification.copy.failure", {
          subject: t("permissionGroup.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  return (
    <Container maxWidth="sm">
      <Loading open={loading && permissionGroupQuery.isFetching} />

      <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}>
        <CardContent>
          <Collapse in={openId !== 0}>
            <Box display="flex" justifyContent="center" width="100%" pb={2}>
              <Button
                startIcon={<GroupAdd />}
                onClick={() => setOpenId(prevState => (prevState === null ? 0 : null))}
              >
                {t("permissionGroup.create")}
              </Button>
            </Box>
          </Collapse>
          <PermissionModal open={open} setOpen={setOpen} />
          {permissionGroupQuery.isError && (
            <Box style={{ margin: "20px auto", textAlign: "center" }}>
              <Typography
                variant="h5"
                align="center"
                color="secondary"
                style={{ marginBottom: "20px" }}
              >
                {t("common:errorWhileFetching")}
              </Typography>
            </Box>
          )}
          {permissionGroupQuery.data?.length === 0 && (
            <Box style={{ margin: "20px auto", textAlign: "center" }}>
              <Typography
                variant="h5"
                align="center"
                color="secondary"
                style={{ marginBottom: "20px" }}
              >
                {t("common:noData")}
              </Typography>
            </Box>
          )}
          <Collapse in={openId === 0}>
            <form onSubmit={createForm.handleSubmit(onSubmitCreate)}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ padding: "0 0 16px 0", width: "100%" }}
              >
                <Grid item xs={6}>
                  <Controller
                    control={createForm.control}
                    name="name"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Név"
                        InputLabelProps={{ shrink: true, required: true }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box display="flex">
                                <Tooltip title="Létrehozás">
                                  <IconButton
                                    type="submit"
                                    size="small"
                                    style={{ margin: "0px 4px" }}
                                  >
                                    <Check />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Mégse">
                                  <IconButton
                                    size="small"
                                    onClick={() => setOpenId(null)}
                                    style={{ margin: "0px 4px" }}
                                  >
                                    <Close />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </Collapse>
          <Divider />
          {permissionGroupQuery.data?.map(permissionGroup => (
            <form key={permissionGroup.id} onSubmit={modifyForm.handleSubmit(onSubmitModify)}>
              <Grid container className={classes.row} style={{ alignItems: "center" }}>
                <Grid item xs={6} className={classes.cell}>
                  {selectedPermissionGroup?.id !== permissionGroup?.id ? (
                    <Typography className={classes.text}>
                      {permissionGroup.name}
                      {permissionGroup.isDefault ? t("permissionGroup.default") : ""}
                    </Typography>
                  ) : (
                    <>
                      <input
                        type="hidden"
                        value={permissionGroup?.id}
                        {...modifyForm.register("id")}
                      />
                      <Controller
                        control={modifyForm.control}
                        name="name"
                        defaultValue=""
                        rules={{
                          required: t("validation.required").toString(),
                        }}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label="Név"
                            InputLabelProps={{
                              shrink: true,
                              required: true,
                            }}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </>
                  )}
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end" className={classes.cell}>
                  {selectedPermissionGroup?.id !== permissionGroup?.id ? (
                    <>
                      {!permissionGroup.isDefault && (
                        <>
                          <Tooltip title={t("permissionGroup.buttons.setPermission").toString()}>
                            <IconButton
                              size="small"
                              color="primary"
                              style={{ margin: "0 8px" }}
                              onClick={() => setOpen(permissionGroup.id)}
                            >
                              <Assignment color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("permissionGroup.buttons.modify").toString()}>
                            <IconButton
                              size="small"
                              color="primary"
                              style={{ margin: "0 8px" }}
                              onClick={() => {
                                modifyForm.setValue("id", permissionGroup.id);
                                setSelectedPermissionGroup(permissionGroup);
                              }}
                            >
                              <Edit color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("permissionGroup.buttons.delete").toString()}>
                            <div>
                              <ConfirmationButton
                                buttonType="ICON"
                                size="small"
                                color="primary"
                                style={{ margin: "0 8px" }}
                                onSubmit={() => onDelete(permissionGroup.id)}
                                title={t("permissionGroup.delete.title").toString()}
                                body={t("permissionGroup.delete.body").toString()}
                              >
                                <Delete color="primary" />
                              </ConfirmationButton>
                            </div>
                          </Tooltip>
                        </>
                      )}
                      <Tooltip title={t("permissionGroup.buttons.copy").toString()}>
                        <IconButton
                          onClick={() => CopyGroup(permissionGroup)}
                          size="small"
                          color="primary"
                          style={{ margin: "0 8px" }}
                        >
                          <FileCopy color="primary" />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title={t("permissionGroup.buttons.modify").toString()}>
                        <IconButton
                          onClick={modifyForm.handleSubmit(onSubmitModify)}
                          size="small"
                          color="primary"
                          style={{ margin: "0 8px" }}
                        >
                          <Check color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("permissionGroup.buttons.cancel").toString()}>
                        <IconButton
                          onClick={() => setSelectedPermissionGroup(null)}
                          size="small"
                          color="primary"
                          style={{ margin: "0 8px" }}
                        >
                          <Close color="primary" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
              <Divider />
            </form>
          ))}
        </CardContent>
      </Card>
    </Container>
  );
};

export default PermissionGroupList;
