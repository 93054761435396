import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { Item, Transaction } from "shared/types";

const ENDPOINT = "/scrap";

export type ScrapEntry = {
  id: number;
  tenantId: number;
  transaction: Transaction;
  comment: string;
  status: string;
  relScrapItems: RelScrapItem[];
};

export type RelScrapItem = {
  item: Item;
  quantity: number;
};

export const getScrapItemListPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = ""
) =>
  axios.post<GenericPageResponse<ScrapEntry>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`
  ); //ADMIN

export const approveScrapItems = (
  param: ScrapEntry[],
  approve: boolean,
  tenantId: number
) =>
  axios.post<GenericPageResponse<ScrapEntry>>(`${ENDPOINT}/approve`, {
    scraps: param,
    approve,
    tenantId,
  }); //ADMIN
