import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Assignment, Close, Done, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { approveScrapItems, getScrapItemListPageable, ScrapEntry } from "shared/network/scrap.api";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

const TenantAllScrapList = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setHeaderButtons } = useHeader();
  const [detailsView, setDetailsView] = useState<ScrapEntry | null>(null);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-scraplist-tenant-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-scraplist-tenant-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const scrapItemsListQuery = useQuery(
    ["scrapItemsList", page, pageSize, filterValue, sortState, selectedRelTenant.tenant.id],
    async () => {
      const { data } = await getScrapItemListPageable(
        page,
        pageSize,
        selectedRelTenant.tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  async function saveStatus(row: ScrapEntry, status: boolean) {
    try {
      if (row) {
        await approveScrapItems([row], status, selectedRelTenant.tenant.id);
        enqueueSnackbar(
          t("common:notification.modify.success", {
            subject: t("scrap.status.subject"),
          }),
          {
            variant: "success",
          },
        );
        scrapItemsListQuery.refetch();
      }
    } catch {
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("scrap.status.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  const columns: GridColDef[] = [
    {
      field: "createdBy",
      headerName: t(`scrap.createdBy`),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => (row.createdBy ? row.createdBy.name : "-"),
    },
    {
      field: "createdOn",
      headerName: t(`scrap.createdOn`),
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.createdOn ? format(new Date(row.createdOn), "yyyy. MM. dd") : "-",
    },
    {
      field: "status",
      headerName: t(`scrap.status.title`),
      flex: 1,
      hide: !selectedRelTenant.tenant.isScrapApproveRequired,
      valueGetter: ({ row }: GridValueGetterParams) => t(`scrap.status.${row.status}`),
    },
    {
      field: "modifier",
      headerName: t(`scrap.modifier`),
      flex: 1,
      hide: !selectedRelTenant.tenant.isScrapApproveRequired,
      valueGetter: ({ row }: GridValueGetterParams) => (row.modifier ? row.modifier.name : "-"),
    },
    {
      field: "modificationDate",
      headerName: t(`scrap.modificationDate`),
      flex: 1,
      hide: !selectedRelTenant.tenant.isScrapApproveRequired,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.modificationDate ? format(new Date(row.modificationDate), "yyyy. MM. dd") : "-",
    },
    {
      field: "comment",
      headerName: t("warehouse.transaction.formValues.comment"),
      flex: 2,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) &&
            (row.status === "NOT_APPROVED" || row.status === "PENDING") && (
              <Tooltip title={t("scrap.accept").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    saveStatus(row as ScrapEntry, true);
                  }}
                >
                  <Done color="primary" />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, ["OFFER_ADMIN"]) &&
            (row.status === "APPROVED" || row.status === "PENDING") && (
              <Tooltip title={t("scrap.decline").toString()}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    saveStatus(row as ScrapEntry, false);
                  }}
                >
                  <Close color="primary" />
                </IconButton>
              </Tooltip>
            )}
          {hasAuthority(account.user, account.permissions, selectedRelTenant, [
            "WAREHOUSE_ADMIN",
          ]) && (
            <Tooltip title={t("scrap.scrapItems").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => {
                  setDetailsView(row as ScrapEntry);
                }}
              >
                <Assignment color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "WAREHOUSE_ADMIN",
        ]) && (
          <Button
            variant="contained"
            component={Link}
            to={`/warehouse/scrap-list/scrap-create`}
            startIcon={<Edit />}
            style={{ margin: "auto" }}
          >
            {t("warehouse.formValues.scrapTitle")}
          </Button>
        )}
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, [account.user, account.permissions, selectedRelTenant, filterValue]); //eslint-disable-line

  return (
    <>
      <PageableTable
        sortState={sortState}
        setSortState={setSortState}
        sessionStorageKey="scraplist-tenant"
        tableState={tableState}
        setTableState={setTableState}
        columns={columns}
        query={scrapItemsListQuery}
        minWidth={{ value: "sm" }}
      />
      <Dialog open={!!detailsView} maxWidth="sm" fullWidth>
        <DialogTitle>{t("scrap.scrapItems")}</DialogTitle>
        <DialogContent>
          <Grid container style={{ border: `1px solid ${COLORS.mainGrey}` }}>
            <Grid container item xs={12} style={{ padding: 8, backgroundColor: COLORS.mainGrey }}>
              <Grid item xs={6}>
                <Typography variant="body2">{t("item.name")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{t("item.quantity")}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              {detailsView?.relScrapItems.map(relscrapitem => {
                return (
                  <Grid container item xs={12} style={{ padding: 8 }}>
                    <Grid item xs={6}>
                      <Typography>{relscrapitem.item.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{relscrapitem.quantity}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setDetailsView(null);
            }}
          >
            {t("common:button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TenantAllScrapList;
