import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import { LowPriority } from "@material-ui/icons";
import Loading from "components/Loading";
import { STATUSES } from "config/constants";
import { RootState } from "config/store";
import { BOX_SHADOW, COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listIssues } from "shared/network/issues.api";
import { Issue } from "shared/types";
import IssueRelIssueCreateModal from "../IssueDetails/IssueRelIssueCreateModal";
import KanbanArrangeModal from "./components/KanbanArrangeModal";
import KanbanItemList from "./components/KanbanItemList";
import KanbanSearchModal from "./components/KanbanSearchModal";

// const columnColors = ["#16a624", "#3396ff", "#ff8a33", "#30c9e8", "#cc4400"];

const KanbanBoard = () => {
  const { t } = useTranslation();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const [searchValue, setSearchValue] = useState("");
  const [arrangeValue, setArrangeValue] = useState("");
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [modalIssue, setModalIssue] = useState<Issue>();

  const issueQuery = useQuery(
    ["issueListQueryKanban", tenant.id, searchValue, arrangeValue],
    async () => {
      const { data } = await listIssues(
        0,
        2000,
        tenant.id,
        account.user.isSuperAdmin || selectedRelTenant.isTenantAdmin
          ? searchValue
          : `assignedUser.id:${account.user.id};${searchValue}`,
        arrangeValue,
      );
      return data.page?.content;
    },
  );
  const issues = issueQuery.data;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const [anchorElArrange, setAnchorElArrange] = useState<HTMLDivElement | HTMLButtonElement | null>(
    null,
  );

  return (
    <>
      <Loading open={issueQuery.isFetching} />
      <IssueRelIssueCreateModal
        key={modalIssue?.id}
        open={createOpen}
        setOpen={setCreateOpen}
        issue={modalIssue}
      />
      <KanbanArrangeModal
        setArrangeValue={setArrangeValue}
        anchorEl={anchorElArrange}
        onClose={() => {
          setAnchorElArrange(null);
        }}
      />
      <KanbanSearchModal
        setSearchValue={setSearchValue}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      />
      <Box display="flex" justifyContent="space-between">
        <Tooltip title={t("common:filter.newFilter").toString()}>
          <IconButton
            size="small"
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event?.currentTarget);
            }}
            style={{
              color: COLORS.white,
              backgroundColor: COLORS.mainLight,
              boxShadow: BOX_SHADOW,
              height: 32,
              width: 32,
            }}
          >
            <FontAwesomeIcon
              icon={faFilter}
              style={{
                fontSize: 14,
                width: 14,
                color: COLORS.white,
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("common:arrangement.newFilter").toString()}>
          <IconButton
            size="small"
            onClick={event => {
              event.stopPropagation();
              setAnchorElArrange(event?.currentTarget);
            }}
            style={{
              color: COLORS.white,
              backgroundColor: COLORS.mainLight,
              boxShadow: BOX_SHADOW,
              height: 32,
              width: 32,
            }}
          >
            <LowPriority />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container justifyContent="center" style={{ minWidth: STATUSES.length * 200 }}>
        {STATUSES.map((status, index) => {
          return (
            <KanbanItemList
              key={index}
              index={index}
              status={status}
              issues={issues}
              setModalIssue={setModalIssue}
              setCreateOpen={setCreateOpen}
              refetch={issueQuery.refetch}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default KanbanBoard;
