import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { TimeEntry, User } from "shared/types";

const ENDPOINT = "/time-entry";

export const createTimeEntry = (
  param: {
    tenantId: number | null | undefined;
    user?: User;
    issue?: { id: string };
    projectId?: string;
    workDate: Date;
    workTime: number;
    description: string;
    offerId?: number | null;
    contractId?: number | null;
    companyId?: number | null;
  },
  tenantId: number,
) => axios.post(`${ENDPOINT}/create`, { param, tenantId }); //ISSUE_GET

export const modifyTimeEntry = (param: TimeEntry, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId }); //ISSUE_GET

export const deleteTimeEntry = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?id=${param}&tenantId=${tenantId}`); //ISSUE_GET

export const getTimeEntryListPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string,
) =>
  axios.get<GenericPageResponse<TimeEntry>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}`,
  ); //ISSUE_GET

export const getInternalTimeEntryListPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string,
) =>
  axios.get<GenericPageResponse<TimeEntry>>(
    `${ENDPOINT}/get-page-by-tenant-no-company?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}`,
  ); //ISSUE_GET

export const getTimeEntryById = (param: number, tenantId: number) =>
  axios.post(`${ENDPOINT}/get-by-id`, { param, tenantId }); //ISSUE_GET
