import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import PageLoading from "components/PageLoading";
import ScrollToTop from "components/ScrollToTop";
import queryClient from "config/query";
import theme from "config/theme";
import roraTheme from "config/roraTheme";
import store from "config/store";
import { SnackbarProvider } from "notistack";
import { StrictMode, Suspense } from "react";
import { DndProvider } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18n";
import mageroTheme from "config/mageroTheme";

ReactDOM.render(
  <StrictMode>
    <DndProvider options={HTML5toTouch}>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <ThemeProvider
            theme={
              !!window.location.hostname.includes("rajosszikviz")
                ? roraTheme
                : !!window.location.hostname.includes("magero")
                ? mageroTheme
                : theme
            }
          >
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              <SnackbarProvider>
                <Suspense fallback={<PageLoading />}>
                  <ScrollToTop />
                  <App />
                </Suspense>
              </SnackbarProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ReduxProvider>
    </DndProvider>
  </StrictMode>,
  document.getElementById("root"),
);
