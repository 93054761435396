import {
  faBowlFood,
  faClipboardList,
  faClipboardQuestion,
  faFileLines,
  faPrescriptionBottle,
  faSprayCanSparkles,
  faTruckLoading,
  faTruckMoving,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Drawer, List, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  Business,
  BusinessCenter,
  Description,
  Dvr,
  Email,
  Equalizer,
  Home,
  HomeWork,
  Kitchen,
  ListAlt,
  LocalOffer,
  LocalShipping,
  Note,
  ReportProblemOutlined,
  ShowChart,
  SupervisorAccount,
  Warning,
} from "@material-ui/icons";
import { Link as RouterLink } from "components/Router";
import { MAGERO_GRADIENT } from "config/mageroTheme";
import { RORA_GRADIENT } from "config/roraTheme";
import { RootState } from "config/store";
import { COLORS, GRADIENT } from "config/theme";
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAnyAuthority, hasAuthority } from "shared/authorization";
import { getSystemProperties } from "shared/network/properties.api";
import { getUnderOverFlow } from "shared/network/warehouse.api";
import { getPageName } from "shared/util/getPageName";
import { ADMIN_TABS } from "views/Admin/AdminTabs";
import { TENANT_DETAILS_TABS } from "views/Admin/Tenants/TenantDetailsTabs";
import { COMPANY_DETAILS_TABS } from "views/Company/CompanyDetailsTabs/CompanyDetailsTabs";
import { COMPANY_TABS } from "views/Company/CompanyTabs";
import { OWN_COMPANY_DETAILS_TABS } from "views/Company/OwnCompanyDetailsTabs/OwnCompanyDetailsTabs";
import { EMPLOYEE_TABS } from "views/Employee/EmployeeDetailsTabs";
import { INVOICES_TABS } from "views/Invoice/InvoicesTabs";
import { ISSUES_TABS } from "views/Issues/IssuesTabs";
import { ITEM_TABS } from "views/Items/ItemsTabs";
import magero from "views/Login/magero.png";
import { MONITORING_TABS } from "views/Monitoring/MonitoringTabs";
import { MYDELIVERY_TABS } from "views/MyDelivery/MyDeliveryTabs";
import { OFFER_TABS } from "views/Offer/OfferTabs";
import { ORDER_TABS } from "views/Order/OrderTabs";
import { USER_TABS } from "views/User/MyCompanyTabs";
import { VEHICLE_TABS } from "views/Vehicle/VehicleTabs";
import { WAREHOUSE_TABS } from "views/Warehouse/WarehouseTabs";
import ProfileMenu from "./ProfileMenu";
import logo from "./RoraLogo.png";
import SidebarItem from "./SidebarItem";
import ItemIcon from "./icons/ItemIcon";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  width: number;
  setWidth: Dispatch<SetStateAction<number>>;
};

const SideBar = ({ open, setOpen, width, setWidth }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [originalHome] = useState(true);
  const matchesSm = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMd = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorElProfile, setAnchorElProfile] = useState<HTMLElement | null>(null);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const isRora = !!window.location.hostname.includes("rajosszikviz");
  const isMagero = !!window.location.hostname.includes("magero");
  const isPromera =
    window.location.hostname.includes("promera.systems") ||
    window.location.hostname.includes("localhost");
  const versionQuery = useQuery("versionQuery", async () => {
    const { data } = await getSystemProperties();
    return data.items?.find(property => property.name === "version")?.value;
  });

  const underOverFlowQuery = useQuery(
    ["underOverFlowQuery", selectedRelTenant.tenant.id],
    async () => {
      const { data } = await getUnderOverFlow(selectedRelTenant.tenant.id);
      return data;
    },
    { refetchInterval: 300000 },
  );

  const handleClickProfile = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElProfile(event.currentTarget);
  };

  useEffect(() => {
    if (!matchesMd && matchesSm) {
      setWidth(80);
    }
  }, [matchesMd, matchesSm, setWidth]);

  return (
    <>
      <ProfileMenu
        anchorElProfile={anchorElProfile}
        setOpen={setOpen}
        setAnchorElProfile={setAnchorElProfile}
      />
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        variant={matchesSm ? "permanent" : "temporary"}
        onMouseOver={() => {
          if (!matchesMd && matchesSm) {
            setWidth(200);
          }
        }}
        onMouseOut={() => {
          if (!matchesMd && matchesSm) {
            setWidth(80);
          }
        }}
        style={{ zIndex: 2 }}
        PaperProps={{
          style: {
            width: matchesMd ? 200 : width,
            transition: "width 0.2s ease-in-out",
            zIndex: 2,
            color: COLORS.white,
            background: isRora ? RORA_GRADIENT : isMagero ? MAGERO_GRADIENT : GRADIENT,
            backgroundSize: "150%",
            border: "unset",
            overflowX: "hidden",
            overflowY: "auto",
            display: "flex",
            justifyContent: "space-between",
          },
        }}
      >
        <Box>
          <Link
            component={RouterLink}
            to="/"
            onClick={() => setOpen(false)}
            style={{
              width: "100%",
              color: COLORS.white,
              borderRadius: 0,
              marginTop: 16,
              marginBottom: 0,
              fontSize: width === 80 ? "2vw" : 24,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isRora ? (
              <img src={logo} alt="Rajos" width={120} style={{ filter: "brightness(100)" }} />
            ) : isMagero ? (
              <img src={magero} alt="Magerő" style={{ width: 150 }} />
            ) : (
              getPageName(window.location.hostname) +
              (selectedRelTenant?.tenant?.isHACCP &&
              !selectedRelTenant?.tenant?.isUseDefault &&
              !selectedRelTenant?.tenant?.isUseReport
                ? " - HACCP"
                : "")
            )}
          </Link>
          <List style={{ padding: isMagero ? "12px 0" : 12 }} onClick={() => setOpen(false)}>
            {selectedRelTenant.tenant.isUseDefault && (
              <SidebarItem
                to={`/home?original=${originalHome}`}
                text={t("home.title")}
                icon={<Home />}
                activeMenuItem={["/", "/home"]}
                width={width}
              />
            )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "ITEM_STATISTIC_GET",
              ]) && (
                <SidebarItem
                  to="/statistics"
                  text={t("statistics.title")}
                  icon={<Equalizer />}
                  activeMenuItem={["/statistics"]}
                  width={width}
                />
              )}
            {(selectedRelTenant.isTenantAdmin ||
              account.user.isSuperAdmin ||
              !account.hasVehicle) &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "USER_ADMIN",
                "EMPLOYEE_GET",
                "EMPLOYEE_ADMIN",
                "WAREHOUSE_ADMIN",
                "WAREHOUSE_GET",
              ]) && (
                <SidebarItem
                  to="/my-company/details"
                  text={t("drawer./my-company")}
                  icon={<Business />}
                  activeMenuItem={USER_TABS.concat(EMPLOYEE_TABS).concat([
                    "/user",
                    "/my-company/users/user-create",
                    "/user-modify",
                    "/employee-create",
                    "/employee-modify",
                    "/department-create",
                    "/department-modify",
                    "/absence-create",
                    "/cargo-create",
                    "/tool-create",
                    "/tool-modify",
                    "/tool-details",
                    "/personal-data-create",
                    "/qualification-create",
                    "/employee-relate/",
                    "/salary-create",
                    "/salary-modify",
                  ])}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "ITEM_GET",
                "ITEM_ADMIN",
                "CATEGORY_ADMIN",
              ]) && (
                <SidebarItem
                  to={
                    hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                      "ITEM_GET",
                      "ITEM_ADMIN",
                    ])
                      ? "/items/list"
                      : "/items/categories"
                  }
                  text={t("drawer./items")}
                  icon={<ItemIcon />}
                  activeMenuItem={ITEM_TABS.concat([
                    "/serial-item-create",
                    "/serial-item-modify",
                    "/item-create",
                    "/item-modify",
                    "/item/details",
                    "/item/serial-item-list",
                  ])}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "VEHICLE_ADMIN",
                "VEHICLE_GET",
              ]) && (
                <SidebarItem
                  to="/vehicles/list"
                  text={t("drawer.vehicle")}
                  icon={<LocalShipping />}
                  activeMenuItem={VEHICLE_TABS.concat([
                    "/vehicles/list",
                    "/vehicles/list/vehicle-create",
                    "/vehicles/list/vehicle-details/items",
                    "/vehicles/list/vehicle-details/items/cargo-create",
                    "/vehicles/list/vehicle-details/items/cargo-remove",
                    "/vehicles/list/vehicle-details/details",
                    "/vehicles/list/vehicle-details/delivery",
                    "/vehicles/list/vehicle-modify",
                    "vehicles/cash-by-vehicle",
                    "vehicles/departure",
                  ])}
                  width={width}
                />
              )}
            {account.user.relUserCompany?.length !== 0 &&
            !account.user.isSuperAdmin &&
            !selectedRelTenant.isTenantAdmin ? (
              <>
                {selectedRelTenant.tenant.isUseDefault &&
                  hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                    "COMPANY_GET",
                    "COMPANY_ADMIN",
                  ]) && (
                    <SidebarItem
                      to="/own-company/company-details"
                      text={t("Cégem")}
                      icon={<BusinessCenter />}
                      activeMenuItem={COMPANY_TABS.concat(OWN_COMPANY_DETAILS_TABS).concat([
                        "/company-modify",
                        "/contact-create",
                        "/contact-modify",
                        "/contact-details",
                        "/companysite-create",
                        "/bank-account-create/",
                        "/bank-account-modify/",
                        "/company-item-create/",
                      ])}
                      width={width}
                    />
                  )}
              </>
            ) : (
              <>
                {selectedRelTenant.tenant.isUseDefault &&
                  hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                    "COMPANY_GET",
                    "COMPANY_ADMIN",
                  ]) && (
                    <SidebarItem
                      to="/companies/list-all"
                      text={t("company.title")}
                      icon={<BusinessCenter />}
                      activeMenuItem={COMPANY_TABS.concat(COMPANY_DETAILS_TABS).concat([
                        "/company-create",
                        "/company-modify",
                        "/contact-create",
                        "/contact-modify",
                        "/contact-details",
                        "/companysite-create",
                        "/bank-account-create/",
                        "/bank-account-modify/",
                        "/company-item-create/",
                      ])}
                      width={width}
                    />
                  )}
              </>
            )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "OFFER_ADMIN",
                "OFFER_GET",
              ]) && (
                <SidebarItem
                  to="/offer/out"
                  text={t("offer.title")}
                  icon={<LocalOffer />}
                  activeMenuItem={OFFER_TABS.concat([
                    "/offer/out/modify",
                    "/offer/out/create",
                    "/offer/out/details",
                    "/offer/out/copy",
                    "/offer/in/modify",
                    "/offer/in/create",
                    "/offer/in/details",
                    "/offer/in/copy",
                  ])}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              !isMagero &&
              (selectedRelTenant.isTenantAdmin ||
                account.user.isSuperAdmin ||
                !account.hasVehicle) &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "ORDER_GET",
              ]) && (
                <SidebarItem
                  to="/order"
                  text={t("drawer./order")}
                  icon={<ListAlt />}
                  activeMenuItem={ORDER_TABS.concat([
                    "/order",
                    "/order/create",
                    "/order/modify",
                    "/order/details",
                  ])}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "CONTRACT_GET",
              ]) && (
                <SidebarItem
                  to="/contract"
                  text={t("contract.title")}
                  icon={<Description />}
                  activeMenuItem={[
                    "/contract",
                    "/contract/modify",
                    "/contract/details",
                    "/contract/create",
                  ]}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "PROJECT_ADMIN",
              ]) && (
                <SidebarItem
                  to="/project/list"
                  text={t("project.title")}
                  icon={<Dvr />}
                  activeMenuItem={[
                    "/project",
                    "/project/details",
                    "/project/create",
                    "/project/modify",
                    "/project/details/data",
                    "/project/details/milestone",
                  ]}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              !isMagero &&
              (selectedRelTenant.isTenantAdmin ||
                account.user.isSuperAdmin ||
                !account.hasVehicle) &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "VEHICLE_ADMIN",
                "VEHICLE_GET",
              ]) && (
                <SidebarItem
                  to="/planning"
                  text={t("drawer./planning")}
                  icon={<FontAwesomeIcon icon={faTruckLoading} style={{ width: 24, height: 24 }} />}
                  activeMenuItem={["/planning", "/inside-delivery-create"]}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              !account.hasOwnWarehouse &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "WAREHOUSE_ADMIN",
                "WAREHOUSE_GET",
              ]) && (
                <Box display="flex" alignItems="center">
                  <SidebarItem
                    to="/warehouse/list"
                    text={t("drawer./warehouse")}
                    icon={<HomeWork />}
                    activeMenuItem={WAREHOUSE_TABS.concat([
                      "/warehouse-create",
                      "/warehouse-modify",
                      "/warehouse-scrap-create",
                      "/warehouse-details",
                      "/warehouse-transaction-create",
                    ])}
                    width={width}
                  />
                  {!!underOverFlowQuery.data?.item ? (
                    <Box
                      style={{
                        background: "white",
                        borderRadius: "50%",
                        minWidth: 30,
                        minHeight: 30,
                        margin: 8,
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Warning color="error" style={{ marginBottom: 4 }} />
                    </Box>
                  ) : null}
                </Box>
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              account.hasOwnWarehouse &&
              !account.user.isSuperAdmin &&
              !selectedRelTenant.isTenantAdmin && (
                <SidebarItem
                  to="/ownwarehouse"
                  text={t("drawer./ownwarehouse")}
                  icon={<HomeWork />}
                  activeMenuItem={["/ownwarehouse"]}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              !isMagero &&
              account.hasVehicle &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "VEHICLE_GET",
                "DELIVERY_GET",
                "DELIVERY_ROUND_GET",
              ]) && (
                <SidebarItem
                  to={`/mydelivery/delivery-list`}
                  text={t("drawer./mydelivery")}
                  icon={<FontAwesomeIcon icon={faTruckMoving} style={{ width: 24, height: 24 }} />}
                  activeMenuItem={MYDELIVERY_TABS}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "ISSUE_GET",
                "ISSUE_ADMIN",
                "ISSUE_TEMPLATE_GET",
              ]) && (
                <SidebarItem
                  to={
                    hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                      "ISSUE_GET",
                    ])
                      ? "/issues/all"
                      : "/issues/templates"
                  }
                  text={t("issues.title")}
                  icon={<Note />}
                  activeMenuItem={ISSUES_TABS.concat([
                    "/issue-type-create",
                    "/custom-fields-create",
                    "/custom-fields-modify",
                    "/issue-template-create",
                    "/issue-template-modify",
                    "/issue-template-details",
                    "/issue-create",
                    "/issue-modify",
                    "/issue-details",
                    "/time-entry-create",
                    "/time-entry-list",
                  ])}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              selectedRelTenant.tenant.schMonitoring &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "MONITORING_GET",
                "MONITORING_ADMIN",
                "MONITORING_DEPOSITOR_GET",
              ]) && (
                <SidebarItem
                  to="/monitoring"
                  text={t("drawer./monitoring")}
                  icon={<ShowChart />}
                  activeMenuItem={MONITORING_TABS.concat([
                    "/machine-details",
                    "/monitoring-entry-create",
                    "/monitoring-entry-modify",
                  ])}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "INVOICE_ADMIN",
                "INVOICE_GET",
                "INCOMING_INVOICE_ADMIN",
                "INCOMING_INVOICE_GET",
              ]) && (
                <SidebarItem
                  to="/invoices"
                  text={t("drawer./invoices")}
                  icon={<Description />}
                  activeMenuItem={INVOICES_TABS.concat([
                    "/invoices/invoice-all/invoice-create",
                    "/invoices/invoice-all/invoice-details",
                    "/invoices/invoice-all/invoice-modify",
                    "/invoices/incoming-invoice/invoice-details",
                    "/invoices/incoming-invoice/invoice-modify",
                  ])}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseDefault && isMagero && (
              <SidebarItem
                to={"/energetic"}
                text={t("Kérdőív")}
                icon={
                  <FontAwesomeIcon icon={faClipboardQuestion} style={{ width: 24, height: 24 }} />
                }
                activeMenuItem={["/energetic"]}
                width={width}
              />
            )}
            {selectedRelTenant.tenant.isUseDefault &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "EMAIL_ADMIN",
                "EMAIL_GET",
              ]) &&
              selectedRelTenant.tenant.isMarketingEmailEnable && (
                <SidebarItem
                  to="/email"
                  activeMenuItem={["/email"]}
                  text={t("drawer./email")}
                  icon={<Email />}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isUseReport && (
              <SidebarItem
                to="/report"
                activeMenuItem={["/report"]}
                text={t("drawer./report")}
                icon={<ReportProblemOutlined />}
                width={width}
              />
            )}
            {selectedRelTenant.tenant.isHACCP &&
              hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "HACCP_ITEM",
              ]) && (
                <SidebarItem
                  to="/haccp-item/daily"
                  activeMenuItem={["/haccp-item"]}
                  text={t("drawer./haccp-item")}
                  icon={<Kitchen />}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isHACCP &&
              hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "HACCP_CHECK",
              ]) && (
                <SidebarItem
                  to="/haccp-check/check"
                  activeMenuItem={["/haccp-check"]}
                  text={t("drawer./haccp-check")}
                  icon={
                    <FontAwesomeIcon icon={faClipboardList} style={{ width: 24, height: 24 }} />
                  }
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isHACCP &&
              hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "HACCP_DEPARTMENT",
              ]) && (
                <SidebarItem
                  to="/haccp-department/check"
                  activeMenuItem={["/haccp-department"]}
                  text={t("drawer./haccp-department")}
                  icon={
                    <FontAwesomeIcon icon={faSprayCanSparkles} style={{ width: 24, height: 24 }} />
                  }
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isHACCP &&
              hasAnyAuthority(account.user, account.permissions, selectedRelTenant, [
                "HACCP_LOCAL_SERVING",
                "HACCP_NOT_LOCAL_SERVING",
                "HACCP_GOODS",
              ]) && (
                <SidebarItem
                  to="/haccp-serving/arriving"
                  activeMenuItem={["/haccp-serving"]}
                  text={t("drawer./haccp-serving")}
                  icon={<FontAwesomeIcon icon={faBowlFood} style={{ width: 24, height: 24 }} />}
                  width={width}
                />
              )}
            {selectedRelTenant.tenant.isHACCP &&
              hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "HACCP_STERILIZATION",
              ]) && (
                <SidebarItem
                  to="/sterilization/list"
                  activeMenuItem={["/sterilization"]}
                  text={t("drawer./sterilization")}
                  icon={
                    <FontAwesomeIcon
                      icon={faPrescriptionBottle}
                      style={{ width: 24, height: 24 }}
                    />
                  }
                  width={width}
                />
              )}
            {account?.user?.isSuperAdmin &&
              selectedRelTenant.tenant.isHACCP &&
              hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "HACCP_DOCUMENTS",
              ]) && (
                <SidebarItem
                  to="/haccp-documents"
                  activeMenuItem={["/haccp-documents"]}
                  text={t("drawer./haccp-documents")}
                  icon={<FontAwesomeIcon icon={faFileLines} style={{ width: 24, height: 24 }} />}
                  width={width}
                />
              )}
            {(account?.user?.isSuperAdmin ||
              (selectedRelTenant.tenant.isUseDefault && selectedRelTenant?.isTenantAdmin)) && (
              <SidebarItem
                to={account?.user?.isSuperAdmin ? "/admin/bundles" : "/admin/currencies"}
                text={t("drawer./admin")}
                icon={<SupervisorAccount />}
                activeMenuItem={ADMIN_TABS.concat(
                  TENANT_DETAILS_TABS.concat([
                    "/bundle-create",
                    "/bundle-modify",
                    "/bundle-details",
                    "/subscription-create",
                    "/tenant-create",
                    "/tenant-modify",
                    "/currency-create",
                    "/currency-modify",
                  ]),
                )}
                width={width}
              />
            )}
          </List>
        </Box>
        <List style={{ padding: 4 }}>
          <SidebarItem
            onClick={handleClickProfile}
            text={account.user.name}
            icon={<Avatar>{account.user.name[0].toUpperCase()}</Avatar>}
            width={width}
          />
          <Typography
            style={{
              textAlign: "center",
              fontWeight: 300,
              color: COLORS.white,
              fontSize: "0.875rem",
              margin: 4,
            }}
          >
            {t("login.version", { version: versionQuery.data })}
          </Typography>
          <Box style={{ textAlign: "center" }}>
            <Typography
              component={Link}
              to="/tos"
              style={{
                textAlign: "center",
                color: COLORS.white,
                textDecoration: "none",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 300,
              }}
            >
              {t("article.tos")}
            </Typography>
            {t("comma")}
            {t("space")}
            <Typography
              component={Link}
              to="/gdpr"
              style={{
                textAlign: "center",
                color: COLORS.white,
                textDecoration: "none",
                fontSize: 14,
                fontStyle: "normal",
                fontWeight: 300,
              }}
            >
              {t("article.gdpr")}
            </Typography>
          </Box>
        </List>
      </Drawer>
    </>
  );
};

export default SideBar;
