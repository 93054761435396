import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import NumberField from "components/NumberField";
import { NUMBER_FORMAT } from "config/constants";
import { RootState } from "config/store";
import { Controller, FieldArrayWithId, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listCargoForVehiclePageable } from "shared/network/cargo.api";
import { getItemListWithWrappingByCompanySite } from "shared/network/rel-item-company-site";
import { listSerialItemsForWarehouse } from "shared/network/serial-item.api";
import { Delivery, Item, SerialItemEntry } from "shared/types";
import { DeliveryItemFormValues } from "./MyDeliveryItemDialog";

export type Props = {
  index: number;
  delivery?: Delivery;
  itemListName: "normalItemList" | "pickUpWithWrappingItemList";
  normalField?: FieldArrayWithId<DeliveryItemFormValues, "pickUpWithWrappingItemList", "key">;
  pickUpWithWrappingRemove?: (index?: number | number[] | undefined) => void;
  pickUpWithWrappingAppend?: (value: any) => void;
  connectedFieldIndex?: number;
  selectedItem: Item | null;
  selectedItemIsSerial: boolean;
  itemList: any;
  remove: (index: number) => void;
  type: "NORMAL" | "PICK_UP" | "PICK_UP_WRAPPING";
};

const MyDeliveryItemModifyRow = ({
  index,
  delivery,
  normalField,
  connectedFieldIndex,
  itemListName,
  selectedItem,
  selectedItemIsSerial,
  itemList,
  remove,
  pickUpWithWrappingRemove,
  pickUpWithWrappingAppend,
  type,
}: Props) => {
  const { t } = useTranslation();
  const form = useFormContext<DeliveryItemFormValues>();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const avaibleSerialItemList = useQuery(
    ["avaibleSerialItemList", tenant.id, selectedItem],
    async () => {
      if (delivery) {
        if (itemListName === "normalItemList") {
          if (delivery?.status === "ACTIVE") {
            const { data } = await listSerialItemsForWarehouse(
              0,
              100000,
              tenant.id,
              selectedItem?.id.toString() || "0",
              delivery?.fromWarehouse?.id.toString() || "0",
            );
            return data.page.content;
          } else if (delivery?.status === "IN_PROGRESS") {
            const { data } = await listCargoForVehiclePageable(
              0,
              1000000,
              delivery.vehicleId,
              tenant.id,
              `quantity!=0;item.id:${selectedItem?.id}`,
              "",
              false,
            );
            return data.page?.content?.map(value => value.itemSerial) || [];
          }
        } else if (itemListName === "pickUpWithWrappingItemList") {
          const { data } = await getItemListWithWrappingByCompanySite(
            0,
            1000000,
            delivery?.companySite,
            tenant.id,
            `item.id:${selectedItem?.id}`,
            "",
            false,
            type === "PICK_UP_WRAPPING",
          );
          return data.page?.content?.map(value => value.itemSerial) || [];
        }
      }
      return Promise.reject();
    },
    { enabled: !!selectedItem },
  );

  const deliveryHelper = form.watch(`${itemListName}.${index}`);

  return (
    <Grid container spacing={2} style={{ paddingBottom: 8 }}>
      <Grid item xs={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography style={{ fontWeight: "bold", fontSize: 16 }}>{index + 1}.</Typography>
      </Grid>
      <Grid item xs={7}>
        <Controller
          name={`${itemListName}.${index}.item`}
          defaultValue={null}
          rules={{
            required: t("validation.required").toString(),
          }}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              options={itemList?.content || []}
              getOptionLabel={(option: Item) => {
                if (option.name && option.productCode) {
                  return `${option.name} (${option.productCode})`;
                }
                return "";
              }}
              getOptionSelected={option => option.id === field.value?.id}
              onChange={(_, value) => {
                field.onChange(value);
                if (value?.type === "SERIAL_NUMBER") {
                  form.setValue(`${itemListName}.${index}.quantity`, "1");
                }
                if (connectedFieldIndex) {
                  if (normalField && connectedFieldIndex > -1) {
                    if (value?.type === "SERIAL_NUMBER") {
                      pickUpWithWrappingRemove?.(connectedFieldIndex);
                    } else {
                      form.setValue(
                        `pickUpWithWrappingItemList.${connectedFieldIndex}.item`,
                        value,
                      );
                    }
                  }
                  if (connectedFieldIndex === -1 && value?.type !== "SERIAL_NUMBER") {
                    pickUpWithWrappingAppend?.({
                      item: value,
                      type: type,
                      connected: normalField?.connected,
                    });
                    form.setValue(`pickUpWithWrappingItemList.${connectedFieldIndex}.item`, value);
                  }
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={type === "PICK_UP_WRAPPING" ? "Göngyöleg" : "Termék"}
                  InputLabelProps={{
                    shrink: true,
                    required: true,
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <Controller
          name={`${itemListName}.${index}.quantity`}
          defaultValue=""
          rules={{
            required: t("validation.required").toString(),
            validate: value => {
              if (value && !value.toString().match(NUMBER_FORMAT)) {
                return t("common:validation.numberFormat").toString();
              }
            },
          }}
          render={({ field, fieldState }) => (
            <NumberField
              field={field}
              fieldState={fieldState}
              onChange={event => {
                const targetValue = Number.parseFloat(event.target.value);
                let newValue: string | number = "";

                if (
                  (deliveryHelper?.maxQuantity && targetValue >= deliveryHelper.maxQuantity) ||
                  (selectedItem?.maxQuantity && targetValue >= selectedItem.maxQuantity)
                ) {
                  newValue = deliveryHelper?.maxQuantity || selectedItem?.maxQuantity || "";
                } else if (targetValue > 0) {
                  newValue = targetValue;
                } else if (deliveryHelper?.maxQuantity === 0 || selectedItem?.maxQuantity === 0) {
                  newValue = 0;
                } else if (targetValue === 0) {
                  newValue = 0;
                } else {
                  newValue = "";
                }

                field.onChange(newValue);
                if (connectedFieldIndex) {
                  if (normalField && connectedFieldIndex > -1) {
                    form.setValue(
                      `pickUpWithWrappingItemList.${connectedFieldIndex}.quantity`,
                      newValue,
                    );
                  }
                }
              }}
              disabled={selectedItemIsSerial}
              label="db"
              InputLabelProps={{ shrink: true, required: true }}
              numberLimits={{ step: "0.01", min: "0" }}
              error={!!fieldState.error}
              helperText={
                (fieldState.error?.message ? fieldState.error?.message + " " : "") +
                `(max: ${deliveryHelper?.maxQuantity || selectedItem?.maxQuantity || 0})`
              }
            />

            /*<TextField
              {...field}
              onChange={event => {
                let targetValue = Number.parseFloat(event.target.value);
                let newValue =
                  targetValue >= selectedItem?.maxQuantity
                    ? selectedItem?.maxQuantity
                    : targetValue >= 0
                    ? targetValue
                    : 0;
                field.onChange(newValue);
                if (connectedFieldIndex) {
                  if (normalField && connectedFieldIndex > -1) {
                    form.setValue(
                      `pickUpWithWrappingItemList.${connectedFieldIndex}.quantity`,
                      newValue,
                    );
                  }
                }
              }}
              disabled={selectedItemIsSerial}
              label={"db"}
              InputLabelProps={{ shrink: true, required: true }}
              error={!!fieldState.error}
              helperText={
                (fieldState.error?.message || "") + `(max: ${selectedItem?.maxQuantity || 0})`
              }
            />*/
          )}
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          size="small"
          color="primary"
          style={{ paddingBottom: 8 }}
          onClick={() => remove(index)}
        >
          <Delete />
        </IconButton>
      </Grid>
      {selectedItemIsSerial && (
        <>
          <Grid item xs={1} />
          <Grid item xs={7}>
            <Controller
              name={`${itemListName}.${index}.itemSerial`}
              rules={{ required: t("validation.required").toString() }}
              defaultValue={null}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  onChange={(_, value) => field.onChange(value)}
                  options={avaibleSerialItemList.data || []}
                  getOptionLabel={(option: SerialItemEntry) => option?.factoryNumber}
                  getOptionSelected={option => option.id === field.value?.id}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t("warehouse.transaction.formValues.factoryNumber")}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default MyDeliveryItemModifyRow;
