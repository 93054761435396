import { Button, Grid, IconButton, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { AUDITLOG_TYPE } from "config/constants";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AuditLogFilter } from "./AuditLog";
import { Close, FilterList } from "@material-ui/icons";
import { COLORS } from "config/theme";

const AuditLogForm = () => {
  const { setValue, control } = useFormContext<AuditLogFilter>();
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Controller
          control={control}
          name="user.id"
          defaultValue={""}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              margin="none"
              name="userId"
              placeholder={t("auditLog.filters.placeholder")}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                style: { paddingRight: 6 },
                endAdornment: (
                  <InputAdornment position="end" style={{ marginLeft: 6 }}>
                    <IconButton onClick={() => setValue("user.id", "")} size="small">
                      <Close style={{ color: COLORS.lightGrey }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={4} style={{ padding: 8 }}>
        <Controller
          control={control}
          name="message"
          defaultValue={""}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              margin="none"
              name="message"
              placeholder={t("auditLog.filters.placeholder")}
              InputProps={{
                style: { paddingRight: 6 },
                endAdornment: (
                  <InputAdornment position="end" style={{ marginLeft: 6 }}>
                    <IconButton onClick={() => setValue("message", "")} size="small">
                      <Close style={{ color: COLORS.lightGrey }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} style={{ padding: 8 }}>
        <Controller
          control={control}
          name="type"
          defaultValue={""}
          render={({ field, fieldState }) => (
            <TextField {...field} select>
              <MenuItem>{t("auditLog.noFilter")}</MenuItem>
              {Object.entries(AUDITLOG_TYPE).map(([key]) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={2} style={{ padding: 8 }}>
        <Controller
          control={control}
          name="status"
          defaultValue={""}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              margin="none"
              name="status"
              placeholder={t("auditLog.filters.placeholder")}
              InputProps={{
                style: { paddingRight: 6 },
                endAdornment: (
                  <InputAdornment position="end" style={{ marginLeft: 6 }}>
                    <IconButton onClick={() => setValue("status", "")} size="small">
                      <Close style={{ color: COLORS.lightGrey }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          startIcon={<FilterList />}
          style={{ height: 40 }}
        >
          {t("auditLog.filters.placeholder")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AuditLogForm;
