import * as React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";
import { Button, ButtonProps, IconButton } from "@material-ui/core";

type Props = {
  onSubmit: () => void;
  title: string;
  body: React.ReactNode;
  buttonType?: "BUTTON" | "ICON";
} & ButtonProps;

const ConfirmationButton = ({
  style,
  className,
  children,
  onSubmit,
  title,
  body,
  buttonType = "BUTTON",
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {buttonType === "ICON" ? (
        <IconButton
          size={rest.size === "small" ? "small" : rest.size === "medium" ? "medium" : undefined}
          disabled={rest.disabled}
          color={rest.color}
          style={style}
          className={className}
          onClick={event => {
            event.stopPropagation();
            setOpen(true);
          }}
        >
          {children}
        </IconButton>
      ) : (
        <Button style={style} className={className} onClick={() => setOpen(true)} {...rest}>
          {children}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
        onClick={event => event.stopPropagation()}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("common:button.cancel")}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              onSubmit();
            }}
          >
            {t("common:button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationButton;
