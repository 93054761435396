import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { acceptOffer, declineOffer, resetOfferStatus } from "shared/network/offer.api";

type Props = {
  confirmation: { type: string; id: number };
  setConfirmation: Dispatch<SetStateAction<{ type: string; id: number }>>;
  refetch: () => void;
};

const OfferStatusChangeModal = ({ confirmation, setConfirmation, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  async function acceptOfferFunction(offerId: number) {
    try {
      await acceptOffer(offerId, selectedRelTenant.tenant.id);
      refetch();
      enqueueSnackbar(
        t("common:notification.acceptance.success", {
          subject: t("offer.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      refetch();
      enqueueSnackbar(
        t("common:notification.acceptance.failure", {
          subject: t("offer.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  async function declineOfferFunction(offerId: number) {
    try {
      await declineOffer(offerId, selectedRelTenant.tenant.id);
      refetch();
      enqueueSnackbar(
        t("common:notification.decline.success", {
          subject: t("offer.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      refetch();
      enqueueSnackbar(
        t("common:notification.decline.failure", {
          subject: t("offer.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  async function resetOfferFunction(offerId: number) {
    try {
      await resetOfferStatus(offerId, selectedRelTenant.tenant.id);
      refetch();
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("offer.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      refetch();
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("offer.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  return (
    <Dialog
      open={
        confirmation.type === "ACCEPT" ||
        confirmation.type === "DECLINE" ||
        confirmation.type === "RESET"
      }
      maxWidth="xs"
    >
      <DialogTitle>
        {confirmation.type === "ACCEPT"
          ? t("offer.accept")
          : confirmation.type === "DECLINE"
          ? t("offer.decline")
          : t("offer.reset")}
      </DialogTitle>
      <DialogContent>
        <Box width="100%">
          <Typography align="center">
            {confirmation.type === "ACCEPT"
              ? t("offer.confirmationText.accept")
              : confirmation.type === "DECLINE"
              ? t("offer.confirmationText.decline")
              : t("offer.confirmationText.reset")}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setConfirmation({ type: "", id: 0 });
            confirmation.type === "ACCEPT"
              ? acceptOfferFunction(confirmation.id)
              : confirmation.type === "DECLINE"
              ? declineOfferFunction(confirmation.id)
              : resetOfferFunction(confirmation.id);
          }}
        >
          {t("common:button.ok")}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            setConfirmation({ type: "", id: 0 });
          }}
        >
          {t("common:button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OfferStatusChangeModal;
