import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { User } from "shared/types";
import { Food } from "./food.api";

export type GoodsAcceptance = {
  id: number;
  tenantId: number;
  number: number;
  delivererName: string;
  deliveryNoteNumber: string;
  food: Food;
  amount: number;
  temperature: number;
  isOk: boolean;
  isNotOk: boolean;
  user: User;
  signPath: string;
};

const ENDPOINT = "/goods-acceptance-sheet";

export const createGoodsAcceptance = (param: any, tenantId: number) =>
  axios.post(`${ENDPOINT}/create`, { param, tenantId });

export const modifyGoodsAcceptance = (param: any, tenantId: number) =>
  axios.put(`${ENDPOINT}/modify`, { param, tenantId });

export const getGoodsAcceptancePage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.post<GenericPageResponse<GoodsAcceptance>>(
    `${ENDPOINT}/get-page-by-tenant?page=${page}&size=${size}&tenantId=${tenantId}&search=${
      search || ""
    }&sort=${sort || ""}`,
  );

export const servingSignature = (id: string, tenantId: string, file: File) => {
  const formData = new FormData();
  formData.set("id", id);
  formData.set("tenantId", tenantId);
  file && formData.set("file", file);

  return axios.put(`${ENDPOINT}/add-signature-file`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //DELIVERY_ADMIN
