import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { ExpenseIdentifier } from "shared/types";

const ENDPOINT = "/expenseidentifier";

type ExpenseIdentifierRequest = {
  expenseType: string,
  subjectId: number,
  expenseIdentifier: string,
};

export const listExpenseIdentifiers = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<ExpenseIdentifier>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //INCOMING_INVOICE_ADMIN


  export const createExpenseIdentifier = (
    params: ExpenseIdentifierRequest[],
    tenantId: number,
  ) =>
  axios.post<GenericPageResponse<ExpenseIdentifier>>(
    `${ENDPOINT}`, {params, tenantId}
  ); //EXPENSE_IDENTIFIER_ADMIN
