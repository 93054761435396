import { Box, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { hasAuthority } from "shared/authorization";
import NewIssueOverviewList from "views/Issues/NewIssueOverviewList";
import AddEntityBar from "./AddEntityBar";

const Home = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const originalHome = query.get("original");

  const homePage = useSelector(
    (state: RootState) => state?.authentication?.account?.user?.homePage,
  );

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  useEffect(() => {
    if (homePage && originalHome !== "true") {
      history.push(homePage);
    } else if (location.pathname === "/" && homePage !== null) {
      history.push("/home");
    }
  }, [history, homePage, originalHome]); //eslint-disable-line

  return (
    <Container>
      <Box display="flex" flexDirection="column" gridGap={16} pb={2}>
        <AddEntityBar />
        {hasAuthority(account.user, account.permissions, selectedRelTenant, ["ISSUE_GET"]) && (
          <NewIssueOverviewList />
        )}
      </Box>
    </Container>
  );
};
export default Home;
