import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { SupplierPriceHistory } from "shared/types";

const ENDPOINT = "/supplier-price-history";

export const createSupplierPriceHistory = (
  param: SupplierPriceHistory,
  tenantId: number,
) => axios.post(`${ENDPOINT}/create`, { param, tenantId });

export const listSupplierPriceHistories = (
  page: number,
  size: number,
  tenantId: number,
  search = "",
  sort = "",
) =>
  axios.get<GenericPageResponse<SupplierPriceHistory>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  );

export const getSupplierPriceHistory = (
  param: number | string,
  tenantId: number | string,
) =>
  axios.post<GenericResponse<SupplierPriceHistory>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  });

export const getSupplierPriceHistoryByCompanyAndItem = (
  param: { companyId: number | string; itemId: number | string },
  tenantId: number | string,
) =>
  axios.post<GenericResponse<SupplierPriceHistory>>(
    `${ENDPOINT}/get-by-company-and-item`,
    { param, tenantId },
  );
