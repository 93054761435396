import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Loading from "components/Loading";
import { times } from "lodash";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SliceStatus } from "shared/common";
import { Delivery, Item, RelItemDelivery } from "shared/types";
import DeliverySerialItemRow from "./DeliverySerialItemRow";
import { modifyDelivery } from "shared/network/delivery.api";

const useStyles = makeStyles({
  header: {
    alignItems: "center",
    border: `1px solid ${COLORS.mainGrey}`,
    background: COLORS.mainGrey,
    height: 60,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  divider: {
    marginTop: 8,
  },
  newItem: {
    marginTop: 20,
    marginBottom: 10,
  },
  footy: {
    alignItems: "center",
    border: `1px solid ${COLORS.mainGrey}`,
    background: COLORS.mainGrey,
    height: 60,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  bodyMain: {
    borderLeft: `1px solid ${COLORS.mainGrey}`,
    borderRight: `1px solid ${COLORS.mainGrey}`,
  },
  bodyCollapse: {
    borderLeft: `1px solid ${COLORS.mainGrey}`,
    borderRight: `1px solid ${COLORS.mainGrey}`,
  },
});

export type Props = {
  selectedDelivery: Delivery | null;
  openId: string | number | null;
  setOpenId: Dispatch<SetStateAction<string | number | null>>;
  refetch: () => void;
};

export type DeliverySerialItemFormValues = {
  itemList: DeliverySerialItemEntry[];
};

export type DeliverySerialItemEntry = {
  item: Item | null;
  quantity: number | string | null;
  price: number | string | null;
  serialNumber: string;
};

const DeliverySerialItemDialog = ({
  selectedDelivery,
  openId,
  setOpenId,
  refetch,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );
  const form = useForm<DeliverySerialItemFormValues>();

  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<SliceStatus>("idle");

  const relItemList = selectedDelivery?.relItemDeliveryItems;

  const { fields, replace } = useFieldArray({
    control: form.control,
    name: "itemList",
    keyName: "key",
  });

  const onSubmitModify = async (values: DeliverySerialItemFormValues) => {
    try {
      setStatus("pending");
      if (selectedDelivery) {
        await modifyDelivery(
          {
            deliveryEntry: selectedDelivery,
            relList:
              relItemList
                ?.filter(
                  entry =>
                    (entry.item.type === "SERIAL_NUMBER" &&
                      entry.quantity === 1) ||
                    entry.item.type !== "SERIAL_NUMBER",
                )
                .concat(values.itemList)
                .map(entry => {
                  return { ...entry, deliveryId: selectedDelivery.id };
                }) || [],
          },
          selectedRelTenant.tenant.id,
        );
      }
      setStatus("success");
      enqueueSnackbar(
        t("common:notification.modify.success", {
          subject: t("deliveryItem.subject"),
        }),
        {
          variant: "success",
        },
      );
      refetch();
      setOpenId(null);
    } catch (e) {
      setStatus("failure");
      enqueueSnackbar(
        t("common:notification.modify.failure", {
          subject: t("deliveryItem.subject"),
        }),
        { variant: "error" },
      );
    }
  };

  useEffect(() => {
    const list = selectedDelivery?.relItemDeliveryItems
      ?.filter(relItem => relItem.item.type === "SERIAL_NUMBER")
      ?.filter(relItem => !relItem.serialNumber)
      .map((relItem: RelItemDelivery) =>
        times(relItem.quantity, index => {
          return {
            item: relItem.item,
            quantity: 1,
            price: relItem.price,
            serialNumber: relItem.serialNumber,
          } as DeliverySerialItemEntry;
        }),
      )
      .flat();
    replace(list || []);
  }, [selectedDelivery, replace]);

  return (
    <Dialog
      open={openId?.toString() === selectedDelivery?.id?.toString()}
      onClose={() => {
        setOpenId(null);
      }}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>{t("delivery.serialItemListTitle")}</DialogTitle>
      <DialogContent>
        {!selectedDelivery?.relItemDeliveryItems?.length ? (
          <Box display="flex" justifyContent="center">
            <Typography className={classes.text}>
              {t("delivery.noItem")}
            </Typography>
          </Box>
        ) : (
          <form
            id="serialItemForm"
            onSubmit={form.handleSubmit(onSubmitModify)}
          >
            <FormProvider {...form}>
              <Loading open={status === "pending"} />
              <Grid
                container
                style={{ padding: "8px 0" }}
                className={classes.header}
              >
                <Grid item xs={3} className={classes.cell}>
                  <Typography className={classes.text} variant="body2">
                    {t("item.name")}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.cell}>
                  <Typography className={classes.text} variant="body2">
                    {t("item.productCode")}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.cell}>
                  <Typography className={classes.text} variant="body2">
                    {t("item.serialNumber")}
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.cell}>
                  <Typography className={classes.text} variant="body2">
                    {t("delivery.priceTitle")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid className={classes.bodyMain}>
                {fields.map((entry, index) => (
                  <DeliverySerialItemRow
                    key={index}
                    index={index}
                    deliveryItem={entry}
                  />
                ))}
              </Grid>
            </FormProvider>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button form="serialItemForm" type="submit" color="primary">
          {t("common:button.save")}
        </Button>
        <Button
          onClick={() => {
            setOpenId(null);
          }}
          variant="text"
        >
          {t("common:button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliverySerialItemDialog;
