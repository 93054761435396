import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { hasAuthority } from "shared/authorization";
import { deleteCompanyNickname } from "shared/network/nickname.api";
import { CompanyNickname } from "shared/types";
import NicknameDialog from "./NicknameDialog";
import { useSelector } from "react-redux";
import { RootState } from "config/store";

type Props = {
  nickname: CompanyNickname;
  refetch: () => void;
};

const NicknameRow = ({ nickname, refetch }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  async function removeNickname(id: number) {
    try {
      await deleteCompanyNickname(id, nickname.tenantId);
      refetch();
      enqueueSnackbar(
        t("common:notification.delete.success", {
          subject: t("companyNickname.subject"),
        }),
        {
          variant: "success",
        },
      );
    } catch (e) {
      enqueueSnackbar(
        t("common:notification.delete.failure", {
          subject: t("companyNickname.subject"),
        }),
        {
          variant: "error",
        },
      );
    }
  }

  const [open, setOpen] = useState(false);

  return (
    <>
      <NicknameDialog open={open} setOpen={setOpen} nickname={nickname} refetch={refetch} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography>{nickname.nickname}</Typography>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_NICKNAME_ADMIN",
        ]) && (
          <Box>
            <Tooltip title={t("companyNickname.modify").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => setOpen(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("companyNickname.delete").toString()}>
              <IconButton
                size="small"
                color="primary"
                style={{ margin: "0 8px" }}
                onClick={() => {
                  if (nickname.id) {
                    removeNickname(nickname.id);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </>
  );
};

export default NicknameRow;
