import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Loading from "components/Loading";
import queryClient from "config/query";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  CheckItemType,
  createCheckItem,
  getCheckItemPage,
  modifyCheckItem,
} from "shared/network/check-item";
import { createHaccpItem, modifyHaccpItem } from "shared/network/haccp-item.api";

type Props = {
  open: boolean;
  onClose: () => void;
  defaultValues: any | null;
  isModify: boolean;
};

type FormValues = {
  title: string;
  isActive: boolean;
  isParent: boolean;
  parentId: number;
};

export type HaccpItemStatus = "BROKEN" | "WAITING_FOR_MECHANIC" | "ACTIVE" | "INACTIVE";

export const HACCP_ITEM_STATUS = ["ACTIVE", "INACTIVE", "BROKEN", "WAITING_FOR_MECHANIC"];

const HaccpCheckDialog = ({ open, onClose, defaultValues, isModify }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<FormValues>();
  const { handleSubmit, setValue, watch } = form;

  const isParent = watch("isParent");

  const [loading, setLoading] = useState(false);

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const { tenant } = selectedRelTenant;

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      if (!defaultValues) {
        await createCheckItem(
          {
            ...values,
            parent: values.parentId && !values.isParent ? { id: values.parentId } : null,
          },
          tenant?.id,
        );
      } else {
        await modifyCheckItem(
          {
            ...defaultValues,
            ...values,
            parent: values.parentId && !values.isParent ? { id: values.parentId } : null,
          },
          tenant?.id,
        );
      }
      onClose();
      queryClient.refetchQueries("checkItemPageQuery");
      queryClient.refetchQueries("checkItemPageQuery2");
      if (!defaultValues) {
        enqueueSnackbar(t("common:notification.create.success"), { variant: "success" });
      } else {
        enqueueSnackbar(t("common:notification.modify.success"), { variant: "success" });
      }
    } catch (error: any) {
      if (!defaultValues) {
        enqueueSnackbar(t("common:notification.create.failure"), { variant: "error" });
      } else {
        enqueueSnackbar(t("common:notification.modify.failure"), { variant: "error" });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    setValue("title", defaultValues?.title || "");
    setValue("isParent", defaultValues?.isParent || false);
    setValue("parentId", defaultValues?.parent?.id || "");
    setValue("isActive", defaultValues?.isActive || true);
  }, [
    defaultValues?.isActive,
    defaultValues?.isParent,
    defaultValues?.parent?.id,
    defaultValues?.title,
    setValue,
  ]);

  const checkItemPageQuery = useQuery(["checkItemPageQuery2", tenant.id], async () => {
    const { data } = await getCheckItemPage(0, 2000, tenant.id, "isParent=true", "title,asc");
    return data?.page?.content || [];
  });

  return (
    <>
      <Loading open={loading} />
      <Dialog maxWidth="xs" fullWidth open={!!open} onClose={onClose}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
              {isModify && t("haccpCheckItem.modify")}
              {!isModify && t("haccpCheckItem.create")}
            </DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" gridGap={16}>
                <Controller
                  name="title"
                  defaultValue=""
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("haccpItem.name")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name="isParent"
                  defaultValue={false}
                  render={props => (
                    <FormControlLabel
                      labelPlacement="end"
                      label={"Főkövetelmény"}
                      control={
                        <Checkbox
                          {...props.field}
                          checked={props.field.value}
                          size="small"
                          onChange={e => props.field.onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
                {!isParent && (
                  <Controller
                    name="parentId"
                    defaultValue={""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        select
                        InputLabelProps={{ shrink: true, required: true }}
                        label={"Főkövetelmény választása"}
                        error={!!fieldState.error}
                        SelectProps={{ displayEmpty: true }}
                        helperText={fieldState.error?.message}
                      >
                        <MenuItem disabled value="">
                          {t("common:choose")}
                        </MenuItem>
                        {checkItemPageQuery.data?.map((value: CheckItemType) => (
                          <MenuItem key={value?.id} value={value?.id}>
                            {value?.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                )}
                <Controller
                  name="isActive"
                  defaultValue={true}
                  render={props => (
                    <FormControlLabel
                      labelPlacement="end"
                      label={"Aktív"}
                      control={
                        <Checkbox
                          {...props.field}
                          checked={props.field.value}
                          size="small"
                          onChange={e => props.field.onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="text" onClick={onClose}>
                {t("common:button.cancel")}
              </Button>
              <Button variant="contained" type="submit">
                {t("common:button.save")}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default HaccpCheckDialog;
