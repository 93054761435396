import axios from "config/axios";
import { FailedOrderReason, GenericListResponse } from "shared/types";

const ENDPOINT = "failed-order-reason";

export const getFailedOrderReasonList = (tenantId: number) =>
  axios.get<GenericListResponse<FailedOrderReason>>(`${ENDPOINT}/all?tenantId=${tenantId}`);

export const createFailedOrderReason = (name: string, tenantId: number, id?: number) =>
  axios.post(`${ENDPOINT}`, { param: { name, id }, tenantId });

export const deleteFailedOrderReason = (id: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?tenantId=${tenantId}&failedOrderReasonId=${id}`);

export const getByOrderId = (tenantId: number, id?: number) =>
  axios.post(`${ENDPOINT}/by-order-id?orderId=${id}`, { tenantId });
