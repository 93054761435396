import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { CheckItemType } from "./check-item";
import { User } from "shared/types";

export type CheckItemsEntry = {
  id: number;
  tenantId: number;
  checkItem: CheckItemType;
  checkAt: string;
  createdBy: User;
};

const ENDPOINT = "/check-items";

export const createCheckItems = (params: any[], tenantId: number) =>
  axios.post(ENDPOINT, { params, tenantId });

export const modifyCheckItems = (param: any, tenantId: number) =>
  axios.put(ENDPOINT, { param, tenantId });

export const getCheckItemsPage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.get<GenericPageResponse<any>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search || ""}&sort=${
      sort || ""
    }`,
  );

export const getCheckItems = (id: number, tenantId: number) =>
  axios.get<GenericResponse<any>>(`${ENDPOINT}/get-by-id?id=${id}&tenantId=${tenantId}`);

export const exportCheckItems = (date: string, tenantId: number) =>
  axios.get<GenericResponse<any>>(
    `${ENDPOINT}/get-check-items-by-week?tenantId=${tenantId}&date=${date}`,
  );
