import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { RelIssueIssueEntry } from "shared/types";

const ENDPOINT = "/rel-issue-issue";

export const createRelIssueIssue = (param: RelIssueIssueEntry, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId });

export const deleteRelIssueIssue = (param: number, tenantId: number) =>
  axios.delete(`${ENDPOINT}?id=${param}&tenantId=${tenantId}`);

export const listRelIssueIssues = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.post<GenericPageResponse<RelIssueIssueEntry>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //INVOICE_GET
