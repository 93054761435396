import axios from "config/axios";
import { GenericPageResponse, GenericResponse } from "shared/common";
import { Project } from "shared/types";

export type DailyReport = {
  id?: number | undefined;
  tenantId: number;
  project: Project;
  reportedDate: string;
  reportStatus: string;
};

const ENDPOINT = "/daily-report";

export const createDailyReport = (param: Partial<DailyReport>, tenantId: number) =>
  axios.post(`${ENDPOINT}`, {
    param,
    tenantId,
  });

export const modifyDailyReport = (param: Partial<DailyReport>, tenantId: number) =>
  axios.put(`${ENDPOINT}`, {
    param,
    tenantId: tenantId,
  }); //ISTENANTADMIN ISSUPERADMIN

export const listDailyReportByTenant = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<DailyReport>>(
    `${ENDPOINT}/get-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //LOGIN_REQUIRED

export const getDailyReportById = (param: number | string, tenantId: number) =>
  axios.post<GenericResponse<DailyReport>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //LOGIN_REQUIRED

export const getFullDailyReportById = (param: number | string, tenantId: number) =>
  axios.post<{
    dailyReportEntry: DailyReport;
    relDailyReportIssueEntries: any;
  }>(`${ENDPOINT}/get-by-id-full`, {
    param,
    tenantId,
  }); //LOGIN_REQUIRED
