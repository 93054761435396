import { Box, Container, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Block, CheckCircleOutline, HourglassEmpty } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCashByVehicle } from "shared/network/delivery.api";
import { updateVehicleCashStatus } from "shared/network/deliveryNote.api";
import { Vehicle } from "shared/types";
import VechileRefuseDialog from "./VechileRefuseDialog";
import { useLocation } from "react-router-dom";
import { COLORS } from "config/theme";
import { CASH_ACCEPT_STATUS_TYPES } from "config/constants";
import { SliceStatus } from "shared/common";
import Loading from "components/Loading";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

export type VehicleCashData = {
  vehicle: Vehicle;
  sumofCash: number;
  cashOnRefuse?: number;
};

const VehicleCashByVehicle = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const idNullable = query.get("id");
  const id = idNullable ? idNullable : "";
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const [date, setDate] = useState(new Date());
  const [selectedRow, setSelectedRow] = useState<VehicleCashData | null>(null);

  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;
  const [refuseDialog, setRefuseDialog] = useState<boolean>(false);

  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-vehicle-cash-by-vehicle-page-number"),
      ) || JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(
        useGetSessionStorageKey("rap-list-vehicle-cash-by-vehicle-page-size"),
      ) || JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const getCashByVehiclesQuery = useQuery(["cashByVehicleQuery", date, tenant.id], async () => {
    const { data } = await getCashByVehicle(tenant.id, format(date, "yyyy-MM-dd"));
    return {
      ...data,
      items: data.items.map((row: VehicleCashData) => {
        return { ...row, id: row.vehicle.id };
      }),
    };
  });

  const [status, setStatus] = useState<SliceStatus>("idle");

  const acceptCashValue = async (row: VehicleCashData) => {
    try {
      setStatus("pending");
      await updateVehicleCashStatus(
        row.vehicle.id,
        format(date, "yyyy-MM-dd"),
        "ACCEPTED",
        tenant.id,
      );
      enqueueSnackbar(
        t("common:notification.acceptance.success", {
          subject: t("vehicle.formValues.cashAccept"),
        }),
        {
          variant: "success",
        },
      );
      getCashByVehiclesQuery.refetch();
      setStatus("success");
    } catch {
      enqueueSnackbar(
        t("common:notification.acceptance.failure", {
          subject: t("vehicle.formValues.cashAccept"),
        }),
        {
          variant: "error",
        },
      );
      setStatus("failure");
    }
  };

  const columns: GridColDef[] = [
    {
      field: "vehicle.licensePlateNumber",
      headerName: t("vehicle.formValues.licensePlateNumber"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.vehicle.licensePlateNumber,
    },
    {
      field: "vehicle.user.name",
      headerName: t("vehicle.formValues.user"),
      flex: 1.25,
      valueGetter: ({ row }: GridValueGetterParams) => row.vehicle?.user?.name,
    },
    {
      field: "sumofCash",
      headerName: t("vehicle.formValues.cash"),
      flex: 0.75,
      valueGetter: ({ row }: GridValueGetterParams) => t("common:number", { num: row.sumofCash }),
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip
          placement="right"
          title={
            <Box>
              <Typography variant="body1">{`${t("vehicle.formValues.cashInHand")}${t(
                "common:number",
                { num: row.cashInHand },
              )}`}</Typography>
              <Typography variant="body1">{`${t("vehicle.formValues.cashInVehicle")}${t(
                "common:number",
                { num: row.cashInVehicle },
              )}`}</Typography>
            </Box>
          }
        >
          <Typography variant="body1">{t("common:number", { num: row.sumofCash })}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: t("vehicle.formValues.cashStatus.title"),
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip title={t(`vehicle.formValues.cashStatus.${row.status}`).toString()}>
          {row.status === CASH_ACCEPT_STATUS_TYPES[0] ? (
            <CheckCircleOutline style={{ color: COLORS.green }} />
          ) : row.status === CASH_ACCEPT_STATUS_TYPES[1] ? (
            <Block style={{ color: COLORS.red }} />
          ) : (
            <HourglassEmpty />
          )}
        </Tooltip>
      ),
      type: "boolean",
    },
    {
      field: "cashStatusSavedOn",
      headerName: t("vehicle.formValues.cashStatusSavedOn"),
      flex: 1.25,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.cashStatusSavedOn ? format(new Date(row.cashStatusSavedOn), "yyyy.MM.dd") : "-",
    },
    {
      field: "actions",
      headerName: " ",
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Tooltip title={t(`vehicle.formValues.cashAcceptButton`).toString()}>
            <div>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  setSelectedRow(row as VehicleCashData);
                  acceptCashValue(row as VehicleCashData);
                }}
                disabled={row.status === CASH_ACCEPT_STATUS_TYPES[0]}
                children={<CheckCircleOutline />}
              />
            </div>
          </Tooltip>

          <Tooltip title={t(`vehicle.formValues.cashDenyButton`).toString()}>
            <div>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  setRefuseDialog(true);
                  setSelectedRow(row as VehicleCashData);
                }}
                disabled={row.status !== CASH_ACCEPT_STATUS_TYPES[2]}
                children={<CloseIcon />}
              />
            </div>
          </Tooltip>
        </Box>
      ),
    },
  ];

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions") {
      history.push(
        `/vehicles/list/vehicle-details/details?id=${params.row.id}&backId=${id}`,
      );
    }
  }

  return (
    <>
      <Loading open={status === "pending"} />
      <Grid container spacing={2} style={{ marginBottom: 20 }}>
        <Grid container spacing={2} item xs={12} justifyContent="center">
          <Grid item xs={4}>
            <KeyboardDatePicker
              size="small"
              value={date}
              onChange={date => {
                date && setDate(date);
              }}
              label={t("delivery.formValues.date")}
              format="yyyy.MM.dd."
              InputLabelProps={{ shrink: true, required: true }}
              variant="inline"
              autoOk
            />
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="lg">
        <SingleQueryTable
          query={getCashByVehiclesQuery}
          columns={columns}
          setTableState={setTableState}
          tableState={tableState}
          sessionStorageKey={`vehicleCashByVehicleList_${tenant.id}`}
          onCellClick={onCellClick}
        />
      </Container>
      <VechileRefuseDialog
        item={selectedRow}
        date={date}
        openDialog={refuseDialog}
        setOpenDialog={setRefuseDialog}
        setStatus={setStatus}
        refetch={getCashByVehiclesQuery.refetch}
      />
    </>
  );
};

export default VehicleCashByVehicle;
