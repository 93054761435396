import { lazy } from "react";

const WarehouseCreate = lazy(() => import("views/Warehouse/WarehouseCreate"));
const WarehouseModify = lazy(() => import("views/Warehouse/WarehouseModify"));
const WarehouseScrapCreate = lazy(() => import("views/Warehouse/WarehouseScrapCreate"));
const WarehouseTabs = lazy(() => import("views/Warehouse/WarehouseTabs"));
const WarehouseDetailsTabs = lazy(() => import("views/Warehouse/WarehouseDetailsTabs"));
const ItemModify = lazy(() => import("views/Items/ItemModify/ItemModify"));
const ItemDetailsTabs = lazy(() => import("views/Items/ItemDetails/ItemDetailsTabs"));
const WarehouseTransactionCreate = lazy(
  () => import("views/Warehouse/Transaction/WarehouseTransactionCreate"),
);
const InventoryCreate = lazy(() => import("views/Warehouse/Inventory/InventoryCreate"));
const SerialItemCreate = lazy(() => import("views/Items/SerialItems/SerialItemCreate"));

export const WAREHOUSE_PRIVATE_ROUTES = [
  {
    path: "/warehouse",
    component: WarehouseTabs,
    permissions: ["WAREHOUSE_GET"],
    routes: [
      {
        path: "/list",
        component: WarehouseTabs,
        permissions: ["WAREHOUSE_GET"],
        routes: [
          {
            path: "/create",
            component: WarehouseCreate,
            permissions: ["WAREHOUSE_ADMIN"],
          },
          {
            path: "/modify",
            component: WarehouseModify,
            permissions: ["WAREHOUSE_ADMIN"],
          },
          {
            path: "/warehouse-details",
            component: WarehouseDetailsTabs,
            permissions: ["WAREHOUSE_GET"],
            routes: [
              {
                path: "/details",
                component: WarehouseDetailsTabs,
                permissions: ["WAREHOUSE_GET"],
              },
              {
                path: "/item-details",
                component: ItemDetailsTabs,
                permissions: ["ITEM_GET"],
                routes: [
                  {
                    path: "/details",
                    component: ItemDetailsTabs,
                    permissions: ["ITEM_GET"],
                  },
                  {
                    path: "/serial-item-list",
                    component: ItemDetailsTabs,
                    permissions: ["ITEM_GET"],
                    routes: [
                      {
                        path: "/serial-item-create",
                        component: SerialItemCreate,
                        permissions: ["ITEM_ADMIN"],
                      },
                    ],
                  },
                ],
              },
              {
                path: "/item-modify",
                component: ItemModify,
                permissions: ["WAREHOUSE_GET"],
              },
              {
                path: "/income",
                component: WarehouseDetailsTabs,
                permissions: ["WAREHOUSE_GET", "WAREHOUSE_ADMIN"],
                routes: [
                  {
                    path: "/create",
                    component: WarehouseTransactionCreate,
                    permissions: ["WAREHOUSE_ADMIN", "TRANSACTION_ADMIN"],
                  },
                ],
              },
              {
                path: "/transaction-create",
                component: WarehouseTransactionCreate,
                permissions: ["TRANSACTION_ADMIN"],
              },
              {
                path: "/expense",
                component: WarehouseDetailsTabs,
                permissions: ["WAREHOUSE_GET", "WAREHOUSE_ADMIN"],
                routes: [
                  {
                    path: "/create",
                    component: WarehouseTransactionCreate,
                    permissions: ["WAREHOUSE_ADMIN", "TRANSACTION_ADMIN"],
                  },
                ],
              },
              {
                path: "/notification-levels",
                component: WarehouseDetailsTabs,
                permissions: ["WAREHOUSE_GET", "WAREHOUSE_ADMIN"],
              },
              {
                path: "/inventory",
                component: WarehouseDetailsTabs,
                permissions: ["WAREHOUSE_GET", "WAREHOUSE_ADMIN"],
                routes: [
                  {
                    path: "/create",
                    component: InventoryCreate,
                    permissions: ["WAREHOUSE_ADMIN"],
                  },
                ],
              },
              {
                path: "/scrap-create",
                component: WarehouseScrapCreate,
                permissions: ["WAREHOUSE_ADMIN"],
              },
            ],
          },
        ],
      },
      {
        path: "/item-transfer",
        component: WarehouseTabs,
        permissions: ["WAREHOUSE_GET", "MILESTONE_ADMIN"],
      },
      {
        path: "/scrap-list",
        component: WarehouseTabs,
        permissions: ["WAREHOUSE_GET", "MILESTONE_ADMIN"],
        routes: [
          {
            path: "/scrap-create",
            component: WarehouseScrapCreate,
            permission: ["WAREHOUSE_GET"],
          },
        ],
      },
    ],
  },
];
