import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getReportById } from "shared/network/report.api";
import CommentList from "views/Comment/CommentList";
import ReportDetailsCard from "./components/ReportDetailsCard";

export type Report = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  name: string;
  description: string;
  tenantId: number | null;
  status: string;
  createdOn: string;
  identifier: number;
  isVisibleForCompany: boolean;
  hasOlderReportSameIssue: boolean;
  hasAnyOtherKnownCourtCase: boolean;
  isValidData: boolean;
};

const ReportDetails = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");
  const { selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const getReportByIdQuery = useQuery(["getReportByIdQuery", id], async () => {
    if (id) {
      const { data } = await getReportById(id, selectedRelTenant?.tenant.id);
      return data.item;
    }
    return Promise.reject();
  });
  const report = getReportByIdQuery.data;

  return (
    <Container maxWidth="md">
      {getReportByIdQuery?.isFetching ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box pb={2}>
            <ReportDetailsCard report={report} tenantId={selectedRelTenant?.tenant.id} />
          </Box>
          {id && <CommentList paramId={id} />}
        </>
      )}
    </Container>
  );
};

export default ReportDetails;
