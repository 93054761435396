import axios from "config/axios";
import { GenericListResponse } from "shared/common";

const ENDPOINT = "/rel-contract-type-tenant";

export const getRelCompanyTypeTenantList = (tenantId: number) =>
  axios.get<
    GenericListResponse<{
      id: number;
      contractType: {
        id: number;
        name: string;
      };
    }>
  >(`${ENDPOINT}/by-tenant?tenantId=${tenantId}`);

export const createRelCompanyTypeTenant = (
  contractType: {
    id: number;
    name: string;
  },
  tenantId: number,
) => axios.post(`${ENDPOINT}`, { params: [contractType], tenantId });

export const deleteRelCompanyTypeTenant = (
  contractTypeId: number,
  tenantId: number,
) =>
  axios.delete(
    `${ENDPOINT}?contractTypeId=${contractTypeId}&tenantId=${tenantId}`,
  );
