import { Box, Button, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { hasAuthority } from "shared/authorization";
import { Company } from "shared/types";
import NicknameDialog from "./NicknameDialog";
import NicknameRow from "./NicknameRow";

const useStyles = makeStyles({
  divider: {
    margin: "8px 8px 8px 8px",
    width: "100%",
    borderBottom: `1px ${COLORS.greyWater} solid`,
  },
  dataHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
  },
  dataContainer: {
    border: `1px solid ${COLORS.mainGrey}`,
    borderRadius: 4,
    padding: 12,
    width: "100%",
  },
});

type Props = {
  company?: Company;
  refetch: () => void;
};

const NicknameList = ({ company, refetch }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  return (
    <Card style={{ backgroundColor: "rgba(255, 255, 255, 0.6)", padding: 16 }}>
      <NicknameDialog open={open} setOpen={setOpen} companyId={company?.id} refetch={refetch} />
      <Box className={classes.dataHeader}>
        <Typography variant="h2">{t("companyNickname.title")}</Typography>
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "COMPANY_NICKNAME_ADMIN",
        ]) && (
          <Button
            variant="text"
            onClick={() => setOpen(true)}
            startIcon={<AddBox />}
            style={{ height: 24 }}
          >
            {t("companyNickname.create").toString()}
          </Button>
        )}
      </Box>
      <Grid container spacing={2}>
        {company?.nicknames?.length === 0 ? (
          <Grid item xs={12}>
            <Box className={classes.dataContainer}>
              <Typography color="secondary">{t("companyNickname.noNickNames")}</Typography>
            </Box>
          </Grid>
        ) : (
          company?.nicknames?.map(nickname => (
            <Grid key={nickname.id} item xs={12} sm={6}>
              <Box className={classes.dataContainer}>
                <NicknameRow key={nickname.id} nickname={nickname} refetch={refetch} />
              </Box>
            </Grid>
          ))
        )}
      </Grid>
    </Card>
  );
};

export default NicknameList;
