import axios from "config/axios";
import { GenericListResponse } from "shared/common";
import { ScalingX } from "./scaling-x.api";
import { ScalingY } from "./scaling-y.api";

export type ScalingPrice = {
  id: number;
  tenantId: number;
  itemId: number;
  isIncomingPrice: boolean;
  supplierCompany: number;
  validFrom: string;
  validTo: string;
  isActive: boolean;
  price: number;
  scalingX: Partial<ScalingX>;
  scalingY: Partial<ScalingY>;
};

const ENDPOINT = "/scaling-price";

export const createScalingPrice = (
  values: { itemList: Partial<ScalingPrice>[]; scale?: number; generateSellPrice: boolean },
  tenantId: number,
) => axios.post(ENDPOINT, { ...values, tenantId });

export const getAllScalingPrice = (
  itemId: number,
  timestamp: string,
  tenantId: number,
  isIncoming: boolean,
) =>
  axios.get<GenericListResponse<ScalingPrice>>(
    `${ENDPOINT}/all?itemId=${itemId}&tenantId=${tenantId}&timestamp=${timestamp}&isIncoming=${isIncoming}`,
  );
