import { Box, Button, Container, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormCard from "components/FormCard";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { format } from "date-fns";
import { deburr } from "lodash";
import { useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  listCargoForVehiclePageable,
  listCargoHistoryForVehiclePageable,
} from "shared/network/cargo.api";
import { getItemsForDailyDelivery } from "shared/network/delivery.api";
import {
  getVehicleStatusHistoryListPageable,
  VehicleStatusHistoryEntry,
} from "shared/network/vehicle-status-history.api";
import { getVehicleByUserId, getVehicleListPageable } from "shared/network/vehicle.api";
import { RelItemDelivery, Vehicle } from "shared/types";
import DailyDeliveryRow from "./DailyDeliveryRow";
import DailyDeliverySignatureDialog from "./DailyDeliverySignatureDialog";

export type DailyDeliveryListForm = {
  vehicle: Vehicle | null;
  deliveryDate: Date;
  startTime: VehicleStatusHistoryEntry | null;
  serialItemList: RelItemDelivery[];
};

const DailyDeliveryList = () => {
  const { t } = useTranslation();
  const form = useForm<DailyDeliveryListForm>();
  const { control, watch, handleSubmit, setValue } = form;
  const history = useHistory();
  const [signatureOpen, setSignatureOpen] = useState<boolean>(false);
  const [deliveryItemData, setDeliveryItemData] = useState<RelItemDelivery[]>([]);
  const vehicle = watch("vehicle");
  const deliveryDate = watch("deliveryDate");
  const startTime = watch("startTime");
  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);
  const tenant = selectedRelTenant.tenant;

  const { fields, replace } = useFieldArray({
    control,
    name: "serialItemList",
    keyName: "key",
  });

  const dailyDeliveryItemsRequest = useQuery(
    ["dailyDeliveryItemsRequest", tenant.id, vehicle],
    async () => {
      if (vehicle?.id) {
        const { data } = await getItemsForDailyDelivery(tenant.id, vehicle?.id);
        return data;
      }
    },
    { enabled: !!vehicle },
  );

  const vehicleListQuery = useQuery(["vehicleListQueryForDailyDelivery", tenant.id], async () => {
    const { data } = await getVehicleListPageable(0, 1000000, tenant.id);

    return data;
  });

  const vehicleStatusHistoryListQuery = useQuery(
    ["vehicleStatusHistoryListQueryForDailyDelivery", tenant.id, vehicle, deliveryDate],
    async () => {
      const { data } = await getVehicleStatusHistoryListPageable(
        0,
        1000000,
        tenant.id,
        (vehicle ? `vehicle.id:${vehicle?.id};` : "") +
          `date=${format(deliveryDate, "yyyy-MM-dd")}`,
        "numberAtDate,asc",
      );

      return data.page.content;
    },
    {
      enabled: !!vehicle && !!deliveryDate,
      onSuccess: data => {
        setValue("startTime", data?.[0]);
      },
    },
  );

  useQuery(
    ["vehicleByUserQueryForMyDelivery", account, tenant],
    async () => {
      const { data } = await getVehicleByUserId(account.user.id, tenant.id);
      return data.item;
    },
    { onSuccess: data => setValue("vehicle", data) },
  );

  const itemsOnVehiclePageableQuery = useQuery(
    ["itemsOnVehiclePageableQueryForDailyList", tenant.id, vehicle?.id],
    async () => {
      const { data } = await listCargoForVehiclePageable(
        0,
        100000,
        Number.parseInt(vehicle?.id?.toString() || ""),
        tenant.id,
      );
      return data.page.content
        .filter(entry => !(entry.reservedQuantity === 0 && entry.quantity === 0))
        .sort((a, b) => {
          let x = deburr(a.item.name);
          let y = deburr(b.item.name);
          return x < y ? 1 : x > y ? -1 : 0;
        });
    },
    { enabled: !!vehicle?.id },
  );

  const itemsOnVehiclePageableHistoryQuery = useQuery(
    ["itemsOnVehiclePageableHistoryQueryForDailyList", tenant.id, vehicle?.id, startTime],
    async () => {
      const { data } = await listCargoHistoryForVehiclePageable(
        0,
        100000,
        Number.parseInt(vehicle?.id?.toString() || ""),
        startTime?.dateTime || "",
        tenant.id,
      );
      return data.page.content
        .filter(entry => !(entry.reservedQuantity === 0 && entry.quantity === 0))
        .sort((a, b) => {
          let x = deburr(a.item.name);
          let y = deburr(b.item.name);
          return x < y ? 1 : x > y ? -1 : 0;
        });
    },
    { enabled: !!vehicle?.id && !!startTime },
  );

  useEffect(() => {
    replace(
      dailyDeliveryItemsRequest.data?.aggregatedItems
        .filter(entry => entry.item.type === "SERIAL_NUMBER")
        .map(entry => entry.relItemDeliveryEntries)
        .flat() || [],
    );
  }, [dailyDeliveryItemsRequest.data, replace]); //eslint-disable-line

  function onSubmitShowDialog(values: DailyDeliveryListForm) {
    const tempNoSerial =
      dailyDeliveryItemsRequest.data?.aggregatedItems
        .filter(entry => entry.item.type !== "SERIAL_NUMBER")
        .map(entry => entry.relItemDeliveryEntries)
        .flat() || [];
    values.serialItemList.forEach(entry => {
      tempNoSerial.push(entry);
    });
    setDeliveryItemData(tempNoSerial);
    setSignatureOpen(true);
  }

  return (
    <Container maxWidth="lg">
      <Loading open={vehicleListQuery.isFetching || dailyDeliveryItemsRequest.isFetching} />
      <form id="dailyDeliveryForm" onSubmit={handleSubmit(onSubmitShowDialog)}>
        <FormProvider {...form}>
          <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="deliveryDate"
                  rules={{
                    required: t("validation.required").toString(),
                  }}
                  defaultValue={new Date()}
                  render={({ field, fieldState }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      variant="inline"
                      label={t("delivery.dailyAggregate.date")}
                      format="yyyy. MM. dd."
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      autoOk
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="vehicle"
                  defaultValue={null}
                  rules={{ required: t("validation.required").toString() }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={vehicleListQuery.data?.page?.content || []}
                      getOptionLabel={(option: Vehicle) =>
                        option.licensePlateNumber +
                        (option.user?.name ? ` (${option.user?.name})` : "")
                      }
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Tooltip
                                style={{
                                  paddingRight: "2px",
                                }}
                                title={t("tooltip.vehiclePlateNumber").toString()}
                              >
                                <Info style={{ color: COLORS.lightBlue }} />
                              </Tooltip>
                            ),
                          }}
                          InputLabelProps={{ shrink: true, required: true }}
                          label={t("tool.formValues.vehiclePlateNumber")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  control={control}
                  name="startTime"
                  defaultValue={null}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      onChange={(_, value) => field.onChange(value)}
                      options={vehicleStatusHistoryListQuery.data || []}
                      getOptionLabel={(option: VehicleStatusHistoryEntry) =>
                        format(new Date(option.dateTime), "yyyy.MM.dd - HH:mm")
                      }
                      getOptionSelected={option => option.id === field.value?.id}
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <Tooltip
                                style={{
                                  paddingRight: "2px",
                                }}
                                title={t("tooltip.vehiclePlateNumber").toString()}
                              >
                                <Info style={{ color: COLORS.lightBlue }} />
                              </Tooltip>
                            ),
                          }}
                          InputLabelProps={{ shrink: true }}
                          label={t("delivery.dailyAggregate.startTime")}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <Grid item xs={12} container>
              <FormCard
                style={{ margin: "16px 0 0 0" }}
                title={t("delivery.dailyAggregate.vehicleItemsTitle")}
              >
                {!itemsOnVehiclePageableQuery?.data ? (
                  <Box textAlign={"center"} width="100%">
                    <Typography>{t("common:noData")}</Typography>
                  </Box>
                ) : (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      container
                      style={{ borderBottom: `2px solid ${COLORS.mainGrey}` }}
                    >
                      <Grid item xs={3}>
                        <Typography variant="body2">{t("delivery.dailyAggregate.item")}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" style={{ textAlign: "right" }}>
                          {t("delivery.dailyAggregate.reservedQuantity")}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" style={{ textAlign: "right" }}>
                          {t("delivery.dailyAggregate.quantity")}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" style={{ textAlign: "right" }}>
                          {t("delivery.dailyAggregate.totalQuantity")}
                        </Typography>
                      </Grid>
                    </Grid>
                    {itemsOnVehiclePageableQuery?.data?.map((item, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        container
                        style={{
                          borderBottom: `1px solid ${COLORS.mainGrey}`,
                          margin: "8px 0 0 0",
                        }}
                      >
                        <Grid item xs={3}>
                          {item.item.name}
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          {item.reservedQuantity}
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          {item.quantity}
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          {item.quantity + item.reservedQuantity}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </FormCard>
            </Grid>

            <Grid item xs={12} container>
              <FormCard style={{ margin: "16px 0 0 0" }} title={t("delivery.dailyAggregate.title")}>
                {!dailyDeliveryItemsRequest?.data?.aggregatedItems ? (
                  <Box textAlign={"center"} width="100%">
                    <Typography>{t("common:noData")}</Typography>
                  </Box>
                ) : (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      container
                      style={{ borderBottom: `2px solid ${COLORS.mainGrey}` }}
                    >
                      <Grid item xs={6}>
                        <Typography variant="body2">{t("delivery.dailyAggregate.item")}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" style={{ textAlign: "right" }}>
                          {t("delivery.dailyAggregate.aggregateQuantity")}
                        </Typography>
                      </Grid>
                    </Grid>
                    {dailyDeliveryItemsRequest?.data?.aggregatedItems.map((item, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        container
                        style={{
                          borderBottom: `1px solid ${COLORS.mainGrey}`,
                          margin: "8px 0 0 0",
                        }}
                      >
                        <Grid item xs={6}>
                          {item.item.name}
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                          {item.sum}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </FormCard>
            </Grid>
            {!!fields?.length && (
              <Grid item xs={12} container>
                <FormCard
                  style={{ margin: "16px 0 0 0" }}
                  title={t("delivery.dailyAggregate.factoryNumberTitle").toString()}
                >
                  {!dailyDeliveryItemsRequest?.data?.aggregatedItems ? (
                    <Box textAlign={"center"} width="100%">
                      <Typography>{t("common:noData")}</Typography>
                    </Box>
                  ) : (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        container
                        style={{ borderBottom: `2px solid ${COLORS.mainGrey}` }}
                      >
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {t("delivery.dailyAggregate.item")}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            {t("delivery.dailyAggregate.company")}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2" style={{ textAlign: "right" }}>
                            {t("delivery.dailyAggregate.factoryNumber")}
                          </Typography>
                        </Grid>
                      </Grid>
                      {fields.map((field, index) => (
                        <DailyDeliveryRow key={index} itemEntry={field} index={index} />
                      ))}
                    </Grid>
                  )}
                </FormCard>
              </Grid>
            )}

            <Grid item xs={12} container>
              <FormCard
                style={{ margin: "16px 0 0 0" }}
                title={t("delivery.dailyAggregate.vehicleItemsHistoryTitle")}
              >
                {!itemsOnVehiclePageableHistoryQuery?.data ? (
                  <Box textAlign={"center"} width="100%">
                    <Typography>{t("common:noData")}</Typography>
                  </Box>
                ) : (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      container
                      style={{ borderBottom: `2px solid ${COLORS.mainGrey}` }}
                    >
                      <Grid item xs={3}>
                        <Typography variant="body2">{t("delivery.dailyAggregate.item")}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" style={{ textAlign: "right" }}>
                          {t("delivery.dailyAggregate.reservedQuantity")}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" style={{ textAlign: "right" }}>
                          {t("delivery.dailyAggregate.quantity")}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" style={{ textAlign: "right" }}>
                          {t("delivery.dailyAggregate.totalQuantity")}
                        </Typography>
                      </Grid>
                    </Grid>
                    {itemsOnVehiclePageableHistoryQuery?.data?.map((item, index) => (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        container
                        style={{
                          borderBottom: `1px solid ${COLORS.mainGrey}`,
                          margin: "8px 0 0 0",
                        }}
                      >
                        <Grid item xs={3}>
                          {item.item.name}
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          {item.reservedQuantity}
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          {item.quantity}
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: "right" }}>
                          {item.quantity + item.reservedQuantity}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </FormCard>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
      <Box display="flex" justifyContent="center" m={2.5} gridGap={8}>
        <Button color="primary" variant="text" onClick={history.goBack}>
          {t("common:button.cancel")}
        </Button>
        <Button type="submit" form="dailyDeliveryForm" color="primary">
          {t("common:button.start")}
        </Button>
      </Box>
      <DailyDeliverySignatureDialog
        vehicle={vehicle}
        relItemList={deliveryItemData}
        open={signatureOpen}
        setOpen={setSignatureOpen}
        refetch={dailyDeliveryItemsRequest.refetch}
        cargoRefetch={itemsOnVehiclePageableQuery.refetch}
        statusRefetch={vehicleStatusHistoryListQuery.refetch}
      />
    </Container>
  );
};

export default DailyDeliveryList;
