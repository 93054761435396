import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { format } from "date-fns";
import { hu } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listDailyReportByTenant } from "shared/network/daily-report.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";

const DailyReportList = () => {
  const { t } = useTranslation();
  const { setTopPadding, setHeaderButtons } = useHeader();
  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);

  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-daily-report-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-daily-report-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const dailyReportListByTenant = useQuery(
    ["dailyReportListByTenant", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await listDailyReportByTenant(
        page,
        pageSize,
        tenant.id,
        filterValue,
        sortState,
      );
      return data;
    },
  );

  const columns: GridColDef[] = [
    {
      field: "reportedDate",
      headerName: t("dailyReport.list.reportedDate"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row.reportedDate ? format(new Date(row.reportedDate), "P", { locale: hu }) : "-",
    },
    {
      field: "reportStatus",
      headerName: t("dailyReport.list.reportStatus"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => t(`dailyReport.status.${row.reportStatus}`),
    },
    {
      field: "createdBy.name",
      headerName: t("dailyReport.list.createdBy"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) => row.createdBy.name || "-",
    },
    {
      field: "updatedBy.name",
      headerName: t("dailyReport.list.updatedBy"),
      flex: 1,
      valueGetter: ({ row }: GridValueGetterParams) =>
        row?.reportStatus === "CLOSED" ? row.updatedBy.name : "-",
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          <Tooltip title={t("common:button.modify").toString()}>
            <IconButton
              size="small"
              color="primary"
              component={Link}
              to={`/my-company/daily-report/modify?id=${row?.id}`}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        <Button startIcon={<AddBox />} component={Link} to="/my-company/daily-report/create">
          {t("dailyReport.list.new")}
        </Button>
      </Box>,
    );

    return () => {
      setHeaderButtons(null);
    };
  }, []);

  useEffect(() => {
    setTopPadding(false);

    return () => {
      setTopPadding(true);
    };
  }, [setTopPadding]);

  return (
    <PageableTable
      sortState={sortState}
      setSortState={setSortState}
      sessionStorageKey="daily-report"
      tableState={tableState}
      setTableState={setTableState}
      columns={columns}
      query={dailyReportListByTenant}
      minWidth={{ value: "md" }}
    />
  );
};

export default DailyReportList;
