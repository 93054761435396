import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { GenericResponse, MonitoringData } from "shared/types";

const ENDPOINT = "/monitoring-data";

export const getMonitoringDataById = (param: number, tenantId: number) =>
  axios.post<GenericResponse<MonitoringData>>(`${ENDPOINT}/get-by-id`, {
    param,
    tenantId,
  }); //MONITORING_GET

export const getMonitoringDataPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<MonitoringData>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //MONITORING_GET

export const createMonitoringData = (param: MonitoringData, tenantId: number) =>
  axios.post(`${ENDPOINT}`, { param, tenantId });

export const modifyMonitoringData = (param: MonitoringData, tenantId: number) =>
  axios.put(`${ENDPOINT}`, { param, tenantId });
