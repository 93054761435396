export const flattenRoutes = (routes: any) =>
  routes
    .map((route: any) => [
      route.routes ? flattenRoutes(route.routes) : [],
      route,
    ])
    .flat(Infinity);

export const combinePaths = (parent: any, child: any) =>
  `${parent.replace(/\/$/, "")}/${child.replace(/^\//, "")}`;

export const buildPaths = (navigation: any, parentPath = "") =>
  navigation.map((route: any) => {
    const path = combinePaths(parentPath, route.path);

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    };
  });

export const setupParents = (routes: any, parentRoute: any = null) =>
  routes.map((route: any) => {
    const withParent = {
      ...route,
      ...(parentRoute && { parent: parentRoute }),
    };

    return {
      ...withParent,
      ...(withParent.routes && {
        routes: setupParents(withParent.routes, withParent),
      }),
    };
  });

export const pathTo: any = (route: any) => {
  if (!route.parent) {
    return [route];
  }

  return [...pathTo(route.parent), route];
};
