import {
  makeStyles,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { IssueType } from "shared/types";

type Props = {
  issueType: IssueType;
};

const useStyles = makeStyles({
  header: {
    borderBottom: `1px ${COLORS.greyWater} solid`,
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    borderBottom: `1px solid ${COLORS.mainGrey}`,
  },
  cell: {
    padding: 8,
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const IssueTypeRow = ({ issueType }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { account, selectedRelTenant } = useSelector(
    (state: RootState) => state.authentication,
  );

  return (
    <Grid container className={classes.row} style={{ alignItems: "center" }}>
      <Grid item xs={6} className={classes.cell}>
        <Typography className={classes.text}>{issueType.type}</Typography>
      </Grid>
      <Grid item container xs={6} justifyContent="flex-end">
        {hasAuthority(account.user, account.permissions, selectedRelTenant, [
          "ISSUE_TYPE_ADMIN",
        ]) && (
          <Tooltip title={t("issueType.modify").toString()}>
            <IconButton
              component={Link}
              to={`/issues/issue-type/issue-type-modify?id=${issueType.id}`}
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default IssueTypeRow;
