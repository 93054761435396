import {
  Box,
  Button,
  Collapse,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, ExpandLess, ExpandMore, VisibilityOutlined } from "@material-ui/icons";
import DownloadIcon from "@material-ui/icons/GetApp";
import { COMMENT_TYPE } from "config/constants";
import { COLORS } from "config/theme";
import { format as dateFormat } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { listPublicComment } from "shared/network/comment.api";
import { getPublicFileByPath } from "shared/network/file.api";
import { Comment } from "shared/types";
import { fileDownload } from "shared/util/fileDownload";
import CommentCreate from "./PublicCommentCreate";

type Props = {
  comment: Comment;
  subjectType?: any;
  subjectId: any;
  email: string;
  token: string;
  tenantId: number;
  reportId: number;
};

const useStyles = makeStyles({
  secondaryNote: {
    color: COLORS.lightGrey,
    fontSize: 15,
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "&.Mui-expanded": {
      "&:before": {
        opacity: "unset",
      },
      "& > .MuiAccordionSummary-root": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      },
      "& + .MuiAccordion-root": {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
  },
  accordionDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 0 0 16px",
  },
  createButton: {
    height: 36,
    margin: "16px",
  },
});

const PublicCommentRow = ({
  comment,
  subjectType,
  subjectId,
  email,
  token,
  tenantId,
  reportId,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [openId, setOpenId] = useState<boolean>(false);

  const commentListQuery = useQuery(
    ["issueCommentList", token, email, tenantId, comment.id, reportId],
    async () => {
      const { data } = await listPublicComment(
        token,
        email,
        tenantId,
        "COMMENT",
        comment.id,
        reportId,
      );
      return data.items;
    },
    { enabled: open },
  );
  const comments = commentListQuery.data;

  return (
    <>
      <CommentCreate
        tenantId={tenantId}
        token={token}
        email={email}
        subjectType="COMMENT"
        subjectId={comment?.id.toString()}
        open={openId}
        setOpen={setOpenId}
        refetch={commentListQuery.refetch}
      />

      <Box width="100%" borderBottom="1px solid rgba(0, 0, 0, 0.12)" pb={1} pt={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between" pb={1}>
          <Typography className={classes.secondaryNote}>
            {`${t("comment.updatedBy")} ${comment.uploaderName} ${t(
              "comment.updatedByExtendHun",
            )} ${t("comment.on")} ${
              comment.createdOn &&
              " (" + dateFormat(new Date(comment.createdOn), "yyyy. MM. dd. HH:mm") + ")"
            }`}
          </Typography>
          <Box display="flex" alignItems="center" gridGap={8}>
            <Typography className={classes.secondaryNote}>
              {t("comment.subCommentCount", {
                count: comment.childrenCount > 0 ? comment.childrenCount : t("comment.none"),
              })}
            </Typography>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </Box>
        <Box pb={1}>
          <Typography>{comment.note}</Typography>
        </Box>
        {comment.type === COMMENT_TYPE[0] && (
          <Box display="flex" alignItems="center" gridGap={4}>
            <Typography
              className={classes.secondaryNote}
              style={{ fontSize: 12, paddingBottom: 4 }}
            >
              {comment.originalFileName}
            </Typography>
            <Tooltip title={t("comment.download").toString()}>
              <IconButton
                className={classes.secondaryNote}
                size="small"
                color="primary"
                onClick={async event => {
                  event.preventDefault();
                  if (comment.id) {
                    try {
                      const { data } = await getPublicFileByPath(
                        comment.filePath,
                        tenantId,
                        email,
                        token,
                        reportId,
                      );
                      fileDownload(data, `${t("common:pdfDownload.COMMENT")}_${comment.id}`);
                    } catch (e) {
                      enqueueSnackbar(
                        t("common:notification.download.failure", {
                          subject: t("comment.subject"),
                        }),
                        {
                          variant: "error",
                        },
                      );
                    }
                  }
                }}
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("Megtekintés").toString()}>
              {comment.filePath.endsWith(".pdf") ? (
                <IconButton
                  className={classes.secondaryNote}
                  size="small"
                  color="primary"
                  onClick={async event => {
                    event.preventDefault();
                    if (comment.id) {
                      try {
                        const { data } = await getPublicFileByPath(
                          comment.filePath,
                          tenantId,
                          email,
                          token,
                          reportId,
                        );
                        const file = new Blob([data], { type: "application/pdf" });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                      } catch (e) {
                        enqueueSnackbar(
                          t("common:notification.download.failure", {
                            subject: t("comment.subject"),
                          }),
                          {
                            variant: "error",
                          },
                        );
                      }
                    }
                  }}
                >
                  <VisibilityOutlined fontSize="small" />
                </IconButton>
              ) : (
                <></>
              )}
            </Tooltip>
          </Box>
        )}
      </Box>
      <Collapse in={open}>
        <Box display="flex" justifyContent="center">
          <Button
            color="primary"
            size="small"
            variant="contained"
            startIcon={<AddBox />}
            className={classes.createButton}
            onClick={() => setOpenId(true)}
          >
            {t("comment.create")}
          </Button>
        </Box>

        {!!comments?.length &&
          comments.map(comment => {
            return (
              <Box pl={4}>
                <PublicCommentRow
                  key={comment.id}
                  comment={comment}
                  subjectType={subjectType}
                  subjectId={subjectId}
                  email={email}
                  token={token}
                  tenantId={tenantId}
                  reportId={reportId}
                />
              </Box>
            );
          })}
      </Collapse>
    </>
  );
};

export default PublicCommentRow;
