import { Box, Button, Grid, Typography } from "@material-ui/core";
import { AddBox, ArrowBack, ArrowForward } from "@material-ui/icons";
import FormCard from "components/FormCard";
import queryClient from "config/query";
import { RootState } from "config/store";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DailyReport } from "shared/network/daily-report.api";
import { RelDailyReportIssue } from "shared/network/rel-daily-report-issue.api";
import DailyReportCreateIssueRow from "./DailyReportCreateIssueRow";

type Props = {
  dailyReport: DailyReport | null;
  relDailyReportIssues: RelDailyReportIssue[] | null;
  setDailyReport: Dispatch<SetStateAction<DailyReport | null>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  isClosed: boolean;
};

const DailyReportCreatePage2 = ({
  dailyReport,
  relDailyReportIssues,
  setActiveStep,
  isClosed,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const tenant = useSelector((state: RootState) => state?.authentication?.selectedRelTenant);
  const form = useFormContext();
  const { control, setValue } = form;

  const fieldArray = useFieldArray({
    control,
    name: "issues",
    keyName: "key",
  });

  async function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    if (!id) {
      history.replace(`/my-company/daily-report/create?id=${dailyReport?.id}`);
    }
    queryClient.refetchQueries([`getInitDailyReport`, dailyReport?.id?.toString(), tenant?.id]);
  }

  async function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    if (!id) {
      history.replace(`/my-company/daily-report/create?id=${dailyReport?.id}`);
    }
    queryClient.refetchQueries([`getInitDailyReport`, dailyReport?.id?.toString(), tenant?.id]);
  }

  useEffect(() => {
    setValue("issues", relDailyReportIssues || []);
  }, [relDailyReportIssues, setValue]);

  return (
    <Box>
      <FormCard title={t("dailyReport.tab2Title")}>
        <Grid container spacing={2}>
          {fieldArray?.fields?.length > 0 ? (
            fieldArray?.fields?.map((field, index) => (
              <DailyReportCreateIssueRow
                key={field.key}
                index={index}
                dailyReport={dailyReport}
                fieldArray={fieldArray}
                isClosed={isClosed}
              />
            ))
          ) : (
            <Box style={{ textAlign: "center", width: "100%", paddingBottom: 8 }}>
              <Typography>{t("dailyReport.noIssue")}</Typography>
            </Box>
          )}
        </Grid>
        {!isClosed && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" width="100%" mt={2}>
              <Button
                startIcon={<AddBox />}
                onClick={event => {
                  fieldArray.append({});
                }}
              >
                {t("dailyReport.addIssue")}
              </Button>
            </Box>
          </Grid>
        )}
      </FormCard>
      <Box display="flex" justifyContent="center" gridGap={8} p={1.5}>
        <Button startIcon={<ArrowBack />} variant="outlined" onClick={handleBack}>
          {t("common:button.prev")}
        </Button>
        <Button endIcon={<ArrowForward />} variant="contained" color="primary" onClick={handleNext}>
          {t("common:button.next")}
        </Button>
      </Box>
    </Box>
  );
};

export default DailyReportCreatePage2;
