import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Block, CheckCircleOutline, Edit } from "@material-ui/icons";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useHeader } from "components/Layout/HeaderContext";
import SingleQueryTable from "components/PageableTable/SingleQueryTable";
import { Link } from "components/Router";
import { RootState } from "config/store";
import { COLORS } from "config/theme";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listArticles } from "shared/network/article.api";
import { TableState } from "views/Company/CompanyList";
import { useGetSessionStorageKey } from "views/Comment/function";

const ListArticle = () => {
  const { t } = useTranslation();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const [tableState, setTableState] = React.useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey(`rap-list-article-page-number`)) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey(`rap-list-article-page-size`)) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });

  const { setHeaderButtons } = useHeader();

  const articleQuery = useQuery(["articles"], async () => {
    const { data } = await listArticles(tenant.id);
    return data;
  });

  useEffect(() => {
    setHeaderButtons(
      <Box display="flex" gridGap={12}>
        <Button component={Link} to="/admin/articles/article-create">
          <FontAwesomeIcon
            icon={faNewspaper}
            style={{
              fontSize: "20px",
              marginRight: 8,
            }}
          />
          {t("article.create")}
        </Button>
        {/*Export gomb*/}
        {/*<Button
            variant="outlined"
            startIcon={<GetApp />}
            style={{ margin: "auto" }}
          >
            {t("common:button.export")}
          </Button>*/}
      </Box>,
    );
    return () => {
      setHeaderButtons(null);
    };
  }, []); //eslint-disable-line

  const columns: GridColDef[] = [
    {
      field: "subjectType",
      headerName: t("article.formValues.subjectType"),
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: "isActive",
      headerName: t("article.formValues.isActive"),
      headerAlign: "center",
      flex: 1,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Grid item xs={12} sm={1} container justifyContent="center">
          {row.isActive ? (
            <Tooltip title={t("article.formValues.active").toString()}>
              <CheckCircleOutline style={{ color: COLORS.green }} />
            </Tooltip>
          ) : (
            <Tooltip title={t("article.formValues.notActive").toString()}>
              <Block style={{ color: COLORS.red }} />
            </Tooltip>
          )}
        </Grid>
      ),
    },
    {
      field: "actions",
      headerName: " ",
      flex: 0.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Tooltip title={t("article.modify").toString()}>
            <IconButton
              component={Link}
              to={`/admin/articles/article-modify?id=${row.id}`}
              size="small"
              color="primary"
              style={{ margin: "0 8px" }}
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  return (
    <Container maxWidth="xs">
      <SingleQueryTable
        query={articleQuery}
        columns={columns}
        setTableState={setTableState}
        tableState={tableState}
        sessionStorageKey={`articleList_${tenant.id}`}
      />
    </Container>
  );
};

export default ListArticle;
