import { Box, Button, Card, Container, Grid, Tooltip, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { RootState } from "config/store";
import { endOfMonth, format, getDaysInMonth, setDate, startOfMonth } from "date-fns";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getChecklistItemPage } from "shared/network/haccp-checklist.api";
import { getSubDepartmentPage, subDepartmentPdfExport } from "shared/network/sub-department.api";
import HaccpDepartmentCheckInputCell from "./HaccpDepartmentCheckInputCell";
import { getCheckSubDepartmentPage } from "shared/network/check-sub-department.api";
import { PictureAsPdf } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useState } from "react";
import Loading from "components/Loading";

const HaccpDepartmentCheck = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const form = useForm<any>();
  const { control, watch } = form;
  const date = watch("date");
  const haccpItem = watch("haccpItem");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const checkSubDepartmentPageQuery = useQuery(
    ["checkSubDepartmentPageQuery", tenant.id, date],
    async () => {
      const { data } = await getCheckSubDepartmentPage(
        0,
        2000,
        tenant.id,
        haccpItem
          ? `date>=${startOfMonth(date).toISOString()};date<=${endOfMonth(date).toISOString()}`
          : "",
      );
      return data?.page?.content || [];
    },
  );

  const subDepartmentPageQuery = useQuery(["subDepartmentPageQuery", tenant.id], async () => {
    const { data } = await getSubDepartmentPage(0, 2000, tenant.id, "", "name");
    return data?.page?.content || [];
  });

  return (
    <Container>
      <Loading open={downloadLoading} />
      <Card style={{ padding: 16, overflow: "auto" }}>
        <FormProvider {...form}>
          <form>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Controller
                  control={control}
                  name="date"
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <KeyboardDatePicker
                      {...field}
                      ref={undefined}
                      className="date"
                      variant="inline"
                      label={t("haccpItem.date")}
                      format="yyyy.MM."
                      InputLabelProps={{ shrink: true }}
                      autoOk
                      views={["year", "month"]}
                      openTo="month"
                      style={{ width: "100%" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2} style={{ display: "flex" }}>
                <Tooltip title="Az összes hűtő ellenőrzésének letöltése PDF-ben, a kiválasztott hónapra.">
                  <Button
                    fullWidth
                    startIcon={<PictureAsPdf />}
                    onClick={async () => {
                      try {
                        setDownloadLoading(true);
                        if (date) {
                          const { data } = await subDepartmentPdfExport(
                            format(date, "yyyy"),
                            format(date, "M"),
                            tenant?.id,
                          );
                          const fileName = data?.item?.fileName || "";
                          let a = document.createElement("a");
                          a.setAttribute("download", fileName);
                          a.setAttribute(
                            "href",
                            "data:application/pdf;base64," + data.item.content,
                          );
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);
                          enqueueSnackbar("PDF letöltése sikeres!", {
                            variant: "success",
                          });
                        }
                      } catch (error: any) {
                        if (error?.data?.status === "NOT_FOUND") {
                          enqueueSnackbar(t("A PDF nem található!"), {
                            variant: "error",
                          });
                        } else {
                          enqueueSnackbar(t("PDF letöltése sikertelen!"), {
                            variant: "error",
                          });
                        }
                      }
                      setDownloadLoading(false);
                    }}
                  >
                    PDF letöltése
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        {!!getDaysInMonth(date) && (
          <Box
            display="flex"
            justifyContent="center"
            p={2}
            minWidth={((subDepartmentPageQuery.data?.length || 0) + 1) * 110}
          >
            <Box width={110}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={1}
                width="100%"
                style={{
                  borderBottom: "1px solid lightgrey",
                  borderRight: "1px solid lightgrey",
                  height: 57,
                }}
              >
                <Typography style={{ fontWeight: "bold", fontSize: 18 }}>{"Nap"}</Typography>
              </Box>
              {Array.from(Array(getDaysInMonth(date))).map((_, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    borderBottom: "1px solid lightgrey",
                    borderRight: "1px solid lightgrey",
                    padding: 8,
                    height: 57,
                  }}
                >
                  <Typography style={{ fontSize: 18, fontWeight: "bold" }}>{index + 1}.</Typography>
                </Box>
              ))}
            </Box>
            {subDepartmentPageQuery.data?.map(subDepartment => {
              const checkSubDepartments = checkSubDepartmentPageQuery.data?.filter(
                value => value.subDepartmentId === subDepartment.id,
              );

              return (
                <Box
                  key={subDepartment.id}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    width="100%"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      height: 57,
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 18, textAlign: "center" }}>
                      {subDepartment.name}
                    </Typography>
                  </Box>
                  {Array.from(Array(getDaysInMonth(date))).map((_, index) => {
                    const checkSubDepartment = checkSubDepartments?.find(value => {
                      return (
                        format(setDate(new Date(date), index + 1), "yyyy-MM-dd") ===
                        format(new Date(value.date), "yyyy-MM-dd")
                      );
                    });

                    return (
                      <HaccpDepartmentCheckInputCell
                        key={index}
                        index={index}
                        subDepartment={subDepartment}
                        date={date}
                        checkSubDepartment={checkSubDepartment}
                      />
                    );
                  })}
                </Box>
              );
            })}
            <Box width={150}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={1}
                width="100%"
                style={{
                  borderBottom: "1px solid lightgrey",
                  borderLeft: "1px solid lightgrey",
                  height: 57,
                }}
              >
                <Typography style={{ fontWeight: "bold", fontSize: 18 }}>Ellenőrzés</Typography>
              </Box>
              {Array.from(Array(getDaysInMonth(date))).map((_, index) => {
                const checkSubDepartment = checkSubDepartmentPageQuery.data?.find(value => {
                  return (
                    format(setDate(new Date(date), index + 1), "yyyy-MM-dd") ===
                    format(new Date(value.date), "yyyy-MM-dd")
                  );
                });

                return (
                  <Box
                    key={index}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderBottom: "1px solid lightgrey",
                      borderLeft: "1px solid lightgrey",
                      padding: 8,
                      height: 57,
                    }}
                  >
                    <HaccpDepartmentCheckInputCell
                      key={index}
                      index={index}
                      date={date}
                      checkSubDepartment={checkSubDepartment}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Card>
    </Container>
  );
};
export default HaccpDepartmentCheck;
