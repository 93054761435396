import axios from "config/axios";
import { GenericListResponse, GenericPageResponse } from "shared/common";

const ENDPOINT = "/haccp-checklist";

export const createChecklistItem = (param: any, tenantId: number) =>
  axios.post(ENDPOINT, { param, tenantId });

export const getChecklistItemPage = (
  page: number,
  size: number,
  tenantId: number,
  search?: string,
  sort?: string,
) =>
  axios.get<GenericPageResponse<any>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search || ""}&sort=${
      sort || ""
    }`,
  );

export const checkLate = (tenantId: number) =>
  axios.get<GenericListResponse<any>>(`${ENDPOINT}/check-late?tenantId=${tenantId}`);

export const checklistPdfExport = (year: string, month: string, tenantId: number) =>
  axios.get(`${ENDPOINT}/check-list-by-month?tenantId=${tenantId}&year=${year}&month=${month}`);
