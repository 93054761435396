import { Box, CircularProgress, Container } from "@material-ui/core";
import { RootState } from "config/store";
import format from "date-fns/format";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { SliceStatus } from "shared/common";
import { getPersonalDataById, modifyEmployeePersonalData } from "shared/network/personaldata.api";
import PersonalDataForm, { PersonalDataFormValues } from "./PersonalDataForm";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";

type Params = {};
type Props = RouteComponentProps<Params>;

const PersonalDataModify = ({ history }: Props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id") || "";
  const personalDataId = query.get("personalDataId") || "";

  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);
  const { t } = useTranslation();

  const [status, setStatus] = useState<SliceStatus>("idle");

  const form = useForm<PersonalDataFormValues>();
  const { enqueueSnackbar } = useSnackbar();

  const personalDataQuery = useQuery(["personalDataQuery", personalDataId], async () => {
    const { data } = await getPersonalDataById(personalDataId, tenant.id);
    return data.item;
  });

  const onSubmitModify = async (values: PersonalDataFormValues) => {
    if (personalDataQuery.data) {
      try {
        setStatus("pending");
        await modifyEmployeePersonalData({
          id: personalDataId,
          filePath: personalDataQuery.data.filePath,
          originalFileName: personalDataQuery.data.originalFileName,
          type: values.type,
          employeeId: id,
          tenantId: tenant.id,
          comment: values.comment,
          status: values.status,
          validTo: format(new Date(values.validTo), "yyyy-MM-dd"),
          name: values.name,
          timeOfObtaining: values.timeOfObtaining
            ? format(new Date(values.timeOfObtaining), "yyyy-MM-dd")
            : "",
          placeOfObtaining: values.placeOfObtaining,
        });
        setStatus("success");
        enqueueSnackbar(
          t("common:notification.create.success", { subject: t("personalData.subject") }),
          { variant: "success" },
        );
        history.goBack();
      } catch (e: any) {
        setStatus("failure");
        if (e.data?.status === "FILE_NOT_FOUND") {
          enqueueSnackbar(
            t("common:notification.create.success", { subject: t("personalData.subject") }),
            { variant: "success" },
          );
        }
      }
    }
  };

  return (
    <Container maxWidth="lg">
      <form onSubmit={form.handleSubmit(onSubmitModify)}>
        {status === "pending" || personalDataQuery.isFetching ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <CircularProgress />
          </Box>
        ) : (
          <FormProvider {...form}>
            <PersonalDataForm personalData={personalDataQuery.data} />
          </FormProvider>
        )}
      </form>
    </Container>
  );
};

export default PersonalDataModify;
