import axios from "config/axios";
import { Company, Item, Project } from "shared/types";
import { GenericPageResponse, GenericResponse } from "../common";

export type UsedProjectItem = {
  project: Partial<Project>;
  company: Partial<Company>;
  item: Partial<Item>;
  quantity: string;
  price: string;
  netPrice: string;
  discountPrice: string;
  monthlyRentalPrice?: string;
  day?: string;
};

const ENDPOINT = "/used-project-item";

export const createUsedProjectItem = (param: Partial<UsedProjectItem>, tenantId: number) =>
  axios.post<GenericResponse<UsedProjectItem>>(`${ENDPOINT}`, { params: [param], tenantId });

export const modifyUsedProjectItem = (param: UsedProjectItem, tenantId: number) =>
  axios.put<GenericResponse<UsedProjectItem>>(`${ENDPOINT}`, { param, tenantId });

export const deleteUsedProjectItem = (id: number, tenantId: number) =>
  axios.delete<GenericResponse<UsedProjectItem>>(`${ENDPOINT}?id=${id}&tenantId=${tenantId}`);

export const getUsedProjectItemPage = (
  tenantId: number,
  page: number = 0,
  size: number = 20,
  search: string = "",
) =>
  axios.get<GenericPageResponse<UsedProjectItem>>(
    `${ENDPOINT}/all?tenantId=${tenantId}&page=${page}&size=${size}&search=${search}`,
  );
