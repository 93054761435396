import { Box, Button, Container, Grid, MenuItem, TextField } from "@material-ui/core";
import { WORKSHEET_TYPE } from "config/constants";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RelCustomFieldWorksheetEntry, Worksheet } from "shared/types";

export type WorksheetFormValues = {
  id?: number;
  tenantId: number;
  name: string;
  prefix: string;
  type: string;
  relCustomFields: RelCustomFieldWorksheetEntry[];
};

type Props = {
  worksheet?: Worksheet;
  setOpen?: any;
  setSelectedReason?: any;
};

const TenantWorksheetForm = ({ worksheet, setOpen, setSelectedReason }: Props) => {
  const { t } = useTranslation();
  const { control, reset } = useFormContext<WorksheetFormValues>();

  function onClose() {
    setOpen(false);
    setSelectedReason(null);
  }

  useEffect(() => {
    reset({
      name: worksheet?.name || "",
      id: worksheet?.id || undefined,
      tenantId: undefined,
      prefix: worksheet?.prefix,
      type: worksheet?.type,
    });
  }, [worksheet]); //eslint-disable-line

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="name"
            defaultValue={worksheet?.name || ""}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("issues.worksheet.new")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="prefix"
            defaultValue={worksheet?.prefix || ""}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("issues.worksheet.prefix")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="type"
            defaultValue={worksheet?.type || ""}
            render={({ field }) => (
              <TextField
                select
                {...field}
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
                label={t("issues.worksheet.type")}
              >
                <MenuItem disabled value="">
                  {t("common:choose")}
                </MenuItem>
                :
                {WORKSHEET_TYPE.map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" m={2} gridGap={8} style={{ width: "100%" }}>
            <Button color="primary" variant="text" onClick={() => onClose()}>
              {t("common:button.cancel")}
            </Button>
            <Button type="submit" color="primary">
              {worksheet ? t("common:button.save") : t("common:button.create")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default TenantWorksheetForm;
