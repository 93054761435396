import { Card, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Add, AddAlarm, Assignment, CalendarToday, Info, Person, Title } from "@material-ui/icons";
import { RootState } from "config/store";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { hasAuthority } from "shared/authorization";
import { updateIssueStatus } from "shared/network/issues.api";
import { Issue } from "shared/types";

type Props = {
  issue: Issue;
  refetch: () => void;
  setCreateOpen: Dispatch<SetStateAction<boolean>>;
  setModalIssue: Dispatch<SetStateAction<Issue | undefined>>;
  length: number;
};

const KanbanItem = ({ issue, refetch, length, setCreateOpen, setModalIssue }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const startDate = new Date(issue.startDate);
  const endDate = new Date(issue.endDate);
  const today = new Date();

  const { account, selectedRelTenant } = useSelector((state: RootState) => state.authentication);

  const [_, drag, preview] = useDrag(() => ({
    type: "card",
    item: { issueItem: issue },
    end: async (item, monitor) => {
      const dropResult: {
        issue: Issue;
      } | null = monitor.getDropResult();
      if (item && dropResult) {
        let tempIssue = item.issueItem;
        if (dropResult.issue.status !== tempIssue.status && tempIssue && dropResult.issue.status) {
          changeIssueStatus(tempIssue, dropResult.issue.status);
        }
      }
    },
    canDrag: true,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver }, drop] = useDrop({
    accept: "card",
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
    drop: () => {
      return { issue };
    },
  });

  const changeIssueStatus = async (value: Issue, status: string) => {
    try {
      await updateIssueStatus(value.id, status, selectedRelTenant.tenant.id);
      refetch();
    } catch (e) {
      enqueueSnackbar(t("common:notification.DEFAULT"), { variant: "error" });
    }
  };

  return (
    <>
      {issue.id === "" ? (
        <Grid
          container
          ref={drop}
          style={{ minHeight: 130 * length, background: isOver ? "lightgreen" : "" }}
        />
      ) : (
        <Card
          elevation={0}
          ref={(element: HTMLDivElement) => {
            drag(element);
            preview(element);
          }}
          style={{
            backgroundColor: isOver
              ? "lightgreen"
              : endDate < today
              ? "#ffcdd7"
              : startDate < today && endDate > today
              ? "#fee6b4"
              : "#dcffc2",
            margin: 8,
            padding: 4,
          }}
        >
          <Grid container xs={12} ref={drop}>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: "bold", margin: 5 }}>{`#${issue.id}`}</Typography>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={9}>
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "ISSUE_GET",
              ]) && (
                <Tooltip title={t("timeEntry.title").toString()}>
                  <IconButton
                    size="small"
                    color="primary"
                    component={Link}
                    to={`/issues/kanban/time-entry-create?id=${issue.id}`}
                  >
                    <AddAlarm style={{ width: 20, height: 20 }} />
                  </IconButton>
                </Tooltip>
              )}
              {hasAuthority(account.user, account.permissions, selectedRelTenant, [
                "ISSUE_GET",
              ]) && (
                <Tooltip title={t("issues.issueDetails").toString()}>
                  <IconButton
                    component={Link}
                    to={`/issues/kanban/issue-details?id=${issue.id}`}
                    size="small"
                  >
                    <Assignment color="primary" style={{ width: 20, height: 20 }} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={"Kapcsolódó feladat hozzádása"}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => {
                    setCreateOpen(true);
                    setModalIssue(issue);
                  }}
                >
                  <Add style={{ width: 20, height: 20 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={issue.description}>
                <Info color="primary" style={{ width: 20, height: 20, marginTop: 6 }} />
              </Tooltip>
            </Grid>
            <Grid item xs={1}>
              <Tooltip title={t("issues.formValues.name").toString()}>
                <Title style={{ width: 20, height: 20 }} />
              </Tooltip>
            </Grid>
            <Grid item xs={11}>
              <Typography style={{ marginLeft: 10 }}>{issue.name}</Typography>
            </Grid>
            {(selectedRelTenant.isTenantAdmin || account.user.isSuperAdmin) && (
              <>
                <Grid item xs={1}>
                  <Tooltip title={t("issues.formValues.assignedUser").toString()}>
                    <Person style={{ width: 20, height: 20 }} />
                  </Tooltip>
                </Grid>
                <Grid item xs={11}>
                  <Typography style={{ marginLeft: 10 }}>
                    {issue.assignedUser?.name || t("common:noAssignedUser")}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={1}>
              <Tooltip
                title={
                  t("issues.formValues.startDate").toString() +
                  " - " +
                  t("issues.formValues.endDate").toString()
                }
              >
                <CalendarToday style={{ width: 20, height: 20 }} />
              </Tooltip>
            </Grid>
            <Grid item xs={10} style={{ marginLeft: 10 }}>
              <Typography>
                {`${format(new Date(issue.startDate), "yyyy.MM.dd")} - ${format(
                  new Date(issue.endDate),
                  "yyyy.MM.dd",
                )}`}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default KanbanItem;
