import axios from "config/axios";

const ENDPOINT = "/file";

export const getFileByPath = (param: string, tenantId: number) =>
  axios.post(
    ENDPOINT,
    { param, tenantId },
    {
      responseType: "blob",
    },
  );

export const getPublicFileByPath = (
  param: string,
  tenantId: number,
  email: string,
  token: string,
  reportId: any,
) =>
  axios.post(
    `${ENDPOINT}/report?email=${email}&token=${token}&reportId=${reportId}`,
    { param, tenantId },
    {
      responseType: "blob",
    },
  );
