import axios from "config/axios";
import { GenericPageResponse } from "shared/common";
import { GenericResponse, MonitoringTransactionData } from "shared/types";

const ENDPOINT = "/monitoring-transaction";

export const getMonitoringTransactionDataById = (
  param: number,
  tenantId: number,
) =>
  axios.post<GenericResponse<MonitoringTransactionData>>(
    `${ENDPOINT}/get-by-id`,
    {
      param,
      tenantId,
    },
  ); //MONITORING_GET

export const getMonitoringTransactionDataPageable = (
  page: number,
  size: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<MonitoringTransactionData>>(
    `${ENDPOINT}/all?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}`,
  ); //MONITORING_GET

export const getMonitoringDepositorPage = (
  page: number,
  size: number,
  companyId: number,
  tenantId: number,
  search: string = "",
  sort: string = "",
) =>
  axios.get<GenericPageResponse<MonitoringTransactionData>>(
    `${ENDPOINT}/depositor-page?page=${page}&size=${size}&tenantId=${tenantId}&search=${search}&sort=${sort}&companyId=${companyId}`,
  ); //MONITORING_DEPOSITOR_GET

export const sendMonitoringReports = (
  clientId: string,
  machineId: string,
  type: string,
  from: string,
  until: string,
  fileType: string,
  tenantId: number,
) =>
  axios.post(`${ENDPOINT}/report-export`, {
    clientId,
    machineId,
    type,
    from,
    until,
    fileType,
    tenantId,
  }); //MONITORING_GET
