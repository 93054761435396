import { Box, Button, Container, IconButton, Tooltip } from "@material-ui/core";
import { AddBox, Assignment, Edit } from "@material-ui/icons";
import { GridCellParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import PageableTable from "components/PageableTable/PageableTable";
import { RootState } from "config/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getHaccpItemPage } from "shared/network/haccp-item.api";
import { useGetSessionStorageKey } from "views/Comment/function";
import { TableState } from "views/DeliveryRound/DeliveryRoundList";
import HaccpItemModal from "./HaccpItemDialog";
import { deburr } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";

const HaccpItem = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tenant } = useSelector((state: RootState) => state.authentication.selectedRelTenant);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [sortState, setSortState] = useState("");
  const [tableState, setTableState] = useState<TableState>({
    page: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-haccp-item-page-number")) ||
        JSON.stringify(0),
    ),
    pageSize: parseInt(
      window.sessionStorage.getItem(useGetSessionStorageKey("rap-haccp-item-page-size")) ||
        JSON.stringify(10),
    ),
    filterOpen: false,
    filterValue: "",
  });
  const { page, pageSize, filterValue } = tableState;

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("haccpItem.name"),
      flex: 1,
    },
    {
      field: "workingTemperature",
      headerName: t("haccpItem.workingTemperature"),
      flex: 1,
    },
    {
      field: "status",
      headerName: t("haccpItem.status.label"),
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => t(`haccpItem.status.${row.status}`),
    },
    {
      field: "actions",
      headerName: " ",
      width: 160,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          <Tooltip title={t("common:button.qrDownload")}>
            <a
              href={`data:image/png;base64, ${row?.qrLinkBase64}`}
              download={"qr-" + deburr(row?.name).replaceAll(" ", "_")}
            >
              <IconButton color="primary">
                <FontAwesomeIcon
                  icon={faQrcode}
                  style={{
                    fontSize: 24,
                    width: 24,
                  }}
                />
              </IconButton>
            </a>
          </Tooltip>
          <IconButton
            color="primary"
            onClick={() => history.push(`/haccp-item/details?id=${row?.id}`)}
          >
            <Assignment />
          </IconButton>
          <IconButton color="primary" onClick={() => setSelectedRow(row)}>
            <Edit />
          </IconButton>
        </Box>
      ),
    },
  ];

  const haccpItemPageQuery = useQuery(
    ["haccpItemPageQuery", page, pageSize, tenant.id, filterValue, sortState],
    async () => {
      const { data } = await getHaccpItemPage(page, pageSize, tenant.id, filterValue, sortState);
      return data;
    },
  );

  function onCellClick(params: GridCellParams) {
    if (params.field !== "actions" && params?.row?.id) {
      history.push(`/haccp-item/details?id=${params?.row?.id}`);
    }
  }

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap={16}
      >
        <Box>
          <Button startIcon={<AddBox />} onClick={() => setOpen(true)}>
            {t("haccpItem.create")}
          </Button>
        </Box>
        <HaccpItemModal
          open={open}
          onClose={() => setOpen(false)}
          defaultValues={selectedRow}
          isModify={false}
        />
        <HaccpItemModal
          open={selectedRow}
          onClose={() => setSelectedRow(null)}
          defaultValues={selectedRow}
          isModify
        />
        <PageableTable
          sortState={sortState}
          setSortState={setSortState}
          sessionStorageKey="haccp-item"
          tableState={tableState}
          setTableState={setTableState}
          columns={columns}
          query={haccpItemPageQuery}
          onCellClick={onCellClick}
          minWidth={{ value: "md" }}
        />
      </Box>
    </Container>
  );
};

export default HaccpItem;
